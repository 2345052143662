import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Input } from 'antd';
import classnames from 'classnames';

import { passwordRule, requiredRule } from 'utils/forms';
import { AT_Least_ONE_DIGIT, AT_Least_EIGHT_LETTERS } from 'constants/Forms';

import { ReactComponent as Check } from 'assets/icons/Circle-Check.svg';
import { ReactComponent as Clear } from 'assets/icons/Circle-clear.svg';

import './BRPasswordInput.less';

const BRPasswordInput = ({ intl, formRef, label, placeholder, ...props }) => {
  const [focusCount, setFocusCount] = useState(0);
  const [showPasswordValidation, setShowPasswordValidation] = useState({
    atLeastEightChars: false,
    containsNumber: false,
    showBothFields: false,
    showSuccessValidationMessage: false
  });

  const checkPasswordValidation = ({ target: { value } }) => {
    const atLeastEightChars = AT_Least_EIGHT_LETTERS.test(value);
    const containsNumber = AT_Least_ONE_DIGIT.test(value);
    setShowPasswordValidation({
      atLeastEightChars,
      containsNumber,
      showBothFields: !containsNumber || !atLeastEightChars,
      showSuccessValidationMessage: containsNumber && atLeastEightChars
    });
    setFocusCount(focusCount + 1);
  };

  const handleShowValidationMessages = () => {
    const passwordValue = formRef.current?.getFieldValue('password');
    setShowPasswordValidation({
      ...showPasswordValidation,
      showBothFields:
        focusCount === 0 ? true : showPasswordValidation.showBothFields,
      showSuccessValidationMessage: !showPasswordValidation.showBothFields
    });
    setFocusCount(focusCount === 0 && !passwordValue ? 0 : focusCount + 1);
  };

  const handleHideValidationMessages = () => {
    setShowPasswordValidation({
      ...showPasswordValidation,
      showSuccessValidationMessage: false
    });
  };

  return (
    <Form.Item
      name="password"
      rules={[
        requiredRule(
          intl.formatMessage(
            {
              id: 'form.required_custom'
            },
            {
              label: intl.formatMessage({
                id: 'sign_up.form_labels.password'
              })
            }
          )
        ),
        passwordRule()
      ]}
      label={label}
      className={classnames('br-password-input')}
      help={
        showPasswordValidation.showBothFields ? (
          <>
            <div
              className={classnames(
                'caption',
                'br-password-input__validation',
                {
                  'br-password-input__validation--is-valid':
                    showPasswordValidation.atLeastEightChars && focusCount !== 0
                },
                {
                  'br-password-input__validation--is-not-valid':
                    !showPasswordValidation.atLeastEightChars &&
                    focusCount !== 0
                }
              )}
            >
              {!showPasswordValidation.atLeastEightChars && focusCount !== 0 ? (
                <Clear />
              ) : (
                <Check />
              )}
              {intl.formatMessage({
                id: 'login.set_new_passowrd.at_least_eight_chars'
              })}
            </div>
            <div
              className={classnames(
                'caption',
                'br-password-input__last-validation',
                {
                  'br-password-input__validation--is-valid':
                    showPasswordValidation.containsNumber && focusCount !== 0
                },
                {
                  'br-password-input__validation--is-not-valid':
                    !showPasswordValidation.containsNumber && focusCount !== 0
                }
              )}
            >
              {!showPasswordValidation.containsNumber && focusCount !== 0 ? (
                <Clear />
              ) : (
                <Check />
              )}
              {intl.formatMessage({
                id: 'login.set_new_passowrd.contains__number'
              })}
            </div>
          </>
        ) : showPasswordValidation.showSuccessValidationMessage ? (
          <div className="br-password-input__validation-success caption">
            <Check />
            {intl.formatMessage({
              id: 'login.set_new_passowrd.validation_success'
            })}
          </div>
        ) : undefined
      }
      hasFeedback
    >
      <Input.Password
        autoComplete="new-password"
        onChange={checkPasswordValidation}
        onFocus={handleShowValidationMessages}
        onBlur={handleHideValidationMessages}
        placeholder={placeholder}
      />
    </Form.Item>
  );
};

export default injectIntl(BRPasswordInput);
