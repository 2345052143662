import dayjs from 'dayjs';
import { useIntl } from 'react-intl';

import {
  getLocaleFromLocalStorage,
  isDashboardArabic
} from 'utils/intl-wrapper';
import { pathLang } from 'utils/helpers';
import { REPORTS_LIST } from 'constants/reports';
import { HOST_NAME } from 'constants/helpers';

import ProgressiveImg from 'components/ProgressiveImage/ProgressiveImage';

import './ReportsList.less';

const ReportsList = () => {
  const language = pathLang || getLocaleFromLocalStorage();
  const intl = useIntl();

  return (
    <div className="br-reports">
      <div className="display-lg-extra">
        {intl.formatMessage({ id: 'bosta_insights.reports.title' })}
      </div>
      <div className="br-reports_cards">
        {REPORTS_LIST.map(
          ({ thumbnail, thumbnailAr, title, titleAr, id, publishDate }) => (
            <div className="br-reports__card">
              <a href={`${HOST_NAME}/${language}/insights/${id}`}>
                <span className="br-reports__card__image">
                  <ProgressiveImg
                    src={
                      isDashboardArabic() ? thumbnailAr || thumbnail : thumbnail
                    }
                    alt="insight image"
                  />
                </span>
              </a>
              <div className="heading">
                {isDashboardArabic() ? titleAr || title : title}
              </div>
              <div className="subtitle-sm">
                {dayjs(publishDate).format('DD MMM, YYYY')}
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ReportsList;
