import ReactQuill from 'react-quill';
import classnames from 'classnames';

import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import './BRRichTextEditor.less';

const BRRichTextEditor = ({
  theme = 'snow',
  className = '',
  value = '',
  onChange = () => {},
  placeholder = '',
  readOnly = false
}) => {
  return (
    <ReactQuill
      theme={theme}
      value={value}
      onChange={onChange}
      className={classnames('br-rich-text-editor', {
        [className]: className
      })}
      placeholder={placeholder}
      modules={modules}
      formats={formats}
      readOnly={readOnly}
    />
  );
};

const modules = {
  toolbar: [
    [{ header: [] }],
    ['bold', 'italic', 'underline', 'strike', 'link'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' }
    ],
    [{ align: [] }, { direction: 'rtl' }],
    [{ color: [] }]
  ]
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'list',
  'bullet',
  'indent',
  'link',
  'align',
  'direction',
  'color'
];

export default BRRichTextEditor;
