import React from 'react';
import { injectIntl } from 'react-intl';

import { PROCESS_DATA } from 'constants/fulfillment';

import Triangle from 'assets/images/fulfillment-triangle.png';

import './FulfillmentProcess.less';

const FulfillmentProcess = ({ intl }) => {
  const { formatMessage } = intl;

  return (
    <div className="br-fulfillment__process-section">
      <p className="br-fulfillment__process-title">
        {formatMessage({
          id: 'bosta_fulfillment.process.title'
        })}
      </p>

      <div className="br-fulfillment__process-album">
        <img
          src={Triangle}
          alt="process direction"
          className={`${
            intl.locale.includes('en')
              ? 'background_vector'
              : 'background_vector-ar'
          }`}
        />
        {PROCESS_DATA.map(({ label, image }) => (
          <div className="br-fulfillment__process-info">
            <img src={image} alt="" />
            <p>{formatMessage({ id: `${label}` })}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default injectIntl(FulfillmentProcess);
