import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Icon from '@ant-design/icons';

import { getStarInfo } from 'services/shipments';
import { DELIVERY_STATES_CODES } from 'constants/states';
import { isDashboardArabic } from 'utils/intl-wrapper';

import PosRequestedModal from '../PosRequestedModal/PosRequestedModal';
import BRButton from 'components/BRButton/BRButton';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import { notify } from 'components/Notify/Notify';
import { openModal } from 'utils/modal';

import { ReactComponent as ProfileIcon } from 'assets/icons/Profile.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone-number.svg';
import { ReactComponent as CardIcon } from 'assets/icons/card-icon.svg';
import { ReactComponent as TooltipIcon } from 'assets/icons/Tooltip.svg';

import './StarInfo.less';

const StarInfo = ({ apiKey, verifyIdentity, trackingDetails }) => {
  const [starInfo, setStarInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const intl = useIntl();

  const isOFD = [
    DELIVERY_STATES_CODES.OUT_FOR_COLLECTION,
    DELIVERY_STATES_CODES.OUT_FOR_DELIVERY,
    DELIVERY_STATES_CODES.OUT_FOR_PICKUP
  ].includes(trackingDetails?.CurrentStatus?.code);

  useEffect(() => {
    if (apiKey && isOFD) {
      fetchStarInfo();
    }
  }, [apiKey, isOFD]);

  const fetchStarInfo = async () => {
    setIsLoading(true);
    try {
      const { data } = await getStarInfo({ token: apiKey });
      setStarInfo(data);
    } catch (error) {
      notify({ msg: error.message });
    }
    setIsLoading(false);
  };

  return (
    <LoadingWrapper loading={isLoading}>
      <div className="br-tracking-details-star-info__container">
        <div>
          <div className="br-tracking-details-star-info__title subheading">
            {intl.formatMessage({ id: 'tracking.star_info.title' })}
          </div>
          <div className="br-tracking-details-star-info__content">
            <Icon
              component={ProfileIcon}
              className="ant-icon-md br-tracking-details__profile-icon"
            />
            <span>{starInfo?.name ?? '***********'}</span>
            <div className="br-tracking-details-star-phone__container">
              <Icon
                component={PhoneIcon}
                className="ant-icon-md br-tracking-details__profile-icon"
              />
              {starInfo?.phone ? (
                <a
                  href={`tel:${starInfo.phone}`}
                  className="br-tracking-details__star-phone"
                >
                  {starInfo.phone}
                </a>
              ) : (
                <span className="br-tracking-details__star-phone-placeholder">
                  {isDashboardArabic() ? '***********01' : '01***********'}
                </span>
              )}
            </div>
          </div>
          <div className="br-tracking-details__star-info-subtitle caption">
            {!isOFD &&
              intl.formatMessage({ id: 'tracking.star_info.subtitle' })}
          </div>

          {trackingDetails?.canRequestPOSMachine && !isOFD && (
            <BRButton
              className="br-tracking__POS-request-btn"
              disabled={!apiKey}
              label={
                <div>
                  <CardIcon />
                  <p className="button">
                    {intl.formatMessage({
                      id: 'tracking.request_pos.btn_text'
                    })}
                  </p>
                </div>
              }
              onClick={() => {
                openModal(PosRequestedModal, {
                  apiKey,
                  trackingNumber: trackingDetails?.TrackingNumber
                });
              }}
            />
          )}

          {!apiKey && (
            <div className="br-date-address-edit__identity-verification">
              <Icon component={TooltipIcon} className="ant-icon-md" />
              <div className="br-date-address-edit__identity-verification-content">
                {intl.formatMessage({
                  id: 'tracking.date_address_edit.identity_verification.subtitle'
                })}
                <BRButton
                  className="button-md"
                  label={intl.formatMessage({
                    id: 'tracking.date_address_edit.identity_verification.action'
                  })}
                  onClick={verifyIdentity}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </LoadingWrapper>
  );
};

export default StarInfo;
