import { fmt } from 'components/IntlWrapper/IntlWrapper';

export const ECOMMERCE_PLUGINS_CONTENT = [
  {
    title: fmt({
      id: 'integrations.ecommerce_plugins.content.secured_linking.title'
    }),
    subtitle: fmt({
      id: 'integrations.ecommerce_plugins.content.secured_linking.subtitle'
    })
  },
  {
    title: fmt({
      id: 'integrations.ecommerce_plugins.content.easy_tracking.title'
    }),
    subtitle: fmt({
      id: 'integrations.ecommerce_plugins.content.easy_tracking.subtitle'
    })
  },
  {
    title: fmt({
      id: 'integrations.ecommerce_plugins.content.quick_actions.title'
    }),
    subtitle: fmt({
      id: 'integrations.ecommerce_plugins.content.quick_actions.subtitle'
    })
  }
];

export const SMART_APIS_CONTENT = [
  {
    title: fmt({
      id: 'integrations.smart_apis.content.various_endpoints.title'
    }),
    subtitle: fmt({
      id: 'integrations.smart_apis.content.various_endpoints.subtitle'
    })
  },
  {
    title: fmt({
      id: 'integrations.smart_apis.content.real_time_tracking.title'
    }),
    subtitle: fmt({
      id: 'integrations.smart_apis.content.real_time_tracking.subtitle'
    })
  }
];
