import TestimonialsCarousel from 'components/TestimonialsCarousel/TestimonialsCarousel';

import './Testimonials.less';

const Testimonials = () => {
  return (
    <section className="br-solutions-testimonials-section">
      <div className="br-solutions-testimonials-container">
        <div className="br-solutions-testimonials-content">
          <div className="br-solutions-testimonials-carousel">
            <TestimonialsCarousel />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
