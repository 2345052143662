import React, { useEffect, useRef } from 'react';
import { injectIntl } from 'react-intl';

import { ECOMMERCE_PLUGINS_CONTENT } from 'constants/integrations';
import { mediaHook } from 'customHooks';
import { renderSVGs } from 'utils/animations';
import { intersectionObserver } from 'utils/animations';

import Grid from 'components/Grid/Grid';

import { ReactComponent as SecuredLinking } from 'assets/icons/SecuredLinking.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/HomeLocationIcon.svg';
import { ReactComponent as QuickActions } from 'assets/icons/QuickActions.svg';
import { ReactComponent as PluginsMainImage } from 'assets/images/PluginsMain.svg';
import { ReactComponent as PluginsSVG1 } from 'assets/images/PluginsSVG1.svg';
import { ReactComponent as PluginsSVG2 } from 'assets/images/PluginsSVG2.svg';
import { ReactComponent as PluginsSVG3 } from 'assets/images/PluginsSVG3.svg';
import { ReactComponent as PluginsSVG4 } from 'assets/images/PluginsSVG4.svg';
import { ReactComponent as PluginsSVG5 } from 'assets/images/PluginsSVG5.svg';
import { ReactComponent as PluginsSVG6 } from 'assets/images/PluginsSVG6.svg';

import './PluginsSection.less';

const ECOMMERCE_PLUGINS_ICONS = [LocationIcon, SecuredLinking, QuickActions];

const PLUGINS_SVGS = [
  {
    Vector: PluginsSVG1,
    from: '100%',
    fromSide: true
  },
  {
    Vector: PluginsSVG2,
    fromBottom: true,
    from: '30%',
    opacityFrom: 1
  },
  {
    Vector: PluginsSVG3,
    from: '-40%',
    fromSide: true
  },
  {
    Vector: PluginsSVG4,
    from: '-100%',
    fromSide: true
  },
  {
    Vector: PluginsSVG5,
    from: '100%',
    fromBottom: true
  },
  {
    Vector: PluginsSVG6,
    opacityFrom: 1
  }
];

const PluginsSection = ({ intl, mobileScreenSizes }) => {
  const { isLargeMobileScreen } = mobileScreenSizes;

  const pluginsSvgsRef = useRef();

  const observerOptions = { rootMargin: '-15%' };

  const observerCallback = (entries, observer) => {
    entries.forEach((entry) => {
      let classList = entry.target.classList;
      if (entry.isIntersecting) {
        classList.add('isVisible');
        classList.remove('isHidden');
        entry.target.childNodes.forEach((svg) => {
          if (svg.classList.contains('from-side')) {
            svg.firstChild.classList.add('slide-from-side');
          } else if (svg.classList.contains('from-bottom')) {
            svg.firstChild.classList.add('slide-from-bottom');
          } else if (svg.classList.contains('from-side-main')) {
            svg.classList.add('slide-from-side');
          }
        });
        observer.disconnect();
      }
    });
  };

  useEffect(() => {
    intersectionObserver(observerCallback, observerOptions, [
      pluginsSvgsRef?.current
    ]);
  }, []);

  return (
    <section className="br-integrations__ecommerce-plugins">
      <div className="br-integrations__ecommerce-plugins__content">
        <Grid
          gridTitle={intl.formatMessage({
            id: 'integrations.ecommerce_plugins.title'
          })}
          gridSubtitle={intl.formatMessage(
            {
              id: 'integrations.ecommerce_plugins.subtitle'
            },
            {
              eCommerce: (
                <span className="br-integrations__ecommerce-plugins__content__underline-span">
                  {intl.formatMessage({
                    id: 'integrations.ecommerce_plugins.ecommerce'
                  })}
                </span>
              ),
              shopify: (
                <span className="br-integrations__ecommerce-plugins__content__underline-span">
                  {intl.formatMessage({
                    id: 'integrations.ecommerce_plugins.shopify'
                  })}
                </span>
              )
            }
          )}
          items={ECOMMERCE_PLUGINS_CONTENT}
          icons={ECOMMERCE_PLUGINS_ICONS}
        />
      </div>
      <div
        className="br-integrations__ecommerce-plugins__image"
        ref={pluginsSvgsRef}
      >
        {isLargeMobileScreen ? (
          <PluginsMainImage className="from-side-main" />
        ) : (
          renderSVGs(PLUGINS_SVGS)
        )}
      </div>
    </section>
  );
};

export default injectIntl(mediaHook(PluginsSection));
