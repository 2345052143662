import React from 'react';
import { Spin } from 'antd';

const LoadingWrapper = ({ loading, children, indicator, className }) => {
  return (
    <Spin
      size="middle"
      spinning={loading}
      indicator={indicator}
      className={className}
    >
      {children}
    </Spin>
  );
};

export default LoadingWrapper;
