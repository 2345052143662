import React from 'react';
import { Modal } from 'antd';

import ProgressiveImg from 'components/ProgressiveImage/ProgressiveImage';

import { ReactComponent as CloseIcon } from 'assets/icons/Close.svg';

import './BRPreviewImageModal.less';

export default function BRPreviewImageModal({
  previewImageTitle,
  img,
  close,
  ...props
}) {
  const handleClose = () => {
    close();
  };
  return (
    <Modal
      footer={null}
      title={
        <>
          <span>{previewImageTitle}</span>
          <CloseIcon onClick={handleClose} />
        </>
      }
      {...props}
      wrapClassName="br-preview-image-modal"
    >
      <span className="br-preview-image-container">
        <ProgressiveImg src={img} />
      </span>
    </Modal>
  );
}
