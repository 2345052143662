import AboutHero from './components/AboutHero/AboutHero';
import Vision from './components/Vision/Vision';
import Partners from './components/Partners/Partners';
import Investors from './components/Investors/Investors';
import News from './components/News/News';
import SignUpAndStart from 'components/SignUpAndStart/SignUpAndStart';

const About = () => {
  return (
    <>
      <AboutHero />
      <Vision />
      <Partners />
      <Investors />
      <News />
      <SignUpAndStart />
    </>
  );
};

export default About;
