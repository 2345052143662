import { BOSTA_BOX_AVAILABILITY } from 'constants/countries/constants/common';
import { visitorCountryCode } from 'utils/helpers';

import SolutionsHero from './components/SolutionsHero/SolutionsHero';
import DeliverySolutions from './components/DeliverySolutions/DeliverySolutions';
import BostaBox from './components/BostaBox/BostaBox';
import InnovativeBots from './components/InnovativeBots/InnovativeBots';
import MoreSolutions from './components/MoreSolutions/MoreSolutions';
import Testimonials from './components/Testimonials/Testimonials';
import SignUpAndStart from 'components/SignUpAndStart/SignUpAndStart';

const Solutions = () => {
  return (
    <>
      <SolutionsHero />
      <DeliverySolutions />
      {BOSTA_BOX_AVAILABILITY[visitorCountryCode] && <BostaBox />}
      <InnovativeBots />
      <MoreSolutions />
      <Testimonials />
      <SignUpAndStart />
    </>
  );
};

export default Solutions;
