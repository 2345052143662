import { useRef } from 'react';

import InternationalShippingHeader from 'components/InternationalShipping/components/InternationalShippingHeader/InternationalShippingHeader';
import InternationalShippingFeatures from 'components/InternationalShipping/components/InternationalShippingFeatures/InternationalShippingFeatures';
import InternationalShippingForm from 'components/InternationalShipping/components/InternationalShippingForm/InternationalShippingForm';

const InternationalShipping = () => {
  const formSectionRef = useRef();

  return (
    <>
      <InternationalShippingHeader formSectionRef={formSectionRef} />
      <InternationalShippingFeatures />
      <InternationalShippingForm ref={formSectionRef} />
    </>
  );
};

export default InternationalShipping;
