import { forwardRef } from 'react';
import { Form, Input, Radio, Select, Checkbox, Tooltip } from 'antd';
import { useIntl } from 'react-intl';

import { phoneNumberRule } from 'utils/forms';
import { isURLHavingKSA, toUrlEncoded } from 'utils/helpers';
import {
  INTERNATIONAL_MONTHLY_ORDERS_OPTIONS,
  INTERNATIONAL_SHIPPING_GCC_COUNTRIES,
  INTERNATIONAL_SHIPPING_SIGNUP_FORM_FIELDS,
  OTHER_OPTION_KEY,
  OTHER_OPTION_PAYLOAD_RESPONSE,
  OTHER_OPTION_PAYLOAD_VALUE
} from 'constants/international-shipping';
import { internationalShippingFormPhoneValidationCountry } from 'constants/countries/countries-mapping';
import { EMAIL_REGEX } from 'constants/Forms';
import { internationalShippingSignup } from 'services/international-shipping';

import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as TooltipIcon } from 'assets/icons/Info-Circle.svg';

import './InternationalShippingForm.less';

const InternationalShippingForm = (props, ref) => {
  const intl = useIntl();

  const {
    NAME,
    EMAIL,
    PHONE,
    BUSINESS_NAME,
    SHIP_TO_KSA,
    SHIP_CAPACITY,
    GCC_COUNTRIES
  } = INTERNATIONAL_SHIPPING_SIGNUP_FORM_FIELDS;

  const [form] = Form.useForm();
  const gccCountries = Form.useWatch(GCC_COUNTRIES, form) || [];

  const onFinish = async (values) => {
    const submissionTimestamp = Date.now();

    if (values.otherCountries) {
      const otherOptionValueKey = `${GCC_COUNTRIES}.${OTHER_OPTION_PAYLOAD_RESPONSE}`;

      const newGccCountries = [
        ...values[GCC_COUNTRIES],
        OTHER_OPTION_PAYLOAD_VALUE
      ].filter((country) => country !== OTHER_OPTION_KEY);

      values[otherOptionValueKey] = values.otherCountries;
      values[GCC_COUNTRIES] = newGccCountries;

      delete values.otherCountries;
    }

    const payload = {
      ...values,
      submissionTimestamp
    };

    try {
      const encodedPayload = toUrlEncoded(payload);
      await internationalShippingSignup(encodedPayload);
    } catch (error) {
      //
    }

    form.resetFields();
    notify({
      msg: intl.formatMessage({
        id: 'international_shipping.sign_up_form.submit_successfully'
      }),
      type: 'success'
    });
  };

  return (
    <div className="br-international-shipping__form-container" ref={ref}>
      <div className="display-lg">
        {intl.formatMessage({
          id: 'international_shipping.sign_up_form.title'
        })}
      </div>
      <Form onFinish={onFinish} form={form}>
        <Form.Item
          name={NAME}
          label={intl.formatMessage({
            id: 'international_shipping.sign_up_form.form_labels.name'
          })}
          rules={[{ required: true }]}
        >
          <Input
            placeholder={intl.formatMessage({
              id: 'international_shipping.sign_up_form.form_placeholders.name'
            })}
          />
        </Form.Item>
        <Form.Item
          name={EMAIL}
          label={intl.formatMessage({
            id: 'international_shipping.sign_up_form.form_labels.email'
          })}
          rules={[
            {
              pattern: EMAIL_REGEX,
              message: intl.formatMessage({
                id: 'international_shipping.sign_up_form.form_validation.email'
              })
            },
            {
              required: true
            }
          ]}
        >
          <Input
            placeholder={intl.formatMessage({
              id: 'international_shipping.sign_up_form.form_placeholders.email'
            })}
          />
        </Form.Item>
        <Form.Item
          name={PHONE}
          label={intl.formatMessage({
            id: 'international_shipping.sign_up_form.form_labels.phone_number'
          })}
          rules={[
            phoneNumberRule({
              message: intl.formatMessage({
                id: 'international_shipping.sign_up_form.form_validation.phone'
              }),
              country: internationalShippingFormPhoneValidationCountry()
            })
          ]}
        >
          <Input
            placeholder={intl.formatMessage({
              id: 'international_shipping.sign_up_form.form_placeholders.phone_number'
            })}
          />
        </Form.Item>
        <Form.Item
          name={BUSINESS_NAME}
          label={intl.formatMessage({
            id: 'international_shipping.sign_up_form.form_labels.business_name'
          })}
          rules={[{ required: true }]}
        >
          <Input
            placeholder={intl.formatMessage({
              id: 'international_shipping.sign_up_form.form_placeholders.business_name'
            })}
          />
        </Form.Item>
        {isURLHavingKSA && (
          <Form.Item
            name={GCC_COUNTRIES}
            label={intl.formatMessage({
              id: 'international_shipping.sign_up_form.form_labels.gcc_countries'
            })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'international_shipping.sign_up_form.form_validation.default_required'
                })
              }
            ]}
          >
            <Checkbox.Group>
              {INTERNATIONAL_SHIPPING_GCC_COUNTRIES.map(({ value, label }) => (
                <Checkbox value={value} key={value}>
                  {label}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
        )}
        {gccCountries.includes(OTHER_OPTION_KEY) && (
          <Form.Item
            name="otherCountries"
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'international_shipping.sign_up_form.form_validation.default_required'
                })
              }
            ]}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'international_shipping.sign_up_form.form_placeholders.other_countries'
              })}
            />
          </Form.Item>
        )}
        <Form.Item
          name={SHIP_TO_KSA}
          label={intl.formatMessage({
            id: 'international_shipping.sign_up_form.form_labels.ship_to_ksa'
          })}
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: 'international_shipping.sign_up_form.form_validation.default_required'
              })
            }
          ]}
        >
          <Radio.Group>
            <Radio value="Yes">
              {intl.formatMessage({ id: 'common.yes' })}
            </Radio>
            <Radio value="No"> {intl.formatMessage({ id: 'common.no' })}</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={SHIP_CAPACITY}
          label={
            <div className="br-international-shipping__form__capacity-label">
              {intl.formatMessage({
                id: 'international_shipping.sign_up_form.form_labels.ship_capacity'
              })}
              <Tooltip
                title={intl.formatMessage({
                  id: 'international_shipping.sign_up_form.capacity_tooltip'
                })}
                getPopupContainer={(trigger) =>
                  trigger.parentElement.parentElement.parentElement
                    .parentElement
                }
              >
                <TooltipIcon />
              </Tooltip>
            </div>
          }
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: 'international_shipping.sign_up_form.form_validation.default_required'
              })
            }
          ]}
        >
          <Select
            options={INTERNATIONAL_MONTHLY_ORDERS_OPTIONS}
            placeholder={intl.formatMessage({
              id: 'international_shipping.sign_up_form.form_placeholders.ship_capacity'
            })}
          />
        </Form.Item>
        <BRButton
          type="danger"
          htmlType="submit"
          className="button-lg"
          label={intl.formatMessage({
            id: 'international_shipping.sign_up_form.sign_up'
          })}
        />
      </Form>
    </div>
  );
};

export default forwardRef(InternationalShippingForm);
