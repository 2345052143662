import { fmt } from 'components/IntlWrapper/IntlWrapper';

export const SOLUTIONS_DELIVERY_SOLUTIONS = [
  {
    title: fmt({ id: 'solutions.delivery_solutions.order_types.title' }),
    subtitle: fmt({ id: 'solutions.delivery_solutions.order_types.subtitle' })
  },
  {
    title: fmt({ id: 'solutions.delivery_solutions.proof_of_delivery.title' }),
    subtitle: fmt({
      id: 'solutions.delivery_solutions.proof_of_delivery.subtitle'
    })
  },
  {
    title: fmt({ id: 'solutions.delivery_solutions.performance.title' }),
    subtitle: fmt({ id: 'solutions.delivery_solutions.performance.subtitle' })
  }
];

export const SOLUTIONS_BOSTA_BOX = [
  {
    title: fmt({ id: 'solutions.bosta_box.import_from_anywhere.title' }),
    subtitle: fmt({ id: 'solutions.bosta_box.import_from_anywhere.subtitle' })
  }
];

export const SOLUTIONS_MORE_SOLUTIONS = [
  {
    title: fmt({ id: 'solutions.more_solutions.fulfillment_services.title' }),
    subtitle: fmt({
      id: 'solutions.more_solutions.fulfillment_services.subtitle'
    })
  },
  {
    title: fmt({ id: 'solutions.more_solutions.cash_on_delivery.title' }),
    subtitle: fmt({
      id: 'solutions.more_solutions.cash_on_delivery.subtitle'
    })
  }
];
