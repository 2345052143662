import { fmt } from 'components/IntlWrapper/IntlWrapper';

export const REPORTS_LIST = [
  {
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/bosta-cms.appspot.com/o/bosta-insights%2Fegypt's_state_e-commerce.png?alt=media&token=b68bf801-c12c-4613-948f-203af9acb4a5",
    thumbnailAr:
      "https://firebasestorage.googleapis.com/v0/b/bosta-cms.appspot.com/o/bosta-insights%2Fegypt's_state_e-commerce_AR.png?alt=media&token=62f04d07-3925-463e-9694-f35808504937",
    reportThumbnail:
      "https://firebasestorage.googleapis.com/v0/b/bosta-cms.appspot.com/o/bosta-insights%2FEgypt's%20State%20of%20E-Commerce%20Header.png?alt=media&token=6b8d69c8-22cb-4d7f-90b6-3ad9b65bb9ee",
    reportThumbnailAr:
      "https://firebasestorage.googleapis.com/v0/b/bosta-cms.appspot.com/o/bosta-insights%2FEgypt's%20State%20of%20E-Commerce%20-%20Header%20-%20Ar.png?alt=media&token=4c3eae2b-b4e9-45af-9791-5929cc67f7a5",
    title: 'Egypt’s State of E-Commerce 2023',
    titleAr: 'تقرير 2023 للتجارة الإلكترونية في مصر ومستقبلها في 2024',
    publishDate: '2024-03-5',
    id: 'egypt-ecommerce-state'
  }
];

export const INDUSTRIES = [
  {
    value: 'E-Commerce',
    label: fmt({ id: 'bosta_insights.reports.industries.ecommerce' })
  },
  {
    value: 'Oil and Gas',
    label: fmt({ id: 'bosta_insights.reports.industries.oil_and_gas' })
  },
  {
    value: 'Engineering',
    label: fmt({ id: 'bosta_insights.reports.industries.engineering' })
  },
  {
    value: 'Manufacturing',
    label: fmt({ id: 'bosta_insights.reports.industries.manufacturing' })
  },
  {
    value: 'Advertising & Marketing',
    label: fmt({ id: 'bosta_insights.reports.industries.advertising' })
  },
  {
    value: 'IT',
    label: fmt({ id: 'bosta_insights.reports.industries.it' })
  },
  {
    value: 'Data science',
    label: fmt({ id: 'bosta_insights.reports.industries.data_science' })
  },
  {
    value: 'Logistics',
    label: fmt({ id: 'bosta_insights.reports.industries.logistics' })
  },
  {
    value: 'Automotive',
    label: fmt({ id: 'bosta_insights.reports.industries.automotive' })
  },
  {
    value: 'Media',
    label: fmt({ id: 'bosta_insights.reports.industries.media' })
  },
  {
    value: 'Banking',
    label: fmt({ id: 'bosta_insights.reports.industries.banking' })
  },
  {
    value: 'Insurance',
    label: fmt({ id: 'bosta_insights.reports.industries.insurance' })
  },
  {
    value: 'Education',
    label: fmt({ id: 'bosta_insights.reports.industries.education' })
  },
  {
    value: 'Medicine',
    label: fmt({ id: 'bosta_insights.reports.industries.medicine' })
  },
  {
    value: 'Real Estate',
    label: fmt({ id: 'bosta_insights.reports.industries.real_estate' })
  },
  {
    value: 'Other',
    label: fmt({ id: 'bosta_insights.reports.industries.other' })
  }
];
