import { Helmet } from 'react-helmet';

const BRHeadManager = ({ children }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      {children}
    </Helmet>
  );
};

export default BRHeadManager;
