import React from 'react';
import { injectIntl } from 'react-intl';

import { LOCALE } from 'constants/intl-wrapper';
import { getStatsList } from 'constants/fulfillment';
import { mediaHook } from 'customHooks';

import Logo from 'assets/images/en-bosta_logo_vertical.png';
import ArabicLogo from 'assets/images/ar-bosta_logo_vertical.png';

import './FulfillmentStats.less';

const FulfillmentStats = ({
  intl,
  mobileScreenSizes: { isMediumMobileScreen }
}) => {
  const { formatMessage } = intl;

  const introLogo = () => {
    return intl.locale.includes(LOCALE.EN) ? Logo : ArabicLogo;
  };

  return (
    <div className="br-fulfillment__stats-section">
      <div className="br-fulfillment__stats-intro">
        <img src={introLogo()} alt="bosta logo" className="bosta_logo" />

        <p>
          {formatMessage({
            id: 'bosta_fulfillment.stats.description'
          })}
        </p>
      </div>
      <div className="br-fulfillment__stats-content">
        <p className="br-fulfillment__stats-headline">
          {formatMessage({
            id: 'bosta_fulfillment.stats.headline'
          })}
        </p>
        <div className="br-fulfillment__stats-grid">
          {getStatsList(isMediumMobileScreen).map(({ value, title }, index) => (
            <div key={index} className="br-fulfillment__stats-card">
              <p className="stats-value">{value}</p>
              <p className="stats-title">{title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default injectIntl(mediaHook(FulfillmentStats));
