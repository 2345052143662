import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal } from 'antd';

import { validateOTP } from 'services/shipments';
import { openModal } from 'utils/modal';

import BROTPCode from 'components/BROTPCode/BROTPCode';
import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';
import PhoneNumberModal from 'components/ShipmentTracking/components/PhoneNumberModal/PhoneNumberModal';

import './VerifyPhoneModal.less';

const VerifyPhoneModal = ({
  intl,
  close,
  phoneNumber,
  trackingNumber,
  resendCode,
  onSuccess,
  phoneNumberModalProps,
  ...props
}) => {
  const [wrongOTP, setWrongOTP] = useState(false);
  const [confirmDisabled, setConfirmDisabled] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleClose = () => {
    close();
  };

  const handleSubmit = async (values) => {
    const payload = {
      trackingNumber,
      phone: phoneNumber,
      otp: values
    };

    try {
      const { token } = await validateOTP(payload);
      onSuccess?.(token);
      handleClose();
      notify({
        msg: intl.formatMessage({
          id: 'tracking.phone_number_modal.otp.success_confirm'
        }),
        type: 'success'
      });
    } catch (error) {
      setWrongOTP(true);
      setConfirmDisabled(true);
      setConfirmLoading(false);
    }
  };

  const handleEditPhoneClick = () => {
    openModal(PhoneNumberModal, {
      trackingNumber,
      ...phoneNumberModalProps
    });
    handleClose();
  };

  return (
    <Modal
      wrapClassName="br-verify-phone-modal"
      title={
        <div className="br-phone-number-modal__header">
          <div className="br-phone-number-modal__title">
            {intl.formatMessage({
              id: 'verify_phone.title'
            })}
          </div>
        </div>
      }
      footer={
        <>
          <BRButton
            type="basic"
            onClick={handleClose}
            label={intl.formatMessage({
              id: 'common.cancel'
            })}
          />
          <BRButton
            type="primary"
            className="br-registration-form-card__submit-btn"
            loading={confirmLoading}
            disabled={confirmDisabled}
            label={intl.formatMessage({
              id: 'common.confirm'
            })}
          />
        </>
      }
      {...props}
    >
      <div className="verify-phone-modal__body">
        <div className="verify-phone__opt-container">
          <BROTPCode
            headerClassName="br-verify-phone-modal__header"
            isInternalComponent
            phoneNumber={phoneNumber}
            wrongOTP={wrongOTP}
            setWrongOTP={setWrongOTP}
            confirmOTP={handleSubmit}
            handleResendCode={resendCode}
            setConfirmDisabled={setConfirmDisabled}
            setConfirmLoading={setConfirmLoading}
          />
        </div>

        <div className="verify-phone-modal__wrong-number">
          <span className="body">
            {intl.formatMessage({
              id: 'verify_phone.not_your_phone'
            })}
          </span>
          <BRButton
            type="link-color"
            onClick={handleEditPhoneClick}
            label={intl.formatMessage({
              id: 'common.change'
            })}
          />
        </div>
      </div>
    </Modal>
  );
};
export default injectIntl(VerifyPhoneModal);
