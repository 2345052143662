import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal, Form, Input, Select } from 'antd';

import { generateOTP } from 'services/shipments';
import {
  ALL_COUNTRIES,
  DEFAULT_COUNTRY
} from 'constants/countries/constants/countries';
import { setCurrentUserCountryData } from 'constants/countries/countries-mapping';

import { LOCALE } from 'constants/intl-wrapper';
import { phoneNumberRule, requiredRule } from 'utils/forms';
import { numberRegex } from 'utils/helpers';
import { openModal } from 'utils/modal';
import { isDashboardArabic } from 'utils/intl-wrapper';

import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

import VerifyPhoneModal from 'components/VerifyPhoneModal.js/VerifyPhoneModal';

import './PhoneNumberModal.less';

const PhoneNumberModal = ({
  intl,
  close,
  trackingNumber,
  setAPIKey,
  afterVerification,
  deliveryCountryCode,
  ...props
}) => {
  const [inputValue, setInputValue] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(
    ALL_COUNTRIES.find(({ codeName }) =>
      [deliveryCountryCode, localStorage.getItem('countryCodeName')].includes(
        codeName
      )
    ) || DEFAULT_COUNTRY
  );
  const [isLoading, setIsLoading] = useState(false);
  const [doesNotBelongToShipment, setDoesNotBelongToShipment] = useState(false);

  const [form] = Form.useForm();

  const handleClose = () => {
    close();
  };

  const handleGenerateOTP = async ({ phone, onSuccess }) => {
    setIsLoading(true);
    const payload = {
      phone,
      trackingNumber
    };

    try {
      await generateOTP(payload);
      notify({
        msg: intl.formatMessage({
          id: 'tracking.phone_number_modal.otp.success_send'
        }),
        type: 'success'
      });
      onSuccess?.();
    } catch (e) {
      const { status } = JSON.parse(JSON.stringify(e));
      if (status === 400) {
        setDoesNotBelongToShipment(true);
      } else {
        notify({
          msg: status
        });
      }
    }
    setIsLoading(false);
  };

  const onFinish = async ({ phone }) => {
    const phoneWithCountryCode = `${selectedCountry.codeNumber}${phone}`;

    await handleGenerateOTP({
      phone: phoneWithCountryCode,
      onSuccess: () => {
        openModal(VerifyPhoneModal, {
          trackingNumber,
          phoneNumber: phoneWithCountryCode,
          onSuccess: (key) => {
            setAPIKey(key);
            afterVerification(key);
          },
          resendCode: () => handleGenerateOTP(phoneWithCountryCode),
          phoneNumberModalProps: {
            setAPIKey
          }
        });

        handleClose();
      }
    });
  };

  const onOk = () => {
    form.validateFields().then(onFinish);
  };

  const handlePhoneNumberFieldChange = ({ target: { value } }) => {
    if (doesNotBelongToShipment) {
      setDoesNotBelongToShipment(false);
    }

    if (numberRegex.test(value) || value === '') {
      setInputValue(value);
      form.setFieldsValue({ phone: value });
    } else {
      form.setFieldsValue({ phone: inputValue });
    }
  };

  return (
    <Modal
      wrapClassName="br-phone-number-modal"
      title={
        <div className="br-phone-number-modal__header">
          <div className="br-phone-number-modal__title">
            {intl.formatMessage({
              id: 'tracking.phone_number_modal.title'
            })}
          </div>
          <div className="br-phone-number-modal__subtitle">
            {intl.formatMessage({
              id: 'tracking.phone_number_modal.subtitle'
            })}
          </div>
        </div>
      }
      footer={
        <>
          <BRButton
            label={intl.formatMessage({
              id: 'common.back'
            })}
            disabled={isLoading}
            onClick={handleClose}
          />
          <BRButton
            type="primary"
            label={intl.formatMessage({
              id: 'common.continue'
            })}
            onClick={onOk}
            disabled={isLoading}
            loading={isLoading}
          />
        </>
      }
      {...props}
    >
      <LoadingWrapper loading={isLoading}>
        <Form form={form}>
          <Form.Item
            className="br-phone-number-field"
            label={intl.formatMessage({
              id: 'tracking.phone_number_modal.phone_field.label'
            })}
          >
            <Input.Group compact>
              <Select
                value={selectedCountry.id}
                className="br-countries-dropdown"
                dropdownMatchSelectWidth={false}
                onChange={(value) => {
                  form.setFieldsValue({ phone: '' });
                  setInputValue('');
                  const country = ALL_COUNTRIES.find(({ id }) => id === value);
                  setSelectedCountry(country);
                  if (!deliveryCountryCode) {
                    setCurrentUserCountryData({
                      codeName: country.codeName
                    });
                  }
                }}
              >
                {ALL_COUNTRIES.map(
                  ({
                    id,
                    codeNumber,
                    codeName,
                    flagIcon,
                    name,
                    arabicName
                  }) => (
                    <Select.Option
                      value={id}
                      key={id}
                      className="br-countries-dropdown__option"
                    >
                      <img
                        className="br-countries-dropdown__country-flag"
                        src={flagIcon}
                        alt="flag"
                      />
                      <div>
                        <span className="br-countries-dropdown__country-name">
                          {intl.locale.includes(LOCALE.EN) ? name : arabicName}
                        </span>
                        <span className="br-countries-dropdown__country-code-number">
                          {codeNumber}
                        </span>
                        <span className="br-countries-dropdown__country-code-name">
                          {codeName}
                        </span>
                      </div>
                    </Select.Option>
                  )
                )}
              </Select>
              <Form.Item
                name="phone"
                className="br-phone-number-field__input"
                value={inputValue}
                onChange={handlePhoneNumberFieldChange}
                rules={[
                  requiredRule(
                    intl.formatMessage(
                      {
                        id: 'form.required_custom'
                      },
                      {
                        label: intl.formatMessage({
                          id: 'tracking.phone_number_modal.phone_field.label'
                        })
                      }
                    )
                  ),
                  phoneNumberRule({
                    message: intl.formatMessage({ id: 'form.phone_not_valid' }),
                    country: selectedCountry,
                    withoutCountryCode: true
                  })
                ]}
                {...(doesNotBelongToShipment && {
                  validateStatus: 'error',
                  help: intl.formatMessage({
                    id: 'tracking.phone_number_modal.phone_field.phone_not_belong_to_shipment'
                  })
                })}
              >
                <Input
                  prefix={
                    isDashboardArabic()
                      ? `${selectedCountry.codeNumber.substring(1)}+`
                      : selectedCountry.codeNumber
                  }
                  placeholder={selectedCountry.phoneNumberPlaceholder}
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Form>
      </LoadingWrapper>
    </Modal>
  );
};

export default injectIntl(PhoneNumberModal);
