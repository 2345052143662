import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Input } from 'antd';

import { mediaHook } from 'customHooks';

import BRHero from 'components/BRHero/BRHero';
import QuestionsSection from './components/QuestionsSection.js/QuestionsSection';
import TrustedCompanies from 'components/TrustedCompanies/TrustedCompanies';
import SignUpAndStart from 'components/SignUpAndStart/SignUpAndStart';

import { ReactComponent as Vector1 } from 'assets/images/question-answer.svg';
import { ReactComponent as SearchUncoloredIcon } from 'assets/icons/search_uncolored.svg';
import { ReactComponent as TrustedCompaniesBg } from 'assets/images/Vector 14.svg';
import { ReactComponent as TrustedCompaniesBgMobile } from 'assets/images/faq-trusted-company-bg-mobile.svg';

import './FAQ.less';

const FAQ = ({ intl, mobileScreenSizes }) => {
  const { isLargeMobileScreen } = mobileScreenSizes;

  const [querySearch, setQuerySearch] = useState('');

  return (
    <div className="br-faq">
      <BRHero
        className="br-faq__header"
        title={intl.formatMessage({ id: 'faq.header.title' })}
        vectors={[<Vector1 />]}
        subTitleComponent={
          <Input
            className="br-faq__header__search-input"
            placeholder={intl.formatMessage({
              id: 'faq.header.search_placeholder'
            })}
            suffix={<SearchUncoloredIcon />}
            onChange={({ target: { value } }) => setQuerySearch(value?.trim())}
          />
        }
      />
      <div className="br-faq__content">
        <QuestionsSection querySearch={querySearch} />
        <div className="br-faq__content__trusted-companies-bg">
          {isLargeMobileScreen ? (
            <TrustedCompaniesBgMobile />
          ) : (
            <TrustedCompaniesBg />
          )}
        </div>
        <TrustedCompanies />
        <SignUpAndStart />
      </div>
    </div>
  );
};

export default injectIntl(mediaHook(FAQ));
