import { useEffect, useState, useCallback } from 'react';

import './CarouselCard.less';

const CarouselCard = ({ linkToPreview, key }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    fetch(`https://api.microlink.io?url=${linkToPreview}`)
      .then((response) => response.json())
      .then(({ data }) => setData(data));
  }, [linkToPreview]);

  const handleOnClickCard = useCallback(() => {
    window.open(linkToPreview);
  }, [linkToPreview]);

  return (
    <div className="br__carousel__card" key={key} onClick={handleOnClickCard}>
      <div className="br__carousel__card__content">
        <div className="br__carousel__card-title">
          <img src={data?.logo?.url} alt="carousel-img" />
        </div>
        <div className="br__carousel__card-image">
          <img src={data?.image?.url} alt="carousel-img" />
        </div>
        <div className="br__carousel__card-description">{data?.title}</div>
      </div>
    </div>
  );
};

export default CarouselCard;
