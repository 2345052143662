import React from 'react';

import { FULFILLMENT_VIDEO_URL } from 'constants/fulfillment';

import './FulfillmentVideo.less';

const FulfillmentVideo = () => {
  return (
    <div className="br-fulfillment__video-section">
      <iframe
        className="br-fulfillment__video-iframe"
        src={FULFILLMENT_VIDEO_URL}
        title="Bosta Fulfillment"
        frameborder="0"
        allow="accelerometer;fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      />
    </div>
  );
};

export default FulfillmentVideo;
