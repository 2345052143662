import React, { useEffect, useRef } from 'react';
import { Layout } from 'antd';

import { visitorCountryCode } from 'utils/helpers';

import Navbar from 'components/Navbar/Navbar';
import Footer from 'components/Footer/Footer';

import './Container.less';

const Container = ({ component: Component }) => {
  const pageRef = useRef('');
  useEffect(() => {
    pageRef?.current?.scrollIntoView();
  }, []);

  return (
    <div ref={pageRef}>
      <Layout
        className={`br-container br_${visitorCountryCode.toLowerCase()}_container`}
      >
        <Navbar />
        <Layout.Content>
          <Component />
        </Layout.Content>
        <Footer />
      </Layout>
    </div>
  );
};

export default Container;
