import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Menu, Dropdown, Input } from 'antd';
import Icon from '@ant-design/icons';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { mediaHook } from 'customHooks';
import {
  getPageAnnouncement,
  MIDDLE_LINKS,
  SIGN_IN_URL
} from 'constants/navbar';
import { COUNTRIES_ENGLISH_LOCALE, LOCALE } from 'constants/intl-wrapper';
import {
  setLocaleInLocalStorage,
  getLocaleFromLocalStorage
} from 'utils/intl-wrapper';
import { isURLHavingKSA, pathLang } from 'utils/helpers';
import { HOST_NAME } from 'constants/helpers';

import { ReactComponent as ArrowIcon } from 'assets/icons/DownArrowIcon.svg';
import { ReactComponent as Logo } from 'assets/icons/Bosta_Logo.svg';
import { ReactComponent as ArabicLogo } from 'assets/icons/Bosta_Logo_Arabic.svg';
import { ReactComponent as SearchIcon } from 'assets/images/search-icon.svg';

import './Navbar.less';

const Navbar = ({
  intl,
  history,
  location,
  mobileScreenSizes: { isLargeMobileScreen, isSmallMobileScreen },
  saudiSignupPage
}) => {
  const [open, setOpen] = useState(false);
  const language = pathLang || getLocaleFromLocalStorage();

  const isTrackingPage = window.location.pathname
    .split('/')
    .includes('tracking-shipments');
  const toggleOpen = () => {
    setOpen(!open);
  };

  const pageAnnouncements = getPageAnnouncement();
  const isPageHasAnnouncements =
    pageAnnouncements?.[language] &&
    dayjs(pageAnnouncements?.endDate) >= dayjs();

  const menu = (menuItems) => (
    <Menu>
      {menuItems?.map(
        ({ link, label, haveAccess = true }) =>
          haveAccess && (
            <li className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child">
              <div key={label} className="ant-dropdown-menu-title-content">
                <a href={`${HOST_NAME}/${language}${link}`}>{label}</a>
              </div>
            </li>
          )
      )}
    </Menu>
  );

  const onSearch = (value) => {
    history.push(`/tracking-shipments?shipment-number=${value?.trim()}`);
  };

  const shipmentTrackingLinkMenu = () => (
    <Menu>
      <Menu.Item>
        <div
          className="br-navbar-tracking-menu-container"
          onClick={(e) => e.stopPropagation()}
        >
          <h5 className="navbar-input-title">
            {intl.formatMessage({ id: 'tracking.navbar_title' })}
          </h5>
          <Input.Search
            placeholder={intl.formatMessage({
              id: 'tracking.input_placeholder'
            })}
            onSearch={onSearch}
            enterButton={<SearchIcon />}
          />
        </div>
      </Menu.Item>
    </Menu>
  );

  const handleChangeLanguage = ({ key }) => {
    if (key.toLowerCase().includes(intl.locale.split('-')[0])) {
      return;
    }
    const language = intl.locale.includes(LOCALE.EN)
      ? isURLHavingKSA
        ? LOCALE.AR_SA
        : LOCALE.AR
      : isURLHavingKSA
      ? COUNTRIES_ENGLISH_LOCALE[1]
      : COUNTRIES_ENGLISH_LOCALE[0];

    setLocaleInLocalStorage(language);
    const newLocation = window.location.pathname.replace(
      `${intl.locale.toLowerCase()}`,
      `${language.toLowerCase()}`
    );
    const origin = window.location.origin;
    const searchParams = window.location.search;

    window.location.assign(`${origin}${newLocation}${searchParams}`);
  };

  const languageMenu = (
    <Menu onClick={handleChangeLanguage}>
      <Menu.Item key={LOCALE.EN}>English</Menu.Item>
      <Menu.Item key={LOCALE.AR}>عربي</Menu.Item>
    </Menu>
  );

  const onSignupClick = () => {
    window.location.pathname.includes('sa')
      ? window.open('/ar-sa/signup', '_parent')
      : history.push('/signup');
  };

  const renderLanguageButton = () => (
    <span
      className={classNames('br-navbar__right-section__locale-link ', {
        'br-navbar__right-section__locale-link-ar':
          getLocaleFromLocalStorage().includes(LOCALE.EN),
        'br-navbar__right-section__locale-link-en':
          getLocaleFromLocalStorage().includes(LOCALE.AR)
      })}
      onClick={() =>
        handleChangeLanguage({
          key: getLocaleFromLocalStorage().includes(LOCALE.EN)
            ? 'arabic'
            : 'english'
        })
      }
    >
      {intl.formatMessage({
        id: `navbar.mobile_${
          getLocaleFromLocalStorage().includes(LOCALE.EN) ? 'arabic' : 'english'
        }`
      })}
    </span>
  );

  return (
    <>
      {isPageHasAnnouncements && (
        <nav>
          <div className="br-navbar-announcments br-navbar sidebar-child">
            {intl.formatMessage({
              id: `navbar.announcement.${pageAnnouncements.id}`
            })}
          </div>
        </nav>
      )}
      <nav>
        <div
          className={classNames(
            'br-navbar',
            {
              'tracking-page': isTrackingPage,
              'br-nav-bar-with-announcements': !!isPageHasAnnouncements
            },

            { 'br-saudi-signup': saudiSignupPage },
            ''
          )}
        >
          <a href={`${HOST_NAME}/${language}/home`}>
            {intl.locale.includes(LOCALE.EN) ? <Logo /> : <ArabicLogo />}
          </a>
          {!isLargeMobileScreen && !isTrackingPage && !saudiSignupPage && (
            <div className="br-navbar__middle-section">
              {MIDDLE_LINKS.map(
                ({ title, url, isMenu = false, menuItems }, index) =>
                  isMenu ? (
                    <Dropdown
                      key={index}
                      overlay={() => menu(menuItems)}
                      overlayClassName=""
                      placement="bottom"
                      getPopupContainer={(trigger) => trigger?.parentNode}
                    >
                      <a className="br-navbar__link navbar-link">
                        {title}
                        <Icon component={ArrowIcon} />
                      </a>
                    </Dropdown>
                  ) : (
                    <a
                      className="br-navbar__link navbar-link"
                      key={index}
                      href={`${HOST_NAME}/${language}${url}`}
                    >
                      {title}
                    </a>
                  )
              )}
            </div>
          )}
          <div
            className={classNames('br-navbar__menu-cotainer', {
              'br-navbar__menu-cotainer-open': open
            })}
          >
            {open && !isTrackingPage && (
              <div className="br-navbar__middle-section">
                {MIDDLE_LINKS.map(
                  ({ title, url, isMenu = false, menuItems }, index) =>
                    isMenu ? (
                      <Dropdown
                        key={index}
                        overlay={() => menu(menuItems)}
                        overlayClassName=""
                        placement="bottom"
                        getPopupContainer={(trigger) => trigger?.parentNode}
                      >
                        <span className="br-navbar__link navbar-link">
                          {title}
                          <Icon component={ArrowIcon} />
                        </span>
                      </Dropdown>
                    ) : (
                      <a
                        className="br-navbar__link navbar-link"
                        key={index}
                        href={`${HOST_NAME}/${language}${url}`}
                      >
                        {title}
                      </a>
                    )
                )}
              </div>
            )}

            <div className="br-navbar__right-section">
              {!isTrackingPage && !saudiSignupPage && (
                <Dropdown
                  overlay={shipmentTrackingLinkMenu}
                  trigger={['hover']}
                  placement={
                    getLocaleFromLocalStorage() === LOCALE.EN
                      ? 'bottomRight'
                      : 'bottomLeft'
                  }
                  overlayClassName="br-nav-bar-tracking-link-menu-container"
                >
                  <span className="br-navbar__link br-navbar__link-track-shipment navbar-link">
                    {intl.formatMessage({ id: 'navbar.track_shipment' })}
                    <Icon component={ArrowIcon} />
                  </span>
                </Dropdown>
              )}
              {!saudiSignupPage && (
                <div className="br-navbar__right-section__locale-link-container">
                  <Dropdown
                    overlay={languageMenu}
                    trigger="click"
                    getPopupContainer={(trigger) => trigger?.parentElement}
                  >
                    <span className="br-navbar__link navbar-link br-navbar__right-section__locale-dropdown">
                      {intl.locale.includes(LOCALE.EN) ? 'عربي' : 'English'}
                      <Icon component={ArrowIcon} />
                    </span>
                  </Dropdown>
                  {renderLanguageButton()}
                </div>
              )}

              {!isTrackingPage && (
                <div className="br-navbar__right-section__registeration-container">
                  <a
                    href={SIGN_IN_URL}
                    target="_blank"
                    rel="noopener"
                    className="br-navbar__link br-navbar__link-sign-in  navbar-link"
                  >
                    {intl.formatMessage({ id: 'navbar.sign_in' })}
                  </a>
                  <a
                    onClick={onSignupClick}
                    target="_blank"
                    rel="noopener"
                    className="br-navbar__link-sign-up navbar-link"
                  >
                    {intl.formatMessage({ id: 'navbar.sign_up' })}
                  </a>
                </div>
              )}
            </div>
          </div>

          {!saudiSignupPage && (
            <div className="br-navbar__right-section-mobile">
              <div className="br-navbar__tracking-mobile">
                {(!isTrackingPage ||
                  (isTrackingPage && isSmallMobileScreen)) && (
                  <Dropdown
                    overlay={shipmentTrackingLinkMenu}
                    trigger={['click']}
                    placement={
                      getLocaleFromLocalStorage() === LOCALE.EN
                        ? 'bottomRight'
                        : 'bottomLeft'
                    }
                    overlayClassName="br-nav-bar-tracking-link-menu-container"
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement.parentElement.parentElement
                        .parentElement
                    }
                  >
                    {isTrackingPage ? (
                      <div className="br-navbar__tracking-search-icon">
                        <SearchIcon />
                      </div>
                    ) : (
                      <span className="br-navbar__link navbar-link">
                        {intl.formatMessage({ id: 'navbar.track_shipment' })}
                        <Icon component={ArrowIcon} />
                      </span>
                    )}
                  </Dropdown>
                )}
                {renderLanguageButton()}
              </div>

              {!isTrackingPage && (
                <div
                  className={classNames('br-navbar__hamburger', {
                    'br-navbar__hamburger-open': open
                  })}
                  onClick={toggleOpen}
                >
                  <div />
                  <div />
                  <div />
                </div>
              )}
            </div>
          )}
        </div>
      </nav>
    </>
  );
};

export default injectIntl(withRouter(mediaHook(Navbar)));
