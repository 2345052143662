import React, { useEffect, useRef } from 'react';
import { injectIntl } from 'react-intl';

import { SMART_APIS_CONTENT } from 'constants/integrations';
import { intersectionObserver } from 'utils/animations';

import Grid from 'components/Grid/Grid';

import SmartAPIs from 'assets/images/Integration2.png';
import { ReactComponent as LocationIcon } from 'assets/icons/HomeLocationIcon.svg';
import { ReactComponent as VariousEndpoints } from 'assets/icons/VariousEndpoints.svg';

import './APIsSection.less';

const SMART_APIS_ICONS = [VariousEndpoints, LocationIcon];

const PluginsAndAPIs = ({ intl }) => {
  const smartApisRef = useRef();

  const observerOptions = { rootMargin: '-15%' };

  const observerCallback = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.childNodes[0].firstChild.classList.add(
          'isVisible',
          'slide-from-bottom'
        );
        entry.target.childNodes[0].firstChild.classList.remove('isHidden');
        entry.target.childNodes[1].classList.add(
          'isVisible',
          'slide-from-bottom'
        );
        entry.target.childNodes[1].classList.remove('isHidden');
        observer.disconnect();
      }
    });
  };

  useEffect(() => {
    intersectionObserver(observerCallback, observerOptions, [
      smartApisRef?.current
    ]);
  }, []);

  return (
    <section className="br-integrations__smart-apis" ref={smartApisRef}>
      <div className="br-integrations__smart-apis__image">
        <img
          src={SmartAPIs}
          alt="Smart APIs"
          className="from-bottom-main isHidden"
        />
      </div>
      <div className="br-integrations__smart-apis__content isHidden">
        <Grid
          gridTitle={intl.formatMessage({
            id: 'integrations.smart_apis.title'
          })}
          gridSubtitle={intl.formatMessage({
            id: 'integrations.smart_apis.subtitle'
          })}
          items={SMART_APIS_CONTENT}
          icons={SMART_APIS_ICONS}
        />
      </div>
    </section>
  );
};

export default injectIntl(PluginsAndAPIs);
