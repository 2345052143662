import { useIntl } from 'react-intl';

import BRHero from 'components/BRHero/BRHero';
import ReportsList from './components/ReportsList/ReportsList';

import { ReactComponent as Vector1 } from 'assets/images/insights_vector.svg';

import './BostaInsights.less';

const BostaInsights = () => {
  const intl = useIntl();

  return (
    <div className="br-bosta-insights">
      <BRHero
        className="br-bosta-insights-hero"
        preTitle={intl.formatMessage({ id: 'bosta_insights.title' })}
        title={intl.formatMessage({ id: 'bosta_insights.subtitle' })}
        titleClassName="br-header__subtitle"
        vectors={[<Vector1 />]}
        secondryComponent={
          <div className="br-bosta-insights__description">
            <div className="subtitle-lg">
              {intl.formatMessage({ id: 'bosta_insights.description' })}
            </div>
            <br />
            <br />
            <div className="subtitle-lg">
              {intl.formatMessage({ id: 'bosta_insights.extra_description' })}
            </div>
          </div>
        }
      />
      <ReportsList />
    </div>
  );
};

export default BostaInsights;
