import { injectIntl } from 'react-intl';

import { ReactComponent as MainSvg } from 'assets/images/download_app_main.svg';
import { ReactComponent as GooglePlayButton } from 'assets/images/googleplay-button.svg';
import { ReactComponent as AppStoreButton } from 'assets/images/appstore-button.svg';

import './DownloadApp.less';

const DownloadApp = ({ intl }) => {
  return (
    <section className="br-download-app-section">
      <div className="br-download-app-seciton-content display-flex align-center">
        <div className="br-download-app-section-svg display-flex">
          <div className="main-svg">
            <MainSvg />
          </div>
        </div>
        <div className="br-download-app-section-title">
          {intl.formatMessage({ id: 'mobile.download_app.title' })}
        </div>
        <div className="br-mobile-download-buttons">
          <button className="app-button">
            <GooglePlayButton />
          </button>
          <button className="app-button">
            <AppStoreButton />
          </button>
        </div>
      </div>
    </section>
  );
};

export default injectIntl(DownloadApp);
