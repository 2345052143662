import { bostaHttp } from 'utils/http';

export const getSignupPricingInfo = (payload) => {
  return bostaHttp.get(`/pricing/calculator`, payload);
};

export const createLeadRecord = (payload) => {
  return bostaHttp.post(`/users/business/landing-page`, payload);
};

export const capitalFormSignUp = (payload) => {
  return bostaHttp.post(`/borrow/capitalForm`, payload);
};
