import { injectIntl } from 'react-intl';

import {
  DASHBOARD_SHIPPING_NEEDS_FEATUERS,
  DASHBOARD_VISIBILITY_WITH_FULL_CONTROL_FEATURES
} from 'constants/dashboard';
import { mediaHook } from 'customHooks';

import DashboardFeature from './components/DashboardFeature/DashboardFeature';

import FeaturesMainImage from 'assets/images/dashboard-features-main-image.png';

import './DashboardFeatures.less';
import WalletMobile from './components/WalletMobile/WalletMobile';

const DashboardFeatures = ({ intl, mobileScreenSizes }) => {
  const { isLargeMobileScreen } = mobileScreenSizes;
  return (
    <div className="br-dashboard-features-container">
      <div className="br-dashboard-features-content">
        <h1 className="br-dashboard-features-title">
          {intl.formatMessage({
            id: 'dashboard.dashboard_features.shipping_needs.title'
          })}
        </h1>
        <div className="br-dashboard-features">
          {DASHBOARD_SHIPPING_NEEDS_FEATUERS.map(
            ({ title, subtitle, image, positionRight }, index) => (
              <DashboardFeature
                key={index}
                title={title}
                subtitle={subtitle}
                image={image}
                positionRight={positionRight}
              />
            )
          )}
        </div>
      </div>
      <div className="br-dashboard-features-content">
        <h1 className="br-dashboard-features-title">
          {intl.formatMessage({
            id: 'dashboard.dashboard_features.visibility_with_full_control.title'
          })}
        </h1>
        <div className="br-dashboard-features">
          {DASHBOARD_VISIBILITY_WITH_FULL_CONTROL_FEATURES.map(
            ({ title, subtitle, image, positionRight }, index) => (
              <DashboardFeature
                key={index}
                title={title}
                subtitle={subtitle}
                image={image}
                positionRight={positionRight}
              />
            )
          )}
        </div>
        {isLargeMobileScreen ? (
          <WalletMobile />
        ) : (
          <div className="br-dashboard-features-main-img">
            <img src={FeaturesMainImage} alt={'main-features'} />
          </div>
        )}
      </div>
    </div>
  );
};

export default injectIntl(mediaHook(DashboardFeatures));
