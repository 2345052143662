import { useEffect, useState, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { Collapse } from 'antd';

import { QUESTIONS_AND_ANSWERS } from 'constants/faq';

import BRHighlighter from 'components/BRHighlighter/BRHighlighter';

import './QuestionsSection.less';

const { Panel } = Collapse;

const isString = (e) => {
  return typeof e === 'string';
};

const QuestionsSection = ({ intl, querySearch }) => {
  const [activeKeys, setActiveKeys] = useState([]);
  const [questionsAndAnswers, setQuestionsAndAnswers] = useState(
    QUESTIONS_AND_ANSWERS
  );

  const isFoundIn = useCallback(
    (text) => {
      return text.toLowerCase().includes(querySearch.toLowerCase());
    },
    [querySearch]
  );

  const search = useCallback(() => {
    const answerKeys = [];
    const results = QUESTIONS_AND_ANSWERS.filter(
      ({ key, question, answer, answerTextToSearch }) => {
        const isFoundInQuestion = isFoundIn(question);
        const isFoundInAnswer = isFoundIn(answer.toSearch);

        if (isFoundInAnswer) {
          answerKeys.push(key);
        }

        return isFoundInQuestion || isFoundInAnswer;
      }
    );

    setActiveKeys(answerKeys);
    setQuestionsAndAnswers(results);
  }, [isFoundIn]);

  // Used to handle if text is not a pure string and has an HTML tag inside
  const customHighlight = (text, regex) => {
    const parts = text.map((t) => (isString(t) ? t.split(regex) : t));

    return parts.map((part, idx) => {
      if (Array.isArray(part)) {
        return part.map((t, i) =>
          regex.test(t) ? <mark key={i}>{t}</mark> : t
        );
      } else {
        return regex.test(part.props.children[0]) ? (
          <mark key={idx}>{part}</mark>
        ) : (
          part
        );
      }
    });
  };

  const renderedQuestions = questionsAndAnswers.map(
    ({ key, question, answer, access = true }) => {
      if (!access) {
        return;
      }
      return (
        <Panel
          showArrow={false}
          header={
            <BRHighlighter
              className="display-sm"
              text={question}
              textToHighlight={querySearch}
            />
          }
          key={key}
          extra={
            <span className="display-sm">
              {activeKeys.includes(key) ? '-' : '+'}
            </span>
          }
        >
          <BRHighlighter
            className="display-sm"
            text={answer.toRender}
            textToHighlight={querySearch}
            Wrapper="pre"
            customRender={
              !isString(answer.toRender)
                ? (regex) => customHighlight(answer.toRender, regex)
                : null
            }
          />
        </Panel>
      );
    }
  );

  useEffect(() => {
    if (querySearch) {
      search();
    } else {
      setActiveKeys([]);
      setQuestionsAndAnswers(QUESTIONS_AND_ANSWERS);
    }
  }, [querySearch, search]);

  return (
    <section className="br-faq__questions-section">
      {renderedQuestions.length ? (
        <Collapse
          bordered={false}
          activeKey={activeKeys}
          onChange={setActiveKeys}
        >
          {renderedQuestions}
        </Collapse>
      ) : (
        <div className="br-faq__questions-section__not-found display-md">
          {intl.formatMessage({
            id: 'faq.not_found'
          })}
        </div>
      )}
    </section>
  );
};

export default injectIntl(QuestionsSection);
