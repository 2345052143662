import { useIntl } from 'react-intl';

import { INTERNATIONAL_SHIPPING_FEATURES } from 'constants/international-shipping';

import FeaturesImage from 'assets/images/international-shipping-features.png';

import './InternationalShippingFeatures.less';

const InternationalShippingFeatures = () => {
  const intl = useIntl();

  return (
    <div className="br-international-shipping__features-container">
      <div className="br-international-shipping__features-content">
        <div className="display-xl">
          {intl.formatMessage({ id: 'international_shipping.features.title' })}
        </div>
        <img src={FeaturesImage} alt="dashboard-image" />
      </div>
      <div className="br-international-shipping__features-list">
        {INTERNATIONAL_SHIPPING_FEATURES.map(
          ({ labelId, icon: Icon }, index) => (
            <div
              className="br-international-shipping__features-list__item"
              key={index}
            >
              <div className="br-international-shipping__features-list__item-icon">
                <Icon />
              </div>
              <div className="br-international-shipping__features-list__item-content">
                <div className="display-xs">
                  {intl.formatMessage({
                    id: `international_shipping.features.list.${labelId}.title`
                  })}
                </div>
                <div className="br-international-shipping__features-list__subtitle display-xs">
                  {intl.formatMessage({
                    id: `international_shipping.features.list.${labelId}.subtitle`
                  })}
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default InternationalShippingFeatures;
