export const ABOUT_CAROUSEL_DATA = [
  // {
  //   link: 'https://egyptian-gazette.com/technology/amr-soltan-joins-bosta-as-a-managing-director/'
  // },
  {
    link: 'https://english.alarabiya.net/News/middle-east/2021/09/08/-Game-changers-Forbes-announces-Middle-East-s-30-under-30-list'
  },
  {
    link: 'https://waya.media/egypts-bosta-expands-to-saudi-arabia/'
  },
  {
    link: 'https://thestartupscene.me/INVESTMENTS/Egypt-s-Bosta-to-Expand-Last-Mile-Delivery-Service-to-KSA-UAE'
  },
  {
    link: 'https://www.menabytes.com/bosta-series-a-2021/'
  }
];
