import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import BRButton from 'components/BRButton/BRButton';
import BlogArticles from './components/BlogArticles/BlogArticles';
import Blog_Homepage_Header from 'assets/images/Blog_Homepage_Header.png';

import './Blog.less';

const Blog = ({ intl, history }) => {
  return (
    <div className="br-blog-page">
      <div className="br-blog-home__header">
        <span className="br-blog-home__image-container">
          <img
            src={Blog_Homepage_Header}
            alt="header"
            className="br-blog-home__image"
          />
        </span>
        <div className="br-blog-home__image-info">
          <h1 className="br-header__title header-title">
            {intl.formatMessage({ id: 'articles.home.title' })}
          </h1>

          <div className="br-header__subtitle display-sm">
            {intl.formatMessage({ id: 'sign_up_and_start.title' })}
          </div>
          <div className="br__sign-up-and-start__section__container__content-cta">
            <BRButton
              type="destructive-primary"
              className="button-lg"
              onClick={() => history.push('/signup')}
              label={intl.formatMessage({
                id: 'sign_up_and_start.start_shipping'
              })}
            />
          </div>
        </div>
      </div>
      <div className="br-blog-home__articles">
        <BlogArticles />
      </div>
    </div>
  );
};

export default withRouter(injectIntl(Blog));
