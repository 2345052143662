import MobileHero from './components/MobileHero/MobileHero';
import MobileFeatures from './components/MobileFeatures/MobileFeatures';
import DownloadApp from './components/DownloadApp/DownloadApp';
import TrustedCompanies from 'components/TrustedCompanies/TrustedCompanies';
import SignUpAndStart from 'components/SignUpAndStart/SignUpAndStart';

const Mobile = () => {
  return (
    <>
      <MobileHero />
      <MobileFeatures />
      <DownloadApp />
      <TrustedCompanies />
      <SignUpAndStart />
    </>
  );
};

export default Mobile;
