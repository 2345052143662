import { fmt } from 'components/IntlWrapper/IntlWrapper';

import EasyProcessImage from 'assets/images/dashboard-features-easy-process-image.png';
import MultipleOrderImage from 'assets/images/dashboard-features-multiple_order_creation-image.png';
import FlexiblePickupsImage from 'assets/images/dashboard-features-flexible_pickups-image.png';
import TeamManagementImage from 'assets/images/dashboard-features-team-management-image.png';
import LanguagesSupportImage from 'assets/images/dashboard-features-languages-support-image.png';
import TicketSupportImage from 'assets/images/dashboard-features-ticket-support-image.png';
import OrderTrackingImage from 'assets/images/dashboard-features-order-tracking-image.png';
import InsightfulReportingImage from 'assets/images/dashboard-features-insightful_reporting-image.png';
import WalletManagementImage from 'assets/images/dashboard-features-wallet-management-image.png';

export const DASHBOARD_SHIPPING_NEEDS_FEATUERS = [
  {
    title: fmt({
      id: 'dashboard.dashboard_features.shipping_needs.easy_process.title'
    }),
    subtitle: fmt({
      id: 'dashboard.dashboard_features.shipping_needs.easy_process.subtitle'
    }),
    image: <img src={EasyProcessImage} alt="easy-process" />,
    positionRight: true
  },
  {
    title: fmt({
      id: 'dashboard.dashboard_features.shipping_needs.multiple_order_creation.title'
    }),
    subtitle: fmt({
      id: 'dashboard.dashboard_features.shipping_needs.multiple_order_creation.subtitle'
    }),
    image: <img src={MultipleOrderImage} alt="multiple-order" />
  },
  {
    title: fmt({
      id: 'dashboard.dashboard_features.shipping_needs.flexible_pickups.title'
    }),
    subtitle: fmt({
      id: 'dashboard.dashboard_features.shipping_needs.flexible_pickups.subtitle'
    }),
    image: <img src={FlexiblePickupsImage} alt="flexible-pickups" />,
    positionRight: true
  },
  {
    title: fmt({
      id: 'dashboard.dashboard_features.shipping_needs.team_management.title'
    }),
    subtitle: fmt({
      id: 'dashboard.dashboard_features.shipping_needs.team_management.subtitle'
    }),
    image: <img src={TeamManagementImage} alt="team-management" />
  },
  {
    title: fmt({
      id: 'dashboard.dashboard_features.shipping_needs.languages_support.title'
    }),
    subtitle: fmt({
      id: 'dashboard.dashboard_features.shipping_needs.languages_support.subtitle'
    }),
    image: <img src={LanguagesSupportImage} alt="language-support" />,
    positionRight: true
  },
  {
    title: fmt({
      id: 'dashboard.dashboard_features.shipping_needs.ticket_support.title'
    }),
    subtitle: fmt({
      id: 'dashboard.dashboard_features.shipping_needs.ticket_support.subtitle'
    }),
    image: <img src={TicketSupportImage} alt="ticket-support" />
  }
];

export const DASHBOARD_VISIBILITY_WITH_FULL_CONTROL_FEATURES = [
  {
    title: fmt({
      id: 'dashboard.dashboard_features.visibility_with_full_control.order_tracking.title'
    }),
    subtitle: fmt({
      id: 'dashboard.dashboard_features.visibility_with_full_control.order_tracking.subtitle'
    }),
    image: <img src={OrderTrackingImage} alt="order-tracking" />,
    positionRight: true
  },
  {
    title: fmt({
      id: 'dashboard.dashboard_features.visibility_with_full_control.insightful_reporting.title'
    }),
    subtitle: fmt({
      id: 'dashboard.dashboard_features.visibility_with_full_control.insightful_reporting.subtitle'
    }),
    image: <img src={InsightfulReportingImage} alt="Insightful reporting" />
  },
  {
    title: fmt({
      id: 'dashboard.dashboard_features.visibility_with_full_control.wallet_management.title'
    }),
    subtitle: fmt({
      id: 'dashboard.dashboard_features.visibility_with_full_control.wallet_management.subtitle'
    }),
    image: <img src={WalletManagementImage} alt="wallet-management" />,
    positionRight: true
  }
];
