import React from 'react';

import FulfillmentHero from './Components/FulfillmentHero';
import FulfillmentHighlight from './Components/FulfillmentHighlight/FulfillmentHighlight';
import FulfillmentProcess from './Components/FulfillmentProcess/FulfillmentProcess';
import FulfillmentFeatures from './Components/FulfillmentFeatures/FulfillmentFeatures';
import FulfillmentStats from './Components/FulfillmentStats/FulfillmentStats';
import CarouselBar from 'components/CarouselBar/CarouselBar';
import FulfillmentVideo from './Components/FulfillmentVideo/FulfillmentVideo';
import FulfillmentSignup from './Components/FulfillmentSignup/FulfillmentSignup';

import './BostaFulfillment.less';

const BostaFulfillment = () => {
  return (
    <div className="br-fulfillment__landing-page">
      <FulfillmentHero />
      <FulfillmentHighlight />
      <FulfillmentProcess />
      <FulfillmentFeatures />
      <FulfillmentStats />
      <FulfillmentVideo />
      <CarouselBar />
      <FulfillmentSignup />
    </div>
  );
};

export default BostaFulfillment;
