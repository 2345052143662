export const NUMBER_TEXT = 'NUMBER_TEXT';
export const PHONE_NUMBER = 'PHONE_NUMBER';
export const TEXT = 'text';
export const phoneRegExpEgypt = /^1[0-2|5]{1}[0-9]{8}$/;
export const phoneRegExpSaudi = /^(\+9665|05)([013-9][0-9]{7})$/;
export const phoneRegExpSaudiWithoutCountryCode = /^(5)([013-9][0-9]{7})$/;
export const phoneRegExpEgyptWithZero = /^01[0-2|5]{1}[0-9]{8}$/;
export const phoneRegExpSaudiLanLineAndPhone =
  /^(\+9665|05)([013-9][0-9]{7})|^[0-9]{9}$/;

export const PHONE_NUMBER_MAX_LENGHT = 11;

export const AT_Least_EIGHT_LETTERS =
  /^[~`!@#$%^&*()_+=[\]\\{}|;':",.\/<>?a-zA-Z0-9-]{8,}$/;
export const AT_Least_ONE_DIGIT = /(?=.*\d)/;

export const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
