import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useContext, useEffect } from 'react';

import { Loader } from '@googlemaps/js-api-loader';

import { AuthContext } from 'context/AuthContext';
import { getLocaleFromLocalStorage } from 'utils/intl-wrapper';
import { pathLang, visitorCountryCode } from 'utils/helpers';
import routes from 'utils/routes';
import { HREF_LINKS } from 'constants/href-links';
import { initializeFreshChat } from 'utils/freshchat';
import { isPackPriceAvailable } from 'constants/countries/countries-mapping';
import { openModal } from 'utils/modal';
import { COUNTRIES_ENGLISH_LOCALE, LOCALE } from 'constants/intl-wrapper';

import Container from 'components/Container/Container';
import Home from 'components/Home/Home';
import AdminLogin from 'components/AdminLogin/AdminLogin';
import Article from 'components/Blog/components/Article/Article';
import SignUp from 'components/SignUp/SignUp';
import BRHeadManager from 'components/BRHeadManager/BRHeadManager';
import NotFound from 'components/NotFound/NotFound';
import BRPreviewImageModal from 'components/BRPreviewImageModal/BRPreviewImageModal';

import 'styles/global-styles.less';

const App = () => {
  const { currentUser } = useContext(AuthContext);
  const language = pathLang || getLocaleFromLocalStorage();

  useEffect(() => {
    initGoogleMaps();
    localStorage.removeItem('user');
    if (isPackPriceAvailable()) {
      openModal(BRPreviewImageModal, {
        img: COUNTRIES_ENGLISH_LOCALE.includes(language)
          ? 'https://storage.googleapis.com/bosta-fe/website/english-pack-price.png'
          : 'https://storage.googleapis.com/bosta-fe/website/arabic-pack-price.png'
      });
    }
  }, []);

  useEffect(() => {
    if (!window.location.pathname.includes('tracking-shipments')) {
      initializeFreshChat();
    }
  }, [window.location.pathname]);

  const initGoogleMaps = () => {
    new Loader({
      apiKey: window.env?.GOOGLE_MAP_API_KEY,
      version: 'weekly'
    }).load();
  };

  return (
    <Router basename={`/${language}`}>
      <BRHeadManager children={HREF_LINKS[visitorCountryCode]} />
      <Switch>
        <Route
          path={`/home`}
          exact
          render={() => <Container component={Home} />}
        />
        {routes.map(
          ({
            path,
            component,
            exact,
            key,
            haveAccess = true,
            requireAuth = false
          }) => (
            <Route
              path={path}
              exact={exact}
              key={key}
              render={() => (
                <Container
                  component={
                    requireAuth
                      ? currentUser
                        ? component
                        : AdminLogin
                      : haveAccess
                      ? component
                      : NotFound
                  }
                />
              )}
            />
          )
        )}
        <Route path="/signup" render={() => <SignUp />} />
        <Route path="/blog/:id" element={<Article />} />
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
};
export default App;
