import { useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import {
  convertArrayToNestedObject,
  regexNumber,
  regexNumberWithNegative
} from 'utils/helpers';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';

import { NUMBER_TEXT, TEXT } from 'constants/Forms';

import './BRFormInputs.less';

const BRFormInputs = (props) => {
  const [valueInput, setValueInput] = useState(null);
  const [count, setCount] = useState(valueInput ? valueInput.length : 0);
  const [countExceed, setCountExceed] = useState(false);
  const [showSubTittle, setShowSubTittle] = useState(true);
  const [isFieldHaveValue, setIsFieldHaveValue] = useState(false);

  //todo how we can change FormRef without it
  //todo onkeyDown

  const {
    maxCount,
    placeholder,
    showCounter,
    showCounterExceed = false,
    optional,
    formRef,
    disabled,
    intl,
    name,
    type,
    title,
    subtitle,
    rules,
    step,
    min,
    prefix,
    suffix,
    allowClear,
    onBlur,
    errorForm,
    help,
    validateStatus,
    autoFocus = false,
    addonAfter,
    hasFeedback,
    className,
    setInputValue,
    inputRef = null,
    maxLength,
    initialCount,
    acceptNegative = false,
    forceLowerCase = false
  } = props;

  const updateFieldValue = (value) => {
    if (Array.isArray(name)) {
      const result = convertArrayToNestedObject(name, value);
      formRef.current.setFieldsValue(result);
    } else {
      formRef.current.setFieldsValue({ [name]: value });
    }
  };

  useEffect(() => {
    if (errorForm) {
      formRef?.current
        ?.validateFields([name])
        .then((er) => {
          setShowSubTittle(true);
        })
        .catch((er) => {
          if (er.errorFields.length > 0) {
            setShowSubTittle(null);
          } else {
            setShowSubTittle(true);
          }
        });
    }
  }, [errorForm, formRef, name]);

  useEffect(() => {
    if (showCounterExceed) {
      setCountExceed(count > maxCount);
    }
  }, [count]);

  useEffect(() => {
    setShowSubTittle(true);
  }, [subtitle]);

  useEffect(() => {
    initialCount && setCount(initialCount);
  }, []);

  useEffect(() => {
    setIsFieldHaveValue(formRef?.current?.getFieldValue([name]));
  }, [formRef?.current]);

  const handleOnNumberChange = (value) => {
    const convertedValue = convertNumber(value);
    if (
      (acceptNegative ? regexNumberWithNegative : regexNumber).test(
        convertedValue
      ) ||
      value.length === 0
    ) {
      updateFieldValue(convertedValue);
      setValueInput(convertedValue);
      setCount(value.length);
    } else {
      updateFieldValue(valueInput);
    }
  };

  const handleOnTextChange = (evt) => {
    if (forceLowerCase) {
      evt.target.value = evt.target.value.toLowerCase();
    }
    const {
      currentTarget: { value }
    } = evt;
    if (setInputValue) {
      setInputValue(value);
    }
    if (type === NUMBER_TEXT) {
      handleOnNumberChange(value);
    } else {
      formRef.current.setFieldsValue({ [name]: convertNumber(value) });
      setCount(value.length);
    }

    formRef?.current
      ?.validateFields([name])
      .then((er) => setShowSubTittle(true))
      .catch((er) => setShowSubTittle(null));
  };

  const convertNumber = (inputValue) => {
    return inputValue.replace(/[٠-٩]/g, (d) => '٠١٢٣٤٥٦٧٨٩'.indexOf(d));
  };

  return (
    <div
      className={classnames('br-forms-new-input', className, {
        [`${className}_with_subtitle`]: subtitle
      })}
    >
      <Form.Item
        className={classnames({
          'br-form-with-sub-title': subtitle
        })}
        validateTrigger
        name={name}
        label={
          <div className="br-form-item-label">
            <div className="br-form-item-label__title">
              <span className="body-medium">{title}</span>
              {optional && (
                <span className="br-form-optional-label">
                  {intl.formatMessage({
                    id: 'form.optional_label'
                  })}
                </span>
              )}
              {(showCounter ||
                (showCounterExceed && countExceed && isFieldHaveValue)) && (
                <div
                  className={classnames('br-form-count__count-label body', {
                    'br-form-count__count-label-exceed': countExceed
                  })}
                >
                  <div>
                    {<span>{count}</span>}/{maxCount}
                  </div>
                </div>
              )}
            </div>
          </div>
        }
        help={
          !validateStatus && subtitle && showSubTittle ? (
            <span className="br-form-item-label__subtitle body">
              {subtitle}
            </span>
          ) : (
            help
          )
        }
        rules={rules}
        hasFeedback={!!hasFeedback}
        {...props}
      >
        <Input
          autoFocus={autoFocus}
          disabled={disabled}
          type={type === NUMBER_TEXT ? TEXT : type}
          step={step}
          min={min}
          prefix={prefix}
          suffix={suffix}
          autoComplete={'' + Math.random()}
          allowClear={allowClear}
          onChange={handleOnTextChange}
          onBlur={onBlur}
          value={valueInput ? valueInput : null}
          placeholder={placeholder || ''}
          addonAfter={addonAfter}
          ref={inputRef}
          maxLength={maxLength}
        />
      </Form.Item>
    </div>
  );
};

export default injectIntl(BRFormInputs);
