import { injectIntl } from 'react-intl';

import BRHero from 'components/BRHero/BRHero';

import { ReactComponent as Vector1 } from 'assets/images/about_hero_vector_1.svg';
import { ReactComponent as Vector2 } from 'assets/images/about_hero_vector_2.svg';

import './AboutHero.less';

const AboutHero = ({ intl }) => {
  return (
    <BRHero
      className="br-about-hero"
      title={intl.formatMessage({ id: 'about.header.title' })}
      subtitle={intl.formatMessage(
        { id: 'about.header.subtitle' },
        {
          TextDiv: (children) => (
            <div className="br-about-header__subtitle">{children}</div>
          )
        }
      )}
      vectors={[<Vector1 />, <Vector2 />]}
    />
  );
};

export default injectIntl(AboutHero);
