import { injectIntl } from 'react-intl';

import SimpleCard from 'components/Integrations/components/SimpleCard/SimpleCard';

import { ReactComponent as PluginsAndApisBg } from 'assets/images/plugins-and-apis_bg.svg';
import { ReactComponent as WooCommerceLogo } from 'assets/icons/WooCommerceLogo.svg';
import { ReactComponent as ShopifyLogo } from 'assets/icons/ShopifyLogo.svg';
import { ReactComponent as APIsDocumentationLogo } from 'assets/icons/APIsDocumentationLogo.svg';

import './PluginsAndAPIsSection.less';

const PluginsAndAPIsSection = ({ intl }) => {
  const pluginsAndAPIs = [
    {
      title: intl.formatMessage({
        id: 'integrations.plugins_and_apis.woocommerce'
      }),
      image: <WooCommerceLogo />
    },
    {
      title: intl.formatMessage({
        id: 'integrations.plugins_and_apis.shopify'
      }),
      image: <ShopifyLogo />
    },
    {
      title: intl.formatMessage({
        id: 'integrations.plugins_and_apis.apis_documentation'
      }),
      image: <APIsDocumentationLogo />
    }
  ];

  const renderedPluginsAndAPIsCards = pluginsAndAPIs.map(
    ({ title, image }, index) => (
      <SimpleCard
        key={index}
        title={title}
        image={image}
        animated
        from={`${(index + 1) * 20}%`}
      />
    )
  );

  return (
    <section className="br-integrations__plugins-and-apis">
      <div className="br-integrations__plugins-and-apis__bg">
        <PluginsAndApisBg />
      </div>
      <h2 className="br-integrations__plugins-and-apis__title display-lg">
        {intl.formatMessage({
          id: 'integrations.plugins_and_apis.title'
        })}
      </h2>
      <h4 className="br-integrations__plugins-and-apis__subtitle display-sm">
        {intl.formatMessage({
          id: 'integrations.plugins_and_apis.subtitle'
        })}
      </h4>
      <div className="br-integrations__plugins-and-apis_cards">
        {renderedPluginsAndAPIsCards}
      </div>
    </section>
  );
};

export default injectIntl(PluginsAndAPIsSection);
