import { injectIntl } from 'react-intl';

import './PrivacyPolicy.less';

const PrivacyPolicy = ({ intl }) => {
  return (
    <div className="br-privacy-policy">
      <span className="grid-title">
        {intl.formatMessage({ id: 'privacy_policy.title' })}
      </span>
      <div className="display-xs">
        {intl.formatMessage({ id: 'privacy_policy.subtitle' })}
      </div>
      <div className="br-privacy-ploicy__description">
        {intl.formatMessage({ id: 'privacy_policy.intro_desc_1' })}
      </div>
      <div className="br-privacy-ploicy__description">
        {intl.formatMessage({ id: 'privacy_policy.intro_desc_2' })}
      </div>
      <div className="br-privacy-ploicy__description">
        {intl.formatMessage({ id: 'privacy_policy.intro_desc_3' })}
      </div>
      <div className="display-xs">
        {intl.formatMessage({
          id: 'privacy_policy.inforamtion_collection.title'
        })}
      </div>
      <div className="br-privacy-ploicy__description">
        {intl.formatMessage({
          id: 'privacy_policy.inforamtion_collection.desc'
        })}
      </div>
      <div className="display-xs">
        {intl.formatMessage({
          id: 'privacy_policy.log_data.title'
        })}
      </div>
      <div className="br-privacy-ploicy__description">
        {intl.formatMessage({
          id: 'privacy_policy.log_data.desc_1'
        })}
      </div>
      <div className="br-privacy-ploicy__description">
        {intl.formatMessage({
          id: 'privacy_policy.log_data.desc_2'
        })}
      </div>
      <div className="br-privacy-ploicy__description">
        {intl.formatMessage({
          id: 'privacy_policy.log_data.desc_3'
        })}
      </div>
      <div className="display-xs">
        {intl.formatMessage({
          id: 'privacy_policy.cookies.title'
        })}
      </div>
      <div className="br-privacy-ploicy__description">
        {intl.formatMessage({
          id: 'privacy_policy.cookies.desc_1'
        })}
      </div>
      <div className="br-privacy-ploicy__description">
        {intl.formatMessage({
          id: 'privacy_policy.cookies.desc_2'
        })}
      </div>
      <div className="display-xs">
        {intl.formatMessage({
          id: 'privacy_policy.security.title'
        })}
      </div>
      <div className="br-privacy-ploicy__description">
        {intl.formatMessage({
          id: 'privacy_policy.security.desc'
        })}
      </div>
      <div className="display-xs">
        {intl.formatMessage({
          id: 'privacy_policy.changes_to_the_privay_policy.title'
        })}
      </div>
      <div className="br-privacy-ploicy__description">
        {intl.formatMessage({
          id: 'privacy_policy.changes_to_the_privay_policy.desc_1'
        })}
      </div>
      <div className="br-privacy-ploicy__description">
        {intl.formatMessage({
          id: 'privacy_policy.changes_to_the_privay_policy.desc_2'
        })}
      </div>
      <div className="br-privacy-ploicy__description">
        {intl.formatMessage({
          id: 'privacy_policy.changes_to_the_privay_policy.desc_3'
        })}
      </div>
      <div className="br-privacy-ploicy__description">
        {intl.formatMessage({
          id: 'privacy_policy.changes_to_the_privay_policy.desc_4'
        })}
      </div>
      <div className="br-privacy-ploicy__description">
        {intl.formatMessage({
          id: 'privacy_policy.changes_to_the_privay_policy.desc_5'
        })}
        <span className="br-privacy-ploicy__help-link">
          {intl.formatMessage({
            id: 'privacy_policy.changes_to_the_privay_policy.desc_6'
          })}
        </span>
        {intl.formatMessage({
          id: 'privacy_policy.changes_to_the_privay_policy.desc_7'
        })}
      </div>
    </div>
  );
};

export default injectIntl(PrivacyPolicy);
