import classnames from 'classnames';
import Icon from '@ant-design/icons';

import { ReactComponent as CircleCheck } from 'assets/icons/Circle-Check.svg';

import './TrackingTimelineDot.less';

const TrackingTimelineDot = (dot, { status, index }) => {
  const isDone = status === 'finish';

  return (
    <div
      className={classnames('br-tracking-timeline-dot__container', {
        'br-tracking-timeline-dot__finished': isDone
      })}
    >
      {isDone && <Icon component={CircleCheck} className={'ant-icon-md'} />}

      {dot}
    </div>
  );
};

export default TrackingTimelineDot;
