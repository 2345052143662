import React from 'react';
import { injectIntl } from 'react-intl';

import BRButton from 'components/BRButton/BRButton';

const FulfillmentHero = ({ intl }) => {
  const { formatMessage } = intl;

  const scrollToRegisterForm = () => {
    let signupSection = document.getElementById('signup-section');
    if (signupSection) {
      signupSection.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <div className="br-fulfillment__hero-section">
      <div className="br-fulfillment__hero-content">
        <p className="br-fulfillment__hero-section-header">
          {formatMessage({
            id: 'bosta_fulfillment.hero.header'
          })}
        </p>
        <p className="br-fulfillment__hero-section-description">
          {formatMessage({
            id: 'bosta_fulfillment.hero.description'
          })}
        </p>
        <BRButton
          label={formatMessage({
            id: 'bosta_fulfillment.hero.signup'
          })}
          type="destructive-primary"
          onClick={scrollToRegisterForm}
          className="br-fulfillment__hero-submit-button"
        />
      </div>
    </div>
  );
};

export default injectIntl(FulfillmentHero);
