import { injectIntl } from 'react-intl';

import { ReactComponent as Email } from 'assets/icons/mailConfirmation.svg';

import './FormConfirmation.less';

const FormConfirmation = ({ intl }) => {
  return (
    <div className="br-form-confirmation-container">
      <Email />
      <span className=" br-form-confirmation-title">
        {intl.formatMessage({ id: 'contact_sales.confirm_mail_title' })}
      </span>
      <span className="br-form-confirmation-subtitle">
        {intl.formatMessage({ id: 'contact_sales.confirm_mail_subtitle' })}
      </span>
    </div>
  );
};

export default injectIntl(FormConfirmation);
