import { useRef, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Form, Input, Upload, Tabs } from 'antd';
import Icon, { UploadOutlined } from '@ant-design/icons';
import { doc, setDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

import { db, storage } from 'firebaseConfig';
import { cleanEmptyString, convertArrayToNestedObject } from 'utils/helpers';
import { LOCALE } from 'constants/intl-wrapper';

import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BRRichTextEditor from 'components/BRRichTextEditor/BRRichTextEditor';

import { ReactComponent as CloseIcon } from 'assets/icons/Close.svg';

import './CreateEditArticle.less';

const COUNTRIES = [
  { flag: 'eg', label: 'Egypt' },
  { flag: 'sa', label: 'KSA' }
];

const CreateEditArticle = ({ match: { params: id }, location: { state } }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const formRef = useRef('');

  useEffect(() => {
    if (state?.article) {
      formRef.current.setFieldsValue(state.article);
    }
  }, []);

  const customRequest = ({ onSuccess, file, name }) => {
    setTimeout(async () => {
      onSuccess();
      await uploadImage({ img: file, name });
    }, 0);
  };

  const uploadImage = async ({ img, name }) => {
    setIsImageUploading(true);
    const storageRef = ref(storage, `/blogs/${img?.name}`);
    const uploadTask = await uploadBytesResumable(storageRef, img);
    const url = await getDownloadURL(uploadTask.ref);
    updateFieldValue({ value: url, name });
    setIsImageUploading(false);
    return url;
  };

  const handleCreateNewArticle = async (data) => {
    try {
      setIsLoading(true);
      const articlesRef = doc(db, 'articles', 'articles');
      await setDoc(
        articlesRef,
        {
          [data.id]: {
            ...cleanEmptyString(data),
            lastUpdate: new Date()
          }
        },
        { merge: true }
      );
      notify({ msg: 'Saved Successfully', type: 'success' });
    } catch (error) {
      notify({ msg: error.message });
    }
    setIsLoading(false);
  };

  const updateFieldValue = ({ value, name }) => {
    if (Array.isArray(name)) {
      const result = convertArrayToNestedObject(name, value);
      formRef?.current.setFieldsValue(result);
    } else {
      formRef?.current.setFieldsValue({ [name]: value });
    }
  };

  const commonContent = (lang) => {
    return (
      <>
        <Form.Item name={[lang, 'keywords']} label="Keywords">
          <Input />
        </Form.Item>
        <Form.Item name={[lang, 'keywordMSV']} label="Keyword MSV">
          <Input />
        </Form.Item>
        <Form.Item name={[lang, 'authorName']} label="Author Name">
          <Input />
        </Form.Item>
      </>
    );
  };

  const sectionContent = ({ name, lang, restField }) => {
    return (
      <>
        <Form.Item {...restField} name={[name, lang, 'title']} label="Title">
          <Input />
        </Form.Item>
        <Form.Item
          {...restField}
          name={[name, lang, 'subtitle']}
          label="Subtitle"
        >
          <Input />
        </Form.Item>
        <Form.Item
          {...restField}
          name={[name, lang, 'content']}
          label="Content"
        >
          <BRRichTextEditor
            onChange={(value) =>
              updateFieldValue({ value, name: [name, lang, 'content'] })
            }
          />
          {/* <Input.TextArea /> */}
        </Form.Item>
        <Form.Item
          {...restField}
          name={[name, lang, 'sectionImage']}
          label="Section Image"
        >
          <Upload
            accept=".png, .jpg, .jpeg"
            maxCount={1}
            customRequest={(data) =>
              customRequest({
                ...data,
                name: [name, lang, 'sectionImage']
              })
            }
          >
            <BRButton label="Upload Section Image" icon={<UploadOutlined />} />
          </Upload>
        </Form.Item>
        <Form.Item
          {...restField}
          name={[name, lang, 'imageDisclaimer']}
          label="Image Disclaimer"
        >
          <Input />
        </Form.Item>
      </>
    );
  };

  return (
    <LoadingWrapper loading={isLoading}>
      <div className="br-create-edit-article">
        <Form onFinish={handleCreateNewArticle} ref={formRef}>
          <Form.Item name="id" label="Article URL" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="publishDate" label="Publish Date">
            <Input />
          </Form.Item>
          <div className="br-create-edit-article__sections">
            <div>
              <h1> English Article Info</h1>
              {commonContent(LOCALE.EN)}
            </div>
            <div>
              <h1> Arabic Article Info</h1>
              {commonContent(LOCALE.AR)}
            </div>
          </div>
          <Tabs
            defaultActiveKey="0"
            tabPosition={'top'}
            items={COUNTRIES.map(({ flag: country, label }, i) => {
              const id = String(i);
              return {
                label,
                key: id,
                children: (
                  <>
                    <div className="br-create-edit-article__sections">
                      <div>
                        <h1>English Content</h1>
                        <Form.Item
                          name={[country, 'en', 'articleTitle']}
                          label="English Article title"
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name={[country, 'en', 'articleIntro']}
                          label="Article Intro"
                        >
                          <BRRichTextEditor
                            onChange={(value) =>
                              updateFieldValue({
                                value,
                                name: [country, 'en', 'articleIntro']
                              })
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          name={[country, 'en', 'bannerImage']}
                          label="Banner Image"
                        >
                          <Upload
                            accept=".png, .jpg, .jpeg"
                            maxCount={1}
                            customRequest={(data) =>
                              customRequest({
                                ...data,
                                name: [country, 'en', 'bannerImage']
                              })
                            }
                          >
                            <BRButton
                              label="Upload Banner Image"
                              icon={<UploadOutlined />}
                            />
                          </Upload>
                        </Form.Item>
                        <Form.Item
                          name={[country, 'en', 'thumbnail']}
                          label="Thumbnail Image"
                        >
                          <Upload
                            accept=".png, .jpg, .jpeg"
                            maxCount={1}
                            customRequest={(data) =>
                              customRequest({
                                ...data,
                                name: [country, 'en', 'thumbnail']
                              })
                            }
                          >
                            <BRButton
                              label="Upload Thumbnail Image"
                              icon={<UploadOutlined />}
                            />
                          </Upload>
                        </Form.Item>
                      </div>
                      <div>
                        <h1>Arabic Content</h1>
                        <Form.Item
                          name={[country, 'ar', 'articleTitle']}
                          label="Article title"
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name={[country, 'ar', 'articleIntro']}
                          label="Article Intro"
                        >
                          <BRRichTextEditor
                            onChange={(value) =>
                              updateFieldValue({
                                value,
                                name: [country, 'ar', 'articleIntro']
                              })
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          name={[country, 'ar', 'bannerImage']}
                          label="Banner Image"
                        >
                          <Upload
                            accept=".png, .jpg, .jpeg"
                            maxCount={1}
                            customRequest={(data) =>
                              customRequest({
                                ...data,
                                name: [country, 'ar', 'bannerImage']
                              })
                            }
                          >
                            <BRButton
                              label="Upload Banner Image"
                              icon={<UploadOutlined />}
                            />
                          </Upload>
                        </Form.Item>
                        <Form.Item
                          name={[country, 'ar', 'thumbnail']}
                          label="Thumbnail Image"
                        >
                          <Upload
                            accept=".png, .jpg, .jpeg"
                            maxCount={1}
                            customRequest={(data) =>
                              customRequest({
                                ...data,
                                name: [country, 'ar', 'thumbnail']
                              })
                            }
                          >
                            <BRButton
                              label=" Upload Thumbnail Image"
                              icon={<UploadOutlined />}
                            />
                          </Upload>
                        </Form.Item>
                      </div>
                    </div>
                    <Form.List name={[country, 'sections']}>
                      {(fields, { add, remove }) => (
                        <div>
                          {fields.map(({ key, name, ...restField }) => (
                            <div
                              key={key}
                              className="br-article-section__container"
                            >
                              <div>
                                <h1>English Section Content</h1>
                                {sectionContent({
                                  name,
                                  lang: LOCALE.EN,
                                  ...restField
                                })}
                              </div>
                              <div>
                                <h1>Arabic Section Content</h1>
                                {sectionContent({
                                  name,
                                  lang: LOCALE.AR,
                                  ...restField
                                })}
                              </div>

                              <Icon
                                component={CloseIcon}
                                className="ant-icon-sm"
                                onClick={() => remove(name)}
                              />
                            </div>
                          ))}
                          <BRButton
                            type="link-color"
                            className="button-lg"
                            onClick={() => add()}
                            // prefixIcon={<Icon component={PlusIcon} />}
                            label="Add Section"
                          />
                        </div>
                      )}
                    </Form.List>
                  </>
                )
              };
            })}
          />

          <BRButton
            htmlType="submit"
            type="primary"
            className="button-lg"
            label="Save"
            loading={isLoading || isImageUploading}
            disabled={isLoading || isImageUploading}
          />
        </Form>
      </div>
    </LoadingWrapper>
  );
};

export default withRouter(CreateEditArticle);
