import { fmt } from 'components/IntlWrapper/IntlWrapper';

export const MOBILE_FEATURES_START_NOW = [
  {
    title: fmt({ id: 'mobile.features.start_now.one_step_sign_up.title' }),
    subtitle: fmt({ id: 'mobile.features.start_now.one_step_sign_up.subtitle' })
  },
  {
    title: fmt({ id: 'mobile.features.start_now.no_more_waiting.title' }),
    subtitle: fmt({ id: 'mobile.features.start_now.no_more_waiting.subtitle' })
  }
];

export const MOBILE_FEATURES_ORDER_CREATION = [
  {
    title: fmt({
      id: 'mobile.features.seamless_order_creation.order_creation.title'
    }),
    subtitle: fmt({
      id: 'mobile.features.seamless_order_creation.order_creation.subtitle'
    })
  },
  {
    title: fmt({
      id: 'mobile.features.seamless_order_creation.pack_for_you.title'
    }),
    subtitle: fmt({
      id: 'mobile.features.seamless_order_creation.pack_for_you.subtitle'
    })
  }
];

export const MOBILE_FEATURES_UPDATE = [
  {
    title: fmt({
      id: 'mobile.features.up_to_date.stay_notified.title'
    }),
    subtitle: fmt({
      id: 'mobile.features.up_to_date.stay_notified.subtitle'
    })
  },
  {
    title: fmt({
      id: 'mobile.features.up_to_date.live_tracking.title'
    }),
    subtitle: fmt({
      id: 'mobile.features.up_to_date.live_tracking.subtitle'
    })
  }
];

export const MOBILE_FEATURES_WALLET = [
  {
    title: fmt({
      id: 'mobile.features.simplified_wallet.monitor_balance.title'
    }),
    subtitle: fmt({
      id: 'mobile.features.simplified_wallet.monitor_balance.subtitle'
    })
  },
  {
    title: fmt({
      id: 'mobile.features.simplified_wallet.cash_out.title'
    }),
    subtitle: fmt({
      id: 'mobile.features.simplified_wallet.cash_out.subtitle'
    })
  }
];
