import { Modal } from 'antd';
import Icon from '@ant-design/icons';

import BRButton from 'components/BRButton/BRButton';

import './BRSuccessModal.less';

const BRSuccessModal = ({
  icon,
  title,
  subtitle,
  primaryBtnProps,
  closeBtnProps,
  close,
  wrapClassName = '',
  ...props
}) => {
  return (
    <Modal
      wrapClassName={`br-success-modal ${wrapClassName}`}
      title={null}
      footer={null}
      width={null}
      {...props}
    >
      {icon && <Icon component={icon} className="br-success-modal__icon" />}
      <div className="br-success-modal__title">{title}</div>
      {subtitle && <div className="br-success-modal__subtitle">{subtitle}</div>}
      {primaryBtnProps && <BRButton {...primaryBtnProps} />}
      <BRButton {...closeBtnProps} onClick={close} />
    </Modal>
  );
};

export default BRSuccessModal;
