import { bostaHttp } from 'utils/http';

import { getCurrentUserCountryData } from 'constants/countries/countries-mapping';

const userCountry = () =>
  JSON.parse(localStorage.getItem('userInfo'))?.user?.country?._id;

export const getAllAreas = (payload) => {
  let url = `/cities/getAllDistricts`;
  const { id } = getCurrentUserCountryData();
  if (id) {
    url = `/cities/getAllDistricts?countryId=${id}`;
  }
  return bostaHttp.get(url, payload);
};

export const fetchCities = (payload) => {
  let url = `/cities`;
  if (userCountry()) {
    url = `/cities?countryId=${userCountry()}`;
  }
  return bostaHttp.get(url, payload);
};
