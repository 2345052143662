import { useState } from 'react';
import { Input } from 'antd';

import { numberRegex } from 'utils/helpers';

const BRFormInputNumber = ({
  maxLength,
  placeholder,
  autoComplete,
  value = null,
  disabled,
  refName,
  handleOnPaste,
  handleOnChangeForm,
  filedName
}) => {
  const [inputValue, setInputValue] = useState(value);

  const handleOnChange = (evt) => {
    const value = evt.currentTarget.value;

    if (numberRegex.test(value) || value.length === 0) {
      handleOnChangeForm(filedName, value);
      setInputValue(value);
      return true;
    } else {
      return false;
    }
  };

  return (
    <Input
      type="text"
      data-hj-allow
      value={inputValue}
      onChange={handleOnChange}
      maxLength={maxLength}
      placeholder={placeholder}
      autoComplete={autoComplete}
      disabled={disabled}
      onPaste={handleOnPaste}
      ref={refName}
    />
  );
};

export default BRFormInputNumber;
