import { useContext } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Input } from 'antd';
import { withRouter } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';

import { auth } from 'firebaseConfig';
import { AuthContext } from 'context/AuthContext';
import { emailRule, requiredRule } from 'utils/forms';

import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';

import './AdminLogin.less';

const AdminLogin = ({ intl, history }) => {
  const { dispatch } = useContext(AuthContext);

  const handleAdminLogin = ({ email, password }) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        dispatch({ type: 'LOGIN', payload: user });
        history.push('/blogs-cms');
        // ...
      })
      .catch(({ message }) => {
        notify({ msg: message });
      });
  };
  return (
    <div className="br-admin-login-container">
      <Form onFinish={handleAdminLogin}>
        <Form.Item
          name="email"
          label="Email"
          validateTrigger="onBlur"
          rules={[
            requiredRule(
              intl.formatMessage(
                {
                  id: 'form.required_custom'
                },
                {
                  label: intl.formatMessage({
                    id: 'contact_sales.form_validation.email'
                  })
                }
              )
            ),
            emailRule(
              intl.formatMessage({
                id: 'form.email_not_valid'
              })
            )
          ]}
        >
          <Input
            placeholder={intl.formatMessage({
              id: 'contact_sales.form_placeholder.email'
            })}
          />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[{ required: true }]}
        >
          <Input placeholder="password" />
        </Form.Item>
        <BRButton
          label="Login"
          type="primary"
          htmlType="submit"
          className="button-lg"
        />
      </Form>
    </div>
  );
};

export default injectIntl(withRouter(AdminLogin));
