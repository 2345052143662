import React, { useEffect, useRef } from 'react';
import { injectIntl } from 'react-intl';

import { CONNECT_AND_SAVE_CONTENT } from 'constants/merged-integrations';
import { mediaHook } from 'customHooks';
import { renderSVGs } from 'utils/animations';
import { intersectionObserver } from 'utils/animations';

import Grid from 'components/Grid/Grid';

import ConnectAndSaveSectionMain from 'assets/images/ConnectAndSaveSectionMain.svg';
import { ReactComponent as ConnectAndSaveSectionSVG1 } from 'assets/images/ConnectAndSaveSectionSVG1.svg';
import { ReactComponent as ConnectAndSaveSectionSVG2 } from 'assets/images/ConnectAndSaveSectionSVG2.svg';
import { ReactComponent as ConnectAndSaveSectionSVG3 } from 'assets/images/ConnectAndSaveSectionSVG3.svg';
import { ReactComponent as ConnectAndSaveSectionSVG4 } from 'assets/images/ConnectAndSaveSectionSVG4.svg';
import { ReactComponent as ConnectAndSaveSectionSVG5 } from 'assets/images/ConnectAndSaveSectionSVG5.svg';
import { ReactComponent as ConnectAndSaveSectionSVG7 } from 'assets/images/cloud1.svg';
import { ReactComponent as ConnectAndSaveSectionSVG8 } from 'assets/images/ConnectAndSaveSectionSVG8.svg';
import { ReactComponent as ConnectAndSaveSectionSVG9 } from 'assets/images/ConnectAndSaveSectionSVG9.svg';
import { ReactComponent as ConnectAndSaveSectionSVG10 } from 'assets/images/ConnectAndSaveSectionSVG10.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';
import { ReactComponent as DeliveryLocationIcon } from 'assets/icons/delivery_location.svg';
import { ReactComponent as PrinterIcon } from 'assets/icons/printer.svg';
import { ReactComponent as PickupLocationIcon } from 'assets/icons/pickup_location.svg';
import { ReactComponent as MessageIcon } from 'assets/icons/message.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';

import './ConnectAndSaveSection.less';

const CONNECT_AND_SAVE_ICONS = [
  SettingsIcon,
  DeliveryLocationIcon,
  PrinterIcon,
  PickupLocationIcon,
  MessageIcon,
  SearchIcon
];

const CONNECTANDSAVE_SVGS = [
  {
    Vector: ConnectAndSaveSectionSVG1,
    from: '300%',
    fromBottom: true,
    opacityFrom: 1
  },
  {
    Vector: ConnectAndSaveSectionSVG2,
    from: '300%',
    fromBottom: true,
    opacityFrom: 1
  },
  {
    Vector: ConnectAndSaveSectionSVG3,
    from: '200%',
    fromBottom: true,
    opacityFrom: 1
  },
  {
    Vector: ConnectAndSaveSectionSVG4,
    from: '300%',
    fromBottom: true,
    opacityFrom: 1
  },
  {
    Vector: ConnectAndSaveSectionSVG5,
    from: '100%',
    fromBottom: true,
    opacityFrom: 1
  },
  {
    Vector: ConnectAndSaveSectionSVG2,
    from: '85%',
    fromSide: true,
    opacityFrom: 1
  },
  { Vector: ConnectAndSaveSectionSVG7, from: '100%', fromSide: true },
  {
    Vector: ConnectAndSaveSectionSVG8,
    from: '50%',
    fromSide: true
  },
  {
    Vector: ConnectAndSaveSectionSVG9,
    opacityFrom: 1
  },
  {
    Vector: ConnectAndSaveSectionSVG10,
    opacityFrom: 1
  }
];

const ConnectAndSaveSection = ({ intl, mobileScreenSizes }) => {
  const { isLargeMobileScreen } = mobileScreenSizes;

  const connectAndSaveSvgsRef = useRef();

  const observerOptions = { rootMargin: '-15%' };

  const observerCallback = (entries, observer) => {
    entries.forEach((entry) => {
      let classList = entry.target.classList;
      if (entry.isIntersecting) {
        classList.add('isVisible');
        classList.remove('isHidden');
        entry.target.childNodes.forEach((svg) => {
          if (svg.classList.contains('from-side')) {
            svg.firstChild.classList.add('slide-from-side');
          } else if (svg.classList.contains('from-bottom')) {
            svg.firstChild.classList.add('slide-from-bottom');
          } else if (svg.classList.contains('from-side-main')) {
            svg.classList.add('slide-from-side');
          }
        });
        observer.disconnect();
      }
    });
  };

  useEffect(() => {
    intersectionObserver(observerCallback, observerOptions, [
      connectAndSaveSvgsRef?.current
    ]);
  }, []);

  return (
    <section className="br-merged-integrations__connect-and-save">
      <div className="br-merged-integrations__connect-and-save__content">
        <Grid
          gridTitle={intl.formatMessage({
            id: 'merged_integrations.connect_and_save.title'
          })}
          items={CONNECT_AND_SAVE_CONTENT}
          icons={CONNECT_AND_SAVE_ICONS}
        />
      </div>
      <div
        className="br-merged-integrations__connect-and-save__image isHidden"
        ref={connectAndSaveSvgsRef}
      >
        {isLargeMobileScreen ? (
          <img
            src={ConnectAndSaveSectionMain}
            className="from-side-main"
            alt="Connect and Save"
          />
        ) : (
          renderSVGs(CONNECTANDSAVE_SVGS)
        )}
      </div>
    </section>
  );
};

export default injectIntl(mediaHook(ConnectAndSaveSection));
