import { useState } from 'react';
import { Pagination } from 'antd';

import './CarouselMobile.less';

const CarouselMobile = ({ carouselContent = [] }) => {
  const [currentCarouselPage, setCurrentCarouselPage] = useState(0);

  const handleCarouselPageChange = (page, pageSize) => {
    setCurrentCarouselPage(page - 1);
  };

  return (
    <>
      <div className="br-partners-mobile-carousel-container">
        {carouselContent[currentCarouselPage]?.map((items, index) => (
          <div className="br-partners-mobile-carousel-item" key={index}>
            {items}
          </div>
        ))}
      </div>
      <Pagination
        defaultCurrent={1}
        defaultPageSize={4}
        total={carouselContent.length * 4}
        className="br-mobile-partners__pagination"
        itemRender={(_, type, Element) => {
          if (['next', 'prev'].includes(type)) return Element;
          return <div></div>;
        }}
        onChange={handleCarouselPageChange}
      />
    </>
  );
};

export default CarouselMobile;
