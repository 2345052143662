import { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { getDocs, collection, query } from 'firebase/firestore';
import classnames from 'classnames';
import dayjs from 'dayjs';

import { db } from 'firebaseConfig';
import { COUNTRIES_ENGLISH_LOCALE, LOCALE } from 'constants/intl-wrapper';
import {
  ARABIC_LANG,
  formatArabicFieldName,
  isURLHavingKSA
} from 'utils/helpers';

import { notify } from 'components/Notify/Notify';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import ProgressiveImg from 'components/ProgressiveImage/ProgressiveImage';

import './Article.less';

const Article = ({
  intl,
  match: {
    params: { id }
  },
  history
}) => {
  const [article, setArticle] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [locale, setLocale] = useState(
    COUNTRIES_ENGLISH_LOCALE.includes(intl.locale) ? LOCALE.EN : ARABIC_LANG
  );

  const getArticle = async () => {
    try {
      setIsLoading(true);
      const q = query(collection(db, 'articles'));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        const pageData = doc.data()[id];

        let countryData =
          isURLHavingKSA && pageData?.sa ? pageData.sa : pageData?.eg;

        if (countryData?.sections?.length) {
          countryData.sections = formatArabicFieldName(countryData.sections);
        }

        let newLocale = locale;
        if (!Object.keys(countryData[locale]).length) {
          setLocale(LOCALE.AR);
          newLocale = LOCALE.AR;
        }
        if (!Object.keys(countryData[newLocale]).length) {
          history.push('/blog');
        }
        setArticle({
          ...pageData,
          pageLanguageData: {
            ...countryData[newLocale],
            ...pageData[newLocale]
          },
          sections: countryData?.sections
        });
      });
    } catch (error) {
      // history.push('/blog');
      notify({ msg: error.message });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getArticle();
  }, []);

  return (
    <LoadingWrapper loading={isLoading}>
      <div className="br-article-container">
        <h1 className="br-header__title header-title">
          {article?.pageLanguageData?.articleTitle}
        </h1>
        {article?.pageLanguageData?.bannerImage && (
          <span className="br-article__section-image__container">
            <ProgressiveImg
              className="br-article__banner-image"
              src={article.pageLanguageData.bannerImage}
              alt="banner image"
            />
          </span>
        )}
        <div
          dangerouslySetInnerHTML={{
            __html: article?.pageLanguageData?.articleIntro
          }}
        />
        {!!article?.sections?.length &&
          article?.sections.map((section) => {
            return (
              <div className="br-article__section">
                {section[locale]?.title && (
                  <div className="br-article__section-title heading-lg-2">
                    {section[locale].title}
                  </div>
                )}
                {section[locale].subtitle && (
                  <div
                    className={classnames(
                      'br-article__section-subtitle br-grid-title grid-title',
                      {
                        'br-article__section-subtitle__with-no-title':
                          !section[locale]?.title
                      }
                    )}
                    dangerouslySetInnerHTML={{
                      __html: section[locale].subtitle
                    }}
                  />
                )}
                {section[locale]?.content && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: section[locale].content
                    }}
                  />
                )}
                {section[locale]?.sectionImage && (
                  <span className="br-article__section-image__container">
                    <ProgressiveImg
                      className="br-article__section-image"
                      src={section[locale].sectionImage}
                      alt="Section image"
                    />
                  </span>
                )}
              </div>
            );
          })}
        <div className="br-article-information-section">
          {article?.pageLanguageData?.keywords && (
            <span>
              <strong>
                {intl.formatMessage({ id: 'articles.keywords' })}:
              </strong>
              {article.pageLanguageData.keywords}
            </span>
          )}
          {article?.pageLanguageData?.keywordMSV && (
            <span>
              <strong>
                {intl.formatMessage({ id: 'articles.keyword_MSV' })}:
              </strong>
              {article.pageLanguageData.keywordMSV}
            </span>
          )}
          {article.pageLanguageData?.authorName && (
            <span>
              <strong>{intl.formatMessage({ id: 'articles.author' })}: </strong>
              {article.pageLanguageData.authorName}
            </span>
          )}
          {article?.pageLanguageData?.publishDate && (
            <span>
              <strong>
                {intl.formatMessage({ id: 'articles.publish_date' })}:
              </strong>
              {dayjs(new Date(article.publishDate)).format('DD MMM, YYYY')}
            </span>
          )}
          {article.pageLanguageData?.buyerPersona && (
            <span>
              <strong>
                {intl.formatMessage({ id: 'articles.buyer_persona' })}:
              </strong>
              {article.buyerPersona}
            </span>
          )}
        </div>
      </div>
    </LoadingWrapper>
  );
};

export default withRouter(injectIntl(Article));
