import classNames from 'classnames';

import Header from 'components/Header/Header';

import './BRHero.less';

const BRHero = ({
  className,
  title,
  subtitle,
  buttonText,
  vectors = [],
  secondTitle,
  onClickButton,
  subTitleComponent,
  hideImages = false,
  secondryComponent = null,
  extraInfo,
  preTitle = false,
}) => {
  return (
    <section className={`br-hero-main ${className}`}>
      <div className="br-hero-header slide-from-bottom">
        <Header
          preTitle={preTitle}
          title={title}
          subtitle={subtitle}
          buttonText={buttonText}
          secondTitle={secondTitle}
          onClickButton={onClickButton}
          subTitleComponent={subTitleComponent}
          extraInfo={extraInfo}
        />
        {secondryComponent && secondryComponent}
      </div>

      {!hideImages && (
        <>
          <div className="r-hex r-hex-slide-from-bottom">
            <div className="r-hex-inner">
              <div className="r-hex-inner-2"></div>
            </div>
          </div>
          {vectors.map((vector, idx) => (
            <div
              key={idx}
              className={classNames(
                `br__hero__content-svg svg-${idx + 1}`,
                {
                  'slide-from-bottom br-hero__all-pages':
                    !vector?.props?.homepageVector
                },
                {
                  'br-hero__home-page-vector': vector?.props?.homepageVector
                }
              )}
            >
              {vector}
            </div>
          ))}
        </>
      )}
    </section>
  );
};

export default BRHero;
