import React from 'react';
import { Timeline } from 'antd';

import { dates } from 'utils/helpers';

import './TrackingLogs.less';

const TrackingLogs = ({ trackingDetails }) => {
  return (
    <Timeline className="br-shipment-details__tracking-timeline">
      {Object.keys(trackingDetails?.GroupedTransitEvents)
        ?.map((title, index) => (
          <Timeline.Item key={index} current={2}>
            <div className="br-shipment-details_tracking-status heading">
              {title}
            </div>
            {trackingDetails?.GroupedTransitEvents[title]
              .map(({ state, timestamp, index }) => (
                <div className="br-shipment-details__tracking-log" key={index}>
                  <span>{state}</span>
                  <span className="br-shipment-details_tracking-details-date">
                    {dates(timestamp, 'h:mm A')}
                  </span>
                </div>
              ))
              .reverse()}
          </Timeline.Item>
        ))
        .reverse()}
    </Timeline>
  );
};

export default TrackingLogs;
