import React from 'react';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';
import { Tag } from 'antd';

import { isSaudi } from 'constants/helpers';

import BRButton from 'components/BRButton/BRButton';

const ServicesContainer = ({
  intl,
  activePlan,
  setActivePlan,
  startingFromValue,
  active,
  plan,
  title,
  cashout,
  description,
  showNextDayfeesUnderPrice,
  nextDayfees,
  plans = {}
}) => {
  return (
    <div
      className={classnames('br-signup__plan-card', {
        active: active
      })}
    >
      <div className="br-signup__plan-card__description">
        {/* will hide it after march  */}
        {plan === plans?.businessTierId && isSaudi() && (
          <Tag className="tag subheading">
            {intl.formatMessage({
              id: 'sign_up.pricing.promotion_text'
            })}
          </Tag>
        )}
        <p className="header subheading">{title}</p>
        <p className="title display-xs">{cashout}</p>
        <p className="description body">{description}</p>
      </div>
      <div className="br-signup__plan-card__price">
        <div className="br-sign-up__plan-card__price-container">
          <span className="header caption-lg">
            {intl.formatMessage({
              id: 'sign_up.pricing.starting_from'
            })}
          </span>
          <div>
            <span className="title display-md">
              {intl.formatMessage(
                {
                  id: `common.${isSaudi() ? 'SAR_modified' : 'EGP_modified'}`
                },
                {
                  cod: startingFromValue
                }
              )}
            </span>
            <span className="br-signup__per-order">
              {intl.formatMessage({
                id: 'sign_up.pricing.per_order'
              })}
            </span>
          </div>
          {showNextDayfeesUnderPrice && !isNaN(nextDayfees) && (
            <p className="br-pricing-plan-card__next-day-fees-text caption-medium">
              {intl.formatMessage(
                {
                  id: 'sign_up.pricing.next_day_transfer_fees_percentage'
                },
                {
                  value: nextDayfees
                }
              )}
            </p>
          )}
          <p className="vat caption">
            {intl.formatMessage({
              id: 'sign_up.pricing.excluding_vat'
            })}
          </p>
        </div>
        <BRButton
          className="button-lg"
          onClick={() => setActivePlan(plan)}
          type={
            activePlan === plan
              ? 'link-color'
              : plan === plans?.businessTierId
              ? 'outline'
              : 'basic'
          }
          label={
            activePlan === plan
              ? intl.formatMessage({
                  id: 'sign_up.pricing.current_plan'
                })
              : intl.formatMessage({
                  id: 'sign_up.pricing.switch_plan'
                })
          }
        />
      </div>
    </div>
  );
};
export default injectIntl(ServicesContainer);
