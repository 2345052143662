import React from 'react';
import { injectIntl } from 'react-intl';

import { CAPITAL_FEATURES } from 'constants/capital';

import './CapitalFeatures.less';

const CapitalFeatures = ({ intl }) => {
  const { formatMessage } = intl;

  return (
    <div className="br-capital__features-section">
      <h3 className="br-capital__features-section-header">
        {formatMessage({
          id: 'bosta_capital.features.header'
        })}
      </h3>
      <div className="br-capital__features-container">
        {CAPITAL_FEATURES.map(({ icon, name }, index) => (
          <div key={index} className="br-capital__features-item">
            <div className="br-capital__features-item-wrapper">{icon}</div>
            <div>
              <p className="br-capital__features-item-title">
                {formatMessage({
                  id: `bosta_capital.features.${name}_title`
                })}
              </p>
              <p className="br-capital__features-item-subtitle">
                {formatMessage({
                  id: `bosta_capital.features.${name}_subtitle`
                })}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default injectIntl(CapitalFeatures);
