import React, { useEffect, useState } from 'react';
import { Form, Select, InputNumber, Tooltip } from 'antd';
import { injectIntl } from 'react-intl';

import { fetchCities } from 'services/cities';
import { getUserCurrency } from 'constants/countries/countries-mapping';
import { LOCALE } from 'constants/intl-wrapper';
import { openModal } from 'utils/modal';
import { calculatePricingFees } from 'services/pricing';
import { sendSegment } from 'utils/segment';

import { notify } from 'components/Notify/Notify';
import BRButton from 'components/BRButton/BRButton';
import PackageGuideModal from '../PackageGuideModal/PackageGuideModal';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

import { ReactComponent as TooltipIcon } from 'assets/icons/Info-Circle.svg';
import FlyersIcon from 'assets/icons/flyers-icon-small.png';

import './PricingCalculatorForm.less';

const PricingCalculatorForm = ({
  intl,
  type,
  tierId,
  setPricingData,
  setCod
}) => {
  const currency = getUserCurrency('').localized.trim();
  const re = new RegExp(`\\${currency}\\s?|(,*)`, 'g');

  const [cities, setCities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (values) => {
    setIsLoading(true);
    const payload = {
      ...values,
      type,
      tierId
    };
    try {
      const result = await calculatePricingFees(payload);
      setPricingData(result);
      setCod(values.cod || 0);
    } catch (error) {
      notify({ msg: error.message, error });
    }
    setIsLoading(false);
  };
  const getCities = async () => {
    try {
      const { data } = await fetchCities();
      setCities(data.list);
    } catch (error) {
      notify({ msg: error.message, error });
    }
  };

  useEffect(() => {
    getCities();
  }, []);

  const SIZES = [
    {
      label: intl.formatMessage({
        id: 'settings.pricing_plan.sizes.small_medium'
      }),
      value: 'Normal',
      sub: intl.formatMessage({
        id: 'pricing_plans_calculator.modal.form_section.sizes.small_medium_size'
      })
    },
    {
      label: intl.formatMessage({ id: 'settings.pricing_plan.sizes.large' }),
      value: 'Large',
      sub: intl.formatMessage({
        id: 'pricing_plans_calculator.modal.form_section.sizes.large_size'
      })
    },
    {
      label: intl.formatMessage({ id: 'settings.pricing_plan.sizes.x_large' }),
      value: 'X-Large',
      sub: intl.formatMessage({
        id: 'pricing_plans_calculator.modal.form_section.sizes.xlarge_size'
      })
    },
    {
      label: intl.formatMessage({
        id: 'settings.pricing_plan.sizes.xxl_white_bag'
      }),
      value: 'White Bag',
      sub: intl.formatMessage({
        id: 'pricing_plans_calculator.modal.form_section.sizes.white_bag_size'
      })
    },
    {
      label: intl.formatMessage({
        id: 'settings.pricing_plan.sizes.light_bulky'
      }),
      value: 'Light Bulky',
      sub: intl.formatMessage({
        id: 'pricing_plans_calculator.modal.form_section.sizes.light_bulky_size'
      }),
      toolTip: intl.formatMessage({
        id: 'settings.pricing_plan.tooltips.light_bulky'
      })
    },
    {
      label: intl.formatMessage({
        id: 'settings.pricing_plan.sizes.heavy_bulky'
      }),
      value: 'Heavy Bulky',
      sub: intl.formatMessage({
        id: 'pricing_plans_calculator.modal.form_section.sizes.heavy_bulky_size'
      }),
      toolTip: intl.formatMessage({
        id: 'settings.pricing_plan.tooltips.heavy_bulky'
      })
    }
  ];

  const handleOnPackageGuideClick = () => {
    openModal(PackageGuideModal);
  };

  return (
    <LoadingWrapper loading={isLoading}>
      <Form
        className="br-pricing-calculator-form"
        onFinish={onFinish}
        // ref={formRef}
        name="pricingCalculatorForm"
        initialValues={{
          size: 'Normal'
        }}
      >
        <div className="br-pricing-calculator-form__form-row">
          {type !== 'CASH_COLLECTION' && (
            <Form.Item
              name="pickupCity"
              label={intl.formatMessage({
                id: 'pricing_plans_calculator.modal.form_section.business_location_label'
              })}
              rules={[{ required: true }]}
            >
              <Select
                className="br-pickups__pickup-settings__form__selector"
                placeholder={intl.formatMessage({
                  id: `form.select_placeholder`
                })}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                getPopupContainer={(trigger) => trigger.parentElement}
                autoComplete={'' + Math.random()}
                onSelect={() => sendSegment('Business_Location_Changed')}
              >
                {cities.map((city) => (
                  <Select.Option key={city._id} value={city.name}>
                    {intl.locale.includes(LOCALE.EN) ? city.name : city.nameAr}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            name="dropOffCity"
            label={intl.formatMessage({
              id: 'pricing_plans_calculator.modal.form_section.consignee_location_label'
            })}
            rules={[{ required: true }]}
          >
            <Select
              className="br-pickups__pickup-settings__form__selector"
              placeholder={intl.formatMessage({
                id: `form.select_placeholder`
              })}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              getPopupContainer={(trigger) => trigger.parentElement}
              autoComplete={'' + Math.random()}
              onSelect={() => sendSegment('Consignee_Location_Changed')}
            >
              {cities.map((city) => (
                <Select.Option key={city._id} value={city.name}>
                  {intl.locale.includes(LOCALE.EN) ? city.name : city.nameAr}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        {type !== 'CASH_COLLECTION' && (
          <Form.Item
            name="size"
            className="br-pricing-calculator-form__package-size-form-item"
            label={
              <div className="br-pricing-calculator-form__package-size-label">
                <span className="body-medium">
                  {intl.formatMessage({
                    id: 'pricing_plans_calculator.modal.form_section.package_size_label'
                  })}
                </span>
                <span className="button" onClick={handleOnPackageGuideClick}>
                  {intl.formatMessage({
                    id: 'pricing_plans_calculator.modal.form_section.package_size_guide'
                  })}
                </span>
              </div>
            }
          >
            <Select
              className="br-pickups__pickup-settings__form__selector"
              placeholder={intl.formatMessage({
                id: `form.select_placeholder`
              })}
              showSearch
              filterOption={(input, option) =>
                option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              getPopupContainer={(trigger) => trigger.parentElement}
              autoComplete={'' + Math.random()}
              optionLabelProp="key"
              onSelect={() => sendSegment('Package_Size_Changed')}
            >
              {SIZES.map((size) => (
                <Select.Option key={size.label} value={size.value}>
                  <div className="br-pricing-calculator-form__size-option-content">
                    <img src={FlyersIcon} alt="flyers-icon" />
                    <div>
                      <span className="body">
                        {size.label}
                        {size.toolTip && (
                          <Tooltip title={size.toolTip}>
                            <TooltipIcon />
                          </Tooltip>
                        )}
                      </span>
                      <span className="caption">{size.sub}</span>
                    </div>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {type !== 'RTO' && (
          <Form.Item
            name="cod"
            label={intl.formatMessage({
              id: 'pricing_plans_calculator.modal.form_section.cash_on_delivery_label'
            })}
          >
            <InputNumber
              formatter={(value) => `${currency}  ${value}`}
              parser={(value) => value.replace(re, '')}
              onChange={() => sendSegment('COD_Changed')}
            />
          </Form.Item>
        )}
      </Form>
      <BRButton
        type="primary"
        htmlType="submit"
        form="pricingCalculatorForm"
        label={intl.formatMessage({
          id: 'pricing_plans_calculator.modal.form_section.submit_button_label'
        })}
        className="br-pricing-calculator-form__submit-button"
      />
    </LoadingWrapper>
  );
};

export default injectIntl(PricingCalculatorForm);
