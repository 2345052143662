import { Modal } from 'antd';

import BRButton from 'components/BRButton/BRButton';

import './BRModal.less';

const BRModal = ({
  title,
  subtitle,
  okBtnProps,
  cancelBtnProps,
  children,
  wrapClassName = '',
  ...props
}) => {
  return (
    <Modal
      wrapClassName={`br-modal ${wrapClassName}`}
      title={
        <div className="br-modal__header">
          <div className="br-modal__title">{title}</div>
          {subtitle && <div className="br-modal__subtitle">{subtitle}</div>}
        </div>
      }
      footer={
        <>
          {cancelBtnProps && <BRButton {...cancelBtnProps} />}
          {okBtnProps && <BRButton {...okBtnProps} />}
        </>
      }
      {...props}
    >
      {children}
    </Modal>
  );
};

export default BRModal;
