import { injectIntl } from 'react-intl';

import { mediaHook } from 'customHooks';
import { PARTNERS_MOBILE_CAROUSEL_CONTENT } from 'constants/carousels-data';
import { visitorCountryCode } from 'utils/helpers';

import PartnersCarouselMobile from 'components/CarouselMobile/CarouselMobile';

import { ReactComponent as AmazonLogo } from 'assets/images/Amazon-logo.svg';
import { ReactComponent as PalmaLogo } from 'assets/images/Paima-logo.svg';
import { ReactComponent as CarrefourLogo } from 'assets/images/Carrefour-logo.svg';
import { ReactComponent as EmmaLogo } from 'assets/images/Emma-logo.svg';
import { ReactComponent as JumiaLogo } from 'assets/images/Jumia-logo.svg';
import { ReactComponent as TaagerLogo } from 'assets/images/Taager-logo.svg';
import { ReactComponent as NoonLogo } from 'assets/images/Noon-logo.svg';
import { ReactComponent as PartnershipMain } from 'assets/images/partnership_main.svg';

import './Partners.less';

const Partners = ({ intl, mobileScreenSizes }) => {
  const { isLargeMobileScreen } = mobileScreenSizes;

  return (
    <section className="br__partner__section">
      <div className="br__partner__section-container">
        <div className="br__partner__section-content">
          <h2 className="br__partner__content-title">
            {intl.formatMessage({ id: 'about.partners.title' })}
          </h2>
          <div className="br__partner__content__partners-logos">
            {isLargeMobileScreen ? (
              <PartnersCarouselMobile
                carouselContent={PARTNERS_MOBILE_CAROUSEL_CONTENT[visitorCountryCode]}
              />
            ) : (
              <>
                <div className="partner-logo">
                  <AmazonLogo />
                </div>
                <div className="partner-logo">
                  <PalmaLogo />
                </div>
                <div className="partner-logo">
                  <CarrefourLogo />
                </div>
                <div className="partner-logo">
                  <EmmaLogo />
                </div>
                <div className="partner-logo">
                  <JumiaLogo />
                </div>
                <div className="partner-logo">
                  <TaagerLogo />
                </div>
                <div className="partner-logo">
                  <NoonLogo />
                </div>
              </>
            )}

            <div className="br__partner__content__more-details">
              {intl.formatMessage({ id: 'about.partners.more_details' })}
            </div>
          </div>
        </div>
        <div className="br__partner__section-content">
          <div className="br__partner__section-philosophy">
            <h2 className="br__partner__content-title">
              {intl.formatMessage({
                id: 'about.partners.partnerships_philosophy_title'
              })}
            </h2>
            <div className="br__partner__content-subtitle">
              {intl.formatMessage(
                {
                  id: 'about.partners.partnerships_philosophy_subtitle'
                },
                {
                  TextDiv: (children) => (
                    <div className="br__partner__content-subtitle">
                      {children}
                    </div>
                  )
                }
              )}
            </div>
            <div className="br__partner__section-svg">
              <PartnershipMain />
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </section>
  );
};

export default injectIntl(mediaHook(Partners));
