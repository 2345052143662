import { injectIntl } from 'react-intl';
import { Button } from 'antd';

import { SOLUTIONS_BOSTA_BOX } from 'constants/solutions';

import Grid from 'components/Grid/Grid';

import { ReactComponent as PlaneIcon } from 'assets/icons/SolutionsPlaneIcon.svg';
import { ReactComponent as BostaBoxImage } from 'assets/images/solutions-bosta-box-image.svg';

import './BostaBox.less';

const BostaBox = ({ intl }) => {
  const SOLUTIONS_BOSTA_BOX_ICONS = [PlaneIcon];

  return (
    <section className="br-solutions-bosta-box-container">
      <div className="br-solutions-bosta-box-content">
        <div className="br-solutions-bosta-box-content-grid">
          <Grid
            gridTitle={intl.formatMessage({
              id: 'solutions.bosta_box.title'
            })}
            gridSubtitle={intl.formatMessage({
              id: 'solutions.bosta_box.subtitle'
            })}
            items={SOLUTIONS_BOSTA_BOX}
            icons={SOLUTIONS_BOSTA_BOX_ICONS}
          />
          <div className="br-solutions-bosta-box-cta">
            <Button
              type="primary"
              className="button-primary"
              onClick={() => window.open('https://bosta.co/bostabox')}
            >
              {intl.formatMessage({
                id: 'solutions.bosta_box.button'
              })}
            </Button>
          </div>
        </div>
        <div className="br-solutions-bosta-box-svg">
          <BostaBoxImage />
        </div>
      </div>
    </section>
  );
};

export default injectIntl(BostaBox);
