import { fmt } from 'components/IntlWrapper/IntlWrapper';

export const CURRENCY = (cod) => ({
  EG: {
    type: 'EGP',
    localized: fmt(
      { id: 'common.egp_amount' },
      {
        amount: cod
      }
    ),
    vat: 0.14
  },
  SA: {
    type: 'SAR',
    localized: fmt(
      { id: 'common.saudi_amount' },
      {
        amount: cod
      }
    ),
    vat: 0.15
  }
});
