import React from 'react';
import { injectIntl } from 'react-intl';

import ClientCard from 'components/BostaCapital/Components/ClientCard/ClientCard';

import { COMMENTS, CAPITAL_VIDEO_URL } from 'constants/capital';

import './CapitalTestimonials.less';

const CapitalTestimonials = ({ intl }) => {
  const { formatMessage } = intl;
  return (
    <div className="br-capital__testimonials-section">
      <div>
        <h3 className="br-capital__testimonials-section-header">
          {formatMessage({
            id: 'bosta_capital.testimonials.title'
          })}
        </h3>
        <p className="br-capital__testimonials-section-subtitle">
          {formatMessage({
            id: 'bosta_capital.testimonials.subtitle'
          })}
        </p>
      </div>
      <div className="br-capital__testimonials-carousel">
        {COMMENTS.map(({ img, name, position, comment, rating }, index) => (
          <ClientCard
            key={index}
            img={img}
            name={name}
            position={position}
            rating={rating}
            comment={comment}
          />
        ))}
      </div>
      <div className="br-capital__video-iframe">
        <iframe
          className="br-capital__video-iframe"
          src={CAPITAL_VIDEO_URL}
          title="Bosta Capital"
          frameborder="0"
          allow="accelerometer;fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        />
      </div>
    </div>
  );
};

export default injectIntl(CapitalTestimonials);
