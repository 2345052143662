export const HREF_LINKS = {
  EG: [
    <link
      rel="alternate"
      hreflang="en-EG"
      href="https://bosta.co/en-eg/home"
    />,
    <link
      rel="alternate"
      hreflang="en-EG"
      href="https://bosta.co/en-eg/solutions"
    />,
    <link
      rel="alternate"
      hreflang="en-EG"
      href="https://bosta.co/en-eg/dashboard"
    />,
    <link
      rel="alternate"
      hreflang="en-EG"
      href="https://bosta.co/en-eg/mobile"
    />,
    <link
      rel="alternate"
      hreflang="en-EG"
      href="https://bosta.co/en-eg/merged-integrations/shopify"
    />,
    <link
      rel="alternate"
      hreflang="en-EG"
      href="https://bosta.co/en-eg/merged-integrations/woocommerce"
    />,
    <link
      rel="alternate"
      hreflang="en-EG"
      href="https://bosta.co/en-eg/merged-integrations/custom-api"
    />,
    <link
      rel="alternate"
      hreflang="en-EG"
      href="https://bosta.co/en-eg/business"
    />,
    <link
      rel="alternate"
      hreflang="en-EG"
      href="https://bosta.co/en-eg/smes"
    />,
    <link
      rel="alternate"
      hreflang="en-EG"
      href="https://bosta.co/en-eg/pricing"
    />,
    <link
      rel="alternate"
      hreflang="en-EG"
      href="https://bosta.co/en-eg/blog"
    />,
    <link
      rel="alternate"
      hreflang="en-EG"
      href="https://bosta.co/en-eg/about"
    />,
    <link
      rel="alternate"
      hreflang="en-EG"
      href="https://bosta.co/en-eg/careers"
    />,
    <link rel="alternate" hreflang="en-EG" href="https://bosta.co/en-eg/faq" />,
    <link
      rel="alternate"
      hreflang="en-EG"
      href="https://bosta.co/en-eg/contact-shipping-sales"
    />,
    <link
      rel="alternate"
      hreflang="en-EG"
      href="https://bosta.co/en-eg/tracking-shipments"
    />,
    <link
      rel="alternate"
      hreflang="en-EG"
      href="https://bosta.co/en-eg/signup"
    />,
    <link
      rel="alternate"
      hreflang="ar-EG"
      href="https://bosta.co/ar-eg/home"
    />,
    <link
      rel="alternate"
      hreflang="ar-EG"
      href="https://bosta.co/ar-eg/solutions"
    />,
    <link
      rel="alternate"
      hreflang="ar-EG"
      href="https://bosta.co/ar-eg/dashboard"
    />,
    <link
      rel="alternate"
      hreflang="ar-EG"
      href="https://bosta.co/ar-eg/mobile"
    />,
    <link
      rel="alternate"
      hreflang="ar-EG"
      href="https://bosta.co/ar-eg/merged-integrations/shopify"
    />,
    <link
      rel="alternate"
      hreflang="ar-EG"
      href="hhttps://bosta.co/ar-eg/merged-integrations/woocommerce"
    />,
    <link
      rel="alternate"
      hreflang="ar-EG"
      href="https://bosta.co/ar-eg/merged-integrations/custom-api"
    />,
    <link
      rel="alternate"
      hreflang="ar-EG"
      href="https://bosta.co/ar-eg/business"
    />,
    <link
      rel="alternate"
      hreflang="ar-EG"
      href="https://bosta.co/ar-eg/smes"
    />,
    <link
      rel="alternate"
      hreflang="ar-EG"
      href="https://bosta.co/ar-eg/pricing"
    />,
    <link
      rel="alternate"
      hreflang="ar-EG"
      href="https://bosta.co/ar-eg/blog"
    />,
    <link
      rel="alternate"
      hreflang="ar-EG"
      href="https://bosta.co/ar-eg/about"
    />,
    <link
      rel="alternate"
      hreflang="ar-EG"
      href="https://bosta.co/ar-eg/careers"
    />,
    <link rel="alternate" hreflang="ar-EG" href="https://bosta.co/ar-eg/faq" />,
    <link
      rel="alternate"
      hreflang="ar-EG"
      href="https://bosta.co/ar-eg/contact-shipping-sales"
    />,
    <link
      rel="alternate"
      hreflang="ar-EG"
      href="https://bosta.co/ar-eg/tracking-shipments"
    />,
    <link
      rel="alternate"
      hreflang="ar-EG"
      href="https://bosta.co/ar-eg/signup"
    />
  ],
  SA: [
    <link
      rel="alternate"
      hreflang="en-SA"
      href="https://bosta.co/en-sa/home"
    />,
    <link
      rel="alternate"
      hreflang="en-SA"
      href="https://bosta.co/en-sa/solutions"
    />,
    <link
      rel="alternate"
      hreflang="en-SA"
      href="https://bosta.co/en-sa/dashboard"
    />,
    <link
      rel="alternate"
      hreflang="en-SA"
      href="https://bosta.co/en-sa/mobile"
    />,
    <link
      rel="alternate"
      hreflang="en-SA"
      href="https://bosta.co/en-sa/merged-integrations/shopify"
    />,
    <link
      rel="alternate"
      hreflang="en-SA"
      href="https://bosta.co/en-sa/merged-integrations/woocommerce"
    />,
    <link
      rel="alternate"
      hreflang="en-SA"
      href="https://bosta.co/en-sa/merged-integrations/custom-api"
    />,
    <link
      rel="alternate"
      hreflang="en-SA"
      href="https://bosta.co/en-sa/business"
    />,
    <link
      rel="alternate"
      hreflang="en-SA"
      href="https://bosta.co/en-sa/smes"
    />,
    <link
      rel="alternate"
      hreflang="en-SA"
      href="https://bosta.co/en-sa/pricing"
    />,
    <link
      rel="alternate"
      hreflang="en-SA"
      href="https://bosta.co/en-sa/blog"
    />,
    <link
      rel="alternate"
      hreflang="en-SA"
      href="https://bosta.co/en-sa/about"
    />,
    <link
      rel="alternate"
      hreflang="en-SA"
      href="https://bosta.co/en-sa/careers"
    />,
    <link rel="alternate" hreflang="en-SA" href="https://bosta.co/en-sa/faq" />,
    <link
      rel="alternate"
      hreflang="en-SA"
      href="https://bosta.co/en-sa/contact-shipping-sales"
    />,
    <link
      rel="alternate"
      hreflang="en-SA"
      href="https://bosta.co/en-sa/tracking-shipments"
    />,
    <link
      rel="alternate"
      hreflang="en-SA"
      href="https://bosta.co/en-sa/signup"
    />,
    <link
      rel="alternate"
      hreflang="ar-SA"
      href="https://bosta.co/ar-sa/home"
    />,
    <link
      rel="alternate"
      hreflang="ar-SA"
      href="https://bosta.co/ar-sa/solutions"
    />,
    <link
      rel="alternate"
      hreflang="ar-SA"
      href="https://bosta.co/ar-sa/dashboard"
    />,
    <link
      rel="alternate"
      hreflang="ar-SA"
      href="https://bosta.co/ar-sa/mobile"
    />,
    <link
      rel="alternate"
      hreflang="ar-SA"
      href="https://bosta.co/ar-sa/merged-integrations/shopify"
    />,
    <link
      rel="alternate"
      hreflang="ar-SA"
      href="https://bosta.co/ar-sa/merged-integrations/woocommerce"
    />,
    <link
      rel="alternate"
      hreflang="ar-SA"
      href="https://bosta.co/ar-sa/merged-integrations/custom-api"
    />,
    <link
      rel="alternate"
      hreflang="ar-SA"
      href="https://bosta.co/ar-sa/business"
    />,
    <link
      rel="alternate"
      hreflang="ar-SA"
      href="https://bosta.co/ar-sa/smes"
    />,
    <link
      rel="alternate"
      hreflang="ar-SA"
      href="https://bosta.co/ar-sa/pricing"
    />,
    <link
      rel="alternate"
      hreflang="ar-SA"
      href="https://bosta.co/ar-sa/blog"
    />,
    <link
      rel="alternate"
      hreflang="ar-SA"
      href="https://bosta.co/ar-sa/about"
    />,
    <link
      rel="alternate"
      hreflang="ar-SA"
      href="https://bosta.co/ar-sa/careers"
    />,
    <link rel="alternate" hreflang="ar-SA" href="https://bosta.co/ar-sa/faq" />,
    <link
      rel="alternate"
      hreflang="ar-SA"
      href="https://bosta.co/ar-sa/contact-shipping-sales"
    />,
    <link
      rel="alternate"
      hreflang="ar-SA"
      href="https://bosta.co/ar-sa/tracking-shipments"
    />,
    <link
      rel="alternate"
      hreflang="ar-SA"
      href="https://bosta.co/ar-sa/signup"
    />
  ]
};
