import IntegrationsHeader from './components/IntegrationsHeader/IntegrationsHeader';
import PluginsSection from './components/PluginsSection/PluginsSection';
import APIsSection from './components/APIsSection/APIsSection';
import PluginsAndAPIsSection from './components/PluginsAndAPIsSection/PluginsAndAPIsSection';
import TrustedCompanies from 'components/TrustedCompanies/TrustedCompanies';
import SignUpAndStart from 'components/SignUpAndStart/SignUpAndStart';

import './Integrations.less';

const Integrations = () => {

  return (
    <div className="br-integrations">
      <IntegrationsHeader />
      <div className="br-integrations__content">
        <PluginsSection />
        <APIsSection />
        <PluginsAndAPIsSection />
        <TrustedCompanies />
        <SignUpAndStart />
      </div>
    </div>
  );
};

export default Integrations;
