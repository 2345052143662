import classNames from 'classnames';

export const renderSVGs = (svgs, className) =>
  svgs.map(
    (
      {
        Vector,
        from,
        startDelay,
        fromX,
        fromY,
        opacityFrom,
        fromSide,
        fromBottom,
        delayedAnimation,
        diagonalAnimation,
        isHidden
      },
      idx
    ) => (
      <div
        className={classNames(
          className,
          { 'from-side': fromSide },
          { 'from-bottom': fromBottom },
          { 'delayed-animation': delayedAnimation },
          { 'diagonal-animation': diagonalAnimation },
          { isHidden: isHidden },
          `svg-${idx + 1}`
        )}
      >
        <Vector
          style={{
            '--from': from,
            '--fromX': fromX,
            '--fromY': fromY,
            '--startDelay': startDelay,
            '--opacityFrom': opacityFrom
          }}
        />
      </div>
    )
  );

export const intersectionObserver = (callback, options, refsArray) => {
  const observer = new IntersectionObserver(callback, options);
  refsArray.map((ref) => {
    observer.observe(ref);
  });
};
