import React from 'react';

import CapitalHero from './Components/CapitalHero';
import CapitalFeatures from './Components/CapitalFeatures/CapitalFeatures';
import CapitalEligable from './Components/CapitalEligable/CapitalEligable';
import CapitalTestimonials from './Components/CapitalTestimonials/CapitalTestimonials';
import CapitalStats from './Components/CapitalStats/CapitalStats';
import CapitalSignup from './Components/CapitalSignup/CapitalSignup';

import './BostaCapital.less';

export const BostaCapital = () => {
  return (
    <div className="br-capital__landing-page">
      <CapitalHero />
      <CapitalFeatures />
      <CapitalEligable />
      <CapitalTestimonials />
      <CapitalStats />
      <CapitalSignup />
    </div>
  );
};

export default BostaCapital;
