import { withRouter } from 'react-router-dom';

import { CUSTOM_API } from 'constants/merged-integrations';

import MergedIntegrationsHeader from './components/MergedIntegrationsHeader/MergedIntegrationsHeader';
import ConnectAndSaveSection from './components/ConnectAndSaveSection/ConnectAndSaveSection';
import TrustedCompanies from 'components/TrustedCompanies/TrustedCompanies';
import SignUpAndStart from 'components/SignUpAndStart/SignUpAndStart';
import Integrations from 'components/Integrations/Integrations';

import { ReactComponent as TrustedCompaniesBg } from 'assets/images/Vector 14.svg';

import './MergedIntegrations.less';

const MergedIntegrations = ({ location }) => {

  return location?.pathname?.includes(CUSTOM_API) ? (
    <Integrations />
  ) : (
    <div className="br-merged-integrations">
      <MergedIntegrationsHeader />
      <div className="br-merged-integrations__content">
        <ConnectAndSaveSection />
        <div className="br-merged-integrations__content__trusted-companies-bg">
          <TrustedCompaniesBg />
        </div>
        <TrustedCompanies />
        <SignUpAndStart />
      </div>
    </div>
  );
};

export default withRouter(MergedIntegrations);
