import React from 'react';

import { CAROUSEL_BAR_PARTNERS } from 'constants/carousels-data';

import './CarouselBar.less';

const CarouselBar = () => {
  return (
    <div className="br-CarouselBar_section">
      <div className="br-CarouselBar_logos">
        <div>
          {CAROUSEL_BAR_PARTNERS.map((item, index) => (
            <img key={index} alt="logo" src={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CarouselBar;
