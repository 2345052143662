import dayjs from 'dayjs';

import {
  getLocaleFromLocalStorage,
  isDashboardArabic,
  setLocaleInLocalStorage
} from 'utils/intl-wrapper';
import { COUNTRIES_ARABIC_LOCALE, LOCALE } from 'constants/intl-wrapper';

import { fmt } from 'components/IntlWrapper/IntlWrapper';

export const DATE_FORMAT = 'D MMM, YYYY';
export const DATE_FORMAT_WITH_DAY = 'ddd, D MMM YYYY';
export const DATE_FORMAT_WITHOUT_YEAR = 'D MMM';
export const DATE_FORMAT_WITH_DAY_WITHOUT_YEAR = 'ddd, D MMM';
export const TIME_FORMAT = 'h:mm A';

export const checkDate = (date) => {
  if (dayjs(date).isToday()) {
    return fmt({ id: `date_options.today` });
  } else if (dayjs(date).isYesterday()) {
    return fmt({ id: `date_options.yesterday` });
  } else if (dayjs(date).isTomorrow()) {
    return fmt({ id: `date_options.tomorrow` });
  } else {
    return;
  }
};

export const checkSmartDate = (
  date,
  format = 'ddd, DD MMM',
  extraFormat = false
) => {
  const formattedDate = checkDate(date);
  if (formattedDate) {
    if (extraFormat) {
      return `${formattedDate}, ${dates(date, 'DD MMM')}`;
    }
    return formattedDate;
  } else {
    return dates(date, format);
  }
};

export const dates = (value, format) => {
  const date = format ? dayjs(value).format(format) : dayjs(value);
  if (isDashboardArabic()) {
    return date?.replace(/\d/g, (d) => '٠١٢٣٤٥٦٧٨٩'[d]);
  } else return date;
};

export const secondsToTime = (secs) => {
  const divisor_for_minutes = secs % (60 * 60);
  const minutes = Math.floor(divisor_for_minutes / 60);

  const divisor_for_seconds = divisor_for_minutes % 60;
  const seconds = Math.ceil(divisor_for_seconds);

  if (getLocaleFromLocalStorage() === LOCALE.EN)
    return ` ${minutes < 10 ? `0${minutes}` : minutes}: ${
      seconds < 10 ? `0${seconds}` : seconds
    }`;
  else {
    return ` ${seconds < 10 ? `0${seconds}` : seconds}: ${
      minutes < 10 ? `0${minutes}` : minutes
    }`;
  }
};

export const numberRegex = /^\d+$/;

export const countDown = (values) => {
  const { timer, setTimer, setDisabled } = values;
  return setInterval(() => {
    if (timer > 0) {
      setTimer(timer - 1);
    }
    if (timer === 0) {
      setDisabled(false);
    }
  }, 1000);
};

export const TWO_MINUTES = 120;

export const convertArrayToNestedObject = (array, value) => {
  //  This function to deal with handlechangeform function
  // if it takes array, It converts the array to nested object so it can set form fields
  const result = {};
  let nestedObj = result;
  array.forEach((key, index) => {
    nestedObj[key] = {};
    if (index === array.length - 1) {
      nestedObj[key] = value;
    } else {
      nestedObj = nestedObj[key];
    }
  });
  return result;
};

export const regexNumber = /^\d+$/;
export const regexNumberWithNegative = /^-?\d*$/;

export const cleanEmptyString = (obj) => {
  if (!obj) return;
  if (dayjs.isDayjs(obj)) return obj;
  if (Array.isArray(obj)) {
    return obj
      .map((v) => (v && typeof v === 'object' ? cleanEmptyString(v) : v))
      .filter((v) => !(v === '' || v === undefined || v === null));
  } else {
    return Object.entries(obj)
      .map(([k, v]) => [
        k,
        v && typeof v === 'object' ? cleanEmptyString(v) : v
      ])
      .reduce(
        (a, [k, v]) =>
          v === '' || v === null || v === undefined ? a : ((a[k] = v), a),
        {}
      );
  }
};

export const COUNTRIES = ['sa', 'eg'];

export const LANGUGAES = ['ar-eg', 'en-eg', 'en-sa', 'ar-sa'];
export const pathLang = !window.location.pathname.split('/')[1].length
  ? getLocaleFromLocalStorage()
  : LANGUGAES.find((str) =>
      str.toLowerCase().includes(window.location.pathname.split('/')[1])
    );

export const visitorCountryCode =
  COUNTRIES.find((country) =>
    pathLang?.toLowerCase()?.includes(country)
  )?.toUpperCase() || COUNTRIES[1].toUpperCase();

export const isURLHavingKSA = visitorCountryCode === COUNTRIES[0].toUpperCase();

export const isContactUsAvailable = () => {
  const availability = {
    EG: false,
    KSA: true
  };
  return availability[isURLHavingKSA ? 'KSA' : 'EG'];
};

export const isUserAuth = () => {
  let userInfo = localStorage.getItem('userInfo');
  if (userInfo) {
    userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const isAuth =
      userInfo?.user?.isPhoneVerified &&
      userInfo?.user?.businessAdminInfo?.businessId;
    return isAuth ? userInfo?.token : false;
  }
  return false;
};

export const isSllrUser = () => {
  const isSllrRegSrc =
    JSON.parse(localStorage.getItem('userInfo'))?.user?.regSrc === 'SLLR';

  if (isUserAuth()) {
    return isSllrRegSrc;
  }

  const { pathname, search } = window.location;

  const isFromSllr =
    isSllrRegSrc ||
    new URLSearchParams(search).get('utm_source') === 'sllr.co' ||
    pathname.includes('sllr-');

  return isFromSllr;
};

export const handleLanguageChange = async (value, redirectTo = false) => {
  await setLocaleInLocalStorage(value);
  redirectTo && window.history.replaceState(null, null, redirectTo);
  window.location.reload();
};

export const getAccountTypePropForSuccessMetrics = () => ({
  ...(isSllrUser() && {
    Account_Type: 'Sllr'
  })
});

export const ARABIC_LANG = 'ar';

export const formatArabicFieldName = (array) => {
  return array.map((obj) => {
    const newObj = {};
    for (const key in obj) {
      if (COUNTRIES_ARABIC_LOCALE.includes(key)) {
        newObj[ARABIC_LANG] = obj[key];
      } else {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  });
};

export const toUrlEncoded = (data) => {
  const parts = [];

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const value = data[key];
      if (Array.isArray(value)) {
        value.forEach((item) => {
          parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(item)}`);
        });
      } else {
        parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
    }
  }
  return parts.join('&');
};
