import dayjs from 'dayjs';

import {
  FINAL_STATE_LABEL,
  INTERNATIONAL_DELIVERY_EVENTS,
  INTERNATIONAL_DELIVERY_LOGS,
  INTERNATIONAL_LOG_STATE_MAPPING,
  INTERNATIONAL_ORDERS_STATES_MAPPING,
  ORDER_TYPES,
  TIMELINE_STEPS_INDEX,
  TIMESTAMP_TARGET_CODES,
  TRACKING_ARRIVAL_DATE_FORMAT,
  TRACKING_DATE_FORMAT
} from 'constants/shipments';
import {
  COMMON_CANCELLATION_STATES,
  DELIVERY_STATES_CODES
} from 'constants/states';
import { checkSmartDate } from './helpers';

import { fmt } from 'components/IntlWrapper/IntlWrapper';

export const getActiveStep = ({ trackingDetails } = {}) => {
  const {
    Type,
    CurrentStatus: { code },
    collectedFromConsignee
  } = trackingDetails;

  switch (Type) {
    case ORDER_TYPES.SEND:
    case ORDER_TYPES.FXF_SEND:
      if (code === DELIVERY_STATES_CODES.CREATED) {
        return TIMELINE_STEPS_INDEX.STEP_0;
      } else if (
        [DELIVERY_STATES_CODES.PICKED_UP_FROM_BUSINESS].includes(code)
      ) {
        return TIMELINE_STEPS_INDEX.STEP_1;
      } else if (code === DELIVERY_STATES_CODES.OUT_FOR_DELIVERY) {
        return TIMELINE_STEPS_INDEX.STEP_3;
      } else if (code === DELIVERY_STATES_CODES.DELIVERED) {
        return TIMELINE_STEPS_INDEX.STEP_4;
      } else {
        return TIMELINE_STEPS_INDEX.STEP_2;
      }
    case ORDER_TYPES.CRP:
      if (
        [
          DELIVERY_STATES_CODES.CREATED,
          DELIVERY_STATES_CODES.EXCEPTION
        ].includes(code)
      ) {
        return TIMELINE_STEPS_INDEX.STEP_1;
      } else if (collectedFromConsignee) {
        return TIMELINE_STEPS_INDEX.STEP_3;
      } else {
        return TIMELINE_STEPS_INDEX.STEP_2;
      }
    case ORDER_TYPES.EXCHANGE:
      if (code === DELIVERY_STATES_CODES.CREATED) {
        return TIMELINE_STEPS_INDEX.STEP_0;
      } else if (code === DELIVERY_STATES_CODES.PICKED_UP_FROM_BUSINESS) {
        return TIMELINE_STEPS_INDEX.STEP_1;
      } else if (
        code === DELIVERY_STATES_CODES.OUT_FOR_PICKUP &&
        !collectedFromConsignee
      ) {
        return TIMELINE_STEPS_INDEX.STEP_3;
      } else if (collectedFromConsignee) {
        return TIMELINE_STEPS_INDEX.STEP_4;
      } else {
        return TIMELINE_STEPS_INDEX.STEP_2;
      }
    case ORDER_TYPES.CASH_COLLECTION:
      if (code === DELIVERY_STATES_CODES.PICKUP_ORDER_CREATED) {
        return TIMELINE_STEPS_INDEX.STEP_1;
      } else if (code === DELIVERY_STATES_CODES.OUT_FOR_COLLECTION) {
        return TIMELINE_STEPS_INDEX.STEP_2;
      } else if (code === DELIVERY_STATES_CODES.DELIVERED) {
        return TIMELINE_STEPS_INDEX.STEP_4;
      } else {
        return TIMELINE_STEPS_INDEX.STEP_1;
      }
    case ORDER_TYPES.SIGN_AND_RETURN:
      if (code === DELIVERY_STATES_CODES.CREATED) {
        return TIMELINE_STEPS_INDEX.STEP_0;
      } else if (code === DELIVERY_STATES_CODES.OUT_FOR_DELIVERY) {
        return TIMELINE_STEPS_INDEX.STEP_2;
      } else if (
        [
          DELIVERY_STATES_CODES.PENDING_CUSTOMER_SIGNATURE,
          DELIVERY_STATES_CODES.SIGN_AND_RETURN_DEBRIEFED
        ].includes(code)
      ) {
        return TIMELINE_STEPS_INDEX.STEP_3;
      } else if (
        collectedFromConsignee &&
        code !== DELIVERY_STATES_CODES.RETURNED_TO_BUSINESS
      ) {
        return TIMELINE_STEPS_INDEX.STEP_4;
      } else if (code === DELIVERY_STATES_CODES.RETURNED_TO_BUSINESS) {
        return TIMELINE_STEPS_INDEX.STEP_5;
      } else {
        return TIMELINE_STEPS_INDEX.STEP_1;
      }
    case ORDER_TYPES.INTERNATIONAL:
      if (code === DELIVERY_STATES_CODES.CREATED) {
        return TIMELINE_STEPS_INDEX.STEP_1;
      } else if (code === DELIVERY_STATES_CODES.OUT_FOR_DELIVERY) {
        return TIMELINE_STEPS_INDEX.STEP_3;
      } else if (code === DELIVERY_STATES_CODES.INTERNATIONAL_DELVERED) {
        return TIMELINE_STEPS_INDEX.STEP_4;
      } else {
        return TIMELINE_STEPS_INDEX.STEP_2;
      }
    default:
      break;
  }
};

export const getStepUpdatedAt = ({
  trackingDetails,
  activeStep,
  step,
  stepIndex
}) => {
  if (activeStep >= stepIndex + 1 && trackingDetails.TransitEvents?.length) {
    if (trackingDetails.Type === ORDER_TYPES.FXF_SEND) {
      trackingDetails.Type = ORDER_TYPES.SEND;
    }

    const reversedEvents = reverseTransitEvents(trackingDetails.TransitEvents);

    const relatedEvent = reversedEvents.find((event) =>
      TIMESTAMP_TARGET_CODES[trackingDetails.Type][step].includes(event.code)
    );

    return checkSmartDate(
      relatedEvent?.timestamp ||
        trackingDetails?.collectedFromConsignee ||
        trackingDetails.CurrentStatus?.timestamp,
      TRACKING_DATE_FORMAT,
      true
    );
  }
};

export const isCanceledOrder = ({ trackingDetails = {} }) => {
  const {
    Type,
    CurrentStatus: { code }
  } = trackingDetails;

  if (Type === ORDER_TYPES.SEND) {
    return [
      ...COMMON_CANCELLATION_STATES,
      DELIVERY_STATES_CODES.RETURNED_TO_BUSINESS
    ].includes(code);
  } else if (Type === ORDER_TYPES.RTO) {
    return true;
  } else {
    return COMMON_CANCELLATION_STATES.includes(code);
  }
};

const isPastArrivalDate = (trackingDetails) => {
  if (!trackingDetails?.PromisedDate) {
    return false;
  }

  const promisedDate = dayjs(trackingDetails.PromisedDate);
  const today = dayjs().startOf('day');

  return promisedDate.isBefore(today);
};

export const getArrivalDate = ({ trackingDetails } = {}) => {
  const {
    Type,
    CurrentStatus: { code, state },
    collectedFromConsignee
  } = trackingDetails;

  if (isCanceledOrder({ trackingDetails })) {
    return fmt({ id: 'tracking.status.canceled' });
  }

  switch (Type) {
    case ORDER_TYPES.SEND:
    case ORDER_TYPES.FXF_SEND:
      if (
        code === DELIVERY_STATES_CODES.CREATED ||
        isPastArrivalDate(trackingDetails)
      ) {
        return state;
      } else if (code === DELIVERY_STATES_CODES.OUT_FOR_DELIVERY) {
        return fmt({ id: 'tracking.arriving_today' });
      } else {
        const isDelivered =
          trackingDetails?.CurrentStatus?.code ===
          DELIVERY_STATES_CODES.DELIVERED;

        const date = isDelivered
          ? trackingDetails?.TransitEvents[
              trackingDetails?.TransitEvents?.length - 1
            ]?.timestamp
          : trackingDetails?.PromisedDate;

        return fmt(
          { id: `tracking.${isDelivered ? 'arrived' : 'arriving_by'}` },
          {
            date: checkSmartDate(date, TRACKING_ARRIVAL_DATE_FORMAT, true),
            span: (children) => (
              <span className="br-tracking-arrival__date">{children}</span>
            )
          }
        );
      }

    case ORDER_TYPES.SIGN_AND_RETURN:
    case ORDER_TYPES.CASH_COLLECTION:
      if (code === DELIVERY_STATES_CODES.OUT_FOR_PICKUP) {
        return fmt({ id: 'tracking.arriving_today' });
      }

      return state;

    case ORDER_TYPES.CRP:
      if (collectedFromConsignee) {
        return fmt({ id: 'tracking.status.picked_up' });
      } else if (code === DELIVERY_STATES_CODES.OUT_FOR_PICKUP) {
        return fmt({ id: 'tracking.arriving_today' });
      } else {
        return state;
      }

    case ORDER_TYPES.EXCHANGE:
      if (code === DELIVERY_STATES_CODES.OUT_FOR_PICKUP) {
        return fmt({ id: 'tracking.arriving_today' });
      } else if (collectedFromConsignee) {
        return fmt({ id: 'tracking.status.exchanged' });
      } else if (
        code !== DELIVERY_STATES_CODES.RETURNED_TO_BUSINESS ||
        isPastArrivalDate(trackingDetails)
      ) {
        return state;
      } else {
        return fmt(
          { id: 'tracking.arriving_by' },
          {
            date: checkSmartDate(
              trackingDetails?.PromisedDate,
              TRACKING_ARRIVAL_DATE_FORMAT,
              true
            ),
            span: (children) => (
              <span className="br-tracking-arrival__date">{children}</span>
            )
          }
        );
      }

    default:
      return trackingDetails?.isInternationalOrder
        ? INTERNATIONAL_ORDERS_STATES_MAPPING[code]
        : state;
  }
};

export const generateInternationalOrdersLogs = (data) => {
  const { internationalOrderEvents } = data || {};

  const logs = [];

  const customsClearedTimestamp =
    internationalOrderEvents?.deliveryEvents?.find((event) =>
      [
        INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_CLEARED_FROM_CUSTOMS,
        INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_IN_TRANSIT_SOURCE
      ].includes(event.eventName)
    )?.createdAt;

  internationalOrderEvents?.deliveryEvents?.forEach((event) => {
    const { eventName, createdAt } = event;

    let customEventName = eventName;

    if (
      customEventName ===
        INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_RECEIVED_AT_WAREHOUSE &&
      dayjs(createdAt) > dayjs(customsClearedTimestamp)
    ) {
      customEventName =
        INTERNATIONAL_DELIVERY_EVENTS.INTERNATIONAL_RECEIVED_AT_WAREHOUSE_DESTINATION;
    }

    if (INTERNATIONAL_DELIVERY_EVENTS[customEventName]) {
      logs.push({
        code: INTERNATIONAL_LOG_STATE_MAPPING[customEventName],
        state: INTERNATIONAL_DELIVERY_LOGS[customEventName],
        timestamp: createdAt
      });
    }
  });

  return logs;
};

export const getInternationalOrderLastUpdatedAt = (trackingDetails) => {
  if (trackingDetails?.TransitEvents) {
    const lastLog =
      trackingDetails.TransitEvents[trackingDetails.TransitEvents.length - 1];

    return lastLog?.timestamp;
  }
};

export const getAdjustedInternationalState = (trackingDetails) => {
  const { CurrentStatus, activeDomesticTN, TrackingNumber } =
    trackingDetails || {};

  if (
    CurrentStatus.code === DELIVERY_STATES_CODES.RECEIVED_AT_WAREHOUSE &&
    activeDomesticTN !== TrackingNumber
  ) {
    CurrentStatus.code =
      DELIVERY_STATES_CODES.INTERNATIONAL_RECEIVED_AT_DESTINATION;
  }

  return CurrentStatus;
};

export const getLastConsigneeLog = (trackingDetails) => {
  const {
    Type,
    collectedFromConsignee,
    TransitEvents = []
  } = trackingDetails || {};

  if (
    [ORDER_TYPES.EXCHANGE, ORDER_TYPES.CRP].includes(Type) &&
    collectedFromConsignee
  ) {
    return {
      state: FINAL_STATE_LABEL[Type],
      timestamp: collectedFromConsignee
    };
  }

  return TransitEvents[TransitEvents.length - 1];
};

const reverseTransitEvents = (array) => {
  return array.sort((a, b) => dayjs(b.timestamp).diff(dayjs(a.timestamp)));
};
