import { fmt } from 'components/IntlWrapper/IntlWrapper';

export const OUR_VALUES = [
  fmt({ id: 'careers.values.integrity' }),
  fmt({ id: 'careers.values.impact' }),
  fmt({ id: 'careers.values.responsibility' }),
  fmt({ id: 'careers.values.growth' })
];

export const PERKS = [
  [
    {
      title: fmt({ id: 'careers.perks.opportunity.title' }),
      subtitle: fmt({ id: 'careers.perks.opportunity.subtitle' })
    },
    {
      title: fmt({ id: 'careers.perks.medical.title' }),
      subtitle: fmt({ id: 'careers.perks.medical.subtitle' })
    },
    {
      title: fmt({ id: 'careers.perks.solidarity.title' }),
      subtitle: fmt({ id: 'careers.perks.solidarity.subtitle' })
    }
  ],
  [
    {
      title: fmt({ id: 'careers.perks.home.title' }),
      subtitle: fmt({ id: 'careers.perks.home.subtitle' })
    },
    {
      title: fmt({ id: 'careers.perks.vacancies.title' }),
      subtitle: fmt({ id: 'careers.perks.vacancies.subtitle' })
    },
    {
      title: fmt({ id: 'careers.perks.locations.title' }),
      subtitle: fmt({ id: 'careers.perks.locations.subtitle' })
    }
  ]
];
