import { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Input } from 'antd';

import { NUMBER_TEXT, TEXT } from 'constants/Forms';

import { notify } from 'components/Notify/Notify';
import BRCityArea, { AVAILABILITY } from 'components/BRCityArea/BRCityArea';
import BRFormInputs from 'components/BRFormInputs/BRFormInputs';
import BRButton from 'components/BRButton/BRButton';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

import { ReactComponent as Mark } from 'assets/icons/mark.svg';

import './BRAddressDetailsSection.less';

const BRAddressDetailsSection = ({
  intl,
  formRef,
  setUserPosition = () => {},
  setSelectedCityArea = () => {},
  dropOffAddress,
  setIsFormValuesChanged
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [initialCityValue, setInitialCityValue] = useState(
    dropOffAddress?.city?.name
  );
  const [initialAreaValue, setInitialAreaValue] = useState(null);
  const [initialDistrictValue, setInitialDistrictValue] = useState(
    dropOffAddress?.district?.name
  );

  const handleLocationError = (browserHasGeolocation) => {
    notify({
      msg: browserHasGeolocation
        ? intl.formatMessage({
            id: 'br_google_map.service_failed'
          })
        : intl.formatMessage({
            id: 'br_google_map.not_supported_geolocation'
          })
    });
    setIsLoading(false);
  };

  const getStreetName = (addressArray) => {
    let streetName = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && 'route' === addressArray[i].types[0]) {
        streetName = addressArray[i].long_name;
      } else if (
        addressArray[i].types[0] &&
        ('administrative_area_level_2' === addressArray[i].types[0] ||
          'administrative_area_level_3' === addressArray[i].types[0])
      ) {
        streetName += ` ${addressArray[i].long_name}`;
      }
    }
    return streetName;
  };

  const getBuildingNumber = (addressArray) => {
    let buildingNumber = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        'street_number' === addressArray[i].types[0]
      ) {
        buildingNumber = addressArray[i].long_name;
        return buildingNumber;
      }
    }
  };

  const getCityAndAreaName = (addressArray) => {
    for (let i = 0; i < addressArray.length; i++) {
      const { long_name, types } = addressArray[i];

      switch (types[0]) {
        case 'administrative_area_level_1':
          setInitialCityValue(long_name);
          break;

        case 'administrative_area_level_2':
          setInitialAreaValue(long_name);
          break;

        case 'administrative_area_level_3':
          setInitialDistrictValue(long_name);
          break;
        default:
          break;
      }
    }
  };

  const getFormattedAddress = async (lat, lng) => {
    try {
      if (window.google) {
        const geocoder = new window.google.maps.Geocoder();
        const response = await geocoder.geocode({
          location: {
            lat,
            lng
          }
        });

        if (response) {
          setIsFormValuesChanged(true);
          const addressArray = response.results[0].address_components,
            streetName = getStreetName(addressArray),
            buildingNumber = getBuildingNumber(addressArray);
          getCityAndAreaName(addressArray);
          await formRef.current?.setFieldsValue({
            address: { firstLine: streetName, buildingNumber }
          });
        }
      }
    } catch (error) {
      notify({ msg: error.message });
    }
    setIsLoading(false);
  };

  const getCurrentPosition = () => {
    if (navigator.geolocation) {
      setIsLoading(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          getFormattedAddress(
            position?.coords?.latitude,
            position?.coords?.longitude
          );
          setUserPosition({
            lat: position?.coords?.latitude,
            lng: position?.coords?.longitude
          });
        },
        () => {
          handleLocationError(false);
        },
        { maximumAge: 6000, timeout: 4000, enableHighAccuracy: true }
      );
    } else {
      // Browser doesn't support Geolocation
      handleLocationError(false);
      setIsLoading(false);
    }
  };

  return (
    <LoadingWrapper loading={isLoading}>
      <div className="br-address-details-section">
        <span className="br-address-details__form-title body-medium">
          <span>
            {intl.formatMessage({
              id: 'address_details.address'
            })}
          </span>

          <BRButton
            prefixIcon={<Mark />}
            label={intl.formatMessage({
              id: 'address_details.get_current_location'
            })}
            type="link-color"
            onClick={getCurrentPosition}
          />
        </span>
        <Form.Item
          name={['address', 'firstLine']}
          className="br-address-details__street"
          label={intl.formatMessage({
            id: 'address_details.address'
          })}
          rules={[
            { required: true },
            {
              max: 250,
              message: intl.formatMessage(
                { id: 'form.maxLen' },
                {
                  label: intl.formatMessage({
                    id: 'address_details.address'
                  }),
                  max: 250
                }
              )
            }
          ]}
          hasFeedback
        >
          <Input
            type={TEXT}
            placeholder={intl.formatMessage({
              id: 'address_details.street_placeholder'
            })}
          />
        </Form.Item>
        <div className="br-additional-info-fields">
          <div className="br-form-row br-additional-info-fields__floor-apt">
            <BRFormInputs
              type={NUMBER_TEXT}
              formRef={formRef}
              name={['address', 'buildingNumber']}
              rules={[
                {
                  max: 25,
                  message: intl.formatMessage(
                    { id: 'form.maxLen' },
                    {
                      label: intl.formatMessage({
                        id: 'address_details.building_placeholder'
                      }),
                      max: 25
                    }
                  )
                }
              ]}
              placeholder={intl.formatMessage({
                id: 'address_details.building_placeholder'
              })}
            />
            <BRFormInputs
              type={NUMBER_TEXT}
              formRef={formRef}
              name={['address', 'floor']}
              rules={[
                {
                  max: 25,
                  message: intl.formatMessage(
                    { id: 'form.maxLen' },
                    {
                      label: intl.formatMessage({
                        id: 'address_details.floor_placeholder'
                      }),
                      max: 25
                    }
                  )
                }
              ]}
              placeholder={intl.formatMessage({
                id: 'address_details.floor_placeholder'
              })}
            />
            <BRFormInputs
              type={NUMBER_TEXT}
              formRef={formRef}
              name={['address', 'apartment']}
              rules={[
                {
                  max: 25,
                  message: intl.formatMessage(
                    { id: 'form.maxLen' },
                    {
                      label: intl.formatMessage({
                        id: 'address_details.apartment'
                      }),
                      max: 25
                    }
                  )
                }
              ]}
              placeholder={intl.formatMessage({
                id: 'address_details.apartment_placeholder'
              })}
            />
          </div>
          <BRFormInputs
            type={TEXT}
            formRef={formRef}
            name={['address', 'secondLine']}
            rules={[
              {
                max: 50,
                message: intl.formatMessage(
                  { id: 'form.maxLen' },
                  {
                    label: intl.formatMessage({
                      id: 'address_details.landmark'
                    }),
                    max: 50
                  }
                )
              }
            ]}
            placeholder={intl.formatMessage({
              id: 'address_details.landmarks_placeholder'
            })}
          />
          <BRCityArea
            name={['address', 'districtId']}
            formRef={formRef}
            availability={AVAILABILITY.DROPOFF}
            onChange={setSelectedCityArea}
            initialCityValue={initialCityValue}
            initialAreaValue={initialAreaValue}
            initialDistrictValue={initialDistrictValue}
          />
        </div>
      </div>
    </LoadingWrapper>
  );
};

export default injectIntl(BRAddressDetailsSection);
