import { injectIntl } from 'react-intl';

import { mediaHook } from 'customHooks';
import {
  PARTNERS_MOBILE_CAROUSEL_CONTENT,
  PARTNERS
} from 'constants/carousels-data';
import { visitorCountryCode } from 'utils/helpers';

import CarouselMobile from 'components/CarouselMobile/CarouselMobile';

import './TrustedCompanies.less';

const TrustedCompanies = ({ intl, mobileScreenSizes }) => {
  const { isLargeMobileScreen } = mobileScreenSizes;

  return (
    <section className="br-trusted-companies-section">
      <div className="br-trusted-companies-section-content">
        <h2 className="br-trusted-companies-section-title display-lg">
          {intl.formatMessage({ id: 'trusted_companies.title' })}
        </h2>
        <div className="br-trusted-companies-section-logos">
          {isLargeMobileScreen ? (
            <CarouselMobile
              carouselContent={PARTNERS_MOBILE_CAROUSEL_CONTENT[visitorCountryCode]}
            />
          ) : (
            <>
              {PARTNERS[visitorCountryCode].map((item) => (
                <div className="partner-logo">{item}</div>
              ))}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default injectIntl(mediaHook(TrustedCompanies));
