import React, { useEffect, useState } from 'react';
import { Button } from 'antd';

import linkedInButton from 'assets/images/linkedInButton.png';

import './BostaClinic.less';

const BostaClinic = () => {
  const calculateTimeLeft = () => {
    const difference = +new Date(2022, 6, 20, 19) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div>
      <main>
        <section
          class="section section-relative "
          id="page-block-isw3p557aqn"
          data-at="section"
        >
          <div
            class="section-holder-border item-block item-absolute"
            data-at="section-border"
          ></div>
          <div
            class="section-holder-overlay item-block item-absolute"
            data-at="section-overlay"
          ></div>
          <div class="section-block">
            <div class="section-inner section-fit section-relative">
              <div class="widget item-absolute  " id="element-1404">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <img
                    class="item-content-box item-block image "
                    data-at="image"
                    alt=""
                    src="//v.fastcdn.co/u/4126587d/61395053-0-Asset-6.png"
                    srcset="//v.fastcdn.co/u/4126587d/61395053-0-Asset-6.png 2x"
                  />
                </div>
              </div>

              <div class="widget item-absolute  " id="element-1192">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <img
                    class="item-content-box item-block image "
                    data-at="image"
                    alt=""
                    src="//v.fastcdn.co/u/4126587d/59459631-0-Artboard-3.png"
                    srcset="//v.fastcdn.co/u/4126587d/59459631-0-Artboard-3.png 2x"
                  />
                </div>
              </div>

              <div class="widget item-absolute  " id="element-1408">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <img
                    class="item-content-box item-block image "
                    data-at="image"
                    alt=""
                    src="//v.fastcdn.co/u/4126587d/61395041-0-Asset-3.png"
                    srcset="//v.fastcdn.co/u/4126587d/61395041-0-Asset-3.png 2x"
                  />
                </div>
              </div>

              <div
                class="widget item-absolute headline  "
                id="element-747"
                data-at="headline"
              >
                <div class="contents">
                  <h1>
                    <span class="x_e6edcaa1 x_7457154a">
                      <span class="x_74f0b483">بوسطة كلينك</span>
                    </span>
                  </h1>
                </div>
              </div>

              <div class="widget item-absolute  " id="element-1402">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <img
                    class="item-content-box item-block image "
                    data-at="image"
                    alt=""
                    src="//v.fastcdn.co/u/4126587d/61395032-0-Asset-2.png"
                    srcset="//v.fastcdn.co/u/4126587d/61395032-0-Asset-2.png 2x"
                  />
                </div>
              </div>

              <div class="widget item-absolute  " id="element-1407">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <img
                    class="item-content-box item-block image "
                    data-at="image"
                    alt=""
                    src="//v.fastcdn.co/u/4126587d/61395041-0-Asset-3.png"
                    srcset="//v.fastcdn.co/u/4126587d/61395041-0-Asset-3.png 2x"
                  />
                </div>
              </div>

              <div
                class="widget item-absolute headline  "
                id="element-1182"
                data-at="headline"
              >
                <div class="contents">
                  <h1>
                    <span class="x_3a6971f3 x_06e86a31">
                      <span class="x_69c6632a">
                        !انضم لمجتمع بيتطور في ثانية
                      </span>
                    </span>
                  </h1>
                </div>
              </div>

              <div
                class="widget item-absolute paragraph  "
                id="element-758"
                data-at="paragraph"
              >
                <div class="contents">
                  <p class="x_45d83595 x_0d0d8539">
                    <span class="x_74f0b483">
                      اتعرف على أقوي قصص النجاح في مجال ريادة الأعمال فى مصر
                      واعرف أهم النصايح والاستراتيجيات حصرياً من خبراء التجارة
                      الإلكترونية
                    </span>
                  </p>
                </div>
              </div>

              <div class="widget item-absolute item-block  " id="element-1653">
                <div
                  class="timer timer-text-top item-block"
                  data-date="2022-05-25T19:00:00.965Z"
                  data-timezone="2"
                >
                  <div
                    class="timer-labels timer-labels-top hidden-mobile"
                    data-at="timer-top-labels"
                  >
                    <div
                      class="timer-column timer-label timer-days"
                      data-at="timer-days"
                    >
                      days
                    </div>
                    <div
                      class="timer-column timer-label timer-hours"
                      data-at="timer-hours"
                    >
                      hours
                    </div>
                    <div
                      class="timer-column timer-label timer-minutes"
                      data-at="timer-minutes"
                    >
                      minutes
                    </div>
                    <div
                      class="timer-column timer-label timer-seconds"
                      data-at="timer-seconds"
                    >
                      seconds
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <div class="timer-date item-block">
                    <div class="timer-column timer-box timer-box-days">
                      <div
                        class="timer-number js-timer-days"
                        data-at="timer-number-days"
                      >
                        {timeLeft?.days || 0}
                      </div>
                    </div>
                    <div class="timer-column timer-box timer-box-hours">
                      <div
                        class="timer-number js-timer-hours"
                        data-at="timer-number-hours"
                      >
                        {timeLeft?.hours || 0}
                      </div>
                    </div>
                    <div class="timer-column timer-box timer-box-minutes">
                      <div
                        class="timer-number js-timer-minutes"
                        data-at="timer-number-minutes"
                      >
                        {timeLeft?.minutes || 0}
                      </div>
                    </div>
                    <div class="timer-column timer-box timer-box-seconds">
                      <div
                        class="timer-number js-timer-seconds"
                        data-at="timer-number-seconds"
                      >
                        {timeLeft?.seconds || 0}
                      </div>
                    </div>
                  </div>
                  <div
                    class="timer-labels timer-labels-bottom hidden-mobile"
                    data-at="timer-bottom-labels"
                  >
                    <div
                      class="timer-column timer-label timer-days"
                      data-at="timer-days"
                    >
                      days
                    </div>
                    <div
                      class="timer-column timer-label timer-hours"
                      data-at="timer-hours"
                    >
                      hours
                    </div>
                    <div
                      class="timer-column timer-label timer-minutes"
                      data-at="timer-minutes"
                    >
                      minutes
                    </div>
                    <div
                      class="timer-column timer-label timer-seconds"
                      data-at="timer-seconds"
                    >
                      seconds
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
              <div
                class="widget item-absolute hidden-mobile "
                id="element-1414"
              >
                <div class="contents cropped item-block" data-at="image-cropp">
                  <img
                    class="item-content-box item-block image "
                    data-at="image"
                    alt=""
                    src="//v.fastcdn.co/u/4126587d/61395296-0-Asset-11.png"
                    srcset="//v.fastcdn.co/u/4126587d/61395296-0-Asset-11.png 2x"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          class="section section-relative "
          id="page-block-cfk8nv8rlco"
          data-at="section"
        >
          <div
            class="section-holder-border item-block item-absolute"
            data-at="section-border"
          ></div>
          <div
            class="section-holder-overlay item-block item-absolute"
            data-at="section-overlay"
          ></div>
          <div class="section-block">
            <div class="section-inner section-fit section-relative">
              <div class="widget item-absolute  " id="element-1411">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <img
                    class="item-content-box item-block image "
                    data-at="image"
                    alt=""
                    src="//v.fastcdn.co/u/4126587d/61395203-0-Asset-9.png"
                    srcset="//v.fastcdn.co/u/4126587d/61395203-0-Asset-9.png 2x"
                  />
                </div>
              </div>

              <div
                class="widget item-absolute headline  "
                id="element-918"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_b13f30bc x_07204851">
                      <span class="x_f2074b6c">إيه هي</span>
                      <br />
                    </span>
                  </h3>
                </div>
              </div>

              <div
                class="widget item-absolute headline  "
                id="element-1410"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_b13f30bc x_07204851">
                      <span class="x_f2074b6c">بوسطة كلينيك</span>
                      <br />
                    </span>
                  </h3>
                </div>
              </div>

              <div class="widget item-absolute  " id="element-1413">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <img
                    class="item-content-box item-block image "
                    data-at="image"
                    alt=""
                    src="//v.fastcdn.co/u/4126587d/61395199-0-Asset-8.png"
                    srcset="//v.fastcdn.co/u/4126587d/61395199-0-Asset-8.png 2x"
                  />
                </div>
              </div>

              <div class="widget item-absolute  " id="element-1412">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <img
                    class="item-content-box item-block image "
                    data-at="image"
                    alt=""
                    src="//v.fastcdn.co/u/4126587d/61395199-0-Asset-8.png"
                    srcset="//v.fastcdn.co/u/4126587d/61395199-0-Asset-8.png 2x"
                  />
                </div>
              </div>

              <div
                class="widget item-absolute paragraph  "
                id="element-917"
                data-at="paragraph"
              >
                <div class="contents">
                  <p class="x_7a88b5a4 x_8642b94b">
                    <span class="x_f2074b6c">
                      بوسطة كلينك هي مبادرة بتساعد أي بيزنس انه يكبر ويتقدم بشكل
                      أسرع عن طريق تجارب واقعية الكلينك بتستضيف خبراء ورواد
                      أعمال في التجارة الالكترونية ومجالات متعددة وده هيوفر
                      مساحة للمنــــاقشة والتعـــــارف وتبــــــادل أهم
                      استراتيجيـــــــــات السوق
                    </span>
                  </p>
                  <p class="x_7a88b5a4 x_8642b94b">
                    <span class="x_f2074b6c">
                      <br />
                    </span>
                  </p>
                  <p class="x_7a88b5a4 x_8642b94b">
                    <span class="x_f2074b6c"></span>
                  </p>
                  <p class="x_7a88b5a4 x_8642b94b">
                    <span class="x_f2074b6c"></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          class="section section-relative "
          id="page-block-qkt1x4jq60i"
          data-at="section"
        >
          <div
            class="section-holder-border item-block item-absolute"
            data-at="section-border"
          ></div>
          <div
            class="section-holder-overlay item-block item-absolute"
            data-at="section-overlay"
          ></div>
          <div class="section-block">
            <div class="section-inner section-fit section-relative">
              <div
                class="widget item-absolute headline  "
                id="element-988"
                data-at="headline"
              >
                <div class="contents">
                  <h1>
                    <span class="x_906b70a2 x_a76aec5f">
                      {' '}
                      <span class="x_69c6632a">محاضـرين بوسطة كلينك</span>
                    </span>
                  </h1>
                </div>
              </div>

              <div class="widget item-absolute  " id="element-1560">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <img
                    class="item-content-box item-block image img-lazy"
                    data-at="image"
                    alt=""
                    src="https://v.fastcdn.co/u/4126587d/61405026-0-Asset-6.png"
                  />
                </div>
              </div>

              <div class="widget item-absolute  " id="element-1592">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <img
                    class="item-content-box item-block image img-lazy"
                    data-at="image"
                    alt=""
                    src="//v.fastcdn.co/u/4126587d/61404924-0-Asset-4.png"
                  />
                </div>
              </div>

              <div
                class="widget item-absolute headline  "
                id="element-991"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_efd3fa39">أحمد أبو بكر</span>
                  </h3>
                </div>
              </div>

              <div
                class="widget item-absolute paragraph  "
                id="element-989"
                data-at="paragraph"
              >
                <div class="contents">
                  <p class="x_dc68832a">مدير المنتج - بشر سوفت</p>
                </div>
              </div>

              <div class="widget item-absolute  " id="element-1623">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <a
                    href="https://www.linkedin.com/in/ahmed-abobakr-2a7052b2"
                    class="url-link item-content-box item-block"
                    id="link-m9vkgkduvsh"
                    data-at="image-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      class="item-content-box item-block image img-lazy"
                      data-at="image"
                      alt=""
                      src={linkedInButton}
                    />
                  </a>
                </div>
              </div>

              <div class="widget item-absolute  " id="element-1593">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <img
                    class="item-content-box item-block image img-lazy"
                    data-at="image"
                    alt=""
                    src="https://v.fastcdn.co/u/4126587d/61404932-0-Asset-5.png"
                  />
                </div>
              </div>

              <div class="widget item-absolute  " id="element-1594">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <img
                    class="item-content-box item-block image img-lazy"
                    data-at="image"
                    alt=""
                    src="//v.fastcdn.co/u/4126587d/61404926-0-Asset-7.png"
                  />
                </div>
              </div>

              <div
                class="widget item-absolute headline  "
                id="element-986"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_efd3fa39">
                      {' '}
                      <span class="x_a269ab78">هالة رياض</span>
                    </span>
                  </h3>
                </div>
              </div>

              <div
                class="widget item-absolute headline  "
                id="element-1572"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_efd3fa39">محمد حازم شريف</span>
                  </h3>
                </div>
              </div>

              <div
                class="widget item-absolute headline  "
                id="element-1576"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_efd3fa39">أحمد برادة</span>
                  </h3>
                </div>
              </div>

              <div
                class="widget item-absolute paragraph  "
                id="element-995"
                data-at="paragraph"
              >
                <div class="contents">
                  <p class="x_dc68832a">شريك مؤسس - حقوقي</p>
                </div>
              </div>

              <div
                class="widget item-absolute paragraph  "
                id="element-1570"
                data-at="paragraph"
              >
                <div class="contents">
                  <p class="x_dc68832a">شريك مؤسس ومدير المنتج - الكوتش</p>
                </div>
              </div>

              <div
                class="widget item-absolute paragraph  "
                id="element-1578"
                data-at="paragraph"
              >
                <div class="contents">
                  <p class="x_dc68832a">مدير تطوير الأعمال - بوسطة</p>
                </div>
              </div>

              <div class="widget item-absolute  " id="element-1624">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <a
                    href="https://www.linkedin.com/in/hala-tarek-riad-22b4bb197"
                    class="url-link item-content-box item-block"
                    id="link-07j85arydmeo"
                    data-at="image-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      class="item-content-box item-block image img-lazy"
                      data-at="image"
                      alt=""
                      src={linkedInButton}
                    />
                  </a>
                </div>
              </div>

              <div class="widget item-absolute  " id="element-1621">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <a
                    href="https://www.linkedin.com/in/muhammadhazem/"
                    class="url-link item-content-box item-block"
                    id="link-wrtocanpy3"
                    data-at="image-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      class="item-content-box item-block image img-lazy"
                      data-at="image"
                      alt=""
                      src={linkedInButton}
                    />
                  </a>
                </div>
              </div>

              <div class="widget item-absolute  " id="element-1622">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <a
                    href="https://www.linkedin.com/in/ahmed-barrada/"
                    class="url-link item-content-box item-block"
                    id="link-lh5qyp0vti"
                    data-at="image-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      class="item-content-box item-block image img-lazy"
                      data-at="image"
                      alt=""
                      src={linkedInButton}
                    />
                  </a>
                </div>
              </div>

              <div class="widget item-absolute  " id="element-1618">
                <div
                  class="contents cropped item-block"
                  data-at="image-cropp"
                ></div>
              </div>

              <div class="widget item-absolute  " id="element-1597">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <img
                    class="item-content-box item-block image img-lazy"
                    data-at="image"
                    alt=""
                    src="//v.fastcdn.co/u/4126587d/61404935-0-Asset-2.png"
                  />
                </div>
              </div>

              <div class="widget item-absolute  " id="element-1598">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <img
                    class="item-content-box item-block image img-lazy"
                    data-at="image"
                    alt=""
                    src="//v.fastcdn.co/u/4126587d/61404927-0-Asset-3.png"
                  />
                </div>
              </div>

              <div class="widget item-absolute  " id="element-1599">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <img
                    class="item-content-box item-block image img-lazy"
                    data-at="image"
                    alt=""
                    src="//v.fastcdn.co/u/4126587d/61404920-0-Asset-1.png"
                  />
                </div>
              </div>

              <div
                class="widget item-absolute headline  "
                id="element-1614"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_efd3fa39">محمد فرجاني</span>
                  </h3>
                </div>
              </div>

              <div
                class="widget item-absolute headline  "
                id="element-1234"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_efd3fa39">منى مخلوف</span>
                  </h3>
                </div>
              </div>

              <div
                class="widget item-absolute headline  "
                id="element-1580"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_efd3fa39">داليا سعيد</span>
                  </h3>
                </div>
              </div>

              <div
                class="widget item-absolute headline  "
                id="element-1566"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_efd3fa39">مراد صلاح</span>
                  </h3>
                </div>
              </div>

              <div
                class="widget item-absolute paragraph  "
                id="element-1616"
                data-at="paragraph"
              >
                <div class="contents">
                  <p class="x_dc68832a">رئيس مجلس الإدارة - كونڤرتد ان</p>
                </div>
              </div>

              <div
                class="widget item-absolute paragraph  "
                id="element-1264"
                data-at="paragraph"
              >
                <div class="contents">
                  <p class="x_dc68832a">خبيرة تسويق علامات تجارية</p>
                </div>
              </div>

              <div
                class="widget item-absolute paragraph  "
                id="element-1582"
                data-at="paragraph"
              >
                <div class="contents">
                  <p class="x_dc68832a">شريك مؤسس - الرحلة</p>
                </div>
              </div>

              <div
                class="widget item-absolute paragraph  "
                id="element-1568"
                data-at="paragraph"
              >
                <div class="contents">
                  <p class="x_dc68832a">المديرالمالي- بوسطة</p>
                </div>
              </div>

              <div class="widget item-absolute  " id="element-1634">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <a
                    href="https://www.linkedin.com/in/mohamedfergany/"
                    class="url-link item-content-box item-block"
                    id="link-2rt4j6kzqil"
                    data-at="image-link"
                    data-link-2rt4j6kzqil=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      class="item-content-box item-block image img-lazy"
                      data-at="image"
                      alt=""
                      src={linkedInButton}
                    />
                  </a>
                </div>
              </div>

              <div class="widget item-absolute  " id="element-1632">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <a
                    href="https://www.linkedin.com/in/mona-makhlouf-40557525/"
                    class="url-link item-content-box item-block"
                    id="link-i9alzotmpe8"
                    data-at="image-link"
                    data-link-i9alzotmpe8=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      class="item-content-box item-block image img-lazy"
                      data-at="image"
                      alt=""
                      src={linkedInButton}
                    />
                  </a>
                </div>
              </div>

              <div class="widget item-absolute  " id="element-1630">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <a
                    href="https://www.linkedin.com/in/daliasaid/"
                    class="url-link item-content-box item-block"
                    id="link-y2yfy2f4k69"
                    data-at="image-link"
                    data-link-y2yfy2f4k69=""
                  >
                    <img
                      class="item-content-box item-block image img-lazy"
                      data-at="image"
                      alt=""
                      src={linkedInButton}
                    />
                  </a>
                </div>
              </div>

              <div class="widget item-absolute  " id="element-1628">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <a
                    href="https://www.linkedin.com/in/mouradsalah/"
                    class="url-link item-content-box item-block"
                    id="link-td3qz69l18"
                    data-at="image-link"
                    data-link-td3qz69l18=""
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      class="item-content-box item-block image img-lazy"
                      data-at="image"
                      alt=""
                      src={linkedInButton}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          class="section section-relative "
          id="page-block-ggdycfzqc7s"
          data-at="section"
        >
          <div
            class="section-holder-border item-block item-absolute"
            data-at="section-border"
          ></div>
          <div
            class="section-holder-overlay item-block item-absolute"
            data-at="section-overlay"
          ></div>
          <div class="section-block">
            <div class="section-inner section-fit section-relative">
              <div
                class="widget item-absolute headline  "
                id="element-1158"
                data-at="headline"
              >
                <div class="contents">
                  <h1>
                    <span class="x_4099d1b7">
                      <span class="x_74f0b483">بوسطة كلينيك</span>
                    </span>
                  </h1>
                </div>
              </div>
              <div
                class="widget item-absolute headline  "
                id="element-1266"
                data-at="headline"
              >
                <div class="contents">
                  <h1>
                    <span class="x_b2d32610 x_0d3e9076">
                      <span class="x_69c6632a">المحاضـرات </span>
                    </span>
                  </h1>
                </div>
              </div>
              <div class="widget item-absolute  " id="element-1417">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <img
                    class="item-content-box item-block image img-lazy"
                    data-at="image"
                    alt=""
                    data-src="//v.fastcdn.co/u/4126587d/61395757-0-Asset-12.png"
                    src="//v.fastcdn.co/u/4126587d/61395757-0-Asset-12.png"
                  />
                </div>
              </div>
              <div class="widget item-absolute  " id="element-1418">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <img
                    class="item-content-box item-block image img-lazy"
                    data-at="image"
                    alt=""
                    data-src="//v.fastcdn.co/u/4126587d/61395734-0-Asset-13.png"
                    src="//v.fastcdn.co/u/4126587d/61395734-0-Asset-13.png"
                  />
                </div>
              </div>
              <div
                class="widget item-absolute headline  "
                id="element-1272"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_e8f3f92d x_6ee82066">
                      <span class="x_a7fe84b4">
                        حقوق تجارتك 2022: اعرف الوضع
                      </span>
                    </span>
                    <span class="x_e8f3f92d x_6ee82066">
                      <span class="x_a7fe84b4">
                        القانوني للتجارة الالكترونية في مصـر
                      </span>
                    </span>
                  </h3>
                </div>
              </div>
              <div class="widget item-absolute  " id="element-1667">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <img
                    class="item-content-box item-block image img-lazy"
                    data-at="image"
                    alt=""
                    data-src="//v.fastcdn.co/u/4126587d/60122845-0-Artboard-2.png"
                    src="//v.fastcdn.co/u/4126587d/60122845-0-Artboard-2.png"
                  />
                </div>
              </div>
              <div
                class="widget item-absolute paragraph  "
                id="element-1274"
                data-at="paragraph"
              >
                <div class="contents">
                  <p class="x_05f58128 x_bcbce646">
                    <span class="x_403c9345">
                      اتعرف على القانون المصري واللي ليك واللي عليك كأونلاين
                      بيزنس من أول حقوق الشراكة لحد الالتزامات الضريبية
                    </span>
                  </p>
                </div>
              </div>
              <div class="widget item-absolute " id="element-1326">
                <div class="contents shape  box figure " data-at="shape"></div>
              </div>
              <div class="widget item-absolute " id="element-1322">
                <div class="contents shape  box figure " data-at="shape"></div>
              </div>
              <div class="widget item-absolute " id="element-1320">
                <div class="contents shape  box figure " data-at="shape"></div>
              </div>
              <div
                class="widget item-absolute headline  "
                id="element-1318"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_a84c1223 x_b7e19525">
                      <span class="x_f2074b6c">مايو</span>
                    </span>
                  </h3>
                </div>
              </div>
              <div
                class="widget item-absolute headline  "
                id="element-1321"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_a84c1223 x_b7e19525">
                      <span class="x_f2074b6c">11</span>
                    </span>
                  </h3>
                </div>
              </div>
              <div
                class="widget item-absolute headline  "
                id="element-1328"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_ff17dd85">
                      <span class="x_f2074b6c">اونلاين</span>
                    </span>
                  </h3>
                </div>
              </div>
              <div class="widget item-absolute " id="element-1297">
                <div
                  class="contents shape  line-horizontal line "
                  data-at="shape"
                ></div>
              </div>
              <div class="widget item-absolute  " id="element-1660">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <img
                    class="item-content-box item-block image img-lazy"
                    data-at="image"
                    alt=""
                    data-src="//v.fastcdn.co/u/4126587d/61396177-0-Asset-21.png"
                    src="//v.fastcdn.co/u/4126587d/61396177-0-Asset-21.png"
                  />
                </div>
              </div>
              <div
                class="widget item-absolute headline  "
                id="element-1161"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_0249e598 x_6ee82066">
                      <span class="x_a7fe84b4">
                        <span class="x_752e43b0">
                          تنمية قيمة مشروعك بمبادرات وشراكات قوية
                        </span>
                      </span>
                    </span>
                  </h3>
                </div>
              </div>
              <div
                class="widget item-absolute paragraph  "
                id="element-1533"
                data-at="paragraph"
              >
                <div class="contents">
                  <p class="x_9480cd66 x_bcbce646">
                    اعرف ازاي من خلال عملك لشراكات استراتيجية هتقدر تزود قوة
                    تواجدك في السوق بين منافسينك
                    <p class="x_9480cd66 x_bcbce646">وتكبر قاعدة عملائك</p>
                  </p>
                </div>
              </div>
              <div class="widget item-absolute  " id="element-1668">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <img
                    class="item-content-box item-block image img-lazy"
                    data-at="image"
                    alt=""
                    data-src="//v.fastcdn.co/u/4126587d/60122845-0-Artboard-2.png"
                    src="//v.fastcdn.co/u/4126587d/60122845-0-Artboard-2.png"
                  />
                </div>
              </div>
              <div class="widget item-absolute " id="element-1342">
                <div class="contents shape  box figure " data-at="shape"></div>
              </div>
              <div class="widget item-absolute " id="element-1336">
                <div class="contents shape  box figure " data-at="shape"></div>
              </div>
              <div
                class="widget item-absolute headline  "
                id="element-1635"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_a84c1223 x_5319a2c7">
                      <span class="x_f2074b6c">مايو</span>
                    </span>
                  </h3>
                </div>
              </div>
              <div class="widget item-absolute " id="element-1333">
                <div class="contents shape  box figure " data-at="shape"></div>
              </div>
              <div
                class="widget item-absolute headline  "
                id="element-1340"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_a84c1223 x_5319a2c7">
                      <span class="x_f2074b6c">25</span>
                    </span>
                  </h3>
                </div>
              </div>
              <div
                class="widget item-absolute headline  "
                id="element-1344"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_ff17dd85">
                      <span class="x_f2074b6c">اونلاين</span>
                    </span>
                  </h3>
                </div>
              </div>
              <div class="widget item-absolute " id="element-1298">
                <div
                  class="contents shape  line-horizontal line "
                  data-at="shape"
                ></div>
              </div>
              <div class="widget item-absolute  " id="element-1512">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <img
                    class="item-content-box item-block image img-lazy"
                    data-at="image"
                    alt=""
                    data-src="//v.fastcdn.co/u/4126587d/61395752-0-Asset-15.png"
                    src="//v.fastcdn.co/u/4126587d/61395752-0-Asset-15.png"
                  />
                </div>
              </div>
              <div class="widget item-absolute  " id="element-1669">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <img
                    class="item-content-box item-block image img-lazy"
                    data-at="image"
                    alt=""
                    data-src="//v.fastcdn.co/u/4126587d/60122845-0-Artboard-2.png"
                    src="//v.fastcdn.co/u/4126587d/60122845-0-Artboard-2.png"
                  />
                </div>
              </div>
              <div
                class="widget item-absolute headline  "
                id="element-1169"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_07f466dd x_5f0ed501">
                      <span class="x_a7fe84b4">
                        مقاييس تحتاجها تجارتك الالكتـرونية لنمو أحسـن
                      </span>
                    </span>
                  </h3>
                </div>
              </div>
              <div
                class="widget item-absolute paragraph  "
                id="element-1534"
                data-at="paragraph"
              >
                <div class="contents">
                  <p class="x_05f58128 x_bcbce646">
                    <span class="x_403c9345">
                      اعرف اهم المقاييس الخاصة بنجاح أي إعلانات هتقوم بيها لنمو
                      تجارتك الإلكترونية
                    </span>
                  </p>
                </div>
              </div>
              <div class="widget item-absolute " id="element-1384">
                <div class="contents shape  box figure " data-at="shape"></div>
              </div>
              <div class="widget item-absolute " id="element-1378">
                <div class="contents shape  box figure " data-at="shape"></div>
              </div>
              <div
                class="widget item-absolute headline  "
                id="element-1380"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_a84c1223 x_5319a2c7">
                      <span class="x_f2074b6c">يونيو</span>
                    </span>
                  </h3>
                </div>
              </div>
              <div class="widget item-absolute " id="element-1376">
                <div class="contents shape  box figure " data-at="shape"></div>
              </div>
              <div
                class="widget item-absolute headline  "
                id="element-1382"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_a84c1223 x_5319a2c7">
                      <span class="x_f2074b6c">8</span>
                    </span>
                  </h3>
                </div>
              </div>
              <div
                class="widget item-absolute headline  "
                id="element-1386"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_ff17dd85">
                      <span class="x_f2074b6c">اونلاين</span>
                    </span>
                  </h3>
                </div>
              </div>
              <div class="widget item-absolute " id="element-1300">
                <div
                  class="contents shape  line-horizontal line "
                  data-at="shape"
                ></div>
              </div>
              <div class="widget item-absolute  " id="element-1661">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <img
                    class="item-content-box item-block image img-lazy"
                    data-at="image"
                    alt=""
                    data-src="//v.fastcdn.co/u/4126587d/61395737-0-Asset-14.png"
                    src="//v.fastcdn.co/u/4126587d/61395737-0-Asset-14.png"
                  />
                </div>
              </div>
              <div class="widget item-absolute  " id="element-1670">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <img
                    class="item-content-box item-block image img-lazy"
                    data-at="image"
                    alt=""
                    data-src="//v.fastcdn.co/u/4126587d/60122845-0-Artboard-2.png"
                    src="//v.fastcdn.co/u/4126587d/60122845-0-Artboard-2.png"
                  />
                </div>
              </div>
              <div
                class="widget item-absolute headline  "
                id="element-1172"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_ce3582e7 x_ef6d09e5">
                      <span class="x_a7fe84b4">
                        <span class="x_752e43b0">
                          من غيـرمخاطرة: تعلم استـراتيجيات توقعات الطلب وإدارة
                          مخزونك
                        </span>
                      </span>
                    </span>
                    <span class="x_ce3582e7 x_ef6d09e5">
                      <span class="x_a7fe84b4">
                        <span class="x_752e43b0">
                          <br />
                        </span>
                      </span>
                    </span>
                  </h3>
                </div>
              </div>
              <div
                class="widget item-absolute paragraph  "
                id="element-1535"
                data-at="paragraph"
              >
                <div class="contents custom-paragaraph">
                  <p class="x_9480cd66 x_bcbce646">
                    <span class="x_403c9345">
                      تعلم استراتيجيات وخطط توقع طلب منتجك في السوق من غير
                      مخاطرة في تخزين البضاعة أو تجاهل لتسعير
                      <p>منتجاتك قدام منافسينك</p>
                    </span>
                  </p>
                </div>
              </div>
              <div class="widget item-absolute " id="element-1370">
                <div class="contents shape  box figure " data-at="shape"></div>
              </div>
              <div class="widget item-absolute " id="element-1364">
                <div class="contents shape  box figure " data-at="shape"></div>
              </div>
              <div
                class="widget item-absolute headline  "
                id="element-1366"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_a84c1223 x_5319a2c7">
                      <span class="x_f2074b6c">يونيو</span>
                    </span>
                  </h3>
                </div>
              </div>
              <div class="widget item-absolute " id="element-1361">
                <div class="contents shape  box figure " data-at="shape"></div>
              </div>
              <div
                class="widget item-absolute headline  "
                id="element-1372"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_ff17dd85">
                      <span class="x_f2074b6c">اونلاين</span>
                    </span>
                  </h3>
                </div>
              </div>
              <div
                class="widget item-absolute headline  "
                id="element-1662"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_727f2cc2 x_5319a2c7">
                      {' '}
                      <span class="x_f2074b6c">22</span>
                    </span>
                  </h3>
                </div>
              </div>
              <div class="widget item-absolute " id="element-1419">
                <div
                  class="contents shape  line-horizontal line "
                  data-at="shape"
                ></div>
              </div>
              <div class="widget item-absolute  " id="element-1520">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <img
                    class="item-content-box item-block image img-lazy"
                    data-at="image"
                    alt=""
                    data-src="//v.fastcdn.co/u/4126587d/61395740-0-Asset-17.png"
                    src="//v.fastcdn.co/u/4126587d/61395740-0-Asset-17.png"
                  />
                </div>
              </div>
              <div
                class="widget item-absolute headline  "
                id="element-1522"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_2ff4a83b x_67ab24c6">
                      <span class="x_a7fe84b4">
                        خطوة بخطوة: ازاي تخلي عدد أكبـر من الناس
                      </span>
                    </span>
                    <span class="x_2ff4a83b x_67ab24c6">
                      <span class="x_a7fe84b4">
                        يتذكر علامتك التجارية ويتعرف عليها؟
                      </span>
                    </span>
                  </h3>
                </div>
              </div>
              <div
                class="widget item-absolute paragraph  "
                id="element-1557"
                data-at="paragraph"
              >
                <div class="contents">
                  <p class="x_05f58128 x_bcbce646">
                    <span class="x_403c9345">
                      زود وصولك عند جمهورك المستهدف على منصات التواصل الإجتماعي
                      بالمحتوى المناسب عشان تقوي ترابطك بيهم وتفضل حاضر في
                      دماغهم بشكل مستمر
                    </span>
                  </p>
                </div>
              </div>
              <div class="widget item-absolute " id="element-1439">
                <div class="contents shape  box figure " data-at="shape"></div>
              </div>
              <div class="widget item-absolute " id="element-1433">
                <div class="contents shape  box figure " data-at="shape"></div>
              </div>
              <div
                class="widget item-absolute headline  "
                id="element-1435"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_a84c1223 x_2a8d1306">
                      <span class="x_f2074b6c">يوليو</span>
                    </span>
                  </h3>
                </div>
              </div>
              <div class="widget item-absolute " id="element-1431">
                <div class="contents shape  box figure " data-at="shape"></div>
              </div>
              <div
                class="widget item-absolute headline  "
                id="element-1441"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_ff17dd85">
                      <span class="x_f2074b6c">اونلاين</span>
                    </span>
                  </h3>
                </div>
              </div>
              <div
                class="widget item-absolute headline  "
                id="element-1437"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_a84c1223 x_5319a2c7">
                      <span class="x_f2074b6c">6</span>
                    </span>
                  </h3>
                </div>
              </div>
              <div class="widget item-absolute " id="element-1442">
                <div
                  class="contents shape  line-horizontal line "
                  data-at="shape"
                ></div>
              </div>
              <div class="widget item-absolute  " id="element-1518">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <img
                    class="item-content-box item-block image img-lazy"
                    data-at="image"
                    alt=""
                    src="//v.fastcdn.co/u/4126587d/61395743-0-Asset-18.png"
                  />
                </div>
              </div>
              <div class="widget item-absolute  " id="element-1671">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <img
                    class="item-content-box item-block image img-lazy"
                    data-at="image"
                    alt=""
                    data-src="//v.fastcdn.co/u/4126587d/60122845-0-Artboard-2.png"
                    src="//v.fastcdn.co/u/4126587d/60122845-0-Artboard-2.png"
                  />
                </div>
              </div>
              <div
                class="widget item-absolute headline  "
                id="element-1427"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_2ff4a83b x_ef6d09e5">
                      <span class="x_a7fe84b4">
                        احسن من اعلاناتك الممولة Conversion مفاتيحك لـ
                      </span>
                    </span>
                  </h3>
                </div>
              </div>
              <div
                class="widget item-absolute paragraph  "
                id="element-1536"
                data-at="paragraph"
              >
                <div class="contents">
                  <p class="x_05f58128 x_e6507cf2">
                    <span class="x_403c9345">
                      اعرف العوامل الأساسية اللي بتأثر على نسبة الـ Conversion
                      Rate الخاصة بـمبيعاتك من خلال موقعك، من اول المنتج ذات
                      نفسه لحد الإعدادات الخاصة بموقعك
                    </span>
                  </p>
                </div>
              </div>
              <div class="widget item-absolute " id="element-1461">
                <div class="contents shape  box figure " data-at="shape"></div>
              </div>
              <div class="widget item-absolute " id="element-1455">
                <div class="contents shape  box figure " data-at="shape"></div>
              </div>
              <div
                class="widget item-absolute headline  "
                id="element-1457"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_a84c1223 x_5319a2c7">
                      <span class="x_f2074b6c">يوليو</span>
                    </span>
                  </h3>
                </div>
              </div>
              <div class="widget item-absolute " id="element-1453">
                <div class="contents shape  box figure " data-at="shape"></div>
              </div>
              <div
                class="widget item-absolute headline  "
                id="element-1463"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_ff17dd85">
                      <span class="x_f2074b6c">اونلاين</span>
                    </span>
                  </h3>
                </div>
              </div>
              <div
                class="widget item-absolute headline  "
                id="element-1459"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_a84c1223 x_5319a2c7">
                      <span class="x_f2074b6c">20</span>
                    </span>
                  </h3>
                </div>
              </div>
              <div class="widget item-absolute " id="element-1464">
                <div
                  class="contents shape  line-horizontal line "
                  data-at="shape"
                ></div>
              </div>
              <div class="widget item-absolute  " id="element-1524">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <img
                    class="item-content-box item-block image img-lazy"
                    data-at="image"
                    alt=""
                    data-src="//v.fastcdn.co/u/4126587d/61395748-0-Asset-19.png"
                    src="//v.fastcdn.co/u/4126587d/61395748-0-Asset-19.png"
                  />
                </div>
              </div>
              <div
                class="widget item-absolute headline  "
                id="element-1471"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_2ff4a83b x_ef6d09e5">
                      <span class="x_a7fe84b4">
                        دليل لاقوي الطرق لتحليل منافسينك في السوق
                      </span>
                    </span>
                  </h3>
                </div>
              </div>
              <div
                class="widget item-absolute paragraph  "
                id="element-1543"
                data-at="paragraph"
              >
                <div class="contents">
                  <p class="x_05f58128 x_bcbce646">
                    <span class="x_403c9345">
                      وصفة التسويق للبزنس الخاصة بيك ناقصها تفهم استراتيجية
                      واتجاه منافسينك في السوق، اتعرف على ازاى تحلل السوق بأسلوب
                      قوي وفعال
                    </span>
                  </p>
                </div>
              </div>
              <div class="widget item-absolute " id="element-1483">
                <div class="contents shape  box figure " data-at="shape"></div>
              </div>
              <div class="widget item-absolute " id="element-1477">
                <div class="contents shape  box figure " data-at="shape"></div>
              </div>
              <div class="widget item-absolute " id="element-1475">
                <div class="contents shape  box figure " data-at="shape"></div>
              </div>
              <div
                class="widget item-absolute headline  "
                id="element-1485"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_ff17dd85">
                      <span class="x_f2074b6c">اونلاين</span>
                    </span>
                  </h3>
                </div>
              </div>
              <div
                class="widget item-absolute headline  "
                id="element-1481"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_a84c1223 x_5319a2c7">
                      <span class="x_f2074b6c">10</span>
                    </span>
                  </h3>
                </div>
              </div>
              <div
                class="widget item-absolute headline  "
                id="element-1479"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_3206b8d5">
                      <span class="x_f2074b6c">أغسطس</span>
                    </span>
                  </h3>
                </div>
              </div>
              <div class="widget item-absolute hidden-mobile" id="element-1486">
                <div
                  class="contents shape  line-horizontal line "
                  data-at="shape"
                ></div>
              </div>
              <div class="widget item-absolute " id="element-1700">
                <div
                  class="contents shape  line-horizontal line "
                  data-at="shape"
                ></div>
              </div>
              <div class="widget item-absolute  " id="element-1525">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <img
                    class="item-content-box item-block image img-lazy"
                    data-at="image"
                    alt=""
                    src="//v.fastcdn.co/u/4126587d/61396470-0-Asset-20.png"
                  />
                </div>
              </div>
              <div
                class="widget item-absolute headline  "
                id="element-1493"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_2ff4a83b x_ef6d09e5">
                      <span class="x_a7fe84b4">
                        طريق مختصر: ازاي تبني خطط مبيعاتك وميزانيتك التشغيلية؟
                      </span>
                    </span>
                  </h3>
                </div>
              </div>
              <div
                class="widget item-absolute paragraph  "
                id="element-1544"
                data-at="paragraph"
              >
                <div class="contents">
                  <p class="x_05f58128 x_66d76769">
                    <span class="x_403c9345">
                      كون هيئة قوية لرأس مالك واوصل لاهدافك في المبيعات بفهم
                      محترف لميزانيتك وتقسيم ممتاز لفلوسك
                    </span>
                  </p>
                </div>
              </div>
              <div class="widget item-absolute " id="element-1505">
                <div class="contents shape  box figure " data-at="shape"></div>
              </div>
              <div class="widget item-absolute " id="element-1499">
                <div class="contents shape  box figure " data-at="shape"></div>
              </div>
              <div class="widget item-absolute " id="element-1497">
                <div class="contents shape  box figure " data-at="shape"></div>
              </div>
              <div
                class="widget item-absolute headline  "
                id="element-1507"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_ff17dd85">
                      <span class="x_f2074b6c">اونلاين</span>
                    </span>
                  </h3>
                </div>
              </div>
              <div
                class="widget item-absolute headline  "
                id="element-1503"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_a84c1223 x_5319a2c7">
                      <span class="x_f2074b6c">17</span>
                    </span>
                  </h3>
                </div>
              </div>
              <div
                class="widget item-absolute headline  "
                id="element-1501"
                data-at="headline"
              >
                <div class="contents">
                  <h3>
                    <span class="x_3206b8d5">
                      <span class="x_f2074b6c">أغسطس</span>
                    </span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="section section-relative "
          id="page-block-ndo1v0x4co"
          data-at="section"
        >
          <div class="section-block">
            <div class="section-inner section-fit section-relative">
              <div className="br-bosta-clinic__last-section">
                <div className="br-bosta-clinic__last-section-header">
                  <span className="br-bosta-clinic__last-section__text br-bosta-clinic__last-section__red-text">
                    العدد محدود!
                  </span>
                  <span className="br-bosta-clinic__last-section__text">
                    متفوتش فرصتك للحضور
                  </span>
                </div>
                <div className="br-bosta-clinic__last-section__footer">
                  <div
                    className="br-bosta-clinic__last-section__image"
                    data-at="image-cropp"
                  >
                    <img
                      class="item-content-box item-block image img-lazy"
                      data-at="image"
                      alt=""
                      src="//v.fastcdn.co/u/4126587d/61397335-0-Asset-22.png"
                      data-src="//v.fastcdn.co/u/4126587d/61397335-0-Asset-22.png"
                      data-retina-src="//v.fastcdn.co/u/4126587d/61397335-0-Asset-22.png"
                    />
                  </div>
                  <Button
                    type="primary"
                    className="button-primary"
                    onClick={() =>
                      window.open(
                        'https://docs.google.com/forms/d/1fQ05dkEV08Zt_pVECO9TEQ-Q44_qA-o6doj55QkoHd0/viewform?edit_requested=true'
                      )
                    }
                  >
                    سجل الآن
                  </Button>
                </div>
              </div>
              {/* <div class="widget item-absolute  " id="element-1177">
                <div class="item-absolute form-btn-geometry">
                  <Button
                    type="primary"
                    className="button-primary"
                    onClick={() =>
                      window.open(
                        'https://docs.google.com/forms/d/1fQ05dkEV08Zt_pVECO9TEQ-Q44_qA-o6doj55QkoHd0/viewform?edit_requested=true'
                      )
                    }
                  >
                    سجل الآن
                  </Button>
                </div>{' '}
              </div>
{/*  */}
              {/* <div class="widget item-absolute  " id="element-1526">
                <div class="contents cropped item-block" data-at="image-cropp">
                  <img
                    class="item-content-box item-block image img-lazy"
                    data-at="image"
                    alt=""
                    src="//v.fastcdn.co/u/4126587d/61397335-0-Asset-22.png"
                    data-src="//v.fastcdn.co/u/4126587d/61397335-0-Asset-22.png"
                    data-retina-src="//v.fastcdn.co/u/4126587d/61397335-0-Asset-22.png"
                  />
                </div>
              </div>  */}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default BostaClinic;
