import { injectIntl } from 'react-intl';

import BRHero from 'components/BRHero/BRHero';

import { ReactComponent as Vector1 } from 'assets/images/about_hero_vector_1.svg';
import { ReactComponent as Vector2 } from 'assets/images/mobile_hero_vector_1.svg';
import { ReactComponent as GooglePlayButton } from 'assets/images/googleplay-button.svg';
import { ReactComponent as AppStoreButton } from 'assets/images/appstore-button.svg';

import './MobileHero.less';

const MobileHero = ({ intl }) => {
  const handleGoToAndroidLink = () => {
    window.open('https://apps.apple.com/eg/app/id1625178514/id1625178514');
  };

  const handleGoToIosLink = () => {
    window.open('https://apps.apple.com/eg/app/id1625178514/id1625178514');
  };

  return (
    <BRHero
      className="br-mobile-hero"
      title={intl.formatMessage({ id: 'mobile.header.title' })}
      subtitle={
        <>
          <div>{intl.formatMessage({ id: 'mobile.header.subtitle' })}</div>
          <div className="br-mobile-download-buttons">
            <button className="app-button" onClick={handleGoToAndroidLink}>
              <GooglePlayButton />
            </button>
            <button className="app-button" onClick={handleGoToIosLink}>
              <AppStoreButton />
            </button>
          </div>
        </>
      }
      vectors={[<Vector1 />, <Vector2 />]}
    />
  );
};

export default injectIntl(MobileHero);
