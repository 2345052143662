import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Collapse } from 'antd';

import { CAPITAL_FAQS, ERADA_STATS, STATS } from 'constants/capital';

import CarouselBar from 'components/CarouselBar/CarouselBar';

import './CapitalStats.less';

const CapitalStats = ({ intl }) => {
  const { formatMessage } = intl;
  const { Panel } = Collapse;

  const [activeKeys, setActiveKeys] = useState(['0']);

  return (
    <>
      <div className="br-capital__stats-section">
        <h3 className="br-capital__stats-section-header">
          {formatMessage({ id: 'bosta_capital.stats.title' })}
        </h3>
        <div className="br-capital__stats-cards">
          {STATS.map((item, index) => (
            <div key={index} className="br-capital__stats-card">
              <p className="stats-value">{item.value}</p>
              <p className="stats-name">{item.name}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="br-capital__why-erada-section">
        <h3 className="br-capital__erada-stats-section-header">
          {formatMessage({
            id: 'bosta_capital.why_erada.title'
          })}
        </h3>
        <div className="br-capital__erada-stats-cards">
          {ERADA_STATS.map((item, index) => (
            <div key={index} className="br-capital__erada-stats-card">
              <p className="stats-value">{item.value}</p>
              <p className="stats-name">{item.name}</p>
            </div>
          ))}
        </div>
        <CarouselBar />
      </div>

      <div className="br-capital__faqs-section">
        <h3>{formatMessage({ id: 'bosta_capital.faqs' })}</h3>
        {CAPITAL_FAQS.map((item) => (
          <Collapse
            activeKey={activeKeys}
            onChange={setActiveKeys}
            ghost
            bordered={true}
          >
            <Panel
              className="br-capital__faqs-title"
              header={item.title}
              key={item.key}
              extra={
                <span className="display-sm">
                  {activeKeys.includes(item.key) ? '-' : '+'}
                </span>
              }
            >
              <p className="br-capital__faqs-subtitle">{item.subtitle}</p>
            </Panel>
          </Collapse>
        ))}
      </div>
    </>
  );
};

export default injectIntl(CapitalStats);
