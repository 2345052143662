import { injectIntl } from 'react-intl';

import { SOLUTIONS_DELIVERY_SOLUTIONS } from 'constants/solutions';

import Grid from 'components/Grid/Grid';

import { ReactComponent as OrderType } from 'assets/icons/SolutionsOrderType.svg';
import { ReactComponent as ShieldIcon } from 'assets/icons/HomeShieldIcon.svg';
import { ReactComponent as GraphIcon } from 'assets/icons/SolutionsGraph.svg';
import { ReactComponent as DeliveryMainImage } from 'assets/images/solutions-delivery-image.svg';

import './DeliverySolutions.less';

const DeliverySolutions = ({ intl }) => {
  const SOLUTIONS_DELIVERY_SOLUTIONS_ICONS = [OrderType, ShieldIcon, GraphIcon];

  return (
    <section className="br-solutions-delivery-container">
      <div className="br-solutions-delivery-content">
        <div className="br-solutions-delivery-content-grid">
          <Grid
            gridTitle={intl.formatMessage({
              id: 'solutions.delivery_solutions.title'
            })}
            gridSubtitle={intl.formatMessage({
              id: 'solutions.delivery_solutions.subtitle'
            })}
            items={SOLUTIONS_DELIVERY_SOLUTIONS}
            icons={SOLUTIONS_DELIVERY_SOLUTIONS_ICONS}
          />
        </div>
        <div className="br-solutions-delivery-svg">
          <DeliveryMainImage />
        </div>
      </div>
    </section>
  );
};

export default injectIntl(DeliverySolutions);
