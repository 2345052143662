import React, { useEffect, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { Button } from 'antd';

import { intersectionObserver } from 'utils/animations';

import { ReactComponent as AboutBusinesses } from 'assets/images/AboutBusinesses.svg';
import { ReactComponent as AboutParcels } from 'assets/images/AboutParcels.svg';
import { ReactComponent as AboutMobileBusinessAndParcels } from 'assets/images/AboutMobileBusinessesAndParcels.svg';
import { ReactComponent as VisionMain } from 'assets/images/vision_main.svg';

import './Vision.less';

const Vision = ({ intl }) => {
  const visionRef = useRef();

  const observerOptions = { rootMargin: '-20%' };

  const observerCallback = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.childNodes.forEach((svg, idx) => {
          //animate last 3 children only
          if (![0, 1].includes(idx)) {
            svg.firstChild.classList.add('slide-from-bottom');
            svg.classList.add('isVisible');
            svg.classList.remove('isHidden');
          }
        });
        observer.disconnect();
      }
    });
  };

  useEffect(() => {
    intersectionObserver(observerCallback, observerOptions, [
      visionRef?.current
    ]);
  }, []);

  return (
    <section className="br__vision__section">
      <div className="br__vision__section-container">
        <div className="br__vision__section-content" ref={visionRef}>
          <div className="br__vision__content-stats">
            {intl.formatMessage(
              { id: 'about.vision.statistics' },
              {
                TextDiv: (children) => (
                  <div className="subtitle-bold">{children}</div>
                )
              }
            )}
          </div>
          <div className="br__vision__section-svg main-svg">
            <VisionMain />
          </div>
          <div className="br__vision__section-svg business-svg isHidden">
            <AboutBusinesses />
          </div>
          <div className="br__vision__section-svg parcels-svg isHidden">
            <AboutParcels style={{ '--from': '-30%' }} />
          </div>
          <div className="br__vision__section-svg business-and-parcels-svg isHidden">
            <AboutMobileBusinessAndParcels style={{ '--from': '0' }} />
          </div>
        </div>
        <div className="br__vision__section-content">
          <h2 className="br__vision__content-title">
            {intl.formatMessage({ id: 'about.vision.title' })}
          </h2>
          <div className="br__vision__content-subtitle heading">
            {intl.formatMessage(
              { id: 'about.vision.subtitle' },
              {
                TextDiv: (children) => (
                  <div className="br__vision__content-subtitle heading">
                    {children}
                  </div>
                )
              }
            )}
          </div>
          <div className="br-vision-content__cta">
            <Button type="primary" className="button-primary">
              {intl.formatMessage({ id: 'about.vision.explore_solutions' })}
            </Button>
          </div>
        </div>
      </div>
      <div></div>
    </section>
  );
};

export default injectIntl(Vision);
