import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';

import { intersectionObserver } from 'utils/animations';

import './SimpleCard.less';

const SimpleCard = ({ title, image, animated, from }) => {
  const simpleCardRef = useRef();

  const observerOptions = { rootMargin: '-15%' };

  const observerCallback = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && animated) {
        entry.target.classList.add('isVisible', 'slide-from-bottom');
        entry.target.classList.remove('isHidden');
        observer.disconnect();
      }
    });
  };

  useEffect(() => {
    intersectionObserver(observerCallback, observerOptions, [
      simpleCardRef?.current
    ]);
  }, []);

  return (
    <div
      className={classNames('br-simple-card', { isHidden: animated })}
      ref={simpleCardRef}
      style={{ '--from': from }}
    >
      <div className="br-simple-card__image">{image}</div>
      <span className="br-simple-card__title display-sm">{title}</span>
    </div>
  );
};

export default SimpleCard;
