import React, { useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { Modal, Form } from 'antd';

import { updateVerifyPhone } from 'services/auth';
import { ERROR_CODES } from 'constants/signup';
import { ALL_COUNTRIES } from 'constants/countries/constants/countries';

import { notify } from 'components/Notify/Notify';
import BRContentHeader from 'components/BRContentHeader/BRContentHeader';
import BRButton from 'components/BRButton/BRButton';
import BRInternationalPhone from 'components/BRInternationalPhone/BRInternationalPhone';

import './UpdatePhoneModal.less';

const UpdatePhoneModal = (props) => {
  const {
    intl,
    close,
    setPhone,
    id,
    withSelect = true,
    initialCountry = false
  } = props;

  const updateFormRef = useRef();

  const [country, setCountry] = useState(
    initialCountry ? initialCountry : ALL_COUNTRIES[0]
  );
  const [usedPhoneNumber, setUsedPhoneNumber] = useState(false);

  const handleSubmit = async ({ phone }) => {
    const newPhone = `${country.codeNumber}${phone}`;
    try {
      const { newCountry } = await updateVerifyPhone(id, { phone: newPhone });
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      const { country } = userInfo?.user;

      userInfo.user = {
        ...userInfo.user,
        country: newCountry ? newCountry : country,
        profile: {
          ...userInfo.user.profile,
          phone: newPhone
        }
      };
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      setPhone(newPhone);
      close();
    } catch (error) {
      if (ERROR_CODES.PHONE_EXISTS.includes(error.errorCode)) {
        setUsedPhoneNumber(true);
      } else {
        notify({ msg: error.message, error });
      }
    }
  };

  return (
    <Modal
      {...props}
      className="verify-update-modal"
      footer={
        <BRButton
          type="primary"
          htmlType="submit"
          form="verifyPhoneUpdateForm"
          label={intl.formatMessage({
            id: 'sign_up.verify_phone.verify_phone_modal.update_and_resend_code'
          })}
        />
      }
      onCancel={close}
    >
      <BRContentHeader
        title={intl.formatMessage({
          id: 'sign_up.verify_phone.verify_phone_modal.title'
        })}
        className="br-update-phone-modal__header br-location-contact-form-header-with-back"
        withBackButton
        isInternalComponent
        onBackButtonClick={close}
      />
      <div className="update-modal">
        <Form
          className="verify-phone-update"
          name="verifyPhoneUpdateForm"
          onFinish={handleSubmit}
          hideRequiredMark={true}
          ref={updateFormRef}
        >
          <BRInternationalPhone
            country={country}
            setCountry={setCountry}
            formRef={updateFormRef}
            usedPhoneNumber={usedPhoneNumber}
            setUsedPhoneNumber={setUsedPhoneNumber}
            withSelect={withSelect}
          />
        </Form>
      </div>
    </Modal>
  );
};
export default injectIntl(withRouter(UpdatePhoneModal));
