import { bostaHttp } from 'utils/http';

export const register = (regSrc, data) => {
  return bostaHttp.post(`/users/business/admin?regSrc=${regSrc}`, data);
};

export const sllrRegister = (regSrc, data) => {
  return bostaHttp.post(`/users/business/sllr-sign-up?regSrc=${regSrc}`, data);
};

export const updateVerifyPhone = (id, data) => {
  return bostaHttp.put(`/users/${id}/business`, data);
};

export const sendCode = (data) => {
  return bostaHttp.post(`/users/business-admin/phone/confirm`, data);
};

export const sendCodePhone = (data) => {
  return bostaHttp.post(`/users/business-admin/phone/verify`, data);
};
