import { fmt } from 'components/IntlWrapper/IntlWrapper';

import { ReactComponent as Deliver } from 'assets/icons/Deliver.svg';
import { ReactComponent as Fulfillment } from 'assets/icons/fullfilment.svg';
import { ReactComponent as Product } from 'assets/icons/Product.svg';

export const LITE = 'LITE';
export const PRO = 'PRO';

export const MARKETING_CHANNELS = {
  FACEBOOK: 'Facebook',
  GOOGLE: 'Google',
  EVENT: 'Event',
  FRIEND: 'From a friend',
  OLD_CUSTOMER: 'Tried Bosta as a customer before',
  EXPANDCART: 'Expandcart',
  ZVENDO: 'zVendo',
  ZYDA: 'Zyda',
  VOWALA: 'Vowala',
  SHOPIFY: 'Shopify',
  WOOCOMMERCE: 'WooCommerce'
};

export const salesChannelOption = [
  'Facebook Shop',
  'Instagram',
  'Facebook Messenger',
  'Tiktok',
  'Shopify',
  'Woo Commerce',
  'Salla',
  'ZID',
  'Magento',
  'Custom Website'
];

export const industryOption = [
  {
    value: 'Books, Arts and Media',
    label: fmt({
      id: 'sign_up.industry_option.books'
    })
  },
  {
    value: 'Electronics',
    label: fmt({
      id: 'sign_up.industry_option.electronics'
    })
  },
  {
    value: 'Cosmetics and personal care',
    label: fmt({
      id: 'sign_up.industry_option.cosmetics'
    })
  },
  {
    value: 'Fashion',
    label: fmt({
      id: 'sign_up.industry_option.fashion'
    })
  },
  {
    value: 'Furniture and appliances',
    label: fmt({
      id: 'sign_up.industry_option.furniture'
    })
  },
  {
    value: 'Healthcare supplements',
    label: fmt({
      id: 'sign_up.industry_option.healthcare'
    })
  },
  {
    value: 'Home and living',
    label: fmt({
      id: 'sign_up.industry_option.home'
    })
  },
  {
    value: 'Gifts',
    label: fmt({
      id: 'sign_up.industry_option.gifts'
    })
  },
  {
    value: 'Jewelry and accessories ',
    label: fmt({
      id: 'sign_up.industry_option.jewelry'
    })
  },
  {
    value: 'Leather',
    label: fmt({
      id: 'sign_up.industry_option.leather'
    })
  },
  {
    value: 'Mothers and babies',
    label: fmt({
      id: 'sign_up.industry_option.mothers'
    })
  },
  {
    value: 'Medical supplies',
    label: fmt({
      id: 'sign_up.industry_option.medical'
    })
  },
  {
    value: 'Office equipment and supplies',
    label: fmt({
      id: 'sign_up.industry_option.office'
    })
  },
  {
    value: 'Pet supplies',
    label: fmt({
      id: 'sign_up.industry_option.pets'
    })
  },
  {
    value: 'Sports wear and equipment',
    label: fmt({
      id: 'sign_up.industry_option.sports'
    })
  },
  {
    value: 'Toys',
    label: fmt({
      id: 'sign_up.industry_option.toys'
    })
  },
  {
    value: 'E-commerce',
    label: fmt({
      id: 'sign_up.industry_option.e_commerce'
    })
  },
  {
    value: 'Food',
    label: fmt({
      id: 'sign_up.industry_option.food'
    })
  },
  {
    value: 'Shoes',
    label: fmt({
      id: 'sign_up.industry_option.shoes'
    })
  }
];

export const heardAboutUs = [
  { value: 'Facebook', label: 'facebook_option' },
  { value: 'Google', label: 'google_option' },
  { value: 'Event', label: 'event_option' },
  { value: 'From a friend ', label: 'friend_option' },
  {
    value: 'Tried Bosta as a customer before',
    label: 'customer_option'
  },
  { value: 'Expandcart', label: 'expandcart' },
  { value: 'zVendo', label: 'zvendo' },
  { value: 'Zyda', label: 'zyda' },
  { value: 'Vowala', label: 'vowala' },
  { value: 'Shopify', label: 'shopify' },
  { value: 'WooCommerce', label: 'woocommerce' }
];

export const INTERESTED_IN_SERVICE_OPTIONS = [
  {
    label: fmt({
      id: 'sign_up.interested_services_options.product_shipment'
    }),
    value: 'Products Shipments'
  },
  // {
  //   label: fmt({
  //     id: 'sign_up.interested_services_options.bosta_box'
  //   }),
  //   value: 'Bosta Box (Cross boarder)'
  // },

  {
    label: fmt({
      id: 'sign_up.interested_services_options.documents'
    }),
    value: 'Documents & B2B'
  },
  {
    label: fmt({
      id: 'sign_up.interested_services_options.bulky'
    }),
    value: 'Bulky'
  }
];

export const VOLUME_OPTIONS = [
  {
    label: '1 - 10',
    value: 'LITE_1'
  },
  {
    label: '11 - 100',
    value: 'LITE_2'
  },
  {
    label: '101 - 500',
    value: 'LITE_3'
  },
  {
    label: '501 - 1,000',
    value: 'PRO_1'
  },
  {
    label: '1,001 - 2,000',
    value: 'PRO_2'
  },
  {
    label: '2,000+',
    value: 'PRO_3'
  }
];

export const PRO_VOLUME_OPTIONS = ['PRO_1', 'PRO_2', 'PRO_3'];

export const LITE_VOLUME_OPTIONS = ['LITE_1', 'LITE_2', 'LITE_3'];

export const STEPPER = {
  SERVICES_STEP: 1,
  PRICING_STEP: 2,
  LAST_STEP: 3,
  SAUDI_LAST_STEP: 2
};

export const SERVICES = [
  {
    apiValue: 'NORMAL_SHIPMENT',
    icon: Deliver,
    title: fmt({
      id: 'sign_up.services.list.shipping.title'
    }),
    subtitle: fmt({
      id: 'sign_up.services.list.shipping.subtitle'
    }),
    defaultChecked: true
  },
  {
    apiValue: 'WITH_PRODUCT_CATALOG',
    icon: Product,
    title: fmt({
      id: 'sign_up.services.list.products.title'
    }),
    subtitle: fmt({
      id: 'sign_up.services.list.products.subtitle'
    }),
    tagText: fmt({
      id: 'sign_up.services.tags.beta'
    }),
    interestedNotificationMsg: fmt({
      id: 'sign_up.services.list.products.notify_interested'
    }),
    interestedSegmentEventAttr: 'Interested-in-sllr',
    isDisabled: true
  },
  {
    apiValue: 'WITH_FULFILLMENT_SHIPMENT',
    icon: Fulfillment,
    title: fmt({
      id: 'sign_up.services.list.fulfillment.title'
    }),
    subtitle: fmt({
      id: 'sign_up.services.list.fulfillment.subtitle'
    }),
    tagText: fmt({
      id: 'sign_up.services.tags.coming_soon'
    }),
    interestedNotificationMsg: fmt({
      id: 'sign_up.services.list.fulfillment.notify_interested'
    }),
    interestedSegmentEventAttr: 'Interested-in-fulfillment',
    isDisabled: true
  }
];

export const PLANS = {
  BUSINESS: 'yiqKg_aGM1',
  BASIC: 'c__CT4DU9I'
};

export const PLANS_SUMMERY_TEXT = (country) => ({
  [country?.pricingSignUpTiers?.businessTierId]: {
    title: `${fmt(
      {
        id: 'sign_up.pricing.cashouts'
      },
      {
        value: fmt({
          id: 'sign_up.pricing.instant'
        })
      }
    )}⚡`,
    subtitle: fmt({
      id: 'sign_up.pricing.instant_description'
    })
  },
  [country?.pricingSignUpTiers?.basicTierId]: {
    title: fmt(
      {
        id: 'sign_up.pricing.cashouts'
      },
      {
        value: fmt({
          id: 'pickups.table_columns.repeatation_types.weekly'
        })
      }
    ),
    subtitle: fmt({
      id: 'sign_up.pricing.weekly_description'
    })
  }
});

export const PAYMENT_FREQUENCY = {
  NEXT_DAY: 'Next Day'
};

export const BUSINESS_NAME_LENGHT = 21;

export const ERROR_CODES = {
  EMAIL_EXISTS: 1012,
  PHONE_EXISTS: [1002, 1053, 1046],
  BUSINESS_NAME_EXIST: 2020
};

export const ACTIVATION_STEPS_KEY = {
  VERIFY_EMAIL: 'VERIFY_EMAIL',
  SHIPPING_PRICES: 'SHIPPING_PRICES',
  BUSINESS_PROFILE: 'BUSINESS_PROFILE',
  PICKUP_LOCATION: 'PICKUP_LOCATION',
  BANK_ACCOUNT: 'BANK_ACCOUNT',
  ACCOUNT_TYPE: 'ACCOUNT_TYPE',
  SUMMARY: 'SUMMARY'
};

export const ACCOUNT_TYPE_RADIO = {
  TAX_ID: 1,
  NATIONAL_ID: 2,
  DEFAULT: 0
};

export const PICKUP = 'pickup';
export const SEND = 'SEND';
export const NORMAL = 'Normal';

export const SLLR_SOURCE = 'sllr.co';
export const SLLR_REG_SRC = 'SLLR';
export const MOBILE_APP_REG_SRC = 'BUSINESS_APP';
export const DASHBOARD_REG_SRC = 'DASHBOARD';

export const FREELANCE_SA_LINK = 'https://freelance.sa/register';

export const EXIST_USER_CODE = 1002;
