import { useState, useRef, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Form } from 'antd';

import { editShipmentAddress } from 'services/shipments';
import { UPDATE } from 'constants/shipments';

import BRModal from 'components/BRModal/BRModal';
import BRAddressDetailsSection from 'components/BRAddressDetailsSection/BRAddressDetailsSection';
import { notify } from 'components/Notify/Notify';

const AddressEditModal = ({
  intl,
  apiKey,
  onSuccess = () => {},
  dropOffAddress = {},
  close,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFormValuesChanged, setIsFormValuesChanged] = useState(false);
  const [selectedCityArea, setSelectedCityArea] = useState({});
  const [userPosition, setUserPosition] = useState({});

  const [form] = Form.useForm();

  const formRef = useRef('');

  const onFinish = async ({ address }) => {
    setIsLoading(true);
    const { cityID } = selectedCityArea || {};
    const { lat, lng } = userPosition || {};
    const payload = {
      updateType: UPDATE,
      updatedParams: {
        dropOffAddress: {
          ...address,
          cityCode: cityID,
          ...(lat &&
            lng && {
              geoLocation: [lat, lng]
            })
        }
      }
    };
    try {
      await editShipmentAddress({ token: apiKey, payload });
      notify({
        msg: intl.formatMessage({
          id: 'tracking.date_address_edit.edit_address_modal.success_edit_msg'
        }),
        type: 'success'
      });
      onSuccess();
      close();
    } catch (e) {
      notify({
        msg: e.message
      });
    }

    setIsLoading(false);
  };

  const handleConfirm = () => {
    form.validateFields().then(onFinish);
  };

  const setFormAddressInitialValues = () => {
    const {
      buildingNumber,
      floor,
      apartment,
      firstLine,
      secondLine,
      district = {}
    } = dropOffAddress;
    
    form?.setFieldsValue({
      address: {
        buildingNumber,
        floor,
        apartment,
        firstLine,
        secondLine,
        districtId: district._id
      }
    });
  };

  const checkFormValuesChange = (address) => {
    const allAddressKeys = Object.keys(address);
    const dropAddress = {
      ...dropOffAddress,
      districtId: dropOffAddress.district?._id
    };
    let isChanged = true;

    for (let i = 0; i < allAddressKeys.length; i++) {
      const currentKey = allAddressKeys[i];

      if (
        address[currentKey] !== dropAddress[currentKey] &&
        (address[currentKey] || dropAddress[currentKey])
      ) {
        isChanged = true;
        break;
      } else {
        isChanged = false;
      }
    }
    setIsFormValuesChanged(isChanged);
  };

  useEffect(() => {
    setFormAddressInitialValues();
  }, []);

  return (
    <BRModal
      title={intl.formatMessage({
        id: 'tracking.date_address_edit.edit_address_modal.title'
      })}
      subtitle={intl.formatMessage({
        id: 'tracking.date_address_edit.edit_address_modal.subtitle'
      })}
      okBtnProps={{
        label: intl.formatMessage({
          id: 'common.save_changes'
        }),
        type: 'primary',
        disabled: isLoading || !isFormValuesChanged,
        loading: isLoading,
        onClick: handleConfirm
      }}
      cancelBtnProps={{
        label: intl.formatMessage({
          id: 'common.cancel'
        }),
        disabled: isLoading,
        onClick: close
      }}
      {...props}
    >
      <Form
        form={form}
        ref={formRef}
        onValuesChange={(_, { address }) => {
          checkFormValuesChange(address);
        }}
      >
        <BRAddressDetailsSection
          formRef={formRef}
          setUserPosition={setUserPosition}
          setSelectedCityArea={setSelectedCityArea}
          dropOffAddress={dropOffAddress}
          setIsFormValuesChanged={setIsFormValuesChanged}
        />
      </Form>
    </BRModal>
  );
};

export default injectIntl(AddressEditModal);
