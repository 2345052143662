import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import BRHero from 'components/BRHero/BRHero';

import { ReactComponent as Vector1 } from 'assets/images/usecases-hero-icon-1.svg';
import { ReactComponent as Vector2 } from 'assets/images/usecases-hero-icon-2.svg';

import './UseCasesHero.less';

const UseCasesHero = ({ intl, history }) => {
  return (
    <BRHero
      className="br-use-cases__hero"
      title={intl.formatMessage({ id: 'use_cases.title' })}
      subtitle={intl.formatMessage({ id: 'use_cases.sub_title' })}
      buttonText={intl.formatMessage({ id: 'use_cases.button_text' })}
      onClickButton={() => history.push('/signup')}
      vectors={[<Vector1 />, <Vector2 />]}
    />
  );
};

export default withRouter(injectIntl(UseCasesHero));
