export const SHOW_BOSTA_INSIGHTS = {
  EG: true,
  SA: false
};

export const VALU_COUNTRIES = {
  EG: true,
  SA: false,
  AE: false
};

export const BOSTA_CAPITAL_AVAILABLE = {
  EG: true
};
