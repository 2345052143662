import { Carousel } from 'antd';
import { injectIntl } from 'react-intl';

import { mediaHook } from 'customHooks';
import { PRICING_CAROUSEL_DATA } from 'constants/pricing';

import './TestimonialsCarousel.less';

const TestimonialsCarousel = ({ intl, mobileScreenSizes }) => {
  const { isLargeMobileScreen } = mobileScreenSizes;

  return (
    <div className="br-testimonial-carousel-container">
      <h3 className="display-lg">
        {intl.formatMessage({ id: 'pricing.trusted_by_section.title' })}
      </h3>
      <Carousel
        slidesToShow={4}
        autoplay
        swipeToSlide
        draggable
        dots={false}
        centerMode={true}
        centerPadding={isLargeMobileScreen ? '40px ' : '120px'}
        responsive={[
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]}
      >
        {PRICING_CAROUSEL_DATA.map(({ name, postion, content, key }) => (
          <div className="br-testimonial-carousel-card-container" key={key}>
            <div className="br-testimonial-carousel-card-header">
              <h4 className="carousel_headings">{name}</h4>
              <h4 className="carousel_subheading">{postion}</h4>
            </div>
            <div>
              <p className="carousel_subheading">{content}</p>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default injectIntl(mediaHook(TestimonialsCarousel));
