import { useState } from 'react';
import { withRouter } from 'react-router';
import { Pagination } from 'antd';
import { injectIntl } from 'react-intl';

import { CUSTOM_API } from 'constants/merged-integrations';
import { HOST_NAME } from 'constants/helpers';
import { getLocaleFromLocalStorage } from 'utils/intl-wrapper';
import { pathLang } from 'utils/helpers';

import UseCasesHero from 'components/UseCases/components/UseCasesHero/UseCasesHero';
import SignUpAndStart from 'components/SignUpAndStart/SignUpAndStart';
import TrustedCompanies from 'components/TrustedCompanies/TrustedCompanies';

import { ReactComponent as FullReportingIcon } from 'assets/icons/full-reporting-icon.svg';
import { ReactComponent as FlexibleProcessIcon } from 'assets/icons/flexible-order-icon.svg';
import { ReactComponent as QuickCashoutsIcon } from 'assets/icons/quick-cashouts-icon.svg';
import { ReactComponent as SmartIntegrationIcon } from 'assets/icons/smart-integration-icon.svg';
import { ReactComponent as StoreAndShipIcon } from 'assets/icons/store-and-ship-icon.svg';
import { ReactComponent as LearnMoreIcon } from 'assets/icons/usecases-learnmore-icon.svg';

import './UseCases.less';

const UseCases = ({ intl, history }) => {
  const [currentCarouselPage, setCurrentCarouselPage] = useState(0);
  const language = pathLang || getLocaleFromLocalStorage();

  const USE_CASES_CAROUSEL_CONTENT = [
    {
      title: intl.formatMessage({
        id: 'use_cases.carousel_content.reporting_system.title'
      }),
      subTitle: intl.formatMessage({
        id: 'use_cases.carousel_content.reporting_system.sub_title'
      }),
      icon: <FullReportingIcon />
    },
    {
      title: intl.formatMessage({
        id: 'use_cases.carousel_content.flexible_process.title'
      }),
      subTitle: intl.formatMessage({
        id: 'use_cases.carousel_content.flexible_process.sub_title'
      }),
      icon: <FlexibleProcessIcon />
    },
    {
      title: intl.formatMessage({
        id: 'use_cases.carousel_content.smart_integrations.title'
      }),
      subTitle: intl.formatMessage({
        id: 'use_cases.carousel_content.smart_integrations.sub_title'
      }),
      learnMore: `/merged-integrations/${CUSTOM_API}`,
      icon: <SmartIntegrationIcon />
    },
    {
      title: intl.formatMessage({
        id: 'use_cases.carousel_content.store_with_bosta.title'
      }),
      subTitle: intl.formatMessage({
        id: 'use_cases.carousel_content.store_with_bosta.sub_title'
      }),
      icon: <StoreAndShipIcon />
    },
    {
      title: intl.formatMessage({
        id: 'use_cases.carousel_content.quick_cash_outs.title'
      }),
      subTitle: intl.formatMessage({
        id: 'use_cases.carousel_content.quick_cash_outs.sub_title'
      }),
      icon: <QuickCashoutsIcon />
    }
  ];

  const handleCarouselPageChange = (page, pageSize) => {
    setCurrentCarouselPage(page - 1);
  };

  return (
    <div>
      <div className="br-use-cases__header-container">
        <UseCasesHero />
      </div>
      <div className="br-use-cases__carousel-content">
        <div className="br-use-cases__carousel-img">
          {USE_CASES_CAROUSEL_CONTENT[currentCarouselPage].icon}
        </div>
        <div className="br-use-cases__carousel-text-content">
          <div className="br-use-cases__carousel-text-container">
            <h1 className="display-lg">
              {USE_CASES_CAROUSEL_CONTENT[currentCarouselPage].title}
            </h1>
            <p className="display-sm">
              {USE_CASES_CAROUSEL_CONTENT[currentCarouselPage].subTitle}
            </p>
            {USE_CASES_CAROUSEL_CONTENT[currentCarouselPage]?.learnMore && (
              <a
                className="button-lg"
                href={`${HOST_NAME}/${language}${USE_CASES_CAROUSEL_CONTENT[currentCarouselPage].learnMore}`}
              >
                {intl.formatMessage({
                  id: 'common.learn_more'
                })}
                <LearnMoreIcon />
              </a>
            )}
          </div>

          <Pagination
            defaultCurrent={1}
            total={50}
            className="br-use-cases__pagination"
            itemRender={(_, type, Element) => {
              if (['next', 'prev'].includes(type)) return Element;
              return <div></div>;
            }}
            onChange={handleCarouselPageChange}
          />
        </div>
      </div>

      <div className="br-use-cases__trusted-by-container">
        <div className="br-use-cases__trusted-by-companies-container">
          <TrustedCompanies />
        </div>
      </div>

      <SignUpAndStart />
    </div>
  );
};

export default injectIntl(withRouter(UseCases));
