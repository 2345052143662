import React, { Children } from 'react';
import { injectIntl } from 'react-intl';

import { FEATURES_DATA } from 'constants/fulfillment';

import BRButton from 'components/BRButton/BRButton';

import CloudUpVector from 'assets/images/cloud-up.svg';
import CloudDownVector from 'assets/images/cloud-down.svg';

import './FulfillmentFeatures.less';

const FulfillmentFeatures = ({ intl }) => {
  const { formatMessage } = intl;

  const scrollToRegisterForm = () => {
    let signupSection = document.getElementById('signup-section');
    if (signupSection) {
      signupSection.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <div className="br-fulfillment__features-section">
      <img
        src={CloudUpVector}
        alt="cloud"
        className="br-fulfillment__features-cloudUp"
      />
      <p className="br-fulfillment__features-title">
        {formatMessage(
          {
            id: 'bosta_fulfillment.features.title'
          },
          {
            span: (children) => <span>{children}</span>
          }
        )}
      </p>
      <div className="br-fulfillment__features-content">
        <div className="br-fulfillment__features-grid">
          {FEATURES_DATA.map(({ icon, title, description }, index) => (
            <div className="br-fulfillment__features-card" key={index}>
              <img
                src={icon}
                alt={title}
                className="br-fulfillment__features-card-icon"
              />
              <p className="br-fulfillment__features-card-title">
                {formatMessage({
                  id: title
                })}
              </p>
              <p className="br-fulfillment__features-card-description">
                {formatMessage({
                  id: description
                })}
              </p>
            </div>
          ))}
        </div>
      </div>
      <BRButton
        label={formatMessage({
          id: 'bosta_fulfillment.hero.signup'
        })}
        type="destructive-primary"
        onClick={scrollToRegisterForm}
        className="br-fulfillment__features-button"
      />

      <img
        src={CloudDownVector}
        alt="cloud"
        className="br-fulfillment__features-cloudDown"
      />
    </div>
  );
};

export default injectIntl(FulfillmentFeatures);
