import React, { useState, useEffect, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { Pagination, Button } from 'antd';

import { mediaHook } from 'customHooks';
import { OUR_VALUES, PERKS } from 'constants/careers';
import { renderSVGs } from 'utils/animations';
import { intersectionObserver } from 'utils/animations';

import SignUpAndStart from 'components/SignUpAndStart/SignUpAndStart';
import BRHero from 'components/BRHero/BRHero';
import PerksCarouselMobile from './components/PerksCarouselMobile/PerksCarouselMobile';

import { ReactComponent as SmallHexagon } from 'assets/images/CareersHeroSmallHexagon.svg';
import { ReactComponent as TinyHexagon } from 'assets/images/CareersHeroTinyHexagon.svg';
import { ReactComponent as CareersSVG1 } from 'assets/images/careers-svg1.svg';
import { ReactComponent as CareersSVG2 } from 'assets/images/careers-svg2.svg';
import { ReactComponent as CareersSVG3 } from 'assets/images/careers-svg3.svg';
import { ReactComponent as CareersSVG4 } from 'assets/images/careers-svg4.svg';
import { ReactComponent as CareersSVG5 } from 'assets/images/careers-svg5.svg';
import { ReactComponent as CareersSVG6 } from 'assets/images/careers-svg6.svg';
import { ReactComponent as CareersSVG7 } from 'assets/images/careers-svg7.svg';
import { ReactComponent as CareersSVG8 } from 'assets/images/careers-svg8.svg';
import { ReactComponent as OurValuesImage } from 'assets/images/CareersOurValuesBackground.svg';
import { ReactComponent as PerksHexagon } from 'assets/images/CareersPerksHexagon.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/CareersHomeIcon.svg';
import { ReactComponent as LocationsIcon } from 'assets/icons/CareersLocationsIcon.svg';
import { ReactComponent as MedicalIcon } from 'assets/icons/CareersMedicalIcon.svg';
import { ReactComponent as SolidarityIcon } from 'assets/icons/CareersSolidarityIcon.svg';
import { ReactComponent as VacanciesIcon } from 'assets/icons/CareersVacanciesIcon.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/HomeProfileIcon.svg';
import { ReactComponent as JobsSearchImage } from 'assets/images/CareersJobSearchImg.svg';

import './Careers.less';

const Careers = ({ intl, mobileScreenSizes }) => {
  const { isLargeMobileScreen } = mobileScreenSizes;

  const [currentCarouselPage, setCurrentCarouselPage] = useState(0);

  const ourValuesRef = useRef();

  const PERKS_ICONS = [
    [<ProfileIcon />, <MedicalIcon />, <SolidarityIcon />],
    [<HomeIcon />, <VacanciesIcon />, <LocationsIcon />]
  ];

  const CAREERS_SVGS = [
    { Vector: CareersSVG1 },
    {
      Vector: CareersSVG2,
      fromX: '150%',
      fromY: '10%',
      diagonalAnimation: true,
      isHidden: true
    },
    {
      Vector: CareersSVG3,
      fromX: '280%',
      fromY: '280%',
      diagonalAnimation: true,
      isHidden: true
    },
    {
      Vector: CareersSVG4,
      from: '180%',
      fromBottom: true,
      isHidden: true
    },
    {
      Vector: CareersSVG5,
      fromX: '-90%',
      fromY: '270%',
      diagonalAnimation: true,
      isHidden: true
    },
    {
      Vector: CareersSVG6,
      from: '-230%',
      fromSide: true,
      isHidden: true
    },
    { Vector: CareersSVG7, from: '-60%', fromSide: true, isHidden: true },
    { Vector: CareersSVG8, from: '60%', fromSide: true, isHidden: true }
  ];

  const handleCarouselPageChange = (page) => {
    setCurrentCarouselPage(page - 1);
  };

  const handleFindJobClick = () => {
    window.open('https://jobs.lever.co/Bosta');
  };

  const observerOptions = { rootMargin: '-15%' };

  const observerCallback = (entries, observer) => {
    entries.forEach((entry) => {
      let diamondSVGs = entry.target?.childNodes[0]?.childNodes;
      let ourValues = entry.target?.childNodes[1]?.childNodes;
      if (entry.isIntersecting) {
        diamondSVGs.forEach((svg) => {
          svg?.classList.add('isVisible');
          svg?.classList.remove('isHidden');
          if (svg.classList.contains('from-side')) {
            svg.firstChild.classList.add('slide-from-side');
          } else if (svg.classList.contains('from-bottom')) {
            svg.firstChild.classList.add('slide-from-bottom');
          } else if (svg.classList.contains('diagonal-animation')) {
            svg.firstChild.classList.add('slide-diagonally');
          }
        });
        ourValues?.forEach((value, idx) => {
          if (idx !== 0) {
            value?.classList?.add('isVisible', 'slide-from-side');
            value?.classList?.remove('isHidden');
          }
        });
        observer.disconnect();
      }
    });
  };

  useEffect(() => {
    intersectionObserver(observerCallback, observerOptions, [
      ourValuesRef?.current
    ]);
  }, []);

  return (
    <div className="br-careers">
      <BRHero
        className="br-careers__header"
        title={intl.formatMessage({ id: 'careers.header.title' })}
        subtitle={intl.formatMessage({
          id: 'careers.header.subtitle'
        })}
        buttonText={intl.formatMessage({
          id: 'careers.header.button'
        })}
        vectors={[<SmallHexagon />, <TinyHexagon />]}
        onClickButton={handleFindJobClick}
      />
      <div className="br-careers__values" ref={ourValuesRef}>
        <div className="br-careers__values-diamond-img">
          {renderSVGs(CAREERS_SVGS)}
        </div>
        <span
          className="br-careers__values-our-values"
          style={{
            '--from': '0',
            '--opacityFrom': '0'
          }}
        >
          <OurValuesImage />
          {OUR_VALUES.map((value, index) => (
            <div
              className="br-careers__values-value display-flex justify-space-between align-center isHidden"
              key={index}
            >
              <span className="br-careers__values-our-values__value heading-sm">
                {value}
              </span>
              {index < OUR_VALUES.length - 1 && (
                <span className="br-careers__values-our-values__circle" />
              )}
            </div>
          ))}
        </span>
      </div>
      <div className="br-careers__perks-section">
        <div className="br-careers__perks">
          <span className="br-careers__perks-hexagon">
            <PerksHexagon />
          </span>
          <div className="br-careers__perks-text-container">
            <span className="br-careers__perks-title display-lg">
              {intl.formatMessage({
                id: 'careers.perks.title'
              })}
            </span>
          </div>
          <div className="br-careers__perks-cards">
            {isLargeMobileScreen ? (
              <PerksCarouselMobile
                perks={PERKS}
                icons={PERKS_ICONS}
                currentPage={currentCarouselPage}
              />
            ) : (
              PERKS[currentCarouselPage].map(({ title, subtitle }, index) => (
                <div className="br-careers__perks-perk" key={index}>
                  {PERKS_ICONS[currentCarouselPage][index]}
                  <span className="br-careers__perks-perk-title">{title}</span>
                  <span className="br-careers__perks-perk-subtitle heading">
                    {subtitle}
                  </span>
                </div>
              ))
            )}
          </div>
          <div className="br-careers__pagination-container">
            <Pagination
              defaultCurrent={1}
              pageSize={isLargeMobileScreen ? 1 : 3}
              total={isLargeMobileScreen ? 6 : PERKS.length}
              className="br-careers__pagination"
              itemRender={(_, type, Element) => {
                if (['next', 'prev'].includes(type)) return Element;
              }}
              onChange={handleCarouselPageChange}
            />
          </div>
        </div>
      </div>
      <div className="br-careers__jobs-section">
        {/* <span className="br-careers__jobs-section-img">
          <JobsRightImage />
        </span> */}
        <div className="br-careers__jobs-section-bottom">
          <div className="br-careers__jobs-section-bottom__search">
            <span className="heading-lg-2">
              {intl.formatMessage({ id: 'careers.jobs.search_and_apply' })}
            </span>
            <span className="br-careers__jobs-section-bottom__search-subtitle display-sm">
              {intl.formatMessage({ id: 'careers.jobs.filter' })}
            </span>
            <Button
              type="primary"
              className="button-primary"
              onClick={handleFindJobClick}
            >
              {intl.formatMessage({ id: 'careers.jobs.find_job' })}
            </Button>
            <JobsSearchImage className="br-careers__jobs-section-bottom__search-img" />
          </div>
          {/* <div className="br-careers__jobs-section-bottom__apply">
            <span className="heading-lg-2">
              {intl.formatMessage({ id: 'careers.jobs.apply_now' })}
            </span>
            <div className="br-careers__jobs-section-bottom__apply-attach-input-button">
              <Input
                className="br-careers__jobs-section-bottom__apply-attach-input"
                placeholder={intl.formatMessage({
                  id: 'careers.jobs.attach_your_cv'
                })}
              />
              <Button className="br-careers__jobs-section-bottom__apply-attach-button">
                {intl.formatMessage({ id: 'common.attach' })}
              </Button>
            </div>
            <TextArea
              className="br-careers__jobs-section-bottom__apply-message"
              placeholder={intl.formatMessage({
                id: 'common.message'
              })}
            ></TextArea>
            <Button type="primary" className="button-primary">
              {intl.formatMessage({ id: 'common.submit' })}
            </Button>
          </div> */}
        </div>
      </div>
      <SignUpAndStart />
    </div>
  );
};

export default injectIntl(mediaHook(Careers));
