import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Radio } from 'antd';
import dayjs from 'dayjs';

import {
  getAvailableRescheduledDates,
  rescheduleShipment
} from 'services/shipments';
import { RESCHEDULE_DELIVERY } from 'constants/shipments';
import { dates, DATE_FORMAT_WITHOUT_YEAR } from 'utils/helpers';

import BRModal from 'components/BRModal/BRModal';
import { notify } from 'components/Notify/Notify';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';

import './DateEditModal.less';

const DateEditModal = ({
  intl,
  apiKey,
  onSuccess = () => {},
  close,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [availableDates, setAvailableDates] = useState([]);

  const formatDate = (date) => {
    const dayjsDate = dayjs(date);

    return {
      formattedDate: dates(dayjsDate, DATE_FORMAT_WITHOUT_YEAR),
      formattedDay: dayjsDate.format('dddd')
    };
  };

  const renderDateLabel = (date) => {
    const { formattedDay, formattedDate } = formatDate(date);

    return (
      <span className="br-available-dates__date-day">
        <span>{formattedDay}</span>
        <span>{formattedDate}</span>
      </span>
    );
  };

  const handleChange = ({ target: { value } }) => {
    setSelectedDate(availableDates[value].dayDate);
  };

  const getDates = async () => {
    setIsLoading(true);

    try {
      const { data: list = [] } = await getAvailableRescheduledDates({
        payload: { days: 5 },
        token: apiKey
      });
      setAvailableDates(list);
      setSelectedDate(list[0]?.dayDate);
      if (!list.length) {
        notify({
          msg: intl.formatMessage({
            id: 'tracking.date_address_edit.edit_date_modal.no_available_days'
          })
        });
      }
    } catch (e) {
      notify({
        msg: e.message
      });
    }

    setIsLoading(false);
  };

  const rescheduleDate = async () => {
    setIsLoading(true);

    const payload = {
      updateType: RESCHEDULE_DELIVERY,
      scheduledAt: selectedDate
    };

    try {
      await rescheduleShipment({
        payload,
        token: apiKey
      });

      notify({
        msg: intl.formatMessage(
          {
            id: 'tracking.date_address_edit.edit_date_modal.success_edit_msg'
          },
          {
            date: formatDate(selectedDate).formattedDate
          }
        ),
        type: 'success'
      });
      onSuccess();
      close();
    } catch (e) {
      notify({
        msg: e.message
      });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getDates();
  }, []);

  return (
    <BRModal
      title={intl.formatMessage({
        id: 'tracking.date_address_edit.edit_date_modal.title'
      })}
      subtitle={intl.formatMessage({
        id: 'tracking.date_address_edit.edit_date_modal.subtitle'
      })}
      okBtnProps={{
        label: intl.formatMessage({
          id: 'common.save_changes'
        }),
        type: 'primary',
        disabled: isLoading || !selectedDate,
        loading: isLoading,
        onClick: rescheduleDate
      }}
      cancelBtnProps={{
        label: intl.formatMessage({
          id: 'common.cancel'
        }),
        disabled: isLoading,
        onClick: close
      }}
      wrapClassName="br-available-dates__modal"
      width={null}
      {...props}
    >
      <LoadingWrapper loading={isLoading}>
        <div className="br-available-dates__label">
          {intl.formatMessage({
            id: 'tracking.date_address_edit.edit_date_modal.label'
          })}
        </div>
        <Radio.Group
          className="br-available-dates"
          options={availableDates.map(({ dayDate }, index) => ({
            label: renderDateLabel(dayDate),
            value: index
          }))}
          defaultValue={0}
          onChange={handleChange}
          optionType="button"
        />
      </LoadingWrapper>
    </BRModal>
  );
};

export default injectIntl(DateEditModal);
