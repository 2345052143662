export const logout = (pathname = null) => {
  const locale = localStorage.getItem('Locale');

  localStorage.removeItem('userInfo');
  localStorage.removeItem('newToken');

  localStorage.clear();
  setTimeout(() => {
    window.location.reload();
  }, 1000);

  localStorage.setItem('Locale', locale ? locale : 'en');
};
