import React, { useEffect, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Table } from 'antd';
import { withRouter } from 'react-router-dom';

import { PRICING_DATA_SOURCE, columns } from 'constants/pricing';
import { mediaHook } from 'customHooks';
import { renderSVGs } from 'utils/animations';
import { intersectionObserver } from 'utils/animations';
import { visitorCountryCode } from 'utils/helpers';
import { isPricingPlansTableAvailable } from 'constants/countries/countries-mapping';

import BRHero from 'components/BRHero/BRHero';
import SignUpAndStart from 'components/SignUpAndStart/SignUpAndStart';
import PricingTableMobile from './components/PricingTableMobile/PricingTableMobile';
import TestimonialsCarousel from 'components/TestimonialsCarousel/TestimonialsCarousel';

import { ReactComponent as Vector1 } from 'assets/images/about_hero_vector_1.svg';
import { ReactComponent as Vector2 } from 'assets/images/pricing-vector.svg';
import { ReactComponent as PricingInfoImage } from 'assets/images/pricing-info-image.svg';
import { ReactComponent as PricingInfoImage1 } from 'assets/images/pricing-info-image-1.svg';
import { ReactComponent as PricingInfoImage2 } from 'assets/images/pricing-info-image-2.svg';
import { ReactComponent as PricingInfoImage3 } from 'assets/images/pricing-info-image-3.svg';
import { ReactComponent as PricingInfoImage4 } from 'assets/images/crest.svg';
import { ReactComponent as PricingInfoImage5 } from 'assets/images/pricing-info-image-5.svg';
import { ReactComponent as PricingInfoImage6 } from 'assets/images/pricing-info-image-6.svg';
import { ReactComponent as PricingInfoImage7 } from 'assets/images/cloud1.svg';
import { ReactComponent as PricingInfoImage8 } from 'assets/images/pricing-info-image-8.svg';
import { ReactComponent as CustomizedIcon } from 'assets/images/pricing-info-icons-14.svg';
import { ReactComponent as DailyCashoutIcon } from 'assets/images/pricing-info-icons-15.svg';
import { ReactComponent as FawryIcon } from 'assets/images/pricing-info-icons-13.svg';
import { ReactComponent as ProductInsuranceIcon } from 'assets/images/pricing-info-icons-12.svg';
import { ReactComponent as VolumeIcon } from 'assets/images/pricing-info-icons-11.svg';

import './Pricing.less';

const Pricing = ({ intl, mobileScreenSizes, history }) => {
  const { isLargeMobileScreen } = mobileScreenSizes;

  const PRICES_SVGS = [
    {
      Vector: PricingInfoImage1,
      from: '20%',
      fromBottom: true,
      opacityFrom: 1
    },
    {
      Vector: PricingInfoImage2,
      from: '-250%',
      fromSide: true,
      opacityFrom: 1
    },
    {
      Vector: PricingInfoImage3,
      from: '-200%',
      fromSide: true,
      opacityFrom: 1
    },
    {
      Vector: PricingInfoImage4,
      from: '-300%',
      fromSide: true,
      opacityFrom: 1
    },
    { Vector: PricingInfoImage5, from: '-1600%', fromBottom: true },
    { Vector: PricingInfoImage5, from: '-900%', fromBottom: true },
    { Vector: PricingInfoImage5, from: '-350%', fromBottom: true },
    {
      Vector: PricingInfoImage6,
      from: '-100%',
      fromSide: true,
      opacityFrom: 1
    },
    { Vector: PricingInfoImage7, from: '60%', fromSide: true },
    { Vector: PricingInfoImage8, from: '190%', fromBottom: true }
  ];

  const pricingSvgsRef = useRef();

  const observerOptions = { rootMargin: '-15%' };

  const observerCallback = (entries, observer) => {
    entries.forEach((entry) => {
      const classList = entry.target.classList;
      if (entry.isIntersecting) {
        classList.add('isVisible');
        classList.remove('isHidden');
        entry.target.childNodes.forEach((svg) => {
          if (svg.classList.contains('from-side')) {
            svg.firstChild.classList.add('slide-from-side');
          } else if (svg.classList.contains('from-bottom')) {
            svg.firstChild.classList.add('slide-from-bottom');
          } else if (svg.classList.contains('from-side-main')) {
            svg.classList.add('slide-from-side');
          }
        });
        observer.disconnect();
      }
    });
  };

  useEffect(() => {
    intersectionObserver(observerCallback, observerOptions, [
      pricingSvgsRef?.current
    ]);
  }, []);

  return (
    <div className="br-pricing">
      <BRHero
        className="br-pricing-hero"
        title={intl.formatMessage({ id: 'pricing.title' })}
        secondTitle={intl.formatMessage({ id: 'pricing.second_title' })}
        subtitle={intl.formatMessage({ id: 'pricing.sub_title' })}
        buttonText={intl.formatMessage({ id: 'pricing.button_text' })}
        vectors={[<Vector2 />, <Vector1 />]}
        onClickButton={() => history.push('/signup')}
      />

      <div className="br-pricing-info-section">
        <div
          className="br-pricing-info-section__svgs isHidden"
          ref={pricingSvgsRef}
        >
          {isLargeMobileScreen ? (
            <PricingInfoImage
              className="br-pricing-info-section__main-image from-side-main"
              style={{ '--from': '-50%' }}
            />
          ) : (
            renderSVGs(PRICES_SVGS)
          )}
        </div>
        <div className="br-pricing-info-section__left-section">
          <h3 className="heading-lg-2 br-pricing-info-section-header">
            {intl.formatMessage({ id: 'pricing.discover_model.title' })}
          </h3>
          <div className="br-pricing__pay-as-you-go-container">
            <h3 className="heading-lg-2 br-pricing-info-section__pay-as-you-go-header">
              {intl.formatMessage({ id: 'pricing.discover_model.subtitle' })}
            </h3>
            <p className="display-sm br-pricing__not-settled-title">
              {intl.formatMessage({ id: 'pricing.discover_model.not_settled' })}
            </p>
            <p className="display-sm br-pricing__not-settled-sub-title">
              {intl.formatMessage({
                id: 'pricing.discover_model.not_settled_answer'
              })}
            </p>
          </div>

          <div className="br-pricing-info__logo-container">
            <div className="br-pricing-info__logo-content">
              <CustomizedIcon />
              <span>
                {intl.formatMessage({
                  id: 'pricing.icons_section.customized_plan_title'
                })}
              </span>
              <span>
                {intl.formatMessage({
                  id: 'pricing.icons_section.customized_plan_sub_title'
                })}
              </span>
            </div>
            <div className="br-pricing-info__logo-content">
              <DailyCashoutIcon />
              <span>
                {intl.formatMessage({
                  id: 'pricing.icons_section.cash_out_title'
                })}
              </span>
              <span>
                {intl.formatMessage({
                  id: 'pricing.icons_section.cash_out_sub_title'
                })}
              </span>
            </div>
            <div className="br-pricing-info__logo-content">
              <FawryIcon />
              <span>
                {intl.formatMessage({
                  id: 'pricing.icons_section.fawry_title'
                })}
              </span>
              <span>
                {intl.formatMessage({
                  id: 'pricing.icons_section.fawry_sub_title'
                })}
              </span>
            </div>
            <div className="br-pricing-info__logo-content">
              <ProductInsuranceIcon />
              <span>
                {intl.formatMessage({
                  id: 'pricing.icons_section.product_title'
                })}
              </span>
              <span>
                {intl.formatMessage({
                  id: 'pricing.icons_section.product_sub_title'
                })}
              </span>
            </div>
            <div className="br-pricing-info__logo-content">
              <VolumeIcon />
              <span>
                {intl.formatMessage({
                  id: 'pricing.icons_section.volume_title'
                })}
              </span>
              <span>
                {intl.formatMessage({
                  id: 'pricing.icons_section.volume_sub_title'
                })}
              </span>
            </div>
          </div>
          <div className="br-pricing-info__logo-container br-pricing-info__second-logo-container"></div>

          <Button
            type="primary"
            className="button-primary br-pricing-info-section-btn"
            onClick={() => history.push('/signup')}
          >
            {intl.formatMessage({
              id: 'pricing.icons_section.button_text'
            })}
          </Button>
        </div>
      </div>

      <div className="br-pricing-info-pricing-table-section">
        <h3 className="heading-lg-2">
          {intl.formatMessage({ id: 'pricing.table_section.title' })}
        </h3>
        <p className="display-sm">
          {intl.formatMessage(
            {
              id: `pricing.table_section.${visitorCountryCode?.toLowerCase()}_subtitle`
            },
            {
              TextDiv: (children) => <div>{children}</div>
            }
          )}
        </p>
        {isPricingPlansTableAvailable() &&
          (isLargeMobileScreen ? (
            <PricingTableMobile prices={PRICING_DATA_SOURCE} />
          ) : (
            <Table
              dataSource={PRICING_DATA_SOURCE}
              columns={columns}
              pagination={false}
              className="br-pricing__pricing-table"
              rowClassName={(row) => row?.className || ''}
            />
          ))}
      </div>

      <div className="br-pricing-info__pricing-estimate-section">
        {/* <div className="br-pricing-info-calculator-container">
          <h3 className="heading-lg-2 br-pricing-info-estimate-header">
            {intl.formatMessage({ id: 'pricing.estimate_section.title' })}
          </h3>
        </div>
        <Form layout="inline" className="br-pricing-calculator__form-content">
          <Form.Item>
            <Input
              placeholder={intl.formatMessage({
                id: 'pricing.estimate_section.no_of_shipment_placeholder'
              })}
            />
          </Form.Item>
          <Form.Item>
            <Input
              placeholder={intl.formatMessage({
                id: 'common.from'
              })}
            />
          </Form.Item>
          <Form.Item>
            <Input
              placeholder={intl.formatMessage({
                id: 'common.to'
              })}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" className="button-primary">
              {intl.formatMessage({
                id: 'common.submit'
              })}
            </Button>
          </Form.Item>
        </Form>
        <div className="br-pricing__estimated-value-result-container">
          <span className="text-lg-normal">
            {intl
              .formatMessage(
                {
                  id: 'common.egp_amount'
                },
                {
                  amount: 450
                }
              )
              .toUpperCase()}
          </span>
          <div>
            <span className="md-spans">
              {intl.formatMessage({
                id: 'pricing.estimate_section.prices_vary'
              })}
            </span>
            <span className="md-spans">
              {intl.formatMessage({
                id: 'pricing.estimate_section.vat_exclusive'
              })}
            </span>
          </div>
        </div> */}
        <TestimonialsCarousel />
        <SignUpAndStart />
      </div>
    </div>
  );
};

export default withRouter(injectIntl(mediaHook(Pricing)));
