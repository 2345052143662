export const URL_FLAGS =
  'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/';

export const AE_RANDOM_ID = 'AE_eF-3f9FZr';

export const ALL_COUNTRIES = [
  {
    id: '60e4482c7cb7d4bc4849c4d5',
    codeNumber: '+20',
    codeNumberArabic: '20+',
    removeZeroWithCodeNumber: true,
    phoneNumberPlaceholder: '1078286109',
    codeName: 'EG',
    name: 'Egypt',
    arabicName: 'مصر',
    flagIcon: `${URL_FLAGS}eg.svg`
  },
  {
    id: 'eF-3f9FZr',
    codeNumber: '+966',
    codeNumberArabic: '966+',
    removeZeroWithCodeNumber: true,
    phoneNumberPlaceholder: '12345678',
    codeName: 'SA',
    name: 'Saudi Arabia',
    arabicName: 'السعودية',
    flagIcon: `${URL_FLAGS}sa.svg`
  },
  {
    id: AE_RANDOM_ID,
    placeholder: '12345678',
    codeNumber: '+971',
    codeNumberArabic: '971+',
    removeZeroWithCodeNumber: true,
    codeName: 'AE',
    code: 'AE',
    name: 'United Arab Emirates',
    arabicName: 'الإمارات العربية المتحدة',
    flagIcon: `${URL_FLAGS}ae.svg`
  }
];

export const DEFAULT_COUNTRY = ALL_COUNTRIES[0];

export const AVAILABLE_COUNTRIES = [
  ...ALL_COUNTRIES,
  {
    codeName: 'AE'
  }
];
