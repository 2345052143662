import Grid from 'components/Grid/Grid';
import { injectIntl } from 'react-intl';

import {
  MOBILE_FEATURES_ORDER_CREATION,
  MOBILE_FEATURES_START_NOW,
  MOBILE_FEATURES_UPDATE,
  MOBILE_FEATURES_WALLET
} from 'constants/mobile';

import { ReactComponent as ClickIcon } from 'assets/icons/MobileClickIcon.svg';
import { ReactComponent as LightingIcon } from 'assets/icons/MobileLightingIcon.svg';
import { ReactComponent as HourglassIcon } from 'assets/icons/MobileHourglassIcon.svg';
import { ReactComponent as PackingIcon } from 'assets/icons/MobilePackingIcon.svg';
import { ReactComponent as NotificationIcon } from 'assets/icons/MobileNotificationIcon.svg';
import { ReactComponent as LiveLocation } from 'assets/icons/MobileLiveLocationIcon.svg';
import { ReactComponent as WalletIcon } from 'assets/icons/MobileWalletIcon.svg';
import { ReactComponent as CreditIcon } from 'assets/icons/MobileCreditIcon.svg';

import { ReactComponent as FeaturesSvgRight } from 'assets/images/mobile_features_1.svg';
import { ReactComponent as FeaturesSvgLeft } from 'assets/images/mobile_features_2.svg';

import './MobileFeatures.less';

const MobileFeatures = ({ intl }) => {
  const MOBILE_FEATURES_START_NOW_ICONS = [ClickIcon, LightingIcon];
  const MOBILE_FEATURES_ORDER_CREATION_ICONS = [HourglassIcon, PackingIcon];
  const MOBILE_FEATURES_UPDATE_ICONS = [NotificationIcon, LiveLocation];
  const MOBILE_FEATURES_WALLET_ICONS = [WalletIcon, CreditIcon];

  return (
    <section className="br-mobile-features-section">
      <div className="br-mobile-features-container">
        <div className="br-mobile-features">
          <div className="br-mobile-features-vector">
            <FeaturesSvgRight />
          </div>
          <div className="br-mobile-features-content">
            <div className="br-mobile-features-grid">
              <Grid
                gridTitle={intl.formatMessage({
                  id: 'mobile.features.start_now.title'
                })}
                items={MOBILE_FEATURES_START_NOW}
                icons={MOBILE_FEATURES_START_NOW_ICONS}
              />
            </div>
            <div className="br-mobile-features-grid">
              <Grid
                gridTitle={intl.formatMessage({
                  id: 'mobile.features.seamless_order_creation.title'
                })}
                items={MOBILE_FEATURES_ORDER_CREATION}
                icons={MOBILE_FEATURES_ORDER_CREATION_ICONS}
              />
            </div>
          </div>
        </div>
        <div className="br-mobile-features-right">
          <div className="br-mobile-features-vector-right">
            <FeaturesSvgLeft />
          </div>
          <div className="br-mobile-features-content">
            <div className="br-mobile-features-grid">
              <Grid
                gridTitle={intl.formatMessage({
                  id: 'mobile.features.up_to_date.title'
                })}
                items={MOBILE_FEATURES_UPDATE}
                icons={MOBILE_FEATURES_UPDATE_ICONS}
              />
            </div>
            <div className="br-mobile-features-grid">
              <Grid
                gridTitle={intl.formatMessage({
                  id: 'mobile.features.simplified_wallet.title'
                })}
                items={MOBILE_FEATURES_WALLET}
                icons={MOBILE_FEATURES_WALLET_ICONS}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default injectIntl(MobileFeatures);
