import { injectIntl } from 'react-intl';
import { Button } from 'antd';
import { withRouter } from 'react-router-dom';

import BRHero from 'components/BRHero/BRHero';
import SignUpAndStart from 'components/SignUpAndStart/SignUpAndStart';
import TrustedCompanies from 'components/TrustedCompanies/TrustedCompanies';
import { BOSTA_BOX_AVAILABILITY } from 'constants/countries/constants/common';
import { visitorCountryCode } from 'utils/helpers';

import SMEExperienceImage from 'assets/images/SMES2.png';
import { ReactComponent as Vector1 } from 'assets/images/about_hero_vector_1.svg';
import { ReactComponent as Vector2 } from 'assets/images/sme-vector.svg';
import { ReactComponent as LiveTrackingIcon } from 'assets/images/sme-live-track.svg';
import { ReactComponent as PackageIcon } from 'assets/images/sme-package.svg';
import { ReactComponent as PricingIcon } from 'assets/images/sme-pricing.svg';
import { ReactComponent as CashIcon } from 'assets/images/sme-cash.svg';
import { ReactComponent as DoorStepIcon } from 'assets/images/sme-doorstep.svg';
import { ReactComponent as LearnMoreIcon } from 'assets/icons/usecases-learnmore-icon.svg';
import { ReactComponent as BostaBoxIcon } from 'assets/images/sme-second-part.svg';

import './SME.less';

const SME = ({ intl, history }) => {
  return (
    <div className="br-sme">
      <BRHero
        className="br-sme-hero"
        title={intl.formatMessage({ id: 'sme.title' })}
        subtitle={intl.formatMessage({ id: 'sme.sub_title' })}
        buttonText={intl.formatMessage({ id: 'sme.button_text' })}
        vectors={[<Vector2 />, <Vector1 />]}
        onClickButton={() => history.push('/signup')}
      />

      <div className="br-sme-experience-container">
        <div className="br-sme-experience-content">
          <h3 className="heading-sm">
            {intl.formatMessage({ id: 'sme.experience_title' })}
          </h3>
          <div className="br-sme-experience-features-container">
            <div className="br-sme-experience-feature">
              <DoorStepIcon />
              <h5 className="display-xs">
                {intl.formatMessage({ id: 'sme.door_step_title' })}
              </h5>
              <p className="display-xs">
                {intl.formatMessage({ id: 'sme.door_step_sub_title' })}
              </p>
            </div>
            <div className="br-sme-experience-feature">
              <LiveTrackingIcon />
              <h5 className="display-xs">
                {intl.formatMessage({ id: 'sme.live_tracking_title' })}
              </h5>
              <p className="display-xs">
                {intl.formatMessage({ id: 'sme.live_tracking_sub_title' })}
              </p>
            </div>
            <div className="br-sme-experience-feature">
              <CashIcon />
              <h5 className="display-xs br-sme-cash-title">
                {intl.formatMessage({ id: 'sme.cash_title' })}
              </h5>
              <p className="display-xs">
                {intl.formatMessage({ id: 'sme.cash_sub_title' })}
              </p>
            </div>
            <div className="br-sme-experience-feature">
              <PackageIcon />
              <h5 className="display-xs">
                {intl.formatMessage({ id: 'sme.package_title' })}
              </h5>
              <p className="display-xs">
                {intl.formatMessage({ id: 'sme.package_sub_title' })}
              </p>
            </div>
            <div className="br-sme-experience-feature">
              <PricingIcon />
              <h5 className="display-xs">
                {intl.formatMessage({ id: 'sme.pricing_title' })}
              </h5>
              <p className="display-xs">
                {intl.formatMessage({ id: 'sme.pricing_sub_title' })}
              </p>
              <span
                className="button-lg"
                onClick={() => history.push('/pricing')}
              >
                {intl.formatMessage({
                  id: 'common.learn_more'
                })}{' '}
                <LearnMoreIcon />
              </span>
            </div>
          </div>
        </div>
        <img src={SMEExperienceImage} alt="hero-img" />
      </div>
      {BOSTA_BOX_AVAILABILITY[visitorCountryCode] && (
        <div className="br-sme-bosta-box-container right">
          <BostaBoxIcon />
          <div className="br-sme-bosta-box-content">
            <h3 className="heading-sm">
              {intl.formatMessage({ id: 'sme.box_title' })}
            </h3>
            <p className="display-xs">
              {intl.formatMessage({ id: 'sme.box_sub_title' })}
            </p>
            <Button
              type="primary"
              className="button-primary"
              onClick={() => window.open('https://bosta.co/bostabox')}
            >
              {intl.formatMessage({ id: 'common.know_more' })}
            </Button>
          </div>
        </div>
      )}
      <div className="br-shipment-tracking__ads-container br-sme-page">
        <div className="br-sme-trusted-by-container">
          <TrustedCompanies />
        </div>
        <SignUpAndStart />
      </div>
    </div>
  );
};

export default withRouter(injectIntl(SME));
