import { injectIntl } from 'react-intl';

import { mediaHook } from 'customHooks';

import CarouselMobile from 'components/CarouselMobile/CarouselMobile';

import { ReactComponent as FawryLogo } from 'assets/images/fawry-logo.svg';
import { ReactComponent as DPDLogo } from 'assets/images/DPD-logo.svg';
import { ReactComponent as KhwarizmiVenturesLogo } from 'assets/images/khwarizmi-ventures-logo.svg';
import { ReactComponent as FourDXVenturesLogo } from 'assets/images/4dx-ventures-logo.svg';
import { ReactComponent as SiliconBadiaLogo } from 'assets/images/silicon-badia-logo.svg';
import { ReactComponent as InvestorsMain } from 'assets/images/investors_main.svg';

import './Investors.less';

const Investors = ({ intl, mobileScreenSizes }) => {
  const { isLargeMobileScreen } = mobileScreenSizes;

  const INVESTORS_MOBILE_CAROUSEL_CONTENT = [
    [
      <FawryLogo />,
      <DPDLogo />,
      <KhwarizmiVenturesLogo />,
      <FourDXVenturesLogo />
    ],
    [<SiliconBadiaLogo />]
  ];

  return (
    <section className="br__investors__section">
      <div className="br__investors__section-container">
        <div className="br__investors__section-content">
          <div className="br__investors__section-main-svg">
            <InvestorsMain />
          </div>
          <h2 className="br__investors__content-title">
            {intl.formatMessage({ id: 'about.investors.title' })}
          </h2>
          <p className="br__investors__content-description">
            {intl.formatMessage({ id: 'about.investors.subtitle' })}
          </p>
          <div className="br__investors__content__investors-logos">
            {isLargeMobileScreen ? (
              <CarouselMobile
                carouselContent={INVESTORS_MOBILE_CAROUSEL_CONTENT}
              />
            ) : (
              <>
                <div className="investors-logo">
                  <FawryLogo />
                </div>
                <div className="investors-logo">
                  <DPDLogo />
                </div>
                <div className="investors-logo">
                  <KhwarizmiVenturesLogo />
                </div>
                <div className="investors-logo">
                  <FourDXVenturesLogo />
                </div>
                <div className="investors-logo">
                  <SiliconBadiaLogo />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default injectIntl(mediaHook(Investors));
