import { fmt } from 'components/IntlWrapper/IntlWrapper';
import { isURLHavingKSA } from 'utils/helpers';

export const DELIVERY_SERVICES = [
  {
    title: fmt({ id: 'home.delivery_services.next_day.title' }),
    subtitle: fmt({ id: 'home.delivery_services.next_day.subtitle' })
  },
  {
    title: fmt({ id: 'home.delivery_services.financial_visibility.title' }),
    subtitle: fmt({
      id: 'home.delivery_services.financial_visibility.subtitle'
    })
  },
  {
    title: fmt({ id: 'home.delivery_services.cash_collection.title' }),
    subtitle: fmt({ id: 'home.delivery_services.cash_collection.subtitle' })
  },
  {
    title: fmt({ id: 'home.delivery_services.ship_everywhere.title' }),
    subtitle: fmt({ id: 'home.delivery_services.ship_everywhere.subtitle' })
  },
  {
    title: fmt({ id: 'home.delivery_services.fulfillment.title' }),
    subtitle: fmt({ id: 'home.delivery_services.fulfillment.subtitle' })
  },
  {
    title: fmt({ id: 'home.delivery_services.international.title' }),
    subtitle: fmt({ id: 'home.delivery_services.international.subtitle' }),
    access: !isURLHavingKSA
  },
  {
    title: fmt({ id: 'home.delivery_services.smart_flyers.title' }),
    subtitle: fmt({ id: 'home.delivery_services.smart_flyers.subtitle' }),
    access: isURLHavingKSA
  }
];

export const SUCCESS_CARDS = [
  {
    title: fmt({
      id: 'home.success.merchants'
    }),
    value: '15,000'
  },
  {
    title: fmt({
      id: 'home.success.hubs'
    }),
    value: '+25'
  },
  {
    title: fmt({
      id: 'home.success.parcels'
    }),
    value: '10,000,000'
  },
  {
    title: fmt({
      id: 'home.success.stars'
    }),
    value: '1,000'
  }
];

export const FLEXIBLE_PRICES = [
  {
    title: '',
    subtitle: fmt({ id: 'home.prices.increase_volume.subtitle' }),
    link: fmt({ id: 'home.prices.increase_volume.link' }),
    linkURL: '/pricing'
  }
];

export const SHIPPING_PARTNERS = [
  {
    title: fmt({ id: 'home.partner.business.title' }),
    subtitle: fmt({ id: 'home.partner.business.subtitle' }),
    link: fmt({ id: 'home.partner.business.link' }),
    linkURL: '/signup'
  },
  {
    title: fmt({ id: 'home.partner.individual.title' }),
    subtitle: fmt({ id: 'home.partner.individual.subtitle' }),
    link: fmt({ id: 'home.partner.individual.link' }),
    linkURL: '/smes'
  }
];
