import { injectIntl } from 'react-intl';

import {
  dates,
  DATE_FORMAT_WITH_DAY_WITHOUT_YEAR,
  TIME_FORMAT
} from 'utils/helpers';
import { openModal } from 'utils/modal';
import { sendSegment } from 'utils/segment';

import BRButton from 'components/BRButton/BRButton';
import ReportProblemModal from 'components/ShipmentTracking/components/ReportProblemModal/ReportProblemModal';

import { ReactComponent as AlertTriangleIcon } from 'assets/icons/alert-triangle.svg';
import { ReactComponent as DeliverGrayIcon } from 'assets/icons/DeliverGrayIcon.svg';

import './ExceptionCard.less';

const ExceptionCard = ({ intl, exceptionData, apiKey, verifyIdentity }) => {
  const { date, exception, userDenial } = exceptionData || {};

  const openReportProblemModal = (key = apiKey) => {
    sendSegment('Consignee_reports_a_problem', {
      Source: 'Consignee_tracking_page'
    });

    openModal(ReportProblemModal, {
      apiKey: key,
      problem: userDenial
    });
  };

  const handleReportProblemClick = () => {
    if (apiKey) {
      openReportProblemModal();
    } else {
      verifyIdentity({
        afterVerification: openReportProblemModal
      });
    }
  };

  return (
    <div className="br-exception-card">
      <div className="br-exception-card__exception-data">
        <div className="br-exception-card__icon">
          <DeliverGrayIcon />
        </div>
        <div className="br-exception-card__data">
          <div className="br-exception-card__data-date">
            {`${dates(date, DATE_FORMAT_WITH_DAY_WITHOUT_YEAR)} - ${dates(
              date,
              TIME_FORMAT
            )}`}
          </div>
          <div className="br-exception-card__data-text">{exception}</div>
        </div>
      </div>
      <BRButton
        label={intl.formatMessage({
          id: 'tracking.report_problem_btn'
        })}
        prefixIcon={<AlertTriangleIcon />}
        onClick={handleReportProblemClick}
      />
    </div>
  );
};

export default injectIntl(ExceptionCard);
