import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal } from 'antd';

import { requestToPayOnline } from 'services/shipments';
import { getUserCurrency } from 'constants/countries/countries-mapping';
import { TRACKING_PAGE_PAYMOB_CARD_PAYMENT } from 'constants/shipments';

import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as CardIcon } from 'assets/icons/card-icon.svg';

import './OnlinePaymentModal.less';

const OnlinePaymentModal = ({
  intl,
  trackingNumber,
  codAmount,
  token,
  close,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [iframeLink, setIframeLink] = useState(null);

  const handlePay = async () => {
    try {
      setIsLoading(true);
      const payload = {
        trackingNumber,
        paymentType: TRACKING_PAGE_PAYMOB_CARD_PAYMENT
      };
      const { result } = await requestToPayOnline({
        token,
        payload
      });
      setIframeLink(result?.iframeLink);
    } catch (error) {
      notify({ msg: error.message });
    }
    setIsLoading(false);
  };

  return (
    <Modal
      open={token && trackingNumber}
      data-testid="pay-modal"
      loading={isLoading}
      title={
        <div className="br-tracking__paymnent-modal-title">
          <span className="display-xs">
            {intl.formatMessage({
              id: 'tracking.online_payment.modal.title'
            })}
          </span>
          <span className="body">
            {intl.formatMessage({
              id: 'tracking.online_payment.modal.subtitle'
            })}
          </span>
        </div>
      }
      width={null}
      wrapClassName="br-tracking__paymnent-modal-wrapper"
      footer={
        <>
          <BRButton
            data-testid="cancel-btn"
            label={intl.formatMessage({
              id: 'common.cancel'
            })}
            type="outline"
            onClick={close}
          />
          {!iframeLink && (
            <BRButton
              data-testid="pay-btn"
              label={intl.formatMessage({
                id: 'tracking.online_payment.modal.pay_now'
              })}
              type="primary"
              onClick={handlePay}
              loading={isLoading}
            />
          )}
        </>
      }
      {...props}
    >
      <div className="br-tracking__payment-modal-container">
        {!iframeLink ? (
          <div className="br-tracking__payment-modal-info">
            <CardIcon />
            <p className="display-xs">
              {intl.formatMessage({
                id: 'tracking.online_payment.modal.amount_to_pay'
              })}
            </p>
            <p data-testid="cod-to-pay" className="display-lg">
              {getUserCurrency(codAmount).localized}
            </p>
          </div>
        ) : (
          <iframe
            className="br-tracking__payment-modal-iframe"
            src={iframeLink}
          />
        )}
      </div>
    </Modal>
  );
};

export default injectIntl(OnlinePaymentModal);
