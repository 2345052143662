import useMedia from 'use-media';

export const mediaHook = (Component) => {
  return function WrappedComponent(props) {
    const isTinylMobileScreen = useMedia({ maxWidth: '382px' });
    const isSmallMobileScreen = useMedia({ maxWidth: '576px' });
    const isMediumMobileScreen = useMedia({ maxWidth: '768px' });
    const isLargeMobileScreen = useMedia({ maxWidth: '992px' });
    const isMediumLargeMobileScreen = useMedia({ maxWidth: '1200px' });
    const isXLargeMobileScreen = useMedia({ maxWidth: '1440px' });
    const mobileScreenSizes = {
      isTinylMobileScreen,
      isSmallMobileScreen,
      isMediumMobileScreen,
      isLargeMobileScreen,
      isXLargeMobileScreen,
      isMediumLargeMobileScreen
    };
    return <Component {...props} mobileScreenSizes={mobileScreenSizes} />;
  };
};
