import { isURLHavingKSA } from 'utils/helpers';

import { fmt } from 'components/IntlWrapper/IntlWrapper';

import { ReactComponent as CashCollection } from 'assets/icons/Cash Collection.svg';
import { ReactComponent as StarIcon } from 'assets/icons/StarIcon.svg';
import { ReactComponent as DollarIcon } from 'assets/icons/currency-dollar-circle.svg';
import { ReactComponent as ThumbsUp } from 'assets/icons/thumbs-up.svg';
import { ReactComponent as SmartShippingIcon } from 'assets/icons/smart-shipping.svg';

export const INTERNATIONAL_SHIPPING_HEADER_FEATURES_LABEL = [
  'receive_payments',
  'fast_delivery',
  'best_prices',
  'ecommerce_needs',
  'flexible_payment_collection'
];

export const INTERNATIONAL_SHIPPING_FEATURES = [
  {
    labelId: 'cash_out',
    icon: CashCollection
  },
  {
    labelId: 'customer_experience',
    icon: StarIcon
  },
  {
    labelId: 'cash_collection',
    icon: isURLHavingKSA ? SmartShippingIcon : DollarIcon
  },
  {
    labelId: 'saving_costs',
    icon: ThumbsUp
  }
];

export const INTERNATIONAL_SHIPPING_SIGNUP_FORM_FIELDS = {
  NAME: 'entry.1689291448',
  EMAIL: 'entry.2129404404',
  PHONE: 'entry.50036819',
  BUSINESS_NAME: 'entry.1221088969',
  SHIP_TO_KSA: 'entry.556390748',
  SHIP_CAPACITY: 'entry.356448814',
  GCC_COUNTRIES: 'entry.1791749407'
};

export const INTERNATIONAL_MONTHLY_ORDERS_OPTIONS = [
  {
    label: fmt({
      id: 'international_shipping.sign_up_form.capacity_options.1'
    }),
    value: '1 to 10 shipments'
  },
  {
    label: fmt({
      id: 'international_shipping.sign_up_form.capacity_options.2'
    }),
    value: '10 to 50 shipments'
  },
  {
    label: fmt({
      id: 'international_shipping.sign_up_form.capacity_options.3'
    }),
    value: '50 to 100 shipments'
  },
  {
    label: fmt({
      id: 'international_shipping.sign_up_form.capacity_options.4'
    }),
    value: 'More than 100 shipments'
  },
  {
    label: fmt({
      id: 'international_shipping.sign_up_form.capacity_options.first_time'
    }),
    value: "First time to expand to KSA, so I can't specify now"
  }
];

export const OTHER_OPTION_KEY = 'OTHER';
export const OTHER_OPTION_PAYLOAD_VALUE = '__other_option__';
export const OTHER_OPTION_PAYLOAD_RESPONSE = 'other_option_response';

export const INTERNATIONAL_SHIPPING_GCC_COUNTRIES = [
  {
    label: fmt({ id: 'international_shipping.gcc_countries.saudi' }),
    value: 'Saudi Arabia'
  },
  {
    label: fmt({ id: 'international_shipping.gcc_countries.jordan' }),
    value: 'Jordan'
  },
  {
    label: fmt({ id: 'international_shipping.gcc_countries.kuwait' }),
    value: 'Kuwait'
  },
  {
    label: fmt({ id: 'international_shipping.gcc_countries.qatar' }),
    value: 'Qatar'
  },
  {
    label: fmt({ id: 'international_shipping.gcc_countries.oman' }),
    value: 'Oman'
  },
  {
    label: fmt({ id: 'international_shipping.gcc_countries.bahrain' }),
    value: 'Bahrain'
  },
  {
    label: fmt({ id: 'international_shipping.gcc_countries.other' }),
    value: OTHER_OPTION_KEY
  }
];
