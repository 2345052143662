import React from 'react';
import { injectIntl } from 'react-intl';

import BRButton from 'components/BRButton/BRButton';

import FulfillmentStaff from 'assets/images/fulfillment-staff-laptop.png';
import FulfillmentVector from 'assets/images/fulfillment-vector.png';

import './FulfillmentHighlight.less';

const FulfillmentHighlight = ({ intl }) => {
  const { formatMessage } = intl;

  const scrollToRegisterForm = () => {
    let signupSection = document.getElementById('signup-section');
    if (signupSection) {
      signupSection.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <div className="br-fulfillment__highlight-section">
      <div className="br-fulfillment__highlight-content">
        <div className="br-fulfillment__highlight-info">
          <p className="br-fulfillment__highlight-title">
            {formatMessage({
              id: 'bosta_fulfillment.highlight_title'
            })}
          </p>
          <BRButton
            label={formatMessage({
              id: 'bosta_fulfillment.hero.signup'
            })}
            type="destructive-primary"
            onClick={scrollToRegisterForm}
          />
        </div>

        <img
          src={FulfillmentStaff}
          alt="fulfillment staff"
          className="br-fulfillment__highlight-image"
        />
      </div>

      <img
        src={FulfillmentVector}
        alt="fulfillment"
        className="br-fulfillment__highlight-vector"
      />
    </div>
  );
};

export default injectIntl(FulfillmentHighlight);
