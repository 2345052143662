import { ALL_COUNTRIES } from './countries';

import InternationalExpressEG_AR from 'assets/images/InternationalExpress_EG_AR.png';
import InternationalExpressEG_EN from 'assets/images/InternationalExpress_EG_EN.png';
import InternationalExpressAE_AR from 'assets/images/InternationalExpress_AE_AR.png';
import InternationalShippingAE_EN from 'assets/images/InternationalExpress_AE_EN.png';

export const PHONE_VALIDATTION_COUNTRY = {
  EG: ALL_COUNTRIES[0],
  SA: ALL_COUNTRIES[2]
};

export const INTERNATIONAL_LANDING_IMAGE = {
  EG: { EN: InternationalExpressEG_EN, AR: InternationalExpressEG_AR },
  SA: { EN: InternationalShippingAE_EN, AR: InternationalExpressAE_AR }
};
