import { useState } from 'react';
import classnames from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import './ProgressiveImage.less';

const LOADING_IMAGE_CLASS_NAME = 'br-image-loading';

const ProgressiveImg = ({ src, className, ...props }) => {
  const [customClass, setCustomClass] = useState(LOADING_IMAGE_CLASS_NAME);

  return (
    <LazyLoadImage
      src={src}
      afterLoad={() => setCustomClass('br-image-loaded')}
      alt={props.alt}
      className={classnames(customClass, { [className]: !!className })}
    />
  );
};
export default ProgressiveImg;
