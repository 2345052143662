import { injectIntl } from 'react-intl';

import CurrentBalanceImage from 'assets/images/dashboard-current-balance.png';
import TransactionsBreakdownImage from 'assets/images/dashboard-transactions-breakdown.png';
import TransferReceiptsImage from 'assets/images/dashboard-transfer-receipts.png';
import DailyCashCycleImage from 'assets/images/dashboard-daily-cash-cycle.png';
import FawryCashOutImage from 'assets/images/dashboard-fawry-cash-out.png';
import CashOutFrequencyImage from 'assets/images/dashboard-cash-out-frequency.png';

import './WalletMobile.less';

const WalletMobile = ({ intl }) => {
  return (
    <div className="br-wallet-mobile-container">
      <h1 className="br-wallet-mobile-title">
        {intl.formatMessage({ id: 'dashboard.wallet_features.title' })}
      </h1>
      <div className="br-wallet-mobile-feature">
        <div className="br-wallet-mobile-feature-image">
          <img src={CurrentBalanceImage} alt="current-balance" />
        </div>
        <h1 className="br-wallet-mobile-feature-title">
          {intl.formatMessage({
            id: 'dashboard.wallet_features.current_balance'
          })}
        </h1>
      </div>
      <div className="br-wallet-mobile-feature">
        <div className="br-wallet-mobile-feature-image">
          <img src={TransactionsBreakdownImage} alt="transaction-breakdown" />
        </div>
        <h1 className="br-wallet-mobile-feature-title">
          {intl.formatMessage({
            id: 'dashboard.wallet_features.transactions_breakdown'
          })}
        </h1>
      </div>
      <div className="br-wallet-mobile-feature">
        <div className="br-wallet-mobile-feature-image">
          <img src={TransferReceiptsImage} alt="transfer-receipts" />
        </div>
        <h1 className="br-wallet-mobile-feature-title">
          {intl.formatMessage({
            id: 'dashboard.wallet_features.transfer_receipts'
          })}
        </h1>
      </div>
      <div className="br-wallet-mobile-feature">
        <div className="br-wallet-mobile-feature-image">
          <img src={DailyCashCycleImage} alt="daily-cash-cycles" />
        </div>
        <h1 className="br-wallet-mobile-feature-title">
          {intl.formatMessage({
            id: 'dashboard.wallet_features.daily_cash_cycles'
          })}
        </h1>
      </div>
      <div className="br-wallet-mobile-feature">
        <div className="br-wallet-mobile-feature-image">
          <img src={FawryCashOutImage} alt="fawry-cash-out" />
        </div>
        <h1 className="br-wallet-mobile-feature-title">
          {intl.formatMessage({
            id: 'dashboard.wallet_features.fawry_cash_out'
          })}
        </h1>
      </div>
      <div className="br-wallet-mobile-feature">
        <div className="br-wallet-mobile-feature-image">
          <img src={CashOutFrequencyImage} alt="cash-out-frequency" />
        </div>
        <h1 className="br-wallet-mobile-feature-title">
          {intl.formatMessage({
            id: 'dashboard.wallet_features.cash_out_frequency'
          })}
        </h1>
      </div>
    </div>
  );
};

export default injectIntl(WalletMobile);
