import React, { useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { Form, Input, Radio, Select } from 'antd';

import { capitalFormSignUp } from 'services/signup';
import { duplicateRule, emailRule, phoneNumberRule } from 'utils/forms';
import { ALL_COUNTRIES } from 'constants/countries/constants/countries';
import { AVG_FINANCING_OPTIONS } from 'constants/capital';

import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';

import BostaEradaLogos from 'components/BostaCapital/Components/BostaEradaLogos';

import './CapitalSignup.less';

const CapitalSignup = ({ intl, history }) => {
  const formRef = useRef();

  const [duplicateNumber, setDuplicateNumber] = useState(null);
  const [duplicateMail, setDuplicateMail] = useState(null);
  const [isLoading, setIsloading] = useState(false);

  const onSubmit = async (values) => {
    const payload = {
      form: values
    };
    setIsloading(true);
    try {
      await capitalFormSignUp(payload);

      notify({
        msg: intl.formatMessage({
          id: 'bosta_capital.form.success'
        }),
        type: 'success'
      });

      formRef.current.resetFields();
      history.push({
        pathname: 'bosta-capital/thank-you'
      });
    } catch (error) {
      notify({
        msg: intl.formatMessage({
          id: 'bosta_capital.form.error'
        })
      });
      if (error?.data?.phone) {
        setDuplicateNumber(error.data.phone);
        notify({
          msg: intl.formatMessage({
            id: 'form.used_phone'
          })
        });
      }
      if (error?.data?.email) {
        setDuplicateMail(error.data.email);
        notify({
          msg: intl.formatMessage({
            id: 'form.used_email'
          })
        });
      }
    }
    setIsloading(false);
  };

  return (
    <div id="signup-section" className="br-capital__signup-section">
      <div className="br-capital__signup-text">
        <h1 className="br-capital__signup-header">
          {intl.formatMessage({
            id: 'bosta_capital.form.title'
          })}
        </h1>
        <p className="br-capital__signup-subtitle">
          {intl.formatMessage({
            id: 'bosta_capital.form.subtitle'
          })}
        </p>
      </div>
      <Form
        className="br-capital__signup-form"
        onFinish={onSubmit}
        ref={formRef}
      >
        <Form.Item
          className="body-medium"
          label={intl.formatMessage({
            id: 'bosta_capital.form.name'
          })}
          name="contactName"
          rules={[{ required: true }]}
        >
          <Input
            placeholder={intl.formatMessage({
              id: 'bosta_capital.form.name_placeholder'
            })}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({
            id: 'bosta_capital.form.businessName'
          })}
          name="businessName"
          rules={[{ required: true }]}
        >
          <Input
            placeholder={intl.formatMessage({
              id: 'bosta_capital.form.businessName_placeholder'
            })}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({
            id: 'bosta_capital.form.email'
          })}
          name="email"
          rules={[
            { required: true },
            emailRule(
              intl.formatMessage({
                id: 'form.email_not_valid'
              })
            ),
            duplicateRule(
              duplicateMail,
              intl.formatMessage({ id: 'form.used_email' })
            )
          ]}
        >
          <Input
            placeholder={intl.formatMessage({
              id: 'bosta_capital.form.email_placeholder'
            })}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({
            id: 'bosta_capital.form.phone_number'
          })}
          name="phone"
          rules={[
            { required: true },
            phoneNumberRule({
              message: intl.formatMessage({ id: 'form.phone_not_valid' }),
              country: ALL_COUNTRIES[0],
              withoutCountryCode: false
            }),
            duplicateRule(
              duplicateNumber,
              intl.formatMessage({ id: 'form.used_phone' })
            )
          ]}
        >
          <Input
            type="number"
            placeholder={intl.formatMessage({
              id: 'bosta_capital.form.phone_number_placeholder'
            })}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({
            id: 'bosta_capital.form.avg_shipments'
          })}
          name="avgMonthlyOrders"
          rules={[{ required: true }]}
        >
          <Input
            type="number"
            placeholder={intl.formatMessage({
              id: 'bosta_capital.form.avg_shipments_placeholder'
            })}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({
            id: 'bosta_capital.form.avg_fund'
          })}
          name="avgExpectedFundAmount"
        >
          <Select
            placeholder={intl.formatMessage({
              id: 'bosta_capital.form.avg_fund_placeholder'
            })}
            options={AVG_FINANCING_OPTIONS}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({
            id: 'bosta_capital.form.avg_income'
          })}
          name="avgMonthlyRevenue"
        >
          <Input
            type="number"
            placeholder={intl.formatMessage({
              id: 'bosta_capital.form.avg_income_placeholder'
            })}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({
            id: 'bosta_capital.form.are_bosta_client'
          })}
          name="isWorkingWithBosta"
          rules={[{ required: true }]}
        >
          <Radio.Group value={'1'}>
            <Radio value={true}>
              {intl.formatMessage({
                id: 'common.yes'
              })}
            </Radio>
            <Radio value={false}>
              {intl.formatMessage({
                id: 'common.no'
              })}
            </Radio>
          </Radio.Group>
        </Form.Item>
        <BRButton
          className="body-medium br-capital__form-submit-btn"
          label={intl.formatMessage({
            id: 'bosta_capital.form.signup'
          })}
          type="destructive-primary"
          htmlType="submit"
          loading={isLoading}
        />
      </Form>
      <BostaEradaLogos />
    </div>
  );
};

export default withRouter(injectIntl(CapitalSignup));
