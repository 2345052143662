import { PhoneNumberUtil } from 'google-libphonenumber';

import { DEFAULT_COUNTRY } from 'constants/countries/constants/countries';
import { getPhoneNumberRegex } from 'constants/countries/countries-mapping';
import { AT_Least_EIGHT_LETTERS, AT_Least_ONE_DIGIT } from 'constants/Forms';

export const FIXED_LINE = 0;

const emailValidator = (value, message) => {
  const emailExp = /^\S+@\S+\.\S+$/;
  if (!value || emailExp.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(message);
};

export const emailRule = (message) => ({
  validator: (_, value) => emailValidator(value, message)
});

const duplicateValidator = (value, duplicated, message) => {
  if (value === duplicated) {
    return Promise.reject(message);
  }
  return Promise.resolve();
};

export const duplicateRule = (duplicated, message) => ({
  validator: (_, value) => duplicateValidator(value, duplicated, message)
});

export const requiredRule = (message) => ({
  required: true,
  message
});

export const notRequiredRule = () => ({
  required: false
});

const phoneNumberValidator = ({
  value,
  message,
  internationlNumbers,
  landlineNumbers,
  country,
  withoutCountryCode
}) => {
  const phoneRegex = getPhoneNumberRegex({
    countryCode: country.codeName,
    withoutCountryCode
  });
  const phoneUtil = PhoneNumberUtil.getInstance();
  let number;

  if (!value || phoneRegex.test(value)) {
    return Promise.resolve();
  }

  try {
    number = phoneUtil.parseAndKeepRawInput(value, country.codeName);
  } catch (e) {
    if (!value) {
      return Promise.resolve();
    }
    return Promise.reject(message);
  }

  if (
    internationlNumbers &&
    phoneUtil.isValidNumber(number) &&
    phoneUtil.getNumberType(number) !== FIXED_LINE
  ) {
    return Promise.resolve();
  } else if (landlineNumbers && phoneUtil.isValidNumber(number)) {
    return Promise.resolve();
  }

  return Promise.reject(message);
};

export const phoneNumberRule = ({
  message,
  internationlNumbers,
  landlineNumbers,
  country = DEFAULT_COUNTRY,
  withoutCountryCode
}) => ({
  validator: (_, value) =>
    phoneNumberValidator({
      value,
      message,
      internationlNumbers,
      landlineNumbers,
      country,
      withoutCountryCode
    })
});

const lengthRuleValidator = (value, message, length) => {
  if (value && value.length <= length) {
    return Promise.resolve();
  }
  return Promise.reject(message);
};

export const lengthRule = (message, length) => ({
  validator: (_, value) => lengthRuleValidator(value, message, length)
});

const phoneNumberContactValidator = (value, message) => {
  const phoneRegExp = /^01[0125][0-9]{8}$/;
  if (value && value.length === 11 && !phoneRegExp.test(value)) {
    return Promise.reject(message);
  } else {
    return Promise.resolve();
  }
};

export const phoneNumberContactRule = (message) => ({
  validator: (_, value) => phoneNumberContactValidator(value, message)
});

const onlyPhoneNumberContactValidator = (value, message) => {
  const phoneRegExp = /^01[0125][0-9]{8}$/;
  if (value && !phoneRegExp.test(value)) {
    return Promise.reject(message);
  } else {
    return Promise.resolve();
  }
};

export const onlyPhoneNumberContactRule = (message) => ({
  validator: (_, value) => onlyPhoneNumberContactValidator(value, message)
});

const fullNameValidator = (value, message) => {
  const fullNameRegex = /\s/;

  if (!value || fullNameRegex.test(value.trim())) {
    return Promise.resolve();
  }
  return Promise.reject(message);
};

export const fullNameRule = (message) => ({
  validator: (_, value) => fullNameValidator(value, message)
});

export const passwordValidator = (value, message) => {
  //at least 8 characters and one digit.
  if (
    !value ||
    (AT_Least_ONE_DIGIT.test(value) && AT_Least_EIGHT_LETTERS.test(value))
  ) {
    return Promise.resolve();
  }
  return Promise.reject(message);
};

export const passwordRule = (message) => ({
  validator: (_, value) => passwordValidator(value, message)
});
