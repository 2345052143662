import { Modal } from 'antd';
import { useIntl } from 'react-intl';

import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as CheckCircle } from 'assets/icons/check-circle.svg';

import './RateConfirmationModal.less';

const RateConfirmationModal = ({ close, ...props }) => {
  const intl = useIntl();

  return (
    <Modal
      wrapClassName="br-rate-confirmation__modal"
      title={null}
      footer={null}
      {...props}
    >
      <div className="br-rate-confirmation-modal__icon">
        <CheckCircle />
      </div>
      <span className="display-sm">
        {intl.formatMessage({
          id: 'rate_delivery_service_modal.success_message'
        })}
      </span>
      <BRButton
        type="primary"
        className="button-md"
        onClick={close}
        label={intl.formatMessage({ id: 'common.confirm' })}
      />
    </Modal>
  );
};

export default RateConfirmationModal;
