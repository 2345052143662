import { injectIntl } from 'react-intl';

import { mediaHook } from 'customHooks';

import BotMockup from 'assets/images/solutions-bot-mockup.png';
import { ReactComponent as BotMainSvg } from 'assets/images/solutions-bot-image.svg';
import { ReactComponent as BotMainSvgMobile } from 'assets/images/solutions-bot-image-mobile.svg';

import './InnovativeBots.less';

const InnovativeBots = ({ intl, mobileScreenSizes }) => {
  const { isLargeMobileScreen } = mobileScreenSizes;

  return (
    <section className="br-solutions-innovative-bots-container">
      <div className="br-solutions-innovative-bots-content">
        <div className="br-solutions-innovative-bots-svg">
          {isLargeMobileScreen ? <BotMainSvgMobile /> : <BotMainSvg />}
        </div>
        <h1 className="br-solutions-innovative-bots-title">
          {intl.formatMessage({ id: 'solutions.innovative_bots.title' })}
        </h1>
        <p className="br-solutions-innovative-bots-subtitle">
          {intl.formatMessage({ id: 'solutions.innovative_bots.subtitle' })}
        </p>
        <div className="br-solution-innovative-bots-features">
          <div className="br-solution-bot-feature">
            <img src={BotMockup} alt="bot-mockup" />
            <h1 className="br-solution-bot-feature-title">
              {intl.formatMessage({
                id: 'solutions.innovative_bots.empowered_actions.title'
              })}
            </h1>
            <p className="br-solution-bot-feature-subtitle caption-lg">
              {intl.formatMessage({
                id: 'solutions.innovative_bots.empowered_actions.subtitle'
              })}
            </p>
          </div>
          <div className="br-solution-bot-feature">
            <img src={BotMockup} alt="bot-mockup" />
            <h1 className="br-solution-bot-feature-title">
              {intl.formatMessage({
                id: 'solutions.innovative_bots.free_updates.title'
              })}
            </h1>
            <p className="br-solution-bot-feature-subtitle caption-lg">
              {intl.formatMessage({
                id: 'solutions.innovative_bots.free_updates.subtitle'
              })}
            </p>
          </div>
          <div className="br-solution-bot-feature">
            <img src={BotMockup} alt="bot-mockup" />
            <h1 className="br-solution-bot-feature-title">
              {intl.formatMessage({
                id: 'solutions.innovative_bots.instant_answers.title'
              })}
            </h1>
            <p className="br-solution-bot-feature-subtitle caption-lg">
              {intl.formatMessage({
                id: 'solutions.innovative_bots.instant_answers.subtitle'
              })}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default injectIntl(mediaHook(InnovativeBots));
