import React from 'react';
import ReactDOM from 'react-dom';
import { Router, BrowserRouter } from 'react-router-dom';

import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import arEG from 'antd/lib/locale/ar_EG';
import dayjs from 'dayjs';

import { getLocaleFromLocalStorage } from 'utils/intl-wrapper';
import { LOCALE } from 'constants/intl-wrapper';
import IntlWrapper, { intl } from 'components/IntlWrapper/IntlWrapper';

export const openModal = (Component, componentProps) => {
  const div = document.createElement('div');
  document.body.appendChild(div);

  const validateMessages = {
    required: intl.formatMessage({ id: 'form.required' }),
    string: {
      max: intl.formatMessage({ id: 'form.maxLen' })
    },
    whitespace: intl.formatMessage({ id: 'form.required' })
  };

  const modalProps = ({ visible, centered, close }) => ({
    visible,
    centered,
    close
    // transitionName: 'none',
    // maskTransitionName: 'none'
  });

  const locale = getLocaleFromLocalStorage();
  const getLocale = () => {
    dayjs.locale(locale);
    if (locale === LOCALE.AR) {
      return arEG;
    } else {
      return enUS;
    }
  };

  const renderModal = (props) => (
    <IntlWrapper>
      <ConfigProvider
        locale={getLocale()}
        getPopupContainer={(trigger) => trigger?.parentElement || document.body}
        form={{ validateMessages }}
      >
        <Component {...props} />
      </ConfigProvider>
    </IntlWrapper>
  );

  const render = (props) => {
    ReactDOM.render(
      <>
        {props.history ? (
          <Router history={props.history}>{renderModal(props)}</Router>
        ) : (
          <BrowserRouter>{renderModal(props)}</BrowserRouter>
        )}
      </>,
      div
    );
  };

  const destroy = () => {
    const unmountResult = ReactDOM.unmountComponentAtNode(div);
    if (unmountResult && div.parentNode) {
      div.parentNode.removeChild(div);
    }
  };

  const close = () => {
    setTimeout(() => {
      destroy();
    }, 0);
    // render({ ...modalProps({ visible: false }) });
  };

  render({
    ...componentProps,
    ...modalProps({ visible: true, centered: true, close })
  });
};
