import React from 'react';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';

import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as WarningAlert } from 'assets/icons/Arrow-left.svg';

import './BRContentHeader.less';

class BRContentHeader extends React.Component {
  render() {
    const {
      title,
      titleClassName,
      subtitle,
      subTitleClassName,
      className,
      rightSideContent,
      isInternalComponent,
      withBackButton,
      onBackButtonClick
    } = this.props;

    return (
      <div
        className={classnames('br-content-header', className, {
          'br-content-header__with-title-only': !subtitle,
          'br-content-header-with-sub-title': subtitle && !isInternalComponent,
          'br-sidebar-content-header-with-sub-title': isInternalComponent
        })}
      >
        {withBackButton && (
          <BRButton
            type="link-gray"
            prefixIcon={<WarningAlert />}
            onClick={onBackButtonClick}
          />
        )}
        <div className={classnames('br-content-header__titles')}>
          <span
            className={classnames(
              titleClassName,
              { 'br-content-header__title display-xs': !isInternalComponent },
              {
                'br-sidebar-content__title display-xs': isInternalComponent
              }
            )}
          >
            {title}
          </span>
          {subtitle && (
            <span
              className={classnames(
                subTitleClassName,
                { 'br-content-header__sub-title ': !isInternalComponent },
                { 'br-sidebar-content__sub-title ': isInternalComponent }
              )}
            >
              {subtitle}
            </span>
          )}
        </div>
        {rightSideContent}
      </div>
    );
  }
}

export default injectIntl(BRContentHeader);
