import React from 'react';
import { Button } from 'antd';
import classnames from 'classnames';

import './BRButton.less';

function BRButton({
  type,
  label,
  suffixIcon,
  prefixIcon,
  className,
  disabled,
  loading,
  onClick,
  ...props
}) {
  // Supported types:
  // primary, outline, basic, treitary-color, treitary-gray, link-color,
  // link-gray, destructive-primary, destructive-basic, destructive-treitary, danger

  return (
    <Button
      disabled={disabled || loading}
      className={classnames(
        'br-button-component',
        className,
        type,
        {
          'br-button-component__with-label': label && !suffixIcon && !prefixIcon
        },
        {
          'br-button-component__with-suffix': suffixIcon
        },
        {
          'br-button-component__with-prefix': prefixIcon
        },
        {
          'br-button-component__with-icon-only': !label
        },
        {
          'br-button-component__loading': loading
        }
      )}
      onClick={onClick}
      {...props}
    >
      {loading && (
        <svg className="br-button-component__loading-svg" viewBox="0 0 50 50">
          <circle
            className="br-button-component__loading-path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="4"
          ></circle>
        </svg>
      )}
      {prefixIcon && (
        <span
          className={classnames(
            {
              'br-button-component__hide-onloading': loading
            },
            {
              'ant-icon-md': className?.includes('button-sm')
            },
            {
              'ant-icon-md': className?.includes('button-md')
            },
            {
              'ant-icon-lg': className?.includes('button-lg')
            }
          )}
        >
          {prefixIcon}
        </span>
      )}
      {label && (
        <span
          className={classnames(
            'br-button-component__label',
            {
              'br-button-component__hide-onloading': loading
            },
            {
              button:
                className?.includes('button-sm') ||
                className?.includes('button-md')
            },
            {
              'button-lg': className?.includes('button-lg')
            }
          )}
        >
          {label}
        </span>
      )}
      {suffixIcon && (
        <span
          className={classnames(
            {
              'br-button-component__hide-onloading': loading
            },
            {
              'ant-icon-md': className?.includes('button-sm')
            },
            {
              'ant-icon-md': className?.includes('button-md')
            },
            {
              'ant-icon-lg': className?.includes('button-lg')
            }
          )}
        >
          {suffixIcon}
        </span>
      )}
    </Button>
  );
}
export default BRButton;
