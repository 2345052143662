export const sendSegment = async (eventName, params, State = 'Success') => {
  const dataToSend = {
    Source: 'WEBSITE',
    State,
    ...params
  };
  if (window.analytics?.user) {
    if (!window.analytics.user().id()) {
      window.analytics.user().identify('consignee@bosta.co');
    }
  }
  window.analytics && (await window.analytics.track(eventName, dataToSend));
};
