import { fmt } from 'components/IntlWrapper/IntlWrapper';

export const PLUGINS = {
  WOOCOMMERCE: 'woocommerce',
  SHOPIFY: 'shopify'
};

export const PLUGIN_KEY = 'plugin';
export const CUSTOM_API = 'custom-api';

export const DEFAULT_PLUGIN = PLUGINS.WOOCOMMERCE;

export const CONNECT_AND_SAVE_CONTENT = [
  {
    title: fmt({
      id: 'merged_integrations.connect_and_save.content.seamless_automation.title'
    }),
    subtitle: fmt({
      id: 'merged_integrations.connect_and_save.content.seamless_automation.subtitle'
    })
  },
  {
    title: fmt({
      id: 'merged_integrations.connect_and_save.content.create_and_track_your_deliveries.title'
    }),
    subtitle: fmt({
      id: 'merged_integrations.connect_and_save.content.create_and_track_your_deliveries.subtitle'
    })
  },
  {
    title: fmt({
      id: 'merged_integrations.connect_and_save.content.print_awbs.title'
    }),
    subtitle: fmt({
      id: 'merged_integrations.connect_and_save.content.print_awbs.subtitle'
    })
  },
  {
    title: fmt({
      id: 'merged_integrations.connect_and_save.content.create_and_track_pickups.title'
    }),
    subtitle: fmt({
      id: 'merged_integrations.connect_and_save.content.create_and_track_pickups.subtitle'
    })
  },
  {
    title: fmt({
      id: 'merged_integrations.connect_and_save.content.open_support_tickets.title'
    }),
    subtitle: fmt({
      id: 'merged_integrations.connect_and_save.content.open_support_tickets.subtitle'
    })
  },
  {
    title: fmt({
      id: 'merged_integrations.connect_and_save.content.search_for_orders.title'
    }),
    subtitle: fmt({
      id: 'merged_integrations.connect_and_save.content.search_for_orders.subtitle'
    })
  }
];
