import React from 'react';
import { injectIntl } from 'react-intl';

import { ReactComponent as EnvelopeIcon } from 'assets/icons/green-envelope.svg';

import './Thankyou.less';

export const ThankYou = ({ intl }) => {
  return (
    <div className="br-capital__thank-you-section">
      <div className="br-capital__thank-you-content">
        <EnvelopeIcon />
        <h1>
          {intl.formatMessage({
            id: 'bosta_capital.thank_you.header'
          })}
        </h1>
        <p>
          {intl.formatMessage({
            id: 'bosta_capital.thank_you.content'
          })}
        </p>
      </div>
    </div>
  );
};

export default injectIntl(ThankYou);
