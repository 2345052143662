import React from 'react';
import { injectIntl } from 'react-intl';

import { LOCALE } from 'constants/intl-wrapper';

import { ReactComponent as BostaLogo } from 'assets/icons/Bosta_Logo.svg';
import { ReactComponent as BostaLogoAr } from 'assets/icons/Bosta_Logo_Arabic.svg';
import EradaImage from 'assets/images/Erada_logo.png';
import EradaImageEn from 'assets/images/Erada_logo_en.PNG';

const BostaEradaLogos = ({ intl }) => {
  const BostaLogoLocale = () => {
    return intl.locale.includes(LOCALE.EN) ? <BostaLogo /> : <BostaLogoAr />;
  };

  const EradaImageSrc = intl.locale.includes(LOCALE.EN)
    ? EradaImageEn
    : EradaImage;

  return (
    <div className="br-capital__hero-logos">
      {BostaLogoLocale()}
      <span></span>
      <img alt="Erada" src={EradaImageSrc} />
    </div>
  );
};

export default injectIntl(BostaEradaLogos);
