import React, { useEffect, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { Button } from 'antd';
import { withRouter } from 'react-router-dom';

import { intersectionObserver } from 'utils/animations';

import { ReactComponent as SignUpAndStartImage1 } from 'assets/images/SignUpAndStartImage1.svg';
import { ReactComponent as SignUpAndStartImage2 } from 'assets/images/SignUpAndStartImage2.svg';
import { ReactComponent as SignUpAndStartImage3 } from 'assets/images/SignUpAndStartImage3.svg';

import './SignUpAndStart.less';
import { mediaHook } from 'customHooks';

const SignUpAndStart = ({ intl, history }) => {
  const SvgContainerRef = useRef();

  const observerOptions = { rootMargin: '-15%' };

  const observerCallback = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.childNodes.forEach((child, idx) => {
          idx !== 0 &&
            child.firstChild.classList.add('slide-from-side', 'isVisible');
        });
        observer.disconnect();
      }
    });
  };

  useEffect(() => {
    intersectionObserver(observerCallback, observerOptions, [
      SvgContainerRef?.current
    ]);
  }, []);

  return (
    <section className="br__sign-up-and-start__section">
      <div className="br__sign-up-and-start__section__container">
        <div className="br__sign-up-and-start__section__container__content">
          <div
            className="br__sign-up-and-start__section__container__content-svg"
            ref={SvgContainerRef}
          >
            <div className="svg-1">
              <SignUpAndStartImage1 />
            </div>
            <div className="svg-2 isHidden">
              <SignUpAndStartImage2 style={{ '--from': '-90%' }} />
            </div>
            <div className="svg-3 isHidden">
              <SignUpAndStartImage3 style={{ '--from': '100%' }} />
            </div>
          </div>
          <h2 className="br__sign-up-and-start__section__container__content-title">
            {intl.formatMessage({ id: 'sign_up_and_start.title' })}
          </h2>
          <div className="br__sign-up-and-start__section__container__content-cta">
            <Button
              type="primary"
              className="button-primary"
              onClick={() => history.push('/signup')}
            >
              {intl.formatMessage({ id: 'sign_up_and_start.start_shipping' })}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withRouter(injectIntl(mediaHook(SignUpAndStart)));
