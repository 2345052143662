import { useState, useRef, useEffect, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { Form } from 'antd';
import classnames from 'classnames';

import { sendSegment } from 'utils/segment';
import {
  secondsToTime,
  numberRegex,
  countDown,
  TWO_MINUTES
} from 'utils/helpers';
import { isDashboardArabic } from 'utils/intl-wrapper';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import { notify } from 'components/Notify/Notify';
import BRFormInputNumber from 'components/BRFormInputNumber/BRFormInputNumber';
import BRButton from 'components/BRButton/BRButton';
import BRContentHeader from 'components/BRContentHeader/BRContentHeader';

import './BROTPCode.less';

const BROTPCode = ({
  title,
  closeModal,
  intl,
  confirmOTP,
  generateOTP,
  onSuccess,
  resendCodeSegmentEvent,
  verificationGenerateOTPCode,
  handleResendCode,
  phoneNumber,
  isInternalComponent,
  wrongOTP,
  setWrongOTP,
  headerClassName,
  setConfirmDisabled,
  setConfirmLoading
}) => {
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [pageLoading, setPageLoading] = useState(false);
  const [pasted, setPasted] = useState(false);
  const [timer, setTimer] = useState(TWO_MINUTES);
  const [names] = useState([
    'firstDigit',
    'secondDigit',
    'thirdDigit',
    'fourthDigit'
  ]);
  const formRef = useRef('');

  const codeStepFirst = useRef(null);
  const codeStepTwo = useRef(null);
  const codeStepThree = useRef(null);
  const codeStepFour = useRef(null);

  const sendOtp = useCallback(async () => {
    try {
      setPageLoading(true);
      if (generateOTP) {
        await generateOTP();
      } else if (verificationGenerateOTPCode) {
        verificationGenerateOTPCode();
      }
    } catch (error) {
      notify({ msg: error.message, error });
    }
    if (codeStepFirst.current) {
      codeStepFirst.current.focus();
    }
    setPageLoading(false);
  }, [generateOTP, verificationGenerateOTPCode]);

  useEffect(() => {
    if (generateOTP) {
      sendOtp();
    } else {
      if (codeStepFirst.current) {
        codeStepFirst.current.focus();
      }
    }
  }, [generateOTP, sendOtp]);

  useEffect(() => {
    const myInterval = countDown({ timer, setTimer, setDisabled });
    return () => {
      clearInterval(myInterval);
    };
  }, [timer]);

  useEffect(() => {
    setTimer(TWO_MINUTES);
    setDisabled(true);
  }, [phoneNumber]);

  const handleOnFinish = async (values) => {
    if (values) {
      try {
        setPageLoading(true);
        setConfirmDisabled && setConfirmDisabled(false);
        setConfirmLoading && setConfirmLoading(true);
        await confirmOTP(values);
        setIsErrorVisible(false);
        onSuccess && onSuccess();
        if (closeModal) {
          closeModal();
        }
      } catch (error) {
        setIsErrorVisible(true);
        if (formRef.current) {
          formRef.current.resetFields();
          codeStepFirst.current.focus();
        }
      }
    }
    setPageLoading(false);
  };

  const handleOnChangeDigits = (name, value) => {
    formRef.current.setFieldsValue({ [name]: value });
    if (name === 'firstDigit' && value !== '') {
      codeStepTwo.current.focus();
    } else if (name === 'secondDigit' && value !== '') {
      codeStepThree.current.focus();
    } else if (name === 'thirdDigit' && value !== '') {
      codeStepFour.current.focus();
    }
    setPasted(false);
    if (
      formRef.current.getFieldValue('firstDigit') &&
      formRef.current.getFieldValue('secondDigit') &&
      formRef.current.getFieldValue('thirdDigit') &&
      formRef.current.getFieldValue('fourthDigit')
    ) {
      const values =
        formRef.current.getFieldValue('firstDigit') +
        formRef.current.getFieldValue('secondDigit') +
        formRef.current.getFieldValue('thirdDigit') +
        formRef.current.getFieldValue('fourthDigit');
      handleOnFinish(values);
    }
  };

  const handleOnPaste = (e) => {
    setPasted(true);
    e.clipboardData.items[0].getAsString((str) => {
      if (numberRegex.test(str)) {
        names.forEach((item, index) => {
          formRef.current.setFieldsValue({ [item]: str[index] });
        });
        handleOnFinish(str);
        codeStepFour.current.focus();
      } else {
        setIsErrorVisible(true);
        codeStepFirst.current.focus();
      }
    });
    setPasted(false);
  };

  const handleWrongOTP = () => {
    setWrongOTP && setWrongOTP(false);
  };

  const formArray = [
    {
      render: (
        <Form.Item
          name="firstDigit"
          key="firstDigit"
          validateStatus={wrongOTP ? 'error' : undefined}
          onChange={handleWrongOTP}
        >
          <BRFormInputNumber
            maxLength={pasted ? 4 : 1}
            filedName="firstDigit"
            handleOnChangeForm={handleOnChangeDigits}
            placeholder="_"
            handleOnPaste={handleOnPaste}
            refName={codeStepFirst}
          />
        </Form.Item>
      )
    },
    {
      render: (
        <Form.Item
          name="secondDigit"
          key="secondDigit"
          validateStatus={wrongOTP ? 'error' : undefined}
          onChange={handleWrongOTP}
        >
          <BRFormInputNumber
            refName={codeStepTwo}
            maxLength={pasted ? 4 : 1}
            filedName="secondDigit"
            handleOnChangeForm={handleOnChangeDigits}
            placeholder="_"
            handleOnPaste={handleOnPaste}
          />
        </Form.Item>
      )
    },
    {
      render: (
        <Form.Item
          name="thirdDigit"
          key="thirdDigit"
          validateStatus={wrongOTP ? 'error' : undefined}
          onChange={handleWrongOTP}
        >
          <BRFormInputNumber
            refName={codeStepThree}
            maxLength={pasted ? 4 : 1}
            filedName="thirdDigit"
            handleOnChangeForm={handleOnChangeDigits}
            placeholder="_"
            handleOnPaste={handleOnPaste}
          />
        </Form.Item>
      )
    },
    {
      render: (
        <Form.Item
          name="fourthDigit"
          key="fourthDigit"
          validateStatus={wrongOTP ? 'error' : undefined}
          onChange={handleWrongOTP}
        >
          <BRFormInputNumber
            refName={codeStepFour}
            maxLength={pasted ? 4 : 1}
            filedName="fourthDigit"
            handleOnChangeForm={handleOnChangeDigits}
            placeholder="_"
            handleOnPaste={handleOnPaste}
          />
        </Form.Item>
      )
    }
  ];

  const handleSendAgain = () => {
    setDisabled(true);
    setIsErrorVisible(false);
    setTimer(TWO_MINUTES);
    handleResendCode ? handleResendCode() : sendOtp();

    if (resendCodeSegmentEvent && !handleResendCode) {
      sendSegment(resendCodeSegmentEvent);
    }
  };

  return (
    <LoadingWrapper loading={pageLoading}>
      {title && (
        <BRContentHeader
          title={title}
          className={headerClassName}
          isInternalComponent={isInternalComponent}
        />
      )}
      <div className="br-otp-validation__content">
        <Form className="br-otp-modal__otp-code" ref={formRef}>
          <div className="br-otp-modal__header">
            <span className="br-otp-modal__code-sent body">
              {intl.formatMessage(
                {
                  id: 'verify_phone.code_sent_label'
                },
                {
                  phoneNumber: (
                    <span className="br-otp-modal__code-sent__phone body-medium">
                      {phoneNumber}
                    </span>
                  )
                }
              )}
            </span>
            {disabled && (
              <span className="br-otp-validation__receive-confirmation body">
                {intl.formatMessage(
                  {
                    id: 'verify_phone.otp_screen.receive_confirmation'
                  },
                  {
                    time: (
                      <span className="br-otp-validation__receive-confirmation__timer body-medium">
                        {secondsToTime(timer)}
                      </span>
                    )
                  }
                )}
              </span>
            )}
            <BRButton
              type={disabled ? 'link-gray' : 'link-color'}
              disabled={disabled}
              onClick={handleSendAgain}
              label={intl.formatMessage({
                id: 'verify_phone.otp_screen.resend_code'
              })}
            />
          </div>

          <div
            className={classnames('br-otp-modal__form-items', {
              'error-visible': isErrorVisible
            })}
          >
            {isDashboardArabic()
              ? formArray.map(
                  (val, index, array) => array[array.length - 1 - index].render
                )
              : formArray.map((item) => item.render)}
          </div>
          {wrongOTP && (
            <p className="br-opt-modal__error-text body">
              {intl.formatMessage({
                id: 'verify_phone.otp_screen.error'
              })}
            </p>
          )}
        </Form>
      </div>
    </LoadingWrapper>
  );
};

export default injectIntl(BROTPCode);
