import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import BRHero from 'components/BRHero/BRHero';

import { ReactComponent as BagIcon } from 'assets/images/about_hero_vector_1.svg';
import { ReactComponent as PieceOfPuzzleIcon } from 'assets/images/piece_of_puzzle.svg';

import './IntegrationsHeader.less';

const IntegrationsHeader = ({ intl, history }) => {
  return (
    <BRHero
      className="br-integrations__header"
      title={intl.formatMessage({ id: 'integrations.header.title' })}
      subtitle={intl.formatMessage({
        id: 'integrations.header.subtitle'
      })}
      buttonText={intl.formatMessage({
        id: 'integrations.header.button'
      })}
      onClickButton={() => history.push('/signup')}
      vectors={[<BagIcon />, <PieceOfPuzzleIcon />]}
    />
  );
};

export default withRouter(injectIntl(IntegrationsHeader));
