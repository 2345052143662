import React from 'react';
import { Button } from 'antd';

import './Header.less';

const Header = ({
  title,
  preTitle,
  subtitle,
  buttonText = false,
  icon1,
  icon2,
  onClickButton,
  secondTitle,
  subTitleComponent = null,
  extraInfo = null
}) => {
  return (
    <div className="br-header">
      {preTitle && (
        <div className="br-header__title header-title">{preTitle}</div>
      )}
      <h1 className="br-header__title header-title">
        {title}
        {secondTitle && (
          <>
            <br />
            {secondTitle}
          </>
        )}
      </h1>
      <div className="br-header__second-content-container">
        <div className="br-header__subtitle display-sm">
          {subTitleComponent || subtitle}
        </div>
        {extraInfo && (
          <div className="br-header__extra navbar-link">{extraInfo}</div>
        )}

        {icon1 && icon1}
        {icon2 && icon2}
        {buttonText && (
          <Button
            type="primary"
            className="button-primary"
            onClick={onClickButton && onClickButton}
          >
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Header;
