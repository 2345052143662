import './NotFound.less';

function NotFound() {
  return (
    <div className="br-not-found">
      <h1 className="header-title">404 - Not Found</h1>
      <p className="display-sm">
        Sorry, the page you are looking for does not exist.
      </p>
    </div>
  );
}
export default NotFound;
