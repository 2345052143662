import { useRef, useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Form } from 'antd';
import querySearch from 'stringquery';
import { withRouter } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';

import { createLeadRecord } from 'services/signup';

import { ALL_COUNTRIES } from 'constants/countries/constants/countries';
import { SIGN_IN_URL } from 'constants/navbar';
import { EXIST_USER_CODE } from 'constants/signup';

import { emailRule, fullNameRule, requiredRule } from 'utils/forms';

import BRFormInputs from 'components/BRFormInputs/BRFormInputs';
import BRInternationalPhone from 'components/BRInternationalPhone/BRInternationalPhone';
import BRButton from 'components/BRButton/BRButton';
import { notify } from 'components/Notify/Notify';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import FormConfirmation from '../FormConfirmation/FormConfirmation';

import SaudiMan from 'assets/images/signup-saudi-man.png';
import BostaLogo from 'assets/images/signup-bosta-logo.svg';
import WaitingVectors from 'assets/images/waiting-vectors.svg';

import './ContactSalesHero.less';

const ContactSalesHero = ({ intl, history }) => {
  const [country, setCountry] = useState(ALL_COUNTRIES[1]);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const formRef = useRef();
  const containerRef = useRef();

  const onFinish = async (values) => {
    const query = querySearch(history?.location?.search);

    const payload = {
      ...values,
      fullName: values.fullName.trim(),
      phone: `${country.codeNumber}${values.phone}`,
      countryId: country.id,
      userUrlData: {
        ...query
      }
    };

    try {
      setIsLoading(true);
      await createLeadRecord(payload);
      window.location?.replace('/contact-shipping-sales/thank-you');
    } catch (error) {
      if (error.errorCode === EXIST_USER_CODE) {
        window.open(SIGN_IN_URL);
      }
      notify({ msg: error.message, error });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (history.location?.pathname.includes('thank-you')) {
      setIsConfirmed(true);
      containerRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <section className="br-contact-sales-hero">
      <img alt="" src={SaudiMan} className="br-contact-sales-hero__manImage" />
      <img
        src={BostaLogo}
        alt="bosta-logo"
        className="br-contact-sales-hero__bosta-logo"
      />
      <img
        src={WaitingVectors}
        alt=""
        className="br-contact-sales-hero__vector"
      />
      <div
        className="br-contact-sales-hero__content_container"
        ref={containerRef}
      >
        <span id="contact-form">
          {intl.formatMessage(
            { id: 'contact_sales.sign_info' },
            {
              TextDiv: (children) => <div>{children}</div>
            }
          )}
        </span>

        <Form
          layout="vertical"
          ref={formRef}
          onFinish={onFinish}
          className="br-contact-sales-hero__form"
        >
          {isLoading && (
            <LoadingWrapper
              loading={isLoading}
              indicator={<LoadingOutlined spin />}
            />
          )}

          {!isLoading && !isConfirmed && (
            <>
              <BRFormInputs
                title={
                  <span className="label">
                    {intl.formatMessage({
                      id: 'contact_form_labels.full_name'
                    })}
                  </span>
                }
                rules={[
                  fullNameRule(
                    intl.formatMessage({
                      id: 'sign_up.full_name_message'
                    })
                  ),
                  requiredRule(
                    intl.formatMessage(
                      {
                        id: 'form.required_custom'
                      },
                      {
                        label: intl.formatMessage({
                          id: 'contact_form_labels.full_name'
                        })
                      }
                    )
                  )
                ]}
                formRef={formRef}
                name="fullName"
                hasFeedback
              />

              <BRInternationalPhone
                country={country}
                formRef={formRef}
                disabled={true}
              />

              <BRFormInputs
                title={
                  <span className="label">
                    {intl.formatMessage({
                      id: 'contact_form_labels.email'
                    })}
                  </span>
                }
                rules={[
                  requiredRule(
                    intl.formatMessage(
                      {
                        id: 'form.required_custom'
                      },
                      {
                        label: intl.formatMessage({
                          id: 'contact_form_labels.email'
                        })
                      }
                    )
                  ),

                  emailRule(
                    intl.formatMessage({
                      id: 'form.email_not_valid'
                    })
                  )
                ]}
                formRef={formRef}
                name="email"
                placeholder="you@company.com"
                hasFeedback
              />

              <BRButton
                label={intl.formatMessage({ id: 'contact_sales.button_text' })}
                type="primary"
                htmlType="submit"
              />
            </>
          )}

          {isConfirmed && <FormConfirmation />}
        </Form>
      </div>
    </section>
  );
};

export default injectIntl(withRouter(ContactSalesHero));
