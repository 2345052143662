import { useEffect, useState, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Progress, Steps } from 'antd';
import querySearch from 'stringquery';
import classnames from 'classnames';

import { openModal } from 'utils/modal';
import { sendSegment } from 'utils/segment';
import {
  isSllrUser,
  getAccountTypePropForSuccessMetrics,
  isURLHavingKSA
} from 'utils/helpers';
import { updateVerifyPhone } from 'services/auth';
import {
  STEPPER,
  SERVICES,
  SLLR_SOURCE,
  SLLR_REG_SRC,
  MOBILE_APP_REG_SRC,
  DASHBOARD_REG_SRC
} from 'constants/signup';
import { isSaudi } from 'constants/helpers';
import { LOCALE } from 'constants/intl-wrapper';
import { AVAILABLE_COUNTRIES } from 'constants/countries/constants/countries';
import { DASHBOARD_URL } from 'constants/common';

import { notify } from 'components/Notify/Notify';
import BRButton from 'components/BRButton/BRButton';
import VerifyPhoneModal from './components/VerifyPhoneModal.js/VerifyPhoneModal';
import RegistrationContainer from 'components/RegistrationContainer/RegistrationContainer';
import PricingContainer from './components/PricingContainer/PricingContainer';
import ServicesContainer from './components/ServicesContainer/ServicesContainer';
import SignupContainer from './components/SignupContainer/SignupContainer';
import NewSignup from './NewSignup';

import { ReactComponent as ExternalLink } from 'assets/icons/Teal-External-link.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/Arrow-left.svg';
import { ReactComponent as Check } from 'assets/icons/Check.svg';

import './SignUp.less';

const SignUp = ({ history, location: { search }, intl }) => {
  const [usedPhoneNumber, setUsedPhoneNumber] = useState(false);
  const [country, setCountry] = useState();
  const [plannedShipmentTypes, setPlannedShipmentTypes] = useState(
    SERVICES.filter(({ defaultChecked }) => defaultChecked).map(
      ({ apiValue }) => apiValue
    )
  );
  const [stepperCurrentStep, setStepperCurrentStep] = useState(
    STEPPER.SERVICES_STEP
  );
  const [notifyMe, setNotifyMe] = useState([]);
  const [activePlan, setActivePlan] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTip, setIsLoadingTip] = useState('');
  const [cashoutType, setCashoutType] = useState(
    intl.formatMessage({ id: 'pickups.table_columns.repeatation_types.weekly' })
  );

  const formRef = useRef();
  const footerRef = useRef();

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const { isPhoneVerified, plannedShipmentType } = userInfo?.user || false;
  const showNewContainer =
    window.location.pathname.includes('sa') && !isPhoneVerified;

  const { Step } = Steps;

  const loadGoogleRecaptcha = (url) => {
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.src = url;
    head.appendChild(script);
  };

  const handleGoToWebsitePage = () => {
    sendSegment('Step1a_Create-Account-Track-link');
    window.open('https://bosta.co/tracking-shipments', '_blank');
  };

  const onChangeServiceCheckbox = (list) => {
    setPlannedShipmentTypes(list);
  };

  const onNotifyChange = ({ value, checked, interestedNotificationMsg }) => {
    setNotifyMe((prevList) => {
      if (checked) {
        notify({
          msg: interestedNotificationMsg,
          type: 'success'
        });
        return [...prevList, value];
      } else {
        return prevList.filter((el) => el !== value);
      }
    });
  };

  const handleStepperBack = () => {
    setStepperCurrentStep(stepperCurrentStep - 1);
  };

  const handleServiceNext = async () => {
    setIsLoading(true);
    const plannedAndNotifiedShipmentTypes = [
      ...new Set([...plannedShipmentTypes, ...notifyMe])
    ];
    const interestedSegmentAttributes = {};
    SERVICES.forEach(({ apiValue, interestedSegmentEventAttr }) => {
      if (interestedSegmentEventAttr) {
        interestedSegmentAttributes[interestedSegmentEventAttr] =
          notifyMe.includes(apiValue);
      }
    });

    try {
      const id = userInfo?.user?._id;
      const payload = {
        plannedShipmentType: plannedAndNotifiedShipmentTypes
      };
      await updateVerifyPhone(id, payload);
      userInfo.user = {
        ...userInfo.user,
        plannedShipmentType: plannedAndNotifiedShipmentTypes
      };
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      sendSegment(
        'Step2b_Choose-Service-Selected',
        interestedSegmentAttributes
      );

      setStepperCurrentStep(stepperCurrentStep + 1);
    } catch (error) {
      notify({
        msg: error?.message,
        error
      });
    }
    setIsLoading(false);
  };

  const handleFinishSignup = async () => {
    setIsLoading(true);
    setIsLoadingTip(
      intl.formatMessage({
        id: 'login.signing_in'
      })
    );

    try {
      const id = userInfo.user?._id;
      await updateVerifyPhone(id, {
        plannedPricingPlan: activePlan
      });
      userInfo.user = {
        ...userInfo.user,
        plannedPricingPlan: activePlan
      };
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      notify({
        msg: intl.formatMessage({
          id: 'sign_up.account_created'
        }),
        type: 'success'
      });
      sendSegment(
        activePlan ===
          userInfo?.user?.country?.pricingSignUpTiers?.businessTierId
          ? 'Step4_Account-Created (1B)'
          : 'Step4_Account-Created (1A)'
      );

      const token = localStorage
        .getItem('newToken')
        .replace('Bearer', '')
        .trim();

      localStorage.removeItem('newToken');
      window.location.href = `${DASHBOARD_URL}/overview?token=${token}`;
    } catch (error) {
      notify({
        msg: error.message,
        type: 'error'
      });
      setIsLoadingTip('');
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getUserCountry();
  }, []);

  const getUserCountry = async () => {
    try {
      const result = await fetch('https://ipinfo.io?token=4552036b3954e5');
      const { country } = await result.json();
      const isCountryAvailable = AVAILABLE_COUNTRIES.find(
        ({ codeName }) => codeName === country
      );
      if (!isCountryAvailable && country) {
        window.location.href = `${DASHBOARD_URL}/unavailable-country`;
        return;
      } else {
        setCountry(
          isURLHavingKSA
            ? AVAILABLE_COUNTRIES[1]
            : country && isCountryAvailable.id
            ? isCountryAvailable
            : AVAILABLE_COUNTRIES[0]
        );
        handleOnViewingPage();
      }
    } catch (error) {
      setCountry(AVAILABLE_COUNTRIES[isURLHavingKSA ? 1 : 0]);
      handleOnViewingPage();
    }
  };

  const handleOnViewingPage = () => {
    loadGoogleRecaptcha('https://www.google.com/recaptcha/api.js');
    setNotifyMe(plannedShipmentType || []);
    plannedShipmentType && setStepperCurrentStep(STEPPER.PRICING_STEP);

    if (search.length > 0) {
      const query = querySearch(search);
      if (
        query.hasOwnProperty('utm_campaign') &&
        query.hasOwnProperty('utm_source') &&
        query.hasOwnProperty('utm_term')
      ) {
        localStorage.setItem('campaignObject', JSON.stringify(query));
      }
      if (query?.utm_source) {
        localStorage.setItem(
          'regSrc',
          query?.utm_source === SLLR_SOURCE
            ? SLLR_REG_SRC
            : query?.utm_source === MOBILE_APP_REG_SRC
            ? MOBILE_APP_REG_SRC
            : DASHBOARD_REG_SRC
        );
      }
    }

    if (userInfo) {
      const { email, phone } = userInfo?.user?.profile;
      if (!isPhoneVerified) {
        openModal(VerifyPhoneModal, {
          phone: phone,
          email,
          userID: userInfo?.user?._id,
          history: history,
          onOpen: () => {
            sendSegment(
              'Step1b_Showing-OTP',
              getAccountTypePropForSuccessMetrics()
            );
          }
        });
      }
    } else {
      sendSegment(
        'Step1_Create-Account-Landing',
        getAccountTypePropForSuccessMetrics()
      );
    }
  };
  return showNewContainer ? (
    <NewSignup
      formRef={formRef}
      usedPhoneNumber={usedPhoneNumber}
      setUsedPhoneNumber={setUsedPhoneNumber}
      country={country}
      setCountry={setCountry}
      showNewContainer={showNewContainer}
    />
  ) : (
    <RegistrationContainer
      isLoading={isLoading}
      tip={isPhoneVerified && isLoadingTip}
      hideTrackOrderInHeader={isPhoneVerified}
      stepper={
        isPhoneVerified && (
          <Steps
            size="small"
            type="navigation"
            className={classnames('br-signup-stepper', {
              'br-signup-stepper__rtl': intl.locale === LOCALE.AR
            })}
            current={stepperCurrentStep}
            responsive={false}
          >
            <Step
              icon={<Check />}
              title={intl.formatMessage({
                id: 'sign_up.stepper.step_1'
              })}
            />
            <Step
              icon={<Check />}
              title={intl.formatMessage({
                id: 'sign_up.stepper.step_2'
              })}
            />

            <Step
              icon={<Check />}
              title={intl.formatMessage({
                id: 'sign_up.stepper.step_3'
              })}
            />
          </Steps>
        )
      }
      content={
        isPhoneVerified ? (
          stepperCurrentStep === STEPPER.SERVICES_STEP ? (
            <ServicesContainer
              notifyMe={notifyMe}
              onNotifyChange={onNotifyChange}
              plannedShipmentTypes={plannedShipmentTypes}
              onChangeServiceCheckbox={onChangeServiceCheckbox}
              stepperCurrentStep={stepperCurrentStep}
            />
          ) : (
            <PricingContainer
              activePlan={activePlan}
              setActivePlan={setActivePlan}
              stepperCurrentStep={stepperCurrentStep}
              footerRef={footerRef}
              setCashoutType={setCashoutType}
            />
          )
        ) : (
          <SignupContainer
            formRef={formRef}
            usedPhoneNumber={usedPhoneNumber}
            setUsedPhoneNumber={setUsedPhoneNumber}
            country={country}
            setCountry={setCountry}
            showNewContainer={showNewContainer}
          />
        )
      }
      footer={
        isPhoneVerified && (
          <div
            className={classnames('br-signup-services__footer', {
              'br-signup-pricing__fixed-footer':
                stepperCurrentStep === STEPPER.PRICING_STEP,
              'br-signup-pricing__fixed-ksa-footer':
                stepperCurrentStep === STEPPER.PRICING_STEP && isSaudi()
            })}
            ref={footerRef}
          >
            <Progress
              percent={((stepperCurrentStep + 1) / STEPPER.LAST_STEP) * 100}
              showInfo={false}
            />
            <div
              className={classnames('br-signup__footer', {
                'br-signup-services__footer':
                  stepperCurrentStep === STEPPER.SERVICES_STEP
              })}
            >
              {stepperCurrentStep + 1 === STEPPER.LAST_STEP && (
                <BRButton
                  type="link-gray"
                  prefixIcon={<ArrowLeft />}
                  onClick={handleStepperBack}
                  label={intl.formatMessage({
                    id: 'common.back'
                  })}
                />
              )}
              <BRButton
                type="primary"
                onClick={
                  stepperCurrentStep === STEPPER.SERVICES_STEP
                    ? handleServiceNext
                    : handleFinishSignup
                }
                label={
                  stepperCurrentStep === STEPPER.SERVICES_STEP
                    ? intl.formatMessage({ id: 'common.next' })
                    : intl.formatMessage(
                        {
                          id: 'sign_up.pricing.get_cashouts'
                        },
                        {
                          cashout: cashoutType
                        }
                      )
                }
                disabled={
                  stepperCurrentStep === STEPPER.SERVICES_STEP &&
                  !plannedShipmentTypes.length
                }
              />
            </div>
          </div>
        )
      }
      actionLink={
        !isPhoneVerified &&
        !isSllrUser() && (
          <BRButton
            type="link-color"
            label={intl.formatMessage({ id: 'login.track_my_order' })}
            className="button-md br-login__track-order"
            suffixIcon={<ExternalLink />}
            onClick={handleGoToWebsitePage}
          />
        )
      }
      signUpPage
    />
  );
};

export default injectIntl(withRouter(SignUp));
