import { injectIntl } from 'react-intl';

import { SOLUTIONS_MORE_SOLUTIONS } from 'constants/solutions';

import Grid from 'components/Grid/Grid';

import MainImage from 'assets/images/Solution2.png';
import { ReactComponent as FulfillmentIcon } from 'assets/icons/SolutionsFulfillmentIcon.svg';
import { ReactComponent as CodIcon } from 'assets/icons/SolutionsCodIcon.svg';

import './MoreSolutions.less';

const MoreSolutions = ({ intl }) => {
  const SOLUTIONS_MORE_SOLUTIONS_ICONS = [FulfillmentIcon, CodIcon];
  return (
    <section className="br-solutions-more-solutions-container">
      <div className="br-solutions-more-solutions-content">
        <div className="br-solutions-more-solutions-content-grid">
          <Grid
            gridTitle={intl.formatMessage({
              id: 'solutions.more_solutions.title'
            })}
            items={SOLUTIONS_MORE_SOLUTIONS}
            icons={SOLUTIONS_MORE_SOLUTIONS_ICONS}
          />
        </div>
        <div className="br-solutions-more-solutions-svg">
          <img src={MainImage} alt="fulfillment" />
        </div>
      </div>
    </section>
  );
};

export default injectIntl(MoreSolutions);
