import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import BRHero from 'components/BRHero/BRHero';

import { ReactComponent as Vector1 } from 'assets/images/solutions-vector-1.svg';
import { ReactComponent as Vector2 } from 'assets/images/solutions-vector-2.svg';

import './SolutionsHero.less';

const SolutionsHero = ({ intl, history }) => {
  return (
    <BRHero
      className="br-solutions-hero"
      title={intl.formatMessage({ id: 'solutions.header.title' })}
      subtitle={intl.formatMessage({ id: 'solutions.header.subtitle' })}
      buttonText={intl.formatMessage({ id: 'solutions.header.button' })}
      onClickButton={() => history.push('/signup')}
      vectors={[<Vector1 />, <Vector2 />]}
    />
  );
};

export default withRouter(injectIntl(SolutionsHero));
