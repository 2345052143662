import { useState } from 'react';
import { injectIntl } from 'react-intl';

import { markAsFakeException } from 'services/shipments';
import { openModal } from 'utils/modal';

import BRModal from 'components/BRModal/BRModal';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import { notify } from 'components/Notify/Notify';
import BRSuccessModal from 'components/BRSuccessModal/BRSuccessModal';

import { ReactComponent as CircleCheckIcon } from 'assets/icons/CircleCheckIcon.svg';

import './ReportProblemModal.less';

const ReportProblemModal = ({ intl, problem, apiKey, close, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async () => {
    setIsLoading(true);

    try {
      await markAsFakeException({ token: apiKey });
      close();

      openModal(BRSuccessModal, {
        icon: CircleCheckIcon,
        title: intl.formatMessage({
          id: 'tracking.report_problem_modal.success_modal.title'
        }),
        subtitle: intl.formatMessage({
          id: 'tracking.report_problem_modal.success_modal.subtitle'
        }),
        closeBtnProps: {
          label: intl.formatMessage({
            id: 'common.close'
          })
        }
      });
    } catch (e) {
      notify({
        msg: e.message
      });
    }

    setIsLoading(false);
  };

  return (
    <BRModal
      title={intl.formatMessage({
        id: 'tracking.report_problem_modal.title'
      })}
      okBtnProps={{
        label: intl.formatMessage({
          id: 'tracking.report_problem_modal.confirm_btn'
        }),
        type: 'destructive-primary',
        disabled: isLoading,
        loading: isLoading,
        onClick: handleConfirm
      }}
      cancelBtnProps={{
        label: intl.formatMessage({
          id: 'common.cancel'
        }),
        disabled: isLoading,
        onClick: close
      }}
      {...props}
    >
      <LoadingWrapper loading={isLoading}>
        <span className="br-report-problem-modal__problem">{problem}</span>
      </LoadingWrapper>
    </BRModal>
  );
};

export default injectIntl(ReportProblemModal);
