import { withRouter } from 'react-router-dom';

import EgyptEcommerceState from './components/EgyptEcommerceState/EgyptEcommerceState';

import './Report.less';

const Report = ({
  match: {
    params: { id }
  }
}) => {
  const REPROTS_ID_MAPPER = {
    'egypt-ecommerce-state': <EgyptEcommerceState />
  };

  return (
    <div className="br-bosta-insights__report">{REPROTS_ID_MAPPER[id]}</div>
  );
};

export default withRouter(Report);
