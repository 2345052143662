import React from 'react';
import { withRouter } from 'react-router-dom';
import Icon from '@ant-design/icons';
import classNames from 'classnames';

import { ReactComponent as KnowMoreIcon } from 'assets/icons/know-more.svg';

import './Grid.less';

const Grid = ({ gridTitle, gridSubtitle, items, icons = [], history }) => {
  return (
    <>
      <span className="br-grid-title grid-title">{gridTitle}</span>
      {gridSubtitle && (
        <h4 className="br-grid-subtitle display-sm">{gridSubtitle}</h4>
      )}
      <div className="br-grid">
        {items.map(
          (
            { title, subtitle, link = false, linkURL, access = true },
            index
          ) => {
            if (!access) {
              return;
            }
            return (
              <div
                className={classNames('br-grid-item', {
                  'br-grid-single-item': items.length === 1
                })}
                key={title}
              >
                {!!icons.length && <Icon component={icons[index]} />}
                <span className="display-xs">{title}</span>
                <span>{subtitle}</span>
                {link && (
                  <span
                    className="br-grid-item__link"
                    onClick={() => history.push(linkURL)}
                  >
                    {link}
                    <KnowMoreIcon />
                  </span>
                )}
              </div>
            );
          }
        )}
      </div>
    </>
  );
};

export default withRouter(Grid);
