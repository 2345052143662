import Icon from '@ant-design/icons';
import { Tooltip } from 'antd';

import {
  BULKY,
  HEAVY_BULKY,
  LIGHT_BULKY,
  PICKUP_LOCATION_OPTIONS,
  XXL
} from 'constants/pricing';

import { fmt, intl } from 'components/IntlWrapper/IntlWrapper';

import { ReactComponent as Tool } from 'assets/icons/Tooltip.svg';

export const formatSizeName = (name) => {
  let formattedName = '';
  if (name?.sizeAlias !== name?.sizeName) {
    formattedName = name.sizeAlias + ' ' + name.sizeName;
    formattedName = formattedName.replace('-', '_');
    formattedName = formattedName.replace(/\s/g, '_');
    formattedName = formattedName.toLowerCase();
  } else {
    formattedName = name?.sizeAlias;
    formattedName = formattedName?.replace('-', '_');
    formattedName = formattedName?.replace(/\s/g, '_');
    formattedName = formattedName?.toLowerCase();
  }
  return formattedName;
};

export const getSizeTooltip = (size) => {
  switch (size) {
    case LIGHT_BULKY:
      return fmt({
        id: 'settings.pricing_plan.tooltips.light_bulky'
      });
    case HEAVY_BULKY:
      return fmt({
        id: 'settings.pricing_plan.tooltips.heavy_bulky'
      });
    case XXL:
      return fmt({
        id: 'settings.pricing_plan.tooltips.xxl_white_bag'
      });
    default:
      break;
  }
};

export const insertIcon = (size) => {
  if ([LIGHT_BULKY, HEAVY_BULKY, XXL].includes(size)) {
    return (
      <Tooltip
        title={getSizeTooltip(size)}
        className="pricing-extra-info__size-tooltip"
      >
        <Icon component={Tool} />
      </Tooltip>
    );
  }
};

export const formatData = (sector, index, showExtraInfo = false, useWeight) => {
  let sectorsFormat = [
    {
      key: index++ / 10,
      name: showExtraInfo ? (
        intl.locale === 'en' ? (
          <div className="pricing-extra-info-container">
            <span className="pricing-extra-info__size-title">
              {sector.dropoffSectorName}
            </span>
            <span className="pricing-extra-info__size-description caption-lg">
              {PICKUP_LOCATION_OPTIONS[sector.dropoffSectorId].value}
            </span>
          </div>
        ) : (
          <div>{sector.dropoffSectorNameArabic}</div>
        )
      ) : intl.locale === 'en' ? (
        sector.dropoffSectorName
      ) : (
        sector.dropoffSectorNameArabic
      ),
      value: PICKUP_LOCATION_OPTIONS[sector.dropoffSectorId]?.value,
      dropOffSectorName: sector.dropoffSectorId
    }
  ];

  if (useWeight) {
    sectorsFormat.push({
      key: index++ * 10,
      name: fmt({
        id: 'settings.pricing_plan.basic_fees'
      }),
      deliver: sector.tierServiceTypes[0].tierSizes[0].cost,
      exchange: sector.tierServiceTypes[1].tierSizes[0].cost,
      return: sector.tierServiceTypes[2].tierSizes[0].cost,
      cash_collection: sector.tierServiceTypes[3].tierSizes[0].cost,
      rto: sector.tierServiceTypes[4].tierSizes[0].cost,
      dropOffSectorName: sector.dropoffSectorId
    });
  } else {
    const typesObj = {
      SEND: [],
      CUSTOMER_RETURN_PICKUP: [],
      CASH_COLLECTION: [],
      RTO: []
    };
    sector.tierServiceTypes.map((serviceType) => {
      typesObj[serviceType.typeName] = serviceType.tierSizes;
    });
    for (let i = 0; i < sector.tierServiceTypes[0].tierSizes.length; i++) {
      if (sector.tierServiceTypes[0].tierSizes[i].sizeName !== BULKY) {
        sectorsFormat.push({
          key: index++ * 10,
          name: showExtraInfo ? (
            <div className="pricing-extra-info-container">
              <span className="pricing-extra-info__size-title">
                <span>
                  {fmt({
                    id: `settings.pricing_plan.sizes.${formatSizeName(
                      sector.tierServiceTypes[0].tierSizes[i]
                    )}`
                  })}
                </span>
                {insertIcon(sector.tierServiceTypes[0].tierSizes[i].sizeAlias)}
              </span>
              <span className="pricing-extra-info__size-description caption-lg">
                {sector.tierServiceTypes[0].tierSizes[i].sizeWidth
                  ? fmt(
                      { id: 'sign_up.pricing.pricing_more_info' },
                      {
                        width:
                          sector.tierServiceTypes[0].tierSizes[i].sizeWidth,
                        height:
                          sector.tierServiceTypes[0].tierSizes[i].sizeHeight
                      }
                    )
                  : sector.tierServiceTypes[0].tierSizes[i].sizeName ===
                    LIGHT_BULKY
                  ? fmt({ id: `settings.pricing_plan.light_bulky_size` })
                  : fmt({ id: `settings.pricing_plan.heavy_bulky_size` })}
              </span>
            </div>
          ) : (
            fmt({
              id: `settings.pricing_plan.sizes.${formatSizeName(
                sector.tierServiceTypes[0].tierSizes[i]
              )}`
            })
          ),
          otherDay: sector.tierServiceTypes[0].tierSizes[i]?.otherDay,
          deliver: Math.round(typesObj['SEND'][i].cost * 10) / 10,
          exchange: [LIGHT_BULKY, HEAVY_BULKY, 'Sign and Return'].includes(
            sector.tierServiceTypes[0].tierSizes[i].sizeName
          )
            ? '-'
            : Math.round(sector.tierServiceTypes[1].tierSizes[i].cost * 10) /
              10,
          return: [LIGHT_BULKY, HEAVY_BULKY, 'Sign and Return'].includes(
            sector.tierServiceTypes[0].tierSizes[i].sizeName
          )
            ? '-'
            : Math.round(typesObj['CUSTOMER_RETURN_PICKUP'][i]?.cost * 10) / 10,
          cash_collection: [
            LIGHT_BULKY,
            HEAVY_BULKY,
            'Sign and Return'
          ].includes(sector.tierServiceTypes[0].tierSizes[i].sizeName)
            ? '-'
            : Math.round(typesObj['CASH_COLLECTION'][i].cost * 10) / 10,
          rto: [LIGHT_BULKY, HEAVY_BULKY, 'Sign and Return'].includes(
            sector.tierServiceTypes[0].tierSizes[i].sizeName
          )
            ? '-'
            : Math.round(typesObj['RTO'][i].cost * 10) / 10,
          dropOffSectorName: sector.dropoffSectorId
        });
      }
    }
  }
  return sectorsFormat;
};

export const formatPricingTableData = (
  pricingData,
  useWeight = false,
  showExtraInfo = false
) => {
  const tableData = [];
  pricingData.forEach((sector, index) => {
    let sectorData = formatData(sector, index * 10, showExtraInfo, useWeight);
    tableData.push(...sectorData);
  });
  return tableData;
};
