import { fmt } from 'components/IntlWrapper/IntlWrapper';
import { Link } from 'react-router-dom';

const shipmentPackedAnswer = () => {
  const title = fmt({
    id: 'faq.questions.shipment_packed.answer.to_render.title'
  });
  const boxSizes = fmt({
    id: 'faq.questions.shipment_packed.answer.to_render.box_sizes'
  });
  const materials = fmt({
    id: 'faq.questions.shipment_packed.answer.to_render.materials'
  });

  return `${title}\n\n${boxSizes}\n\n${materials}`;
};

/* 
  TODO: Move HTML from `QUESTIONS_AND_ANSWERS` to component.
  For now, if `answer` has HTML tag, so that, search is handled by `answerTextToSearch`.
*/
export const QUESTIONS_AND_ANSWERS = [
  {
    key: '0',
    question: fmt({
      id: 'faq.questions.what_bosta.question'
    }),
    answer: {
      toRender: fmt({
        id: 'faq.questions.what_bosta.answer.to_render'
      }),
      toSearch: fmt({
        id: 'faq.questions.what_bosta.answer.to_search'
      })
    }
  },
  {
    key: '1',
    question: fmt({
      id: 'faq.questions.how_start.question'
    }),
    answer: {
      toRender: fmt(
        {
          id: 'faq.questions.how_start.answer.to_render'
        },
        {
          TextLink: (children) => (
            <Link to="/contact-shipping-sales">{children}</Link>
          )
        }
      ),
      toSearch: fmt({
        id: 'faq.questions.how_start.answer.to_search'
      })
    }
  },
  {
    key: '2',
    question: fmt({
      id: 'faq.questions.estimated_price.question'
    }),
    answer: {
      toRender: fmt(
        {
          id: 'faq.questions.estimated_price.answer.to_render'
        },
        {
          TextLink: (children) => <Link to="/pricing">{children}</Link>
        }
      ),
      toSearch: fmt({
        id: 'faq.questions.estimated_price.answer.to_search'
      })
    }
  },
  {
    key: '3',
    question: fmt({
      id: 'faq.questions.what_areas.question'
    }),
    answer: {
      toRender: fmt({
        id: 'faq.questions.what_areas.answer.to_render'
      }),
      toSearch: fmt({
        id: 'faq.questions.what_areas.answer.to_search'
      })
    }
  },
  {
    key: '4',
    question: fmt({
      id: 'faq.questions.delivery_time.question'
    }),
    answer: {
      toRender: fmt({
        id: 'faq.questions.delivery_time.answer.to_render'
      }),
      toSearch: fmt({
        id: 'faq.questions.delivery_time.answer.to_search'
      })
    }
  },
  {
    key: '5',
    question: fmt({
      id: 'faq.questions.order_delivered.question'
    }),
    answer: {
      toRender: fmt({
        id: 'faq.questions.order_delivered.answer.to_render'
      }),
      toSearch: fmt({
        id: 'faq.questions.order_delivered.answer.to_search'
      })
    }
  },
  {
    key: '6',
    question: fmt({
      id: 'faq.questions.shipment_packed.question'
    }),
    answer: {
      toRender: shipmentPackedAnswer(),
      toSearch: fmt({
        id: 'faq.questions.shipment_packed.answer.to_search'
      })
    }
  },
  {
    key: '7',
    question: fmt({
      id: 'faq.questions.flyers_packing.question'
    }),
    answer: {
      toRender: fmt(
        {
          id: 'faq.questions.flyers_packing.answer.to_render'
        },
        {
          Link: (children) => (
            <Link to="/contact-shipping-sales">{children}</Link>
          ),
          TextSpan: (children) => <u>{children}</u>
        }
      ),
      toSearch: fmt({
        id: 'faq.questions.flyers_packing.answer.to_search'
      })
    }
  },
  {
    key: '8',
    question: fmt({
      id: 'faq.questions.request_time.question'
    }),
    answer: {
      toRender: fmt({
        id: 'faq.questions.request_time.answer.to_render'
      }),
      toSearch: fmt({
        id: 'faq.questions.request_time.answer.to_search'
      })
    }
  },
  {
    key: '9',
    question: fmt({
      id: 'faq.questions.products_not_deliver.question'
    }),
    answer: {
      toRender: fmt({
        id: 'faq.questions.products_not_deliver.answer.to_render'
      }),
      toSearch: fmt({
        id: 'faq.questions.products_not_deliver.answer.to_search'
      })
    }
  },
  {
    key: '10',
    question: fmt({
      id: 'faq.questions.cod_time.question'
    }),
    answer: {
      toRender: fmt({
        id: 'faq.questions.cod_time.answer.to_render'
      }),
      toSearch: fmt({
        id: 'faq.questions.cod_time.answer.to_search'
      })
    }
  },
  {
    key: '11',
    question: fmt({
      id: 'faq.questions.cod_amount.question'
    }),
    answer: {
      toRender: fmt({
        id: 'faq.questions.cod_amount.answer.to_render'
      }),
      toSearch: fmt({
        id: 'faq.questions.cod_amount.answer.to_search'
      })
    }
  },
  {
    key: '12',
    question: fmt({
      id: 'faq.questions.package_weight.question'
    }),
    answer: {
      toRender: fmt({
        id: 'faq.questions.package_weight.answer.to_render'
      }),
      toSearch: fmt({
        id: 'faq.questions.package_weight.answer.to_search'
      })
    }
  },
  {
    key: '13',
    question: fmt({
      id: 'faq.questions.bosta_integrate.question'
    }),
    answer: {
      toRender: fmt({
        id: 'faq.questions.bosta_integrate.answer.to_render'
      }),
      toSearch: fmt({
        id: 'faq.questions.bosta_integrate.answer.to_search'
      })
    }
  },
  {
    key: '14',
    question: fmt({
      id: 'faq.questions.shipments_per_month.question'
    }),
    answer: {
      toRender: fmt({
        id: 'faq.questions.shipments_per_month.answer.to_render'
      }),
      toSearch: fmt({
        id: 'faq.questions.shipments_per_month.answer.to_search'
      })
    }
  },
  {
    key: '15',
    question: fmt({
      id: 'faq.questions.mobile_app.question'
    }),
    answer: {
      toRender: fmt({
        id: 'faq.questions.mobile_app.answer.to_render'
      }),
      toSearch: fmt({
        id: 'faq.questions.mobile_app.answer.to_search'
      })
    },
    access: false //!!isURLHavingKSA
  },
  {
    key: '16',
    question: fmt({
      id: 'faq.questions.smart_pack.question'
    }),
    answer: {
      toRender: fmt({
        id: 'faq.questions.smart_pack.answer.to_render'
      }),
      toSearch: fmt({
        id: 'faq.questions.smart_pack.answer.to_search'
      })
    },
    access: false //!!isURLHavingKSA
  },
  {
    key: '17',
    question: fmt({
      id: 'faq.questions.customer_support.question'
    }),
    answer: {
      toRender: fmt(
        {
          id: 'faq.questions.customer_support.answer.to_render'
        },
        {
          TextSpan: (children) => (
            <Link to="/" onClick={(e) => e.preventDefault()}>
              {children}
            </Link>
          )
        }
      ),
      toSearch: fmt({
        id: 'faq.questions.customer_support.answer.to_search'
      })
    }
  }
];
