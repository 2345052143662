import { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { injectIntl } from 'react-intl';
import { doc, getDoc } from 'firebase/firestore';
import dayjs from 'dayjs';

import { db, ARTICLES_DOC_ID } from 'firebaseConfig';
import { COUNTRIES_ENGLISH_LOCALE, LOCALE } from 'constants/intl-wrapper';
import { ARABIC_LANG, isURLHavingKSA, pathLang } from 'utils/helpers';
import { HOST_NAME } from 'constants/helpers';
import { getLocaleFromLocalStorage } from 'utils/intl-wrapper';

import { notify } from 'components/Notify/Notify';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BRButton from 'components/BRButton/BRButton';
import ProgressiveImg from 'components/ProgressiveImage/ProgressiveImage';

import './BlogArticles.less';

const articlesRef = doc(db, 'articles', ARTICLES_DOC_ID);

const BlogArticles = ({ intl, history, showEditBtn = false }) => {
  const language = pathLang || getLocaleFromLocalStorage();

  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const locale = COUNTRIES_ENGLISH_LOCALE.includes(intl.locale)
    ? LOCALE.EN
    : ARABIC_LANG;

  const getArticles = async () => {
    try {
      setIsLoading(true);
      const docSnap = await getDoc(articlesRef);
      if (docSnap.exists()) {
        setArticles(
          Object.values(docSnap.data()).sort(function (a, b) {
            return new Date(b.publishDate) - new Date(a.publishDate);
          })
        );
      }
    } catch (error) {
      notify({ msg: error.message });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getArticles();
  }, []);

  return (
    <LoadingWrapper loading={isLoading}>
      <div className="br-blog-articles">
        {articles.map((article) => {
          if (
            !Object.keys(
              isURLHavingKSA ? article?.sa[locale] : article?.eg[locale]
            ).length
          ) {
            return;
          }
          return (
            <div className="br-blog-article" key={article.id}>
              <a href={`${HOST_NAME}/${language}/blog/${article.id}`}>
                <span className="br-blog-articles__article-image">
                  <ProgressiveImg
                    src={
                      isURLHavingKSA && article.sa
                        ? article?.sa[locale]?.thumbnail ||
                          article?.sa[LOCALE.AR]?.thumbnail
                        : article?.eg[locale]?.thumbnail ||
                          article?.eg[LOCALE.AR]?.thumbnail
                    }
                    alt="article image"
                  />
                </span>
              </a>
              <div className="br-blog-articles__article-title display-xs">
                <a href={`${HOST_NAME}/${language}/blog/${article.id}`}>
                  {isURLHavingKSA && article.sa
                    ? article.sa[locale].articleTitle ||
                      article.sa[LOCALE.AR].articleTitle
                    : article.eg[locale].articleTitle ||
                      article.eg[LOCALE.AR].articleTitle}
                </a>
              </div>

              <div className="br-blog-articles__article-info">
                {article[locale]?.authorName && (
                  <>
                    <span className="br-blog-articles__article-info__by">
                      {intl.formatMessage({ id: 'articles.by' })}
                    </span>
                    <span>{article[locale]?.authorName}</span>

                    <span className="br-blog-articles__circle"></span>
                  </>
                )}
                <span>
                  {article.publishDate &&
                    dayjs(new Date(article.publishDate)).format('DD MMM, YYYY')}
                </span>

                {showEditBtn && article?.id && (
                  <span className="br-blog-articles__edit-article">
                    <BRButton
                      label="Edit Article"
                      type="destructive-primary"
                      onClick={() =>
                        history.push({
                          pathname: `/create-article/${article.id}`,
                          state: { article }
                        })
                      }
                    />
                  </span>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </LoadingWrapper>
  );
};

export default injectIntl(withRouter(BlogArticles));
