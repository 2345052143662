import React from 'react';
import { injectIntl } from 'react-intl';
import { Form, Input, Select } from 'antd';
import ReactResizeDetector from 'react-resize-detector';
import classNames from 'classnames';

import { requiredRule, phoneNumberRule } from 'utils/forms';
import { LOCALE } from 'constants/intl-wrapper';
import { PHONE_NUMBER } from 'constants/Forms';
import { ALL_COUNTRIES } from 'constants/countries/constants/countries';

import BRFormInputs from 'components/BRFormInputs/BRFormInputs';

import './BRInternationalPhone.less';

const BRInternationalPhone = ({
  intl,
  formRef,
  country,
  setCountry,
  usedPhoneNumber,
  setUsedPhoneNumber = () => {},
  withSelect = true,
  disabled = false
}) => {
  return (
    <ReactResizeDetector>
      {({ width }) => (
        <Form.Item
          label={intl.formatMessage({
            id: 'contact_form_labels.phone'
          })}
          className={classNames('br-international-phone', {
            'br-international-phone__without-select': !withSelect
          })}
        >
          <Input.Group compact>
            {withSelect && (
              <Select
                value={country?.id}
                disabled={disabled}
                autoComplete={'' + Math.random()}
                dropdownMatchSelectWidth={width}
                onChange={(value) => {
                  formRef.current.setFieldsValue({ phone: '' });
                  setCountry(
                    ALL_COUNTRIES.find((country) => country.id === value)
                  );
                }}
              >
                {ALL_COUNTRIES.map((country, index) => (
                  <Select.Option value={country?.id} key={index}>
                    <img
                      className="br-international-phone__country-flag"
                      src={country.flagIcon}
                      alt="flag"
                    />
                    <div>
                      <span className="br-international-phone__country-name">
                        {intl.locale.includes(LOCALE.EN)
                          ? country.name
                          : country.arabicName}
                      </span>
                      <span className="br-international-phone__country-code-number">
                        {country.codeNumber}
                      </span>
                      <span className="br-international-phone__country-code-name">
                        {country.codeName}
                      </span>
                    </div>
                  </Select.Option>
                ))}
              </Select>
            )}
            <BRFormInputs
              type={PHONE_NUMBER}
              formRef={formRef}
              name="phone"
              prefix={
                intl.locale.includes(LOCALE.EN)
                  ? country?.codeNumber
                  : country?.codeNumberArabic || country?.codeNumber
              }
              placeholder={country?.phoneNumberPlaceholder}
              rules={[
                requiredRule(
                  intl.formatMessage({
                    id: 'sign_up.phone_required_message'
                  })
                ),
                phoneNumberRule({
                  message: intl.formatMessage({ id: 'form.phone_not_valid' }),
                  country,
                  withoutCountryCode: true
                })
              ]}
              validateStatus={usedPhoneNumber ? 'error' : undefined}
              help={
                usedPhoneNumber
                  ? intl.formatMessage({
                      id: 'sign_up.phone_already_used'
                    })
                  : undefined
              }
              onChange={() => setUsedPhoneNumber(false)}
              hasFeedback
            />
          </Input.Group>
        </Form.Item>
      )}
    </ReactResizeDetector>
  );
};

export default injectIntl(BRInternationalPhone);
