import './PricingTableMobile.less';

const PricingTableMobile = ({ prices }) => {
  return (
    <div className="br-pricing-table-mobile">
      {prices.map((price) => (
        <div className="br-pricing-table-mobile-data">
          <h1 className="br-pricing-table-mobile-title">{price.service}</h1>
          <div className="br-pricing-table-mobile-prices-list">
            <div className="br-pricing-table-mobile-city">Cairo</div>
            <div className="br-pricing-table-mobile-price">{price.cairo}</div>
          </div>
          <div className="br-pricing-table-mobile-prices-list">
            <div className="br-pricing-table-mobile-city">Alex</div>
            <div className="br-pricing-table-mobile-price">{price.alex}</div>
          </div>
          <div className="br-pricing-table-mobile-prices-list">
            <div className="br-pricing-table-mobile-city">Delta-Canal</div>
            <div className="br-pricing-table-mobile-price">{price.delta}</div>
          </div>
          <div className="br-pricing-table-mobile-prices-list">
            <div className="br-pricing-table-mobile-city">Upper-RedSea</div>
            <div className="br-pricing-table-mobile-price">{price.upper}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PricingTableMobile;
