export const WITHOUT_COUNTRY_CODE = 'WITHOUT_COUNTRY_CODE';
export const DEFAULT = 'DEFAULT';

export const PHONE_NUMBER_REGEX = {
  EG: {
    WITHOUT_COUNTRY_CODE: /^1[0-2|5]{1}[0-9]{8}$/,
    DEFAULT: /^(\+201|01)[0-2|5]{1}[0-9]{8}$/
  },
  SA: {
    WITHOUT_COUNTRY_CODE: /^(05|5)([013-9][0-9]{7})$/,
    DEFAULT: /^(\+9665|05|5)([013-9][0-9]{7})$/
  },
  AE: {
    WITHOUT_COUNTRY_CODE: /^(50|51|52|54|55|56|58|2|3|4|6|7|9)\d{7}$/,
    DEFAULT: /^(?:\+971|00971|0)?(?:50|51|52|54|55|56|2|3|4|6|7|9)\d{7}$/
  }
};

export const BOSTA_BOX_AVAILABILITY = {
  EG: true,
  SA: false
};

export const PACK_PRICE_AVAILABILITY = {
  EG: true,
  SA: false
};
