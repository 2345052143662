import DashboardFeatures from './components/DashboardFeatures/DashboardFeatures';
import DashboardHero from './components/DashboardHero/DashboardHero';
import TestimonialsCarousel from 'components/TestimonialsCarousel/TestimonialsCarousel';
import SignUpAndStart from 'components/SignUpAndStart/SignUpAndStart';

const Dashboard = () => {
  return (
    <>
      <DashboardHero />
      <DashboardFeatures />
      <TestimonialsCarousel />
      <SignUpAndStart />
    </>
  );
};

export default Dashboard;
