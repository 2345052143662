import { Steps } from 'antd';

import { ORDER_TYPES, TRACKING_STEPS } from 'constants/shipments';
import { getActiveStep, getStepUpdatedAt } from 'utils/tracking';

import TrackingTimelineDot from './components/TrackingTimelineDot/TrackingTimelineDot';

import './TrackingTimeline.less';

const TrackingTimeline = ({ trackingDetails = {} }) => {
  const activeStep = getActiveStep({ trackingDetails });

  const getTrackingSteps = () =>
    TRACKING_STEPS[trackingDetails.Type] || TRACKING_STEPS[ORDER_TYPES.SEND];

  return (
    <Steps current={activeStep} progressDot={TrackingTimelineDot}>
      {getTrackingSteps().map((step, index) => (
        <Steps.Step
          title={step.title}
          subTitle={getStepUpdatedAt({
            trackingDetails,
            activeStep,
            step: step.type,
            stepIndex: index
          })}
        />
      ))}
    </Steps>
  );
};

export default TrackingTimeline;
