import axios from 'axios';
import { isURLHavingKSA } from 'utils/helpers';

const headers = {
  'Content-Type': 'application/x-www-form-urlencoded'
};

const url = isURLHavingKSA
  ? 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSefG-XuZyeBmAmfpZs9crFRY4gPZAEbbvQVqMEg8sLIuqWZTQ/formResponse'
  : 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSctddycJ62--9mNqAxOkDSOibYghJDu4EYOdDyx_GkDyp4izQ/formResponse';

export const internationalShippingSignup = async (payload) => {
  return await axios.post(url, payload, { headers });
};
