import { withRouter } from 'react-router';

import BlogArticles from 'components/Blog/components/BlogArticles/BlogArticles';
import BRButton from 'components/BRButton/BRButton';

import './BlogContentManager.less';

const BlogContentManager = ({ history }) => {
  return (
    <div className="br-blogs-content-manager-container">
      <div className="br-blogs-content-manager__create-btn">
      <BRButton
        label="Create Article"
        type="primary"
        className="button-lg"
        onClick={() => history.push('/create-article')}
      />
      </div>
      <BlogArticles showEditBtn={false} />
    </div>
  );
};

export default withRouter(BlogContentManager);
