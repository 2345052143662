import { injectIntl } from 'react-intl';

import Vectors from 'assets/images/signup-vectors.svg';

import './SmartShipping.less';

const SmartShipping = ({ intl }) => {
  const SmartShippingElement = ({ name }) => {
    return (
      <div className="br-smart-shipping__element">
        <span className="signup-md">
          {intl.formatMessage({
            id: `contact_sales.${name}`
          })}
        </span>
        <span className="signup-sm">
          {intl.formatMessage(
            {
              id: `contact_sales.${name}_subtitle`
            },
            {
              TextDiv: (children) => <div>{children}</div>
            }
          )}
        </span>
      </div>
    );
  };

  return (
    <div className="br-smart-shipping">
      <div className="br-smart-shipping__header">
        <span>
          {intl.formatMessage({
            id: 'contact_sales.smart_shipping_title'
          })}
        </span>
      </div>
      <div className="br-smart-shipping__content">
        <img src={Vectors} alt="vector" />
        <SmartShippingElement name="fast_cash_collection" />
        <SmartShippingElement name="unique_service" />
        <SmartShippingElement name="smart_shipping" />
        <SmartShippingElement name="milestone_benefits" />
      </div>
    </div>
  );
};

export default injectIntl(SmartShipping);
