// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey:
    window?.env?.FIREBASE_KEY,
  authDomain: 'bosta-cms.firebaseapp.com',
  projectId: 'bosta-cms',
  storageBucket: 'bosta-cms.appspot.com',
  messagingSenderId: '725525467925',
  appId: '1:725525467925:web:e3f987224970774cb40f1c',
  measurementId: 'G-WGK55CW5R6'
};

export const HEADER_DOC_ID = 'xOOwNNhBPDnrFu8HVizK';
export const ARTICLES_DOC_ID = 'articles';
// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth();
export const db = getFirestore(app);
export const storage = getStorage();
