import React from 'react';
import { injectIntl } from 'react-intl';

import { ELIGABLE_PARAGRAPHS } from 'constants/capital';

import eligableImage from 'assets/images/eligable_companies.jpg';

import './CapitalEligable.less';

const CapitalEligable = ({ intl }) => {
  const { formatMessage } = intl;

  return (
    <div className="br-capital__eligable-section">
      <h3 className="br-capital__eligable-section-header">
        {formatMessage({
          id: 'bosta_capital.eligable.title'
        })}
      </h3>
      <div className="br-capital__eligable-content">
        <div className="br-capital__eligable-paragraphs">
          {ELIGABLE_PARAGRAPHS.map((item) => (
            <div key={item.key} className="br-capital__eligable-item">
              <span className="br-capital__eligable-num">{item.key}</span>
              <div className="br-capital__eligable-paragraph">
                <p className="paragraph-title">{item.title}</p>
                <p className="paragraph-subtitle">{item.subtitle}</p>
              </div>
            </div>
          ))}
        </div>
        <img src={eligableImage} alt="eligable-companies-thumbnail" />
      </div>
    </div>
  );
};

export default injectIntl(CapitalEligable);
