import { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Tabs } from 'antd';

import { DEFAULT_PLUGIN, PLUGINS } from 'constants/merged-integrations';

import BRHero from 'components/BRHero/BRHero';

import { ReactComponent as WooCommerceLogoUncolored } from 'assets/icons/WooCommerceLogoUncolored.svg';
import { ReactComponent as ShopifyLogoUncolored } from 'assets/icons/ShopifyLogoUncolored.svg';
import { ReactComponent as IntegrateIcon } from 'assets/images/integrate.svg';
import { ReactComponent as BagIcon } from 'assets/images/about_hero_vector_1.svg';

import './MergedIntegrationsHeader.less';

const { TabPane } = Tabs;
const { WOOCOMMERCE, SHOPIFY } = PLUGINS;

const PluginsTabs = ({ tabs, activeTabKey, onTabChange }) => {
  const renderedTabs = tabs.map(({ key, icon, title, subtitle }) => (
    <TabPane
      tab={
        <span className="br-merged-integrations__header__text__tab display-md">
          {icon}
          <span className="tab-text">{title}</span>
        </span>
      }
      key={key}
      className="display-sm"
    >
      {subtitle}
    </TabPane>
  ));

  return (
    <Tabs size="large" activeKey={activeTabKey} onChange={onTabChange}>
      {renderedTabs}
    </Tabs>
  );
};

const MergedIntegrationsHeader = ({ intl, history, location }) => {
  const [plugin, setPlugin] = useState(DEFAULT_PLUGIN);

  const plugins = [
    {
      key: WOOCOMMERCE,
      icon: <WooCommerceLogoUncolored />,
      title: intl.formatMessage({
        id: 'merged_integrations.header.subtitles.woocommerce.title'
      }),
      subtitle: intl.formatMessage({
        id: 'merged_integrations.header.subtitles.woocommerce.subtitle'
      })
    },
    {
      key: SHOPIFY,
      icon: <ShopifyLogoUncolored />,
      title: intl.formatMessage({
        id: 'merged_integrations.header.subtitles.shopify.title'
      }),
      subtitle: intl.formatMessage({
        id: 'merged_integrations.header.subtitles.shopify.subtitle'
      })
    }
  ];

  const handleTabChange = (activeKey) => {
    setPlugin(activeKey);
    history.push(`/merged-integrations/${activeKey}`);
  };

  useEffect(() => {
    if (location?.pathname) {
      const linkPlugin = location.pathname.includes(SHOPIFY)
        ? SHOPIFY
        : WOOCOMMERCE;

      setPlugin(linkPlugin);
    }
  }, [location]);

  return (
    <BRHero
      className="br-merged-integrations__header"
      title={intl.formatMessage({ id: 'merged_integrations.header.title' })}
      subtitle={
        <PluginsTabs
          tabs={plugins}
          onTabChange={handleTabChange}
          activeTabKey={plugin}
        />
      }
      buttonText={intl.formatMessage({
        id: 'merged_integrations.header.button'
      })}
      onClickButton={() => history.push('/contact-shipping-sales')}
      vectors={[<IntegrateIcon />, <BagIcon />]}
    />
  );
};

export default injectIntl(withRouter(MergedIntegrationsHeader));
