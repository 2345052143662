import React from 'react';
import { message } from 'antd';
import Icon from '@ant-design/icons';
import classnames from 'classnames';

import { ReactComponent as Close } from 'assets/icons/Close.svg';

import './Notify.less';

const MESSAGES_TYPES = {
  SUCCESS: 'success',
  WARNING: 'warning',
  INFO: 'info',
  ERROR: 'error'
};

const toastConfigObject = {
  duration: 5,
  maxCount: 1
};

message.config(toastConfigObject);

const configureContentShape = (msg, title) => {
  const onCloseMessage = () => {
    message.success({
      content: 'Hide',
      className: 'br-notify-message__hidden'
    });
  };
  return (
    <div className="br-notify__message">
      <div className="br-notify__message__content">
        {title && (
          <span className="br-notify__message__title body-medium">
            {title}.
          </span>
        )}
        <span
          className={classnames('br-notify__message__subtitle', {
            'body-medium': !title,
            body: title
          })}
        >
          {msg}
        </span>
      </div>
      <Icon
        className="br-notify__message__close-icon"
        component={Close}
        onClick={onCloseMessage}
      />
    </div>
  );
};

const controlNotificationType = ({ messageType, msg, title }) => {
  switch (messageType) {
    case MESSAGES_TYPES.SUCCESS:
      return message.success({
        content: configureContentShape(msg, title),
        className: MESSAGES_TYPES.SUCCESS
      });
    case MESSAGES_TYPES.WARNING:
      return message.warning({
        content: configureContentShape(msg, title),
        className: MESSAGES_TYPES.WARNING
      });
    case MESSAGES_TYPES.INFO:
      return message.info({
        content: configureContentShape(msg, title),
        className: MESSAGES_TYPES.INFO
      });
    case MESSAGES_TYPES.ERROR:
    default:
      return message.error({
        content: configureContentShape(msg, title),
        className: MESSAGES_TYPES.ERROR
      });
  }
};

export const notify = ({ msg, title, type = 'error' }) => {
  return controlNotificationType({ messageType: type, title, msg });
};
