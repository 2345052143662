import classNames from 'classnames';

import './DashboardFeature.less';

const DashboardFeature = ({ title, subtitle, image, positionRight }) => {
  return (
    <div className="br-dashboard-feature-container">
      <div
        className={classNames('br-dashboard-feature-content', {
          'right': positionRight
        })}
      >
        <div className="br-dashboard-feature-description">
          <h1 className="br-dashboard-feature-title">{title}</h1>
          <p className="br-dashbaord-feature-subtitle">{subtitle}</p>
        </div>
        <div className="br-dashbaord-feature-image">{image}</div>
      </div>
    </div>
  );
};

export default DashboardFeature;
