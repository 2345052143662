import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Form, Input, Select } from 'antd';
import classnames from 'classnames';

import { HOST_NAME } from 'constants/helpers';
import { INDUSTRIES, REPORTS_LIST } from 'constants/reports';
import {
  getLocaleFromLocalStorage,
  isDashboardArabic
} from 'utils/intl-wrapper';
import { cleanEmptyString, pathLang } from 'utils/helpers';
import { emailRule, phoneNumberRule, requiredRule } from 'utils/forms';
import { submitReportForms } from 'services/reports';

import BRButton from 'components/BRButton/BRButton';
import BRFormInputs from 'components/BRFormInputs/BRFormInputs';
import SuccessCard from '../components/SuccessCard/SuccessCard';
import ProgressiveImg from 'components/ProgressiveImage/ProgressiveImage';
import { notify } from 'components/Notify/Notify';

import { ReactComponent as ArrowLeft } from 'assets/icons/Arrow-left.svg';

import './EgyptEcommerceState.less';

const EgyptEcommerceState = () => {
  const intl = useIntl();
  const language = pathLang || getLocaleFromLocalStorage();

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const formRef = useRef();

  const onFormChange = (_, allValues) => {
    setFormValues(cleanEmptyString(allValues));
  };

  const isSubmitButtonDisabled = () => {
    if (formValues) {
      const requiredKeys = [
        'name',
        'email',
        'companyName',
        'jobTitle',
        'industry'
      ];
      return !requiredKeys.every((key) => formValues.hasOwnProperty(key));
    }
    return true;
  };

  const onFinish = async (values) => {
    try {
      setIsLoading(true);
      await submitReportForms(values);
      setIsFormSubmitted(true);
    } catch (error) {
      setIsFormSubmitted(false);
      notify({ msg: error.message });
    }
    setIsLoading(false);
  };

  return (
    <div>
      <a href={`${HOST_NAME}/${language}/insights`}>
        <BRButton
          className="br-report-back-button"
          type="basic"
          prefixIcon={<ArrowLeft />}
          label={intl.formatMessage({
            id: 'bosta_insights.reports.back_to_all_reports'
          })}
        />
      </a>
      <div
        className={classnames('br-egypt-ecommerce-state-report__container', {
          'br-egypt-ecommerce-state-report__container__success': isFormSubmitted
        })}
      >
        <div className="br-egypt-ecommerce-state-report__text-section">
          <span className="br-report__card__image">
            <ProgressiveImg
              src={
                isDashboardArabic()
                  ? REPORTS_LIST[0].reportThumbnailAr
                  : REPORTS_LIST[0].reportThumbnail
              }
              alt="insight image"
            />
          </span>
          <h1 className="header-title">
            {intl.formatMessage({
              id: 'bosta_insights.reports.egypt_ecommerce_state_report.title'
            })}
          </h1>
          <span className="text-gray subtitle-lg">
            {intl.formatMessage({
              id: 'bosta_insights.reports.egypt_ecommerce_state_report.subtitle'
            })}
          </span>
          <br />
          <span className="text-gray subtitle-lg">
            {intl.formatMessage({
              id: 'bosta_insights.reports.egypt_ecommerce_state_report.description'
            })}
          </span>
          <div className="text-gray display-sm br-egypt-ecommerce-state-report__report-conetnt-section">
            {intl.formatMessage({
              id: 'bosta_insights.reports.egypt_ecommerce_state_report.report_content_section.title'
            })}
          </div>
          <div className="text-gray subtitle-lg">
            <ul>
              {[...Array(6)].map((_, index) => (
                <li>
                  {intl.formatMessage({
                    id: `bosta_insights.reports.egypt_ecommerce_state_report.report_content_section.description_list.step_${
                      index + 1
                    }`
                  })}
                </li>
              ))}
            </ul>
          </div>
        </div>
        {isFormSubmitted ? (
          <SuccessCard {...formValues} />
        ) : (
          <div className="br-egypt-ecommerce-state-report__form-section">
            <div className="table-title">
              {intl.formatMessage({
                id: 'bosta_insights.reports.egypt_ecommerce_state_report.form.title'
              })}
            </div>
            <Form
              onFinish={onFinish}
              onValuesChange={onFormChange}
              ref={formRef}
            >
              <Form.Item
                label={intl.formatMessage({
                  id: 'bosta_insights.reports.egypt_ecommerce_state_report.form.name'
                })}
                name="name"
                rules={[{ required: true }]}
              >
                <Input
                  placeholder={intl.formatMessage({
                    id: 'bosta_insights.reports.egypt_ecommerce_state_report.form.name_placeholder'
                  })}
                  disabled={isLoading}
                />
              </Form.Item>
              <Form.Item
                label={intl.formatMessage({
                  id: 'bosta_insights.reports.egypt_ecommerce_state_report.form.email'
                })}
                name="email"
                validateTrigger="onBlur"
                rules={[
                  requiredRule(
                    intl.formatMessage(
                      {
                        id: 'form.required_custom'
                      },
                      {
                        label: intl.formatMessage({
                          id: 'contact_sales.form_validation.email'
                        })
                      }
                    )
                  ),
                  emailRule(
                    intl.formatMessage({
                      id: 'form.email_not_valid'
                    })
                  )
                ]}
              >
                <Input
                  placeholder={intl.formatMessage({
                    id: 'bosta_insights.reports.egypt_ecommerce_state_report.form.email_placeholder'
                  })}
                  disabled={isLoading}
                />
              </Form.Item>
              <BRFormInputs
                name="phone"
                title={intl.formatMessage({
                  id: 'bosta_insights.reports.egypt_ecommerce_state_report.form.phone_number'
                })}
                placeholder={intl.formatMessage({
                  id: 'bosta_insights.reports.egypt_ecommerce_state_report.form.phone_number_placeholder'
                })}
                optional
                rules={[
                  phoneNumberRule(
                    intl.formatMessage({
                      id: 'form.phone_not_valid'
                    })
                  )
                ]}
                formRef={formRef}
                disabled={isLoading}
              />
              <BRFormInputs
                label={intl.formatMessage({
                  id: 'bosta_insights.reports.egypt_ecommerce_state_report.form.company_name'
                })}
                name="companyName"
                placeholder={intl.formatMessage({
                  id: 'bosta_insights.reports.egypt_ecommerce_state_report.form.company_name_placeholder'
                })}
                rules={[{ required: true }]}
                formRef={formRef}
                disabled={isLoading}
              />
              <BRFormInputs
                title={intl.formatMessage({
                  id: 'bosta_insights.reports.egypt_ecommerce_state_report.form.company_website'
                })}
                name="companyWebsite"
                placeholder={intl.formatMessage({
                  id: 'bosta_insights.reports.egypt_ecommerce_state_report.form.company_website_placeholder'
                })}
                optional
                formRef={formRef}
                disabled={isLoading}
              />
              <BRFormInputs
                label={intl.formatMessage({
                  id: 'bosta_insights.reports.egypt_ecommerce_state_report.form.job_title'
                })}
                placeholder={intl.formatMessage({
                  id: 'bosta_insights.reports.egypt_ecommerce_state_report.form.job_title_placeholder'
                })}
                name="jobTitle"
                rules={[{ required: true }]}
                formRef={formRef}
                disabled={isLoading}
              />
              <Form.Item
                label={intl.formatMessage({
                  id: 'bosta_insights.reports.egypt_ecommerce_state_report.form.company_industry'
                })}
                name="industry"
                rules={[{ required: true }]}
              >
                <Select
                  options={INDUSTRIES}
                  placeholder={intl.formatMessage({
                    id: 'bosta_insights.reports.egypt_ecommerce_state_report.form.company_industry_placeholder'
                  })}
                  disabled={isLoading}
                />
              </Form.Item>
              <BRButton
                block
                type="danger"
                className="button-lg"
                disabled={isSubmitButtonDisabled()}
                loading={isLoading}
                htmlType="submit"
                label={intl.formatMessage({
                  id: 'bosta_insights.reports.egypt_ecommerce_state_report.form.download_report'
                })}
              />
            </Form>
          </div>
        )}
      </div>
    </div>
  );
};

export default EgyptEcommerceState;
