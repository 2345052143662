import React, { useEffect, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';

import {
  DELIVERY_SERVICES,
  SUCCESS_CARDS,
  FLEXIBLE_PRICES,
  SHIPPING_PARTNERS
} from 'constants/home';
import { renderSVGs } from 'utils/animations';
import { intersectionObserver } from 'utils/animations';
import { isURLHavingKSA, pathLang } from 'utils/helpers';
import { getLocaleFromLocalStorage } from 'utils/intl-wrapper';

import BRHero from 'components/BRHero/BRHero';
import Grid from 'components/Grid/Grid';
import SignUpAndStart from 'components/SignUpAndStart/SignUpAndStart';
import TrustedCompanies from 'components/TrustedCompanies/TrustedCompanies';

import { ReactComponent as LocationIcon } from 'assets/icons/HomeLocationIcon.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/HomeProfileIcon.svg';
import { ReactComponent as WalletIcon } from 'assets/icons/HomeWalletIcon.svg';
import { ReactComponent as ShieldIcon } from 'assets/icons/HomeShieldIcon.svg';
import { ReactComponent as NightIcon } from 'assets/icons/HomeNightIcon.svg';
import { ReactComponent as CashCollectionIcon } from 'assets/icons/HomeCashCollectionIcon.svg';
import { ReactComponent as PlaneIcon } from 'assets/icons/HomePlaneIcon.svg';
import { ReactComponent as SuitcaseIcon } from 'assets/icons/HomeSuitcaseIcon.svg';
import { ReactComponent as PartnersImage } from 'assets/images/HomePartnersImage.svg';
import { ReactComponent as Vector1 } from 'assets/images/home_hero_vector_1.svg';
import { ReactComponent as Vector2 } from 'assets/images/cloud1.svg';
import { ReactComponent as Vector4 } from 'assets/images/home_hero_vector_4.svg';
import { ReactComponent as Vector5 } from 'assets/images/home_hero_vector_5.svg';
import { ReactComponent as ServicesImage1 } from 'assets/images/HomeServicesImage-1.svg';
import { ReactComponent as ServicesImage2 } from 'assets/images/crest.svg';
import { ReactComponent as ServicesImage3 } from 'assets/images/HomeServicesImage-3.svg';
import { ReactComponent as ServicesImage4 } from 'assets/images/HomeServicesImage-4.svg';
import { ReactComponent as ServicesImage5 } from 'assets/images/HomeServicesImage-5.svg';
import { ReactComponent as ServicesImage6 } from 'assets/images/HomeServicesImage-6.svg';
import { ReactComponent as ServicesImage7 } from 'assets/images/HomeServicesImage-7.svg';
import { ReactComponent as ServicesImage8 } from 'assets/images/HomeServicesImage-8.svg';
import { ReactComponent as ServicesImage9 } from 'assets/images/HomeServicesImage-9.svg';
import { ReactComponent as ServicesImage10 } from 'assets/images/HomeServicesImage-10.svg';
import { ReactComponent as FlyersIcon } from 'assets/icons/shop-icon.svg';
import MotorcycleGIF from 'assets/images/motorcycle.gif';

import './Home.less';

const Home = ({ intl, history }) => {
  const DELIVERY_SERVICES_ICONS = [
    NightIcon,
    WalletIcon,
    CashCollectionIcon,
    LocationIcon,
    ShieldIcon,
    PlaneIcon,
    FlyersIcon
  ];

  const SHIPPING_PARTNERS_ICONS = [ProfileIcon, SuitcaseIcon];

  const SERVICES_IMAGES = [
    { Vector: ServicesImage1, from: '400%', fromSide: true },
    { Vector: ServicesImage2, from: '250%', fromSide: true },
    { Vector: ServicesImage3, from: '200%', fromSide: true },
    { Vector: ServicesImage4, from: '200%', fromSide: true },
    { Vector: ServicesImage5, from: '400%', fromSide: true },
    { Vector: ServicesImage6, from: '100%', fromSide: true },
    { Vector: ServicesImage7, from: '-90%', fromSide: true },
    {
      Vector: ServicesImage8,
      from: '100%',
      fromBottom: true,
      delayedAnimation: true,
      isHidden: true,
      startDelay: '1s',
      opacityFrom: 1
    },
    {
      Vector: ServicesImage9,
      from: '100%',
      fromBottom: true,
      delayedAnimation: true,
      isHidden: true,
      startDelay: '1s',
      opacityFrom: 1
    },
    {
      Vector: ServicesImage10,
      from: '100%',
      fromBottom: true,
      delayedAnimation: true,
      isHidden: true,
      startDelay: '1s',
      opacityFrom: 1
    }
  ];

  const observerOptions = { rootMargin: '-25%' };

  const deliveryServicesRef = useRef();
  const successNumbersRef = useRef();
  const pricesSectionRef = useRef();

  const handleDeliveryServicesAnimations = (children, observer) => {
    //adding animation classes on scroll to delivery services elements
    children.forEach((child) => {
      if (child.classList.contains('from-bottom-parent')) {
        child.classList.add('slide-from-bottom');
      } else if (child.classList.contains('from-bottom')) {
        child.firstChild.classList.add('slide-from-bottom');
      } else if (child.classList.contains('from-side')) {
        child.firstChild.classList.add('slide-from-side');
      }
      if (child.classList.contains('delayed-animation')) {
        setTimeout(() => {
          child.firstChild.classList.add('isVisible');
        }, 1100);
      }
    });
    observer.unobserve(deliveryServicesRef?.current);
  };

  const handleSuccessNumbersAnimations = (firstChild, lastChild, observer) => {
    //adding animation classes on scroll to success numbers elements
    firstChild.classList.add('slide-from-bottom');
    lastChild.childNodes.forEach((child) => {
      child.classList.add('slide-from-bottom');
    });
    observer.unobserve(successNumbersRef?.current);
  };

  const handleHomePricesAnimations = (firstChild, lastChild, observer) => {
    //adding animation classes on scroll to home prices elements
    firstChild.classList.add('r-hex-slide-from-bottom');
    lastChild.classList.add('slide-from-bottom');
    observer.unobserve(pricesSectionRef?.current);
  };

  const observerCallback = (entries, observer) => {
    entries.forEach((entry) => {
      let classList = entry.target.classList;
      if (entry.isIntersecting) {
        classList.add('isVisible');
        classList.remove('isHidden');
        if (classList.contains('br-home__delivery-services')) {
          handleDeliveryServicesAnimations(entry.target?.childNodes, observer);
        } else if (classList.contains('br-home__success-numbers')) {
          handleSuccessNumbersAnimations(
            entry.target?.firstChild,
            entry.target?.lastChild,
            observer
          );
        } else if (classList.contains('br-home__prices')) {
          handleHomePricesAnimations(
            entry.target?.firstChild,
            entry.target?.lastChild,
            observer
          );
        }
      }
    });
  };

  useEffect(() => {
    intersectionObserver(observerCallback, observerOptions, [
      deliveryServicesRef?.current,
      successNumbersRef?.current,
      pricesSectionRef?.current
    ]);
  }, []);

  return (
    <div
      className={classnames('br-home', {
        'br-home-ksa': isURLHavingKSA
      })}
    >
      <>
        <BRHero
          className="br-home__header"
          title={intl.formatMessage({ id: 'home.header.title' })}
          subtitle={intl.formatMessage({ id: 'home.header.subtitle' })}
          buttonText={intl.formatMessage({ id: 'home.header.button' })}
          onClickButton={() => history.push('/signup')}
          vectors={[
            <Vector1
              homepageVector
              className="slide-from-side"
              style={{
                '--from': '70%',
                '--to': 0,
                '--animationDelay': '2s'
              }}
            />,
            <Vector2
              homepageVector
              className="slide-from-side"
              style={{
                '--from': '-50%',
                '--to': 0,
                '--animationDelay': '2s'
              }}
            />,
            <img
              homepageVector
              src={MotorcycleGIF}
              alt=""
              className="slide-from-bottom"
              style={{
                '--from': '90%',
                '--to': 0,
                '--animationDelay': '1.5s'
              }}
            />,
            <Vector4
              homepageVector
              className="slide-from-side"
              style={{
                '--from': '90%',
                '--to': 0,
                '--animationDelay': '2s'
              }}
            />,
            <Vector5
              homepageVector
              className="slide-from-bottom"
              style={{
                '--from': '25%',
                '--to': 0,
                '--animationDelay': '2s',
                '--opacityFrom': '1'
              }}
            />
          ]}
        />
      </>
      <div
        className="br-home__delivery-services isHidden"
        ref={deliveryServicesRef}
      >
        <div className="br-home__delivery-services__services from-bottom-parent">
          <Grid
            gridTitle={intl.formatMessage({
              id: 'home.delivery_services.title'
            })}
            items={DELIVERY_SERVICES}
            icons={DELIVERY_SERVICES_ICONS}
          />
        </div>
        {renderSVGs(SERVICES_IMAGES, 'br-home__delivery-services-right-svg')}
      </div>
      <div
        className="br-home__success-numbers isHidden"
        ref={successNumbersRef}
      >
        <h2 className="grid-title" style={{ '--animationDelay': '2s' }}>
          {intl.formatMessage({
            id: 'home.success.title'
          })}
        </h2>
        <div className="br-home__success-numbers__cards">
          {SUCCESS_CARDS.map(({ title, value }, i) => (
            <div
              key={i}
              style={{
                '--from': '100%',
                '--to': 0,
                '--animationDelay': '1.5s'
              }}
            >
              <span>{value}</span>
              <span>{title}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="br-home__prices isHidden" ref={pricesSectionRef}>
        <div className="r-hex" style={{ '--from': '15%', '--to': '-140px' }}>
          <div className="r-hex-inner">
            <div className="r-hex-inner-2"></div>
          </div>
        </div>
        <div className="br-home__prices-text">
          <Grid
            gridTitle={intl.formatMessage({
              id: 'home.prices.title'
            })}
            items={FLEXIBLE_PRICES}
          />
        </div>
      </div>
      <div className="br-home__prices-partners-container">
        <span className="br-home__prices-partners__img">
          <PartnersImage />
        </span>
        <div className="br-home__prices-partners__partners">
          <Grid
            gridTitle={intl.formatMessage({
              id: 'home.partner.title'
            })}
            items={SHIPPING_PARTNERS}
            icons={SHIPPING_PARTNERS_ICONS}
          />
        </div>
        <TrustedCompanies />
        <SignUpAndStart />
      </div>
    </div>
  );
};

export default withRouter(injectIntl(Home));
