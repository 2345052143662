import { useIntl } from 'react-intl';

import { notify } from 'components/Notify/Notify';
import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as SuccessIcon } from 'assets/icons/SuccessIcon.svg';
import { ReactComponent as CopyLink } from 'assets/icons/CopyLink.svg';

import './SuccessCard.less';

const SuccessCard = ({ email }) => {
  const intl = useIntl();

  const handleCopyLink = () => {
    try {
      navigator.clipboard.writeText(window.location.href);
      notify({
        msg: intl.formatMessage({ id: 'common.url_copied' }),
        type: 'success'
      });
    } catch (error) {
      notify({ msg: error.message });
    }
  };

  return (
    <div className="br-success-card">
      <SuccessIcon />
      <div className="display-xs">
        {intl.formatMessage(
          {
            id: 'bosta_insights.reports.egypt_ecommerce_state_report.success_card.title'
          },
          { email }
        )}
      </div>
      <div className="text-gray subtitle-md">
        {intl.formatMessage({
          id: 'bosta_insights.reports.egypt_ecommerce_state_report.success_card.subtitle'
        })}
      </div>
      <BRButton
        label={intl.formatMessage({
          id: 'bosta_insights.reports.egypt_ecommerce_state_report.success_card.share'
        })}
        type="basic"
        onClick={handleCopyLink}
        suffixIcon={
          <span className="br-success-card__copy-link">
            <CopyLink />{' '}
            {intl.formatMessage({
              id: 'bosta_insights.reports.egypt_ecommerce_state_report.success_card.copy_link'
            })}
          </span>
        }
        block
      />
    </div>
  );
};

export default SuccessCard;
