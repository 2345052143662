import { injectIntl } from 'react-intl';
import { Carousel } from 'antd';

import { mediaHook } from 'customHooks';
import { ABOUT_CAROUSEL_DATA } from 'constants/about';

import CarouselCard from 'components/CarouselCard/CarouselCard';

import './News.less';

const News = ({ intl, mobileScreenSizes }) => {
  const { isLargeMobileScreen } = mobileScreenSizes;

  return (
    <section className="br__news__section">
      <div className="br__news__section__container">
        <div className="br__news__content">
          <h2 className="br__news__content__title">
            {intl.formatMessage({ id: 'about.news.title' })}
          </h2>
          <div className="br__news__content__carousel">
            <Carousel
              slidesToShow={3}
              autoplay
              swipeToSlide
              draggable
              dots={false}
              centerMode
              centerPadding={isLargeMobileScreen ? '50px ' : '120px'}
              responsive={[
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]}
            >
              {ABOUT_CAROUSEL_DATA.map(
                ({ link }, idx) => (
                  <CarouselCard linkToPreview={link} key={idx} />
                )
              )}
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default injectIntl(mediaHook(News));
