import React, { useRef } from 'react';
import { injectIntl } from 'react-intl';
import Icon from '@ant-design/icons';

import Navbar from 'components/Navbar/Navbar';
import SignupContainer from './components/SignupContainer/SignupContainer';

import BostaLogo from 'assets/images/signup-bosta-logo.svg';
import ArabicMan from 'assets/images/signup-arabic-man.png';
import SaudiMan from 'assets/images/signup-saudi-man.png';
import { ReactComponent as Smooth } from 'assets/icons/smooth.svg';
import { ReactComponent as Easy } from 'assets/icons/easy.svg';
import { ReactComponent as Dollar } from 'assets/icons/dollar.svg';
import { ReactComponent as Clock } from 'assets/icons/clock.svg';
import Vectors from 'assets/images/signup-vectors.svg';
import WaitingVectors from 'assets/images/waiting-vectors.svg';
import BostaVectors from 'assets/images/bosta-offers-vectors.png';

import './NewSignup.less';

const NewSignup = ({
  formRef,
  usedPhoneNumber,
  setUsedPhoneNumber,
  country,
  setCountry,
  showNewContainer
}) => {
  const elementRef = useRef();

  return (
    <>
      <Navbar saudiSignupPage />
      <div className="br-new-signup">
        <div className="br-new-signup__form-container" ref={elementRef}>
          <h1 className="signup-xl">تسجيل الاشتراك في شركة بوسطة السعودية</h1>
          <SignupContainer
            formRef={formRef}
            usedPhoneNumber={usedPhoneNumber}
            setUsedPhoneNumber={setUsedPhoneNumber}
            country={country}
            setCountry={setCountry}
            showNewContainer={showNewContainer}
          />
          <img alt="" src={BostaLogo} />
        </div>
        <div className="br-new-signup__bosta-offers">
          <span className="signup-title br-new-signup__bosta-offers-header">
            ماذا توفر لك بوسطة؟
          </span>
          <div className="br-new-signup__bosta-offers-container">
            <div className="br-new-signup__bosta-offers-content">
              <div>
                <div className="br-new-signup__bosta-offers-title signup-header">
                  <span>حلول</span>
                  <span className="br-new-signup__red">متكاملة</span>
                </div>
                <span>نقدم لك جميع الخدمات اللوجستية اللي تحتاجها:</span>
                <span>
                  شحن وتوصيل سريع ثاني يوم، استرجاع واستبدال الشحنات، تحصيل
                  الأموال.
                </span>
              </div>
              <div>
                <div className="br-new-signup__bosta-offers-title signup-header">
                  <span>تجربة شحن</span>
                  <span className="br-new-signup__red">ذكية</span>
                </div>
                <span>
                  اشحن، تتبع شحناتك، وحصّل أموالك بضغطة زر من{' '}
                  <span className="body-medium">تطبيق بوسطة</span>.
                </span>
                <span>
                  امسح الكود، اربطه بطلب عميلك، واشحنه من خلال الفلاير الذكي و
                  بدون الحاجة لطباعة بوليصة.
                </span>
              </div>
            </div>
            <img
              alt=""
              src={ArabicMan}
              className="br-new-signup__offers-image"
            />
            <img
              src={BostaVectors}
              alt=""
              className="br-new-signup__offers-vectors"
            />
          </div>
        </div>
        <div className="br-new-signup__fast-easy">
          <span className="br-new-signup__red signup-header-2">
            السرعة والسهولة
          </span>
          <div className="br-new-signup__fast-easy-cards">
            <div className="br-new-signup__fast-easy-card">
              <div>
                <Icon component={Smooth} className="ant-icon-lg" />
                <span className="signup-md">السلاسة</span>
              </div>
              <span className="signup-sm">
                سهولة الربط بتطبيق بوسطة وتوفر لك تجربة استخدام سلسة
              </span>
            </div>
            <div className="br-new-signup__fast-easy-card">
              <div>
                <Icon component={Easy} className="ant-icon-lg" />
                <span className="signup-md">السهولة</span>
              </div>
              <span className="signup-sm">
                عملية تحضير الطلب للشحن أصبحت أسهل
              </span>
            </div>
            <div className="br-new-signup__fast-easy-card">
              <div>
                <Icon component={Clock} className="ant-icon-lg" />
                <span className="signup-md">توفير الوقت</span>
              </div>
              <span className="signup-sm">لن تحتاج لطباعة بوليصة الطلب!</span>
            </div>
            <div className="br-new-signup__fast-easy-card">
              <div>
                <Icon component={Dollar} className="ant-icon-lg" />
                <span className="signup-md">تحصيل</span>
              </div>
              <span className="signup-sm">
                سيتم تحصيل المبالغ في أقل من 48 ساعة
              </span>
            </div>
          </div>
          <img src={Vectors} alt="" />
        </div>
        <div className="br-new-signup__eish-waiting">
          <img
            alt=""
            src={SaudiMan}
            className="br-new-signup__eish-waiting-saudi"
          />
          <img
            alt=""
            src={BostaLogo}
            className="br-new-signup__eish-waiting-logo"
          />
          <img
            src={WaitingVectors}
            alt=""
            className="br-new-signup__eish-waiting-vectors"
          />
          <div>
            <span className="br-new-signup__red signup-h1-2">ايش تنتظر؟</span>
            <span className="signup-h2">
              سجل الآن وسيتواصل معك أحد أفراد فريقنا في أقرب وقت!
            </span>
            <span className="signup-text br-new-signup__teal">
              جاهزين لمساعدتك والإجابة على جميع أسئلتك في أي وقت
            </span>
            <a
              className="br-navbar__link-sign-up navbar-link"
              onClick={() => {
                elementRef.current.scrollIntoView({ behavior: 'smooth' });
              }}
            >
              سجل الآن
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
export default injectIntl(NewSignup);
