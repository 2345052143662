import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import arEG from 'antd/lib/locale/ar_EG';
import dayjs from 'dayjs';
import 'dayjs/locale/ar';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import isTomorrow from 'dayjs/plugin/isTomorrow';

import { getLocaleFromLocalStorage } from 'utils/intl-wrapper';
import { LOCALE } from 'constants/intl-wrapper';
import { AuthContextProvider } from 'context/AuthContext';

import App from 'App';
import IntlWrapper, { intl } from 'components/IntlWrapper/IntlWrapper';
import * as serviceWorker from 'serviceWorker';
import UpdateLocale from 'dayjs/plugin/updateLocale';
import SegmentInit from 'SegmentInit';

dayjs.extend(UpdateLocale);
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(isTomorrow);

const validateMessages = {
  required: intl.formatMessage({ id: 'form.required' }),
  string: {
    max: intl.formatMessage({ id: 'form.maxLen' })
  }
};

dayjs.updateLocale('ar', {
  meridiem: (hour) => {
    return hour > 12 ? 'م' : 'ص';
  }
});
const locale = getLocaleFromLocalStorage();
const getLocale = () => {
  dayjs.locale(locale);
  if ([LOCALE.AR, LOCALE.AR_SA].includes(locale)) {
    return arEG;
  } else {
    return enUS;
  }
};

ReactDOM.render(
  <React.StrictMode>
    <IntlWrapper>
      <AuthContextProvider>
        <ConfigProvider
          locale={getLocale()}
          form={{
            validateMessages,
            scrollToFirstError: true
          }}
          getPopupContainer={(trigger) =>
            trigger?.parentElement || document.body
          }
        >
          {SegmentInit()}

          <App />
        </ConfigProvider>
      </AuthContextProvider>
    </IntlWrapper>
  </React.StrictMode>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
