import React from 'react';

import { ReactComponent as StarIcon } from 'assets/icons/Star-Icon.svg';

import './ClientCard.less';

const ClientCard = ({ img, name, position, rating, comment }) => {
  const renderRate = () => {
    let arr = [];
    for (let i = 0; i < rating; i++) {
      arr.push(<StarIcon key={i} />);
    }
    return arr;
  };

  return (
    <div className="br-capital__testimonial-client-card">
      <div className="br-capital__client-info">
        <img alt="client-image" src={img} />
        <div>
          <p className="client-business">{name}</p>
          <p className="client-name"> {position}</p>
        </div>
      </div>
      <div className="br-capital__client-opinion">
        <div className="br-capital__tetsimonials-rate"> {renderRate()}</div>
        <p>{comment}</p>
      </div>
    </div>
  );
};

export default ClientCard;
