import React from 'react';

import StartSection from './StartSection/StartSection';
import SmartShipping from './SmartShipping/SmartShipping';
import ContactSalesHero from './ContactSalesHero/ContactSalesHero';

import './ContactSales.less';

const ContactSales = () => {

  return (
    <div>
      <StartSection />
      <SmartShipping />
      <ContactSalesHero />
    </div>
  );
};

export default ContactSales;
