import Icon from '@ant-design/icons';
import { useIntl } from 'react-intl';

import { INTERNATIONAL_SHIPPING_HEADER_FEATURES_LABEL } from 'constants/international-shipping';
import { COUNTRIES_ENGLISH_LOCALE } from 'constants/intl-wrapper';
import { getInternationalShippingLandingImage } from 'constants/countries/countries-mapping';

import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as CheckIcon } from 'assets/icons/Check.svg';

import './InternationalShippingHeader.less';

const InternationalShippingHeader = ({ formSectionRef }) => {
  const intl = useIntl();

  const { EN: EnglishImage, AR: ArabicImage } =
    getInternationalShippingLandingImage();

  const handleSignUpClick = () => {
    if (formSectionRef.current) {
      formSectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  };

  return (
    <div className="br-international-shipping__header-container">
      <div className="br-international-shipping__header-content">
        <div className="display-xl">
          {intl.formatMessage({ id: 'international_shipping.header.title' })}
        </div>
        <div className="display-md">
          {intl.formatMessage({ id: 'international_shipping.header.subtitle' })}
        </div>
        <div className="br-internatioanl-shipping__header-features">
          {INTERNATIONAL_SHIPPING_HEADER_FEATURES_LABEL.map((label, index) => (
            <div key={index}>
              <Icon
                component={CheckIcon}
                className="br-international-shipping__header-check__icon"
              />
              <span>
                {intl.formatMessage({
                  id: `international_shipping.header.features.${label}`
                })}
              </span>
            </div>
          ))}
        </div>
        <BRButton
          type="danger"
          label={intl.formatMessage({
            id: 'international_shipping.sign_up_form.sign_up'
          })}
          onClick={handleSignUpClick}
        />
      </div>
      <img
        src={
          COUNTRIES_ENGLISH_LOCALE.includes(intl.locale)
            ? EnglishImage
            : ArabicImage
        }
        alt="border-link-icon"
      />
    </div>
  );
};

export default InternationalShippingHeader;
