import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

import BRHero from 'components/BRHero/BRHero';

import { ReactComponent as Vector1 } from 'assets/images/about_hero_vector_1.svg';
import { ReactComponent as Vector2 } from 'assets/images/dashboard-hero-vector-1.svg';

import './DashboardHero.less';

const DashboardHero = ({ intl, history }) => {
  return (
    <BRHero
      className="br-dashboard-hero"
      title={intl.formatMessage({ id: 'dashboard.header.title' })}
      subtitle={intl.formatMessage({ id: 'dashboard.header.subtitle' })}
      buttonText={intl.formatMessage({ id: 'dashboard.header.button' })}
      onClickButton={() => history.push('/signup')}
      vectors={[<Vector1 />, <Vector2 />]}
    />
  );
};

export default withRouter(injectIntl(DashboardHero));
