import { injectIntl } from 'react-intl';

import { COUNTRIES_ENGLISH_LOCALE } from 'constants/intl-wrapper';

import ArabicManAR from 'assets/images/signup-arabic-man.png';
import ArabicManEN from 'assets/images/signup-arabic-man-en.png';
import BostaLogo from 'assets/images/signup-bosta-logo.svg';

import './StartSection.less';

const StartSection = ({ intl }) => {
  const RenderListItem = ({ name }) => {
    return (
      <li>
        {intl.formatMessage({
          id: `contact_sales.services_list.${name}`
        })}
      </li>
    );
  };

  return (
    <div className="br-start-section">
      <div className="br-start-section-content">
        <h1 className="br-start-section__title">
          {intl.formatMessage({ id: 'contact_sales.title' })} {' '}
          {intl.formatMessage({ id: 'contact_sales.sub_title' })}
        </h1>
        <ul className="br-start-section__pros">
          <RenderListItem name="ship" />
          <RenderListItem name="service" />
          <RenderListItem name="smart" />
          <RenderListItem name="cash" />
          <RenderListItem name="timing" />
        </ul>
        <a className="br-navbar__link-sign-up navbar-link" href="#contact-form">
          {intl.formatMessage({ id: 'contact_sales.button_text' })}
        </a>
      </div>
      <img
        alt="arabicMan"
        src={
          COUNTRIES_ENGLISH_LOCALE.includes(intl.locale)
            ? ArabicManEN
            : ArabicManAR
        }
        className="br-start-section__manImage"
      />
      <img
        src={BostaLogo}
        alt="bosta-logo"
        className="br-start-section__bosta-logo"
      />
    </div>
  );
};

export default injectIntl(StartSection);
