const PerksCarouselMobile = ({ perks, icons, currentPage }) => {
  const getCarouselData = (currentPage) => {
    const data = [];

    perks.map((values) => data.push(...values));
    return data[currentPage];
  };

  const getIcons = (currentPage) => {
    const perksIcons = [];
    icons.map((icon) => perksIcons.push(...icon));
    return perksIcons[currentPage];
  };

  const { title, subtitle } = getCarouselData(currentPage);
  const icon = getIcons(currentPage);

  return (
    <>
      <div className="br-careers__perks-perk">
        {icon}
        <span className="br-careers__perks-perk-title">{title}</span>
        <span className="br-careers__perks-perk-subtitle heading">
          {subtitle}
        </span>
      </div>
    </>
  );
};

export default PerksCarouselMobile;
