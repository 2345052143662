export const RTO = 'RTO';

export const DELIVERY_STATES_CODES = {
  CREATED: 10,
  PICKUP_ORDER_CREATED: 11,
  ROUTE_ASSIGNED: 20,
  PICKED_UP_FROM_BUSINESS: 21,
  OUT_FOR_PICKUP: 22,
  PICKED_UP_FROM_CONSIGNEE: 23,
  RECEIVED_AT_WAREHOUSE: 24,
  IN_TRANSIT_BETWEEN_HUBS: 30,
  OUT_FOR_COLLECTION: 40,
  OUT_FOR_DELIVERY: 41,
  PENDING_CUSTOMER_SIGNATURE: 42,
  SIGN_AND_RETURN_DEBRIEFED: 43,
  DELIVERED: 45,
  RETURNED_TO_BUSINESS: 46,
  EXCEPTION: 47,
  TERMINATED: 48,
  UNCOVERED_CITY: 49,
  LOST: 100,
  DAMAGED: 101,
  ARCHIVED: 104,
  // INTERNATIONAL STATES:
  INTERNATIONAL_RECEIVED_AT_DESTINATION: 24.1,
  INTERNATIONAL_IN_TRANSIT_DESTINATION: 30.1,
  INTERNATIONAL_TRANSFERRED: 26,
  INTERNATIONAL_DELVERED: 60
};

export const COMMON_CANCELLATION_STATES = [
  DELIVERY_STATES_CODES.TERMINATED,
  DELIVERY_STATES_CODES.LOST,
  DELIVERY_STATES_CODES.DAMAGED,
  DELIVERY_STATES_CODES.ARCHIVED,
  DELIVERY_STATES_CODES.UNCOVERED_CITY
];
