import React, { useState } from 'react';
import { Select } from 'antd';
import { mediaHook } from 'customHooks';
import { injectIntl } from 'react-intl';

import { sendSegment } from 'utils/segment';

import './DeliveryTypesSection.less';

const DeliveryTypesSection = ({ setType, mobileScreenSizes, intl }) => {
  const { isLargeMobileScreen } = mobileScreenSizes;

  const TYPES_INFO = {
    SEND: {
      label: intl.formatMessage({
        id: 'orders.order_types.forward'
      }),
      sub: intl.formatMessage({
        id: 'pricing_plans_calculator.modal.delivery_types_section.send_sub'
      })
    },
    EXCHANGE: {
      label: intl.formatMessage({
        id: 'orders.order_types.exchange'
      }),
      sub: intl.formatMessage({
        id: 'pricing_plans_calculator.modal.delivery_types_section.exchange_sub'
      })
    },
    CASH_COLLECTION: {
      label: intl.formatMessage({
        id: 'orders.order_types.cash_collection'
      }),
      sub: intl.formatMessage({
        id: 'pricing_plans_calculator.modal.delivery_types_section.cash_collection_sub'
      })
    },
    CUSTOMER_RETURN_PICKUP: {
      label: intl.formatMessage({
        id: 'orders.order_types.customer_return_pickup'
      }),
      sub: intl.formatMessage({
        id: 'pricing_plans_calculator.modal.delivery_types_section.return_sub'
      })
    },
    RTO: {
      label: intl.formatMessage({
        id: 'orders.status_filter.rto'
      }),
      sub: intl.formatMessage({
        id: 'pricing_plans_calculator.modal.delivery_types_section.rto_sub'
      })
    }
  };
  const TYPES = [
    {
      label: intl.formatMessage({
        id: 'orders.order_types.forward'
      }),
      value: 'SEND',
      sub: intl.formatMessage({
        id: 'pricing_plans_calculator.modal.delivery_types_section.send_sub'
      })
    },
    {
      label: intl.formatMessage({
        id: 'orders.order_types.exchange'
      }),
      value: 'EXCHANGE',
      sub: intl.formatMessage({
        id: 'pricing_plans_calculator.modal.delivery_types_section.exchange_sub'
      })
    },
    {
      label: intl.formatMessage({
        id: 'orders.order_types.customer_return_pickup'
      }),
      value: 'CUSTOMER_RETURN_PICKUP',
      sub: intl.formatMessage({
        id: 'pricing_plans_calculator.modal.delivery_types_section.return_sub'
      })
    },
    {
      label: intl.formatMessage({
        id: 'orders.order_types.cash_collection'
      }),
      value: 'CASH_COLLECTION',
      sub: intl.formatMessage({
        id: 'pricing_plans_calculator.modal.delivery_types_section.cash_collection_sub'
      })
    },
    {
      label: intl.formatMessage({
        id: 'orders.status_filter.rto'
      }),
      value: 'RTO',
      sub: intl.formatMessage({
        id: 'pricing_plans_calculator.modal.delivery_types_section.rto_sub'
      })
    }
  ];
  const [selectedType, setSelectedType] = useState('SEND');

  const handleOnTypeSelect = (value) => {
    setSelectedType(value);
    setType(value);
    sendSegment('Order_Type_Changed');
  };

  return (
    <div className="br-pricing-calculator__delivery-types__container">
      {isLargeMobileScreen ? (
        <div className="br-pricing-calculator_delivery-types__mobile-select__container">
          <span className="body-medium">
            {intl.formatMessage({
              id: 'pricing_plans_calculator.modal.delivery_types_section.select_label'
            })}
          </span>
          <Select
            className="br-pickups__pickup-settings__form__selector"
            placeholder={intl.formatMessage({
              id: `form.select_placeholder`
            })}
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            getPopupContainer={(trigger) => trigger.parentElement}
            autoComplete={'' + Math.random()}
            onChange={(value) => handleOnTypeSelect(value)}
            defaultValue={TYPES[0].value}
          >
            {TYPES.map((type) => (
              <Select.Option key={type.value} value={type.value}>
                {type.label}
              </Select.Option>
            ))}
          </Select>
        </div>
      ) : (
        <div className="br-pricing-calculator__delivery-types-box-container">
          {TYPES.map((type, id) => (
            <div
              className={selectedType === type.value ? 'selected_type' : ''}
              onClick={() => handleOnTypeSelect(type.value)}
              key={id}
            >
              <span className="body">{type.label}</span>
            </div>
          ))}
        </div>
      )}

      <div className="br-pricing-calculator__delivery-type-info-container">
        <span className="body-medium">{TYPES_INFO[selectedType].label}</span>
        <span className="caption">{TYPES_INFO[selectedType].sub}</span>
      </div>
    </div>
  );
};

export default injectIntl(mediaHook(DeliveryTypesSection));
