import messages from 'messages';
import {
  COUNTRIES_ENGLISH_LOCALE,
  LOCALE,
  LOCAL_STORAGE_LOCALE_KEY
} from 'constants/intl-wrapper';

export const flattenMessages = (nestedMessages = messages.en, prefix = '') => {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      Object.assign(messages, { [prefixedKey]: value });
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
};

export function getLocaleFromLocalStorage() {
  return (
    localStorage.getItem(LOCAL_STORAGE_LOCALE_KEY) ||
    COUNTRIES_ENGLISH_LOCALE[0]
  );
}

export function setLocaleInLocalStorage(locale) {
  localStorage.setItem(LOCAL_STORAGE_LOCALE_KEY, locale);
}

export function changeDocumentDirection(locale) {
  if ([LOCALE.AR.toLowerCase(), LOCALE.AR_SA.toLowerCase()].includes(locale)) {
    document.dir = 'rtl';
  } else {
    document.dir = 'ltr';
  }
}

const AR = 'ar';

export const isDashboardArabic = () =>
  [AR, LOCALE.AR.toLowerCase(), LOCALE.AR_SA.toLowerCase()].includes(
    getLocaleFromLocalStorage()?.toLowerCase()
  );

export function formatNumbersBasedOnLanguage(value) {
  const locale = isDashboardArabic() ? 'ar-EG' : LOCALE.EN;
  return new Intl.NumberFormat(locale).format(value);
}
