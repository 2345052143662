import './BRHighlighter.less';

const BRHighlighter = ({
  text = '',
  textToHighlight = '',
  className = '',
  caseSensitive = false,
  Wrapper = 'span',
  customRender
}) => {
  if (!textToHighlight.trim()) {
    return <Wrapper className={className}>{text}</Wrapper>;
  }
  const SPECIAL_CHAR_FOR_REGEX = /([.?*+^$[\]\\(){}|-])/g;
  const escapeRegExp = (str) => str.replace(SPECIAL_CHAR_FOR_REGEX, '\\$1');
  const regex = new RegExp(
    `(${escapeRegExp(textToHighlight)})`,
    caseSensitive ? '' : 'i'
  );

  return (
    <Wrapper className={`br-highlighter ${className}`}>
      {customRender?.(regex) ||
        text
          .split(regex)
          .filter(String)
          .map((part, i) => {
            return regex.test(part) ? <mark key={i}>{part}</mark> : part;
          })}
    </Wrapper>
  );
};

export default BRHighlighter;
